import { SelfTimerInterval } from 'models/SelfTimerInterval';

export type Settings = {
  dailyDurationChangeTo20Count: number;
  favoritesMeditationAutoPlay: boolean;
  favoritesTheoriesAutoPlay: boolean;
  firstMediaStarted: boolean;
  hasPresentedHome: boolean;
  hasSeenPopupWarningSharingFreeMonth: boolean;
  lastDateDisplayedRoadBlockSubscription?: number;
  lastSessionAddDuration: number;
  lastTimerDuration: number;
  lastTimerInterval: SelfTimerInterval;
  packsAutoPlay: Array<string>;
  playbackSpeed: string;
  player: { code: string; courseId: string } | null;
  resumePlayingList: Record<string, number>;
  showAbreviatedMinutesMeditated: boolean;
  unlockedGuestPacks: Array<string>;
};

export const DEFAULT_SETTINGS: Settings = {
  dailyDurationChangeTo20Count: 0,
  favoritesMeditationAutoPlay: false,
  favoritesTheoriesAutoPlay: false,
  firstMediaStarted: false,
  hasPresentedHome: false,
  hasSeenPopupWarningSharingFreeMonth: false,
  lastSessionAddDuration: 0,
  lastTimerDuration: 1,
  lastTimerInterval: SelfTimerInterval.NONE,
  packsAutoPlay: [] as string[],
  playbackSpeed: '1',
  player: null,
  resumePlayingList: {} as Record<string, number>,
  showAbreviatedMinutesMeditated: false,
  unlockedGuestPacks: [] as string[],
};

export const normalizeSettings = (settings: string): Settings => {
  try {
    const parsedSettings = JSON.parse(settings);
    return { ...DEFAULT_SETTINGS, ...parsedSettings };
  } catch (e) {
    return DEFAULT_SETTINGS;
  }
};
