import React, { FC, memo } from 'react';
import { Container, FavoriteButton, Title } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Favorite: FC<Props> = ({ className, courseId }) => {
  const { isFavorite, toggleFavorite } = useConnect(courseId);

  return (
    <Container className={className}>
      <FavoriteButton isFavorite={isFavorite} light onClick={toggleFavorite} />
      <Title>Favorite</Title>
    </Container>
  );
};

export default memo(Favorite);
