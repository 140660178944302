const addZero = (n: number) => (n < 10 ? `0${n}` : n);

function parseTime(
  totalSeconds: number,
): { hours: number; minutes: number; seconds: number } {
  const total = Math.floor(totalSeconds);

  if (total <= 0) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }

  const seconds = total % 60;
  const minutes = Math.floor(total / 60) % 60;
  const hours = Math.floor(total / 3600);

  return { hours, minutes, seconds };
}

export function formatDurationLong(totalSeconds: number): string {
  const { hours, minutes, seconds } = parseTime(Math.round(totalSeconds));

  const hoursWord = `hour${hours === 1 ? '' : 's'}`;
  const minutesWord = `minute${minutes === 1 ? '' : 's'}`;
  const secondsWord = `second${seconds === 1 ? '' : 's'}`;

  const hourString = hours > 0 ? `${hours} ${hoursWord}` : '';
  const minuteString =
    minutes > 0
      ? `${hours > 0 && seconds > 0 ? minutes + 1 : minutes} ${minutesWord}`
      : '';
  const secondString =
    seconds > 0 || (seconds === 0 && !minuteString)
      ? `${seconds} ${secondsWord}`
      : '';

  return (hours > 0 ? [hourString, minuteString] : [minuteString, secondString])
    .filter((v) => v !== '')
    .join(', ');
}

export function formatTime(
  totalSeconds: number,
  showHours: boolean = false,
): string {
  const { hours, minutes, seconds } = parseTime(totalSeconds);

  return `${showHours ? `${addZero(hours)}:` : ''}${addZero(minutes)}:${addZero(
    seconds,
  )}`;
}
