import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { trackEvent, trackMediaEvent } from 'services/analytics';
import { PackType } from 'models/Pack';
import { CoursePermission } from 'models/Course';
import { PlayerAutoplayType } from 'graphql/generated/globalTypes';
import {
  getSettings,
  getCourse,
  updateSettings,
  getProfile,
} from 'graphql/requests';
import useModal from './useModal';
import usePlayer from './usePlayer';

const useCourseActions = () => {
  const { openUnlockContent } = useModal();
  const { openCourse } = usePlayer();
  const { replace } = useHistory();

  const play = useCallback(
    async (courseId: string, autoplayType?: PlayerAutoplayType) => {
      const course = await getCourse(courseId);
      if (!course) return;

      const { isAllContentUnlocked } = await getProfile();
      const { unlockedGuestPacks } = await getSettings();

      if (
        !isAllContentUnlocked &&
        course.permission === CoursePermission.UNLOCKED &&
        course.packType === PackType.GUEST &&
        !unlockedGuestPacks?.includes(course.packId)
      ) {
        course.permission = CoursePermission.LOCKED;
      }

      if (course.permission === CoursePermission.UNLOCKED) {
        await openCourse({
          id: courseId,
          autoplayType:
            autoplayType === PlayerAutoplayType.PACK && !course.packAutoPlay
              ? null
              : autoplayType,
        });
      } else if (course.permission === CoursePermission.FORBIDDEN) {
        trackEvent('Purchase Initiate Checkout', {
          source: course.section,
          trackID: courseId,
          type: 'content',
        });

        replace('/checkout');
      } else if (course.permission === CoursePermission.LOCKED) {
        openUnlockContent({ courseId });

        trackMediaEvent('Media Special Content Challenge', courseId);
      }
    },
    [openCourse, openUnlockContent, replace],
  );

  const toggleAutoPlay = useCallback(
    async (autoplayType: PlayerAutoplayType, packId?: string) => {
      const {
        packsAutoPlay,
        favoritesMeditationAutoPlay,
        favoritesTheoriesAutoPlay,
      } = await getSettings();

      if (packId && autoplayType === PlayerAutoplayType.PACK) {
        const isPackAutoPlayEnabled = packsAutoPlay.includes(packId);
        await updateSettings(
          {
            packsAutoPlay: isPackAutoPlayEnabled
              ? packsAutoPlay.filter((v) => v !== packId)
              : [...packsAutoPlay, packId],
          },
          true,
        );
      } else if (autoplayType === PlayerAutoplayType.FAVORITES_PRACTICE) {
        await updateSettings(
          { favoritesMeditationAutoPlay: !favoritesMeditationAutoPlay },
          true,
        );
      } else if (autoplayType === PlayerAutoplayType.FAVORITES_THEORY) {
        await updateSettings(
          { favoritesTheoriesAutoPlay: !favoritesTheoriesAutoPlay },
          true,
        );
      }
    },
    [],
  );

  return { play, toggleAutoPlay };
};

export default useCourseActions;

export type UseCourseActions = ReturnType<typeof useCourseActions>;
