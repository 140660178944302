import notNullable from 'lib/notNullable';

const configVar = (variable: string = '', optIn = true) =>
  variable ? JSON.parse(variable) : !optIn;

export const ApiConfig = {
  API: notNullable(process.env.REACT_APP_API),
  API_SECRET: notNullable(process.env.REACT_APP_API_SECRET),
  STRIPE_KEY: notNullable(process.env.REACT_APP_STRIPE_KEY),
  ANONYMOUS_TOKEN: notNullable(process.env.REACT_APP_ANONYMOUS_TOKEN),
  AMPLITUDE_KEY: notNullable(process.env.REACT_APP_AMPLITUDE_KEY),
  BRAZE_KEY: notNullable(process.env.REACT_APP_BRAZE_KEY),
  GO_LAMPVSCLOUD_ID: notNullable(process.env.REACT_APP_GO_LAMPVSCLOUD_ID),
};

export const AppConfig = {
  appName: 'Waking Up',
  appStoreURL:
    'https://itunes.apple.com/us/app/waking-up-with-sam-harris/id1307736395?mt=8',
  companyName: 'Waking Up LLC',
  playStoreURL:
    'https://play.google.com/store/apps/details?id=org.wakingup.android&hl=en',
  privacyURL: 'https://wakingup.com/privacy-policy',
  supportEmail: 'support@wakingup.com',
  supportURL: 'https://help.wakingup.com',
  termsURL: 'https://wakingup.com/terms-of-service',
  publicAppIconURL: 'https://app.wakingup.com/lightrail-icon.png',
};

export const DevConfig = {
  amplitudeLogging: configVar(process.env.REACT_APP_AMPLITUDE_LOGGING),
  brazeEnablePushNotifications: configVar(
    process.env.REACT_APP_BRAZE_ENABLE_PUSH_NOTIFICATIONS,
  ),
  brazeLogging: configVar(process.env.REACT_APP_BRAZE_LOGGING),
  debugSection: configVar(process.env.REACT_APP_DEBUG_SECTION),
};

// We may deprecate "isTest" & "isProd"
export const isTest = process.env.NODE_ENV === 'test';
export const isProd = process.env.NODE_ENV === 'production';

export const APP_BUILD = process.env.REACT_APP_BUILD || 'development';
export const isDevelopment = APP_BUILD === 'development';
export const isStaging = APP_BUILD === 'staging';
export const isTesting = APP_BUILD === 'testing';
export const isProduction = APP_BUILD === 'production';
export const isLocal = process.env.NODE_ENV !== 'production';
