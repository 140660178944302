import { ApiConfig, AppConfig } from 'config';
import capitalize from 'lib/capitalize';
import formatCents from 'lib/formatCents';
import React, { FC, memo } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { Props } from './types';
import { Button } from './styles';
import useLogic from './logic';

const StripePlan: FC<Props> = ({
  email,
  isPublic,
  isLoading,
  onToken,
  plan,
  short,
  ...rest
}) => {
  const { handleSubscriptionClick, handleToken } = useLogic({
    isPublic: !!isPublic,
    planId: plan.id,
    onToken,
  });

  return (
    <StripeCheckout
      amount={plan.amount}
      description={`${capitalize(plan.interval)}ly Subscription`}
      email={email || undefined}
      image={AppConfig.publicAppIconURL}
      locale="auto"
      name={AppConfig.appName}
      stripeKey={ApiConfig.STRIPE_KEY}
      token={handleToken}
      zipCode
    >
      <Button
        {...rest}
        data-cypress-id="subscription-purchase-button"
        disabled={isLoading}
        isLoading={isLoading}
        onClick={handleSubscriptionClick}
      >
        {short ? (
          `US ${formatCents(plan.amount)}`
        ) : (
          <>
            Subscribe for US{formatCents(plan.amount)}/
            <span>{plan.interval}</span>
          </>
        )}
      </Button>
    </StripeCheckout>
  );
};

export default memo(StripePlan);
