import DefaultCheck from 'components/Icons/Check';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.pad.lg} ${({ theme }) => theme.pad.md};
  background-color: ${({ theme }) => theme.colors.snow};
  border-radius: ${({ theme }) => theme.radius.sm};
  color: ${({ theme }) => theme.textLightBg.text};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.font.textNotSoSmall};
  transition: padding 0.2s;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.margin.sm};
  }

  &:active {
    padding-left: ${({ theme }) => theme.pad.lg};
    padding-right: ${({ theme }) => theme.pad.lg};
  }
`;

export const Check = styled(DefaultCheck)`
  width: 1rem;
`;
