import styled from 'styled-components';
import DefaultIconButton from 'components/IconButton';

export const Container = styled(DefaultIconButton)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue800};
  border-radius: ${({ theme }) => theme.radius.sm};
  color: ${({ theme }) => theme.colors.snow};
  display: flex;
  flex-flow: row nowrap;
  font-size: ${({ theme }) => theme.font.textSuperSmall};
  margin: auto 0 0;
  padding: ${({ theme }) => theme.pad.md};
  text-decoration: none;
  text-transform: uppercase;
`;

export const Title = styled.span`
  margin-left: 0.875rem;
  font-size: ${({ theme }) => theme.font.textSuperSmall};
  text-transform: uppercase;
`;
