import styled from 'styled-components';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultSelect from 'components/Inputs/Select';
import Button from 'components/Button';
import DefaultConfirmation from 'components/Confirmation';
import { media } from 'styles/mixins';

export const Form = styled.form.attrs(() => ({ noValidate: true }))`
  width: 100%;
  max-width: 24rem;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: 1.85rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.25;
  text-align: center;
`;

export const Description = styled.div`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.2;
  padding: 1rem 3rem 2rem;
  text-align: center;
  white-space: pre-wrap;

  ${media.tabletLandscape} {
    padding: 1rem 0 2rem;
  }
`;

export const Paragraph = styled.p`
  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

export const TextField = styled(DefaultTextField)`
  margin-bottom: 1rem;
`;

export const Submit = styled(Button)`
  &&& {
    margin-top: 0.5rem;
  }
`;

export const Confirmation = styled(DefaultConfirmation)`
  width: 6.25rem;
`;

export const Select = styled(DefaultSelect)`
  margin-bottom: 1rem;
`;
