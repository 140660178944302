import styled, { css } from 'styled-components';
import { media } from 'styles/mixins';
import DefaultImage from 'components/Image';
import DefaultStretchedImage from 'components/StretchedImage';
import Text from 'components/Text';
import DefaultLockIcon from 'components/Icons/Lock';
import DefaultStarIcon from 'components/Icons/Star';
import {
  DEFAULT_SLIDE_HEIGHT,
  TABLET_SLIDE_HEIGHT,
  TABLET_LANDSCAPE_SLIDE_HEIGHT,
  PHONE_SLIDE_HEIGHT,
} from '../dimensions';

export const Container = styled.div<{ $isSelected: boolean }>`
  height: ${DEFAULT_SLIDE_HEIGHT};
  transition: padding 0.25s ease-in-out;
  padding: ${({ $isSelected, theme }) => ($isSelected ? '0' : theme.pad.sm)};
  &:active {
    padding: ${({ $isSelected, theme }) =>
      $isSelected ? theme.pad.xsm : theme.pad.md};
  }

  ${media.tabletLandscape} {
    height: ${TABLET_LANDSCAPE_SLIDE_HEIGHT};
  }

  ${media.tabletPortrait} {
    height: ${TABLET_SLIDE_HEIGHT};
  }
  ${media.phonePortrait} {
    height: ${PHONE_SLIDE_HEIGHT};
  }
`;

export const Image = styled(DefaultImage)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;
`;

export const StretchedImage = styled(DefaultStretchedImage)<{
  $backgroundColor: string;
}>`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  cursor: pointer;

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: ${({ theme }) => theme.margin.md};
  width: 100%;
`;

export const Title = styled.h5`
  color: ${({ theme }) => theme.colors.snow};
  font-size: ${({ theme }) => theme.font.textSuperBig};
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

export const Description = styled(Text)`
  font-size: ${({ theme }) => theme.font.textNotSoSmall};
  max-width: 65%;
  opacity: 0.8;
  min-height: 1.4377rem;
`;

export const Status = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled.div`
  background-color: ${({ theme }) => theme.darkGrey};
  border-radius: 50%;
  display: flex;
  height: 4rem;
  margin: auto;
  width: 4rem;
`;

export const LockIcon = styled(DefaultLockIcon)`
  color: ${({ theme }) => theme.colors.snow};
  margin: auto;
`;

export const StarIcon = styled(DefaultStarIcon)`
  color: ${({ theme }) => theme.colors.snow};
  margin: auto;
`;
