import React, { FC, memo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { PlayerType } from 'graphql/generated/globalTypes';
import Portal from 'components/Portal';
import PlayerContainer from './Container';
import CoursePlayer from './Course';
import SelfTimerPlayer from './SelfTimer';
import useConnect from './connect';

const Player: FC = () => {
  const { courseId, isMaximized, toggleDisplayStatus, type } = useConnect();

  return (
    <Portal>
      <AnimatePresence>
        {type && (
          <PlayerContainer
            isMaximized={isMaximized}
            toggleDisplayStatus={toggleDisplayStatus}
          >
            {type === PlayerType.COURSE && courseId && (
              <CoursePlayer
                key={courseId}
                courseId={courseId}
                isMaximized={isMaximized}
              />
            )}
            {type === PlayerType.SELFTIMER && (
              <SelfTimerPlayer isMaximized={isMaximized} />
            )}
          </PlayerContainer>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default memo(Player);
