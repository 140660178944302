import { useCallback } from 'react';
import { LogicProps } from './types';

const useLogic = ({ courseId, onPlay }: LogicProps) => {
  const handlePlay = useCallback(() => {
    if (onPlay) {
      onPlay(courseId);
    }
  }, [courseId, onPlay]);

  return {
    handlePlay,
  };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
