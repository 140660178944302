import React, { FC, memo } from 'react';
import { Container, ShareButton, Title } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Share: FC<Props> = ({ className, courseId, trackHash }) => {
  const { shareLink } = useConnect(courseId, trackHash);

  return (
    <Container className={className}>
      <ShareButton onClick={shareLink} />
      <Title>Share</Title>
    </Container>
  );
};

export default memo(Share);
