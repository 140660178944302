/* istanbul ignore file */
/*
 * My attempts to test this file with Jest and JSDOM have been unsuccessful, and
 * this should be dropped from the codebase anyway.
 */
const loadedScripts: { [url: string]: boolean | undefined } = {};

/**
 * @deprecated Use a script tag in index.html instead.
 */
async function scriptLoader(url: string): Promise<string> {
  if (loadedScripts[url]) {
    return Promise.resolve(url);
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.onload = () => {
      loadedScripts[url] = true;
      resolve(url);
    };
    script.onerror = () => reject(url);
    script.async = true;
    script.src = url;

    document.body.appendChild(script);
  });
}

export default scriptLoader;
