import React, { FC, memo } from 'react';
import PlayIcon from 'components/Icons/Play';
import { Container } from './styles';
import { Props } from './types';

const PlayButton: FC<Props> = ({ onClick = () => {}, ...rest }) => (
  <Container onClick={onClick} {...rest}>
    <PlayIcon />
  </Container>
);

export default memo(PlayButton);
