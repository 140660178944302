import { useEffect } from 'react';
import { useRouteMatch, useParams } from 'react-router';
import useAuth from 'graphql/hooks/useAuth';
import useMe from 'graphql/hooks/useMe';
import useSubscription from 'graphql/hooks/useSubscription';

const useConnect = () => {
  const { logout } = useAuth();
  const { isAnonymous, profile } = useMe();
  const { company } = useParams<{ company?: string }>();
  const subscription = useSubscription({ variables: { code: company } });

  const { url } = useRouteMatch();
  const isMobileWebview = useRouteMatch({
    path: '/mobile/subscription',
    exact: true,
  });

  useEffect(() => {
    if (isAnonymous) {
      logout('checkout');
    }
  }, [isAnonymous, logout]);

  return {
    isAnonymous,
    isMobileWebview,
    subscription,
    url,
    user: profile,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
