import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { ApiConfig } from 'config';
import localCacheTypes from 'graphql/localCacheTypes';
import { getToken, setToken } from './storage';

const getTimezoneOffsetInSeconds = () => {
  return new Date().getTimezoneOffset() * 60 * -1;
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Accept: 'application/json',
      Authorization: getToken(),
      'X-Application-Secret': ApiConfig.API_SECRET,
      timezone: getTimezoneOffsetInSeconds(),
      'app-client': 'web',
    },
  };
});

const uploadLink = createUploadLink({
  uri: `${ApiConfig.API}/api/graphql`,
  credentials: 'same-origin',
  fetchOptions: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
});

const apolloCache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, uploadLink]),
  cache: apolloCache,
  typeDefs: localCacheTypes,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  },
});

export const clearApolloStore = () => apolloClient.clearStore();

// @ts-ignore
window.__SET_AUTH_TOKEN__ = (token: string) => {
  setToken(token);
  window.location.reload();
};
