import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/mixins';
import LogoBase from 'components/Logo';
import DefaultVideoPlayer from 'components/VideoPlayer';
import DefaultButton from 'components/Button';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
}))`
  align-items: center;
  background-color: #f4f4f8;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  min-height: 90vh;
  padding: 1rem 0;
`;

export const Logo = styled(LogoBase).attrs(() => ({
  lightBg: true,
}))`
  margin-bottom: 2.8rem;
  width: 220px;

  ${media.phonePortrait} {
    width: 180px;
  }
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: 2.3125rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.2;
  margin-bottom: 2.4rem;
  text-align: center;
  width: 650px;

  ${media.phoneLandscape} {
    font-size: 1.5rem;
    width: 90%;
  }
`;

export const VideoPlayer = styled(DefaultVideoPlayer)`
  height: 405px;
  margin-bottom: 2rem;
  max-width: 90%;
  width: 720px;

  ${media.phoneLandscape} {
    height: 240px;
    width: 426px;
  }

  ${media.phonePortrait} {
    height: calc(90vw * (9 / 16));
    width: 90vw;
  }
`;

export const Buttons = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 710px;

  ${media.phoneLandscape} {
    flex-flow: column nowrap;
    width: 390px;
  }

  ${media.phonePortrait} {
    align-items: stretch;
    width: 90vw;
  }
`;

export const Button = styled(DefaultButton).attrs(() => ({
  disableElevation: true,
}))`
  flex: 1 1 0;
  &.MuiButtonBase-root {
    color: ${({ theme }) => theme.colors.snow};
    margin: 0 1rem;
    padding: 0.8rem 1.4rem;
  }
  &.MuiButton-containedPrimary {
    background-color: ${({ theme }) => theme.colors.blue600};
  }
  &.MuiButton-contained.Mui-disabled {
    color: rgba(255, 255, 255, 0.75);
  }
  .MuiButton-label {
    text-transform: none !important;
    font-weight: normal !important;
  }

  ${media.phoneLandscape} {
    &.MuiButtonBase-root {
      margin: 0.4rem 0;
    }
    .MuiButton-label {
      font-size: 1.2rem;
    }
  }

  ${media.phonePortrait} {
    flex: 0 1 auto;
    .MuiButton-label {
      font-size: 1rem;
    }
  }
`;
