/* eslint-disable import/prefer-default-export */
import { FormEvent } from 'react';
import { ReactStripeElements } from 'react-stripe-elements';

export const handleSubmit = ({
  stripe,
}: {
  stripe?: ReactStripeElements.StripeProps;
}) => (ev: FormEvent<HTMLFormElement>) => {
  ev.preventDefault();

  if (!stripe) return false;

  stripe
    .createPaymentMethod('card', {
      billing_details: { name: 'Pepe Toledo' },
    })
    .then(({ paymentMethod }) => {
      // eslint-disable-next-line no-console
      console.log('Received Stripe PaymentMethod:', paymentMethod);
    });
};
