import React, { FC, memo } from 'react';
import {
  Accept,
  Actions,
  Cancel,
  Container,
  Description,
  Modal,
  Title,
} from './styles';
import useConnect from './connect';

const ResetCourseConfirmModal: FC = () => {
  const { accept, closeModal, isOpen } = useConnect();

  return (
    <Modal
      disableRestoreFocus
      hideLogo
      reverseClose
      open={isOpen}
      onClose={closeModal}
    >
      <Container>
        <Title>Mark Course as unplayed</Title>
        <Description>Are you sure you want to continue?</Description>
        <Actions>
          <Cancel onClick={closeModal}>No</Cancel>
          <Accept onClick={accept}>Yes</Accept>
        </Actions>
      </Container>
    </Modal>
  );
};

export default memo(ResetCourseConfirmModal);
