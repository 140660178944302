import { useCallback, useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { ReactStripeElements } from 'react-stripe-elements';
import { up } from 'lib/paths';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';
import useStripeSource from 'graphql/hooks/useStripeSource';

const useConnect = () => {
  const { profile, loading: isLoadingMe } = useMe();
  const { source, loading: isLoadingStripeSource } = useStripeSource();
  const { openUpdatePaymentCard } = useModal();
  const { url } = useRouteMatch();

  const { firstName, lastName } = useMemo(
    () => ({ firstName: profile.firstName, lastName: profile.lastName }),
    [profile],
  );

  const upUrl = useMemo(() => up(url), [url]);

  const submit = useCallback(
    (token: ReactStripeElements.PatchedTokenResponse) => {
      if (token.token?.id) {
        openUpdatePaymentCard({ tokenId: token.token.id });
      }
    },
    [openUpdatePaymentCard],
  );

  return {
    firstName,
    lastName,
    loading: isLoadingMe || isLoadingStripeSource,
    source,
    submit,
    upUrl,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
