export const ANIMATION_ROUTE_BLACKLIST = [
  /.*\/(practice|meditations|theory)\/+/,
];

export const getKey = (pathname: string) => {
  if (pathname.includes('practice')) {
    return 'practice';
  }
  if (pathname.includes('theory')) {
    return 'theory';
  }
  return pathname;
};
