import styled from 'styled-components';
import DefaultLink from 'components/Link';

export const Container = styled.div`
  align-items: center;
  align-self: stretch;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: ${({ theme }) => theme.colors.subscription.grey300};
  display: flex;
  flex-flow: column nowrap;
  font-size: 0.5625rem;
  justify-content: flex-end;
  line-height: 0.6875rem;
  padding: 6.75rem 0 1.45rem 0;
  text-align: center;
`;

export const Conditions = styled.div`
  font-size: inherit;
  margin-bottom: 0.4375rem;
  width: 282px;
`;

export const Terms = styled.div`
  font-size: inherit;
  width: 215px;
`;

export const Link = styled(DefaultLink)`
  color: inherit;
  font-size: inherit;
`;
