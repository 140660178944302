import React, { FC, memo } from 'react';
import {
  Accept,
  Actions,
  Cancel,
  Container,
  Description,
  Modal,
} from './styles';
import useConnect from './connect';

const UnlockContentModal: FC = () => {
  const { accept, closeModal, description, isOpen } = useConnect();

  return (
    <Modal
      disableRestoreFocus
      hideLogo
      reverseClose
      open={isOpen}
      onClose={closeModal}
    >
      <Container>
        <Description>{description}</Description>
        <Actions>
          <Cancel onClick={closeModal}>Cancel</Cancel>
          <Accept onClick={accept}>Unlock</Accept>
        </Actions>
      </Container>
    </Modal>
  );
};

export default memo(UnlockContentModal);
