import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Info = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;

  ${media.phoneLandscape} {
    max-width: 100%;
    min-height: 16rem;
  }
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.textSuperMegaBig};
  font-weight: bold;
  color: white;
  margin-bottom: 0.5rem;
`;

export const Description = styled.div`
  opacity: 0.8;
  font-size: ${({ theme }) => theme.font.textSuperBig};
  font-weight: 300;
  padding: 0 ${({ theme }) => theme.pad.xxl};
`;

export const Image = styled.img`
  width: 100%;
  margin-bottom: 1rem;
`;

export const iconWidth = 36;
