/* eslint-disable import/prefer-default-export */
import { GetFeatureContent_feature_content } from 'graphql/generated/GetFeatureContent';

export const normalizeFeatureContent = (
  featuredContent: GetFeatureContent_feature_content,
) => {
  return {
    deepLink: (featuredContent.deep_link || '').replace(
      /(https|wakingup):\/\/(app|dynamic).wakingup.com\//,
      '/',
    ),
    imageUrl: featuredContent.image_url || '',
    isUnlocked: featuredContent.is_unlocked || false,
  };
};

export type FeatureContent = ReturnType<typeof normalizeFeatureContent>;
