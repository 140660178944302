import React, { FC, memo } from 'react';
import logo from 'assets/images/SquaredLogo.png';
import HomeIcon from 'components/Icons/Home';
import LessonIcon from 'components/Icons/Lesson';
import MeditationIcon from 'components/Icons/Meditation';
import HeartHollowIcon from 'components/Icons/HeartHollow';
import AvatarIcon from 'components/Icons/Avatar';
import { Container, Links, LogoImg, NavLink, Separator, Timer } from './styles';
import { Props, Route } from './types';
import useConnect from './connect';

const ICON_WIDTH = 22;
const NAVLINKS: Route[] = [
  {
    icon: <HomeIcon size={ICON_WIDTH} />,
    title: 'Home',
    path: '/',
    exact: true,
  },
  {
    icon: <LessonIcon size={ICON_WIDTH} />,
    title: 'Theory',
    path: '/theory',
  },
  {
    icon: <MeditationIcon size={ICON_WIDTH} />,
    title: 'Practice',
    path: '/practice',
  },
  {
    icon: <HeartHollowIcon size={ICON_WIDTH} />,
    title: 'Favorites',
    path: '/favorites',
  },
];
const SETTINGS = {
  icon: <AvatarIcon size={ICON_WIDTH} />,
  title: 'Settings',
  path: '/account',
};

const Desktop: FC<Props> = ({ className }) => {
  const { openSelfTimer } = useConnect();

  return (
    <Container className={className}>
      <LogoImg
        id="nav-desktop-logo"
        alt="waking up logo"
        aria-hidden
        src={logo}
      />
      <Links>
        {NAVLINKS.map(({ exact, icon, path, title }) => (
          <NavLink
            key={path}
            exact={!!exact}
            to={path}
            icon={icon}
            title={title}
            id={`nav-desktop-${title.toLowerCase()}`}
          />
        ))}
        <Separator />
        <NavLink
          to={SETTINGS.path}
          icon={SETTINGS.icon}
          title={SETTINGS.title}
        />
      </Links>
      <Timer onClick={openSelfTimer} />
    </Container>
  );
};

export default memo(Desktop);
