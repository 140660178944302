import React from 'react';
import styled from 'styled-components';
import { BaseLink } from '..';

export interface StyleProps {
  darkBg?: boolean;
}

export const BackLinkBase = styled(({ darkBg, ...rest }) => (
  <BaseLink {...rest} />
)).attrs<StyleProps>(({ theme, darkBg }) => ({
  color: darkBg ? theme.colors.snow : theme.colors.black,
}))`
  display: flex;
  align-items: center;

  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
`;
