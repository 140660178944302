import React, { FC, memo } from 'react';
import { Container, Title } from './styles';
import { Props } from './types';

export const NavLink: FC<Props> = ({ icon, title, ...rest }) => (
  <Container {...rest}>
    {icon}
    <Title>{title}</Title>
  </Container>
);

export default memo(NavLink);
