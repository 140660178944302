/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import DefaultMaterialSwitch from '@material-ui/core/Switch';
import { Props } from './types';

const WIDTH = 42;
const HEIGHT = 26;

export const Container = styled.div<{ $labelPosition: Props['labelPosition'] }>`
  align-items: center;
  color: ${({ theme }) => theme.textLightBg.text};
  display: flex;
  flex-flow: ${({ $labelPosition }) =>
    $labelPosition === 'right' ? 'row-reverse nowrap' : 'row nowrap'};
  justify-content: flex-start;
`;

export const Label = styled.span<{ $labelPosition: Props['labelPosition'] }>`
  margin-left: ${({ $labelPosition }) =>
    $labelPosition === 'right' ? '0.8rem' : '0'};
  margin-right: ${({ $labelPosition }) =>
    $labelPosition === 'right' ? '0' : '0.6rem'};
`;

export const MaterialSwitch = styled(DefaultMaterialSwitch)`
  &.MuiSwitch-root {
    height: ${HEIGHT}px;
    margin: 0;
    padding: 0;
    width: ${WIDTH}px;
  }
  .MuiSwitch-switchBase {
    padding: 2px;
    &.Mui-checked {
      transform: translateX(${WIDTH - HEIGHT}px);
      color: #ffffff;
      & + .MuiSwitch-track {
        background-color: ${({ theme }) => theme.colors.blue500};
        opacity: 1;
        border: none;
      }
    }
    &.Mui-focusVisible .MuiSwitch-thumb {
      color: ${({ theme }) => theme.colors.blue500};
      border: 6px solid #ffffff;
    }
  }
  .MuiSwitch-thumb {
    width: ${HEIGHT - 4}px;
    height: ${HEIGHT - 4}px;
  }
  .MuiSwitch-track {
    border-radius: ${HEIGHT / 2}px;
    border: 1px solid #bdbdbd;
    background-color: #bdbdbd;
    opacity: 1;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;
