import styled from 'styled-components';
import DefaultWrapper from '../Wrapper';

const BORDER_WIDTH = '1px';
const BUTTON_MARGIN = '5px';
const ARROW_WIDTH = '0.3rem';
const ARROW_HEIGHT = '0.4rem';

export const Container = styled.div``;

export const Wrapper = styled(DefaultWrapper)<{ readOnly: boolean }>`
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'default')};
  user-select: ${({ readOnly }) => (readOnly ? 'none' : 'default')};
`;

export const TimeContainer = styled.div<{ $hasLabel: boolean }>`
  display: inline-flex;
  border: ${BORDER_WIDTH} solid ${({ theme }) => theme.colors.blue500};
  border-radius: ${({ theme }) => theme.radius.sm};
  margin: ${({ $hasLabel, theme }) =>
    `${$hasLabel ? theme.margin.xxxxsm : theme.pad.md} ${theme.pad.xl} ${
      $hasLabel ? '0' : theme.pad.md
    } ${theme.pad.xl}`};
`;

export const Value = styled.div`
  margin: 0;
  padding: ${({ theme }) => theme.pad.sm};
`;

export const Arrow = styled.div<{ $increment?: boolean }>`
  border-left: ${ARROW_WIDTH} solid transparent;
  border-right: ${ARROW_WIDTH} solid transparent;
  border-top: ${({ $increment, theme }) =>
    $increment ? 'none' : `${ARROW_HEIGHT} solid ${theme.colors.snow}`};
  border-bottom: ${({ $increment, theme }) =>
    $increment ? `${ARROW_HEIGHT} solid ${theme.colors.snow}` : 'none'};
  margin-left: ${BUTTON_MARGIN};
  margin-right: calc(${BUTTON_MARGIN} - ${BORDER_WIDTH});
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  background: transparent;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const ButtonsGroup = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.blue500};
  justify-content: space-evenly;
`;

export const Input = styled.input`
  border: 0;
  width: 1.6rem;
  text-align: right;
  &:focus {
    outline: 0;
  }
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.error};
  margin-top: ${({ theme }) => theme.margin.md};
`;

export const Suffix = styled.div`
  display: inline-block;
  width: 2.5rem;
  text-align: center;
`;
