import styled from 'styled-components';
import DefaultButton from '@material-ui/core/Button';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import { ContainerProps } from './types';

export const Container = styled(DefaultButton).attrs<ContainerProps>(() => ({
  variant: 'contained',
  color: 'primary',
}))<ContainerProps>`
  &&& {
    border-radius: ${({ theme }) => theme.radius.md};
    border: ${({ theme, mode }) =>
      mode === 'outlined' ? `1px solid ${theme.colors.snow} ` : 'none'};
    color: ${({ theme }) => theme.colors.snow};
    cursor: pointer;
    min-height: ${({ theme }) => theme.heights.formElement};
    min-width: max-content;
    outline: none;
    position: relative;
    text-decoration: none;
    width: 100%;

    .MuiButton-containedPrimary {
      background-color: ${({ theme }) => theme.colors.blue500};
    }

    .MuiButton-label {
      text-decoration: none;
      text-transform: uppercase;
      font-weight: ${({ theme }) => theme.font.weight.medium};
    }

    &:disabled,
    .Mui-disabled {
      opacity: ${({ $isLoading }) => ($isLoading ? '1' : '0.6')};
      cursor: default;
      pointer-events: none;
      background-color: ${({ theme }) => theme.colors.blue500};
    }
  }
`;

export const Loader = styled(CircularProgress).attrs<CircularProgressProps>(
  () => ({ size: 20, color: 'inherit' }),
)`
  color: white;
`;
