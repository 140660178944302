import { format } from 'date-fns';
import stringToEnum from 'lib/stringToEnum';
import { formatDurationLong } from 'lib/formatTime';
import { PackType } from 'models/Pack';
import { GetCourses_courses_data } from 'graphql/generated/GetCourses';
import { GetFeatures_features_data_course } from 'graphql/generated/GetFeatures';
import { GetDaily_daily } from 'graphql/generated/GetDaily';

export enum CourseType {
  LESSON = 'lesson',
  MEDITATION = 'meditation',
}

export enum MeditationType {
  ADVANCED = 'advanced',
  COURSE = 'course',
  DAILY = 'daily',
  SPECIAL_INTRO = 'special_intro',
}

export enum CourseStatus {
  UNSTARTED = 'unstarted',
  STARTED = 'started',
  FINISHED = 'finished',
}

export enum CoursePermission {
  UNLOCKED = 'unlocked',
  LOCKED = 'locked',
  FORBIDDEN = 'forbidden',
}

export const normalizeCourse = (
  course:
    | GetCourses_courses_data
    | GetFeatures_features_data_course
    | GetDaily_daily,
) => {
  const today = new Date();
  const {
    displayTitle,
    displaySubtitle,
    displayDescription,
    dailyDate,
  } = course.is_daily
    ? {
        displayTitle: 'Daily Meditation',
        displaySubtitle: format(today, 'MMMM d, yyyy'),
        displayDescription: format(today, 'MMMM d, yyyy'),
        dailyDate: today.toISOString(),
      }
    : {
        displayTitle: course.title_line_1 || course.title || '',
        displaySubtitle:
          course.title_line_2 && course.title_line_2 !== course.title
            ? course.title_line_2
            : '',
        displayDescription: formatDurationLong(course.mp3_length || 0),
        dailyDate: undefined,
      };

  return {
    color: 'rgb(100, 137, 255)',
    courseHash: course.course_hash || '',
    courseNumber: course.course_number || -1,
    courseType:
      stringToEnum(CourseType, course.course_type) || CourseType.LESSON,
    dailyDate: dailyDate || '',
    dailyStatus: course.daily_status || '',
    deepLink: '',
    description: course.description || '',
    displayTitle,
    displaySubtitle,
    displayFullTitle: course.is_daily
      ? 'Daily Meditation'
      : `${displayTitle}${displaySubtitle ? `\n${displaySubtitle}` : ''}`,
    displayDescription,
    displayImage: course.is_daily
      ? course.square_image?.original || ''
      : course.image_url || '',
    displayThumbnail: course.is_daily
      ? course.square_image?.thumb_x1 || course.square_image?.thumb_x2 || ''
      : course.thumbnail_url?.thumb_x1 || course.thumbnail_url?.thumb_x2 || '',
    feature_image_url: course.feature_image_url || '',
    hasNotes: !!course.note_title && !!course.note_text,
    hasSpeedControl: false,
    id: course.id || '',
    imageUrl: course.image_url || '',
    isDaily: course.is_daily || false,
    isFavorite: course.is_favorite || false,
    isFree: course.is_free || false,
    isIntroCourse:
      course.course_type === CourseType.MEDITATION && !course.image_url,
    isLastIntro: false,
    isUnlocked: course.is_unlocked || false,
    lockable: course.lockable || false,
    meditationType:
      stringToEnum(MeditationType, course.meditation_type) ||
      MeditationType.COURSE,
    mp3_file_name: course.mp3_file_name || '',
    mp3_file_size: course.mp3_file_size || 0,
    mp3_url: course.mp3_url || '',
    mp3_length: course.mp3_length || 0,
    mp3_2_file_name: course.mp3_2_file_name || '',
    mp3_2_file_size: course.mp3_2_file_size || 0,
    mp3_2_url: course.mp3_2_url || '',
    mp3_2_length: course.mp3_2_length || 0,
    noteSubtitle: course.note_subtitle || '',
    noteText: course.note_text || '',
    noteTitle: course.note_title || '',
    packHash: '',
    packId: '',
    packTitle: '',
    packType: PackType.MEDITATION,
    packAutoPlay: false,
    permission: CoursePermission.UNLOCKED,
    playedFirstTimeAt: (course.played_first_time_at as string) || '',
    playedLastTimeAt: (course.played_last_time_at as string) || '',
    rectangleImageColor: course.rectangle_image?.color
      ? `#${course.rectangle_image.color}`
      : '',
    rectangleImageOriginal: course.rectangle_image?.original || '',
    rectangleImageThumb:
      course.rectangle_image?.thumb_x1 ||
      course.rectangle_image?.thumb_x2 ||
      '',
    section: course.course_type === 'lesson' ? 'theory' : 'practice',
    shareable: course.shareable || false,
    squareImageColor: course.square_image?.color
      ? `#${course.square_image.color}`
      : '',
    squareImageOriginal: course.square_image?.original || '',
    squareImageThumb:
      course.square_image?.thumb_x1 || course.square_image?.thumb_x2 || '',
    status: stringToEnum(CourseStatus, course.status) || CourseStatus.UNSTARTED,
    thumbnail_url:
      course.thumbnail_url?.thumb_x1 || course.thumbnail_url?.thumb_x2 || '',
    title: course.is_daily ? 'Daily Meditation' : course.title || '',
    titleLine1: course.title_line_1 || '',
    titleLine2: course.title_line_2 || '',

    // OLD
    course_hash: course.course_hash || '',
    course_number: course.course_number || -1,
    course_type:
      stringToEnum(CourseType, course.course_type) || CourseType.LESSON,
    daily_date: dailyDate || '',
    daily_status: course.daily_status || '',
    deep_link: '',
    image_url: course.image_url || '',
    is_daily: course.is_daily || false,
    is_favorite: course.is_favorite || false,
    is_free: course.is_free || false,
    is_unlocked: course.is_unlocked || false,
    meditation_type:
      stringToEnum(MeditationType, course.meditation_type) ||
      MeditationType.COURSE,
    note_subtitle: course.note_subtitle || '',
    note_text: course.note_text || '',
    note_title: course.note_title || '',
    played_first_time_at: (course.played_first_time_at as string) || '',
    played_last_time_at: (course.played_last_time_at as string) || '',
    rectangle_image_original: course.rectangle_image?.original || '',
    rectangle_image_thumb:
      course.rectangle_image?.thumb_x1 ||
      course.rectangle_image?.thumb_x2 ||
      '',
    square_image_original: course.square_image?.original || '',
    square_image_thumb:
      course.square_image?.thumb_x1 || course.square_image?.thumb_x2 || '',
    title_line_1: course.title_line_1 || '',
    title_line_2: course.title_line_2 || '',
  };
};

export type Course = ReturnType<typeof normalizeCourse>;

export const getCoursePermission = (isSubscribed: boolean, isFree: boolean) => {
  if (!isSubscribed && !isFree) {
    return CoursePermission.FORBIDDEN;
  }
  return CoursePermission.UNLOCKED;
};

export const getDailyPermission = (
  isSubscribed: boolean,
  isDailyUnlocked: boolean,
) => {
  if (!isSubscribed) {
    return CoursePermission.FORBIDDEN;
  }
  if (!isDailyUnlocked) {
    return CoursePermission.LOCKED;
  }
  return CoursePermission.UNLOCKED;
};

export const getNextIntroCourse = (introCourses: Course[]) => {
  const introCoursesDescSorted = introCourses.sort(
    (a, b) => b.course_number - a.course_number,
  );
  const higherIntroCourseFinishedIndex = introCoursesDescSorted.findIndex(
    ({ status }) => status === CourseStatus.FINISHED,
  );

  if (higherIntroCourseFinishedIndex === -1) {
    return introCoursesDescSorted[introCoursesDescSorted.length - 1];
  }
  if (higherIntroCourseFinishedIndex === 0) {
    return introCoursesDescSorted[0];
  }
  return introCoursesDescSorted[higherIntroCourseFinishedIndex - 1];
};

export const ANNIVERSARY_COURSE = normalizeCourse({
  course_hash: 'D8D148',
  course_number: null,
  course_type: 'lesson',
  daily_date: null,
  daily_status: 0,
  description: null,
  feature_image_url:
    'https://d2uk1wgjryl0y1.cloudfront.net/courses/feature_images/658/original/img-fe1dec93b23ead132031.png',
  id: '658',
  image_url:
    'https://d2uk1wgjryl0y1.cloudfront.net/courses/images/658/original/img-d6e46ea8ee8adeb87aeb.jpg',
  is_daily: null,
  is_favorite: false,
  is_free: null,
  is_unlocked: true,
  lockable: null,
  meditation_type: 'course',
  mp3_file_name: 'course-13119ec2d058f837e26f.mp3',
  mp3_file_size: 9520714,
  mp3_length: 595,
  mp3_url:
    'https://d2uk1wgjryl0y1.cloudfront.net/courses/mp3s/658/original/course-13119ec2d058f837e26f.mp3',
  mp3_2_file_name: null,
  mp3_2_file_size: null,
  mp3_2_length: null,
  mp3_2_url: null,
  note_subtitle: null,
  note_text: null,
  note_title: null,
  played_first_time_at: null,
  played_last_time_at: null,
  rectangle_image: {
    color: null,
    original: null,
    thumb_x1: null,
    thumb_x2: null,
    __typename: 'RectangleImage',
  },
  seconds_played: null,
  shareable: true,
  square_image: {
    color: null,
    original: null,
    thumb_x1: null,
    thumb_x2: null,
    __typename: 'SquareImage',
  },
  status: 'unstarted',
  thumbnail_url: {
    thumb_x1:
      'https://d2uk1wgjryl0y1.cloudfront.net/courses/images/658/thumb_x1/img-d6e46ea8ee8adeb87aeb.jpg',
    thumb_x2:
      'https://d2uk1wgjryl0y1.cloudfront.net/courses/images/658/thumb_x2/img-d6e46ea8ee8adeb87aeb.jpg',
    __typename: 'Thumbnail',
  },
  title: 'Waking Up Turns 2',
  title_line_1: null,
  title_line_2: null,
  __typename: 'Course',
}) as Course;
