/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledInput = styled.input`
  -moz-appearance: textfield;
  background: ${({ theme }) => theme.colors.snow};
  border: none;
  border-radius: ${({ theme }) => theme.radius.sm};
  color: ${({ theme }) => theme.textInput};
  height: ${({ theme }) => theme.heights.formElement};
  font-weight: 600;
  margin-right: ${({ theme }) => theme.pad.md};
  min-width: 0;
  padding: 0 ${({ theme }) => theme.pad.xl};
  text-align: center;
  width: 100%;
  &::placeholder {
    color: ${({ theme }) => theme.textFaint};
    opacity: 1;
  }
  &:focus {
    outline: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
