import styled from 'styled-components';
import DefaultHeaderLink from 'components/Links/HeaderLink';

export const SectionHeader = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

interface TitleProps {
  darkBg?: boolean;
}

export const Title = styled.span<TitleProps>`
  color: ${({ theme, darkBg }) =>
    darkBg ? theme.colors.snow : theme.colors.black};
  font-size: ${({ theme }) => theme.font.textSuperSmall};
  font-weight: bold;
  text-transform: uppercase;
`;

export const Expander = styled.div`
  flex: 1;
`;

export const HeaderLink = styled(DefaultHeaderLink)`
  margin-right: 4px;
`;
