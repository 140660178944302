import styled from 'styled-components';
import { Form as DefaultForm } from 'formik';
import DefaultSectionHeader from 'components/SectionHeader';
import { media } from 'styles/mixins';

export const Container = styled.div`
  color: ${({ theme }) => theme.textLightBg.text};
  flex: 1;
  max-width: 25rem;
`;

export const Header = styled(DefaultSectionHeader)`
  ${media.tabletPortrait} {
    display: none;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: 1.15rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: ${({ theme }) => theme.margin.lg};
  text-align: center;

  ${media.tabletPortrait} {
    margin-top: ${({ theme }) => theme.margin.md};
  }

  ${media.phoneLandscape} {
    margin-top: 0;
  }
`;

export const Form = styled(DefaultForm).attrs(() => ({ noValidate: true }))`
  color: ${({ theme }) => theme.textLightBg.text};
  & > * {
    margin-bottom: 1rem;
  }
`;

export const Error = styled.p`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.error};
  font-weight: ${({ theme }) => theme.font.weight.book};
  text-align: center;
`;

export const Contact = styled.p`
  color: ${({ theme }) => theme.textLightBg.text};
  font-weight: ${({ theme }) => theme.font.weight.book};
  margin-top: 0.5rem;
  text-align: center;
  a {
    color: ${({ theme }) => theme.textLightBg.text};
  }
`;
