import { useRef, useEffect, MutableRefObject } from 'react';

const useUpdatedRef = <T>(state: T): MutableRefObject<T> => {
  const stateRef = useRef<T>(state);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  return stateRef;
};

export default useUpdatedRef;

export type UseStateRef = ReturnType<typeof useUpdatedRef>;
