import DefaultAvatarEditor from 'components/AvatarEditor';
import DefaultTextField from 'components/Inputs/TextField';
import HeaderLink from 'components/Links/HeaderLink';
import DefaultSectionHeader from 'components/SectionHeader';
import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div``;

export const SectionHeader = styled(DefaultSectionHeader)`
  ${media.tabletPortrait} {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.margin.xxxl};
  width: 100%;

  ${media.tabletPortrait} {
    margin-top: ${({ theme }) => theme.margin.xl};
  }

  ${media.phoneLandscape} {
    margin-top: 0;
  }
`;

export const Form = styled.form.attrs(() => ({ noValidate: true }))`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey};
  display: flex;
  flex-direction: column;
  width: 375px;
`;

export const AvatarEditor = styled(DefaultAvatarEditor)`
  height: 8.75rem;
  width: 10.65rem;
  margin-bottom: 2.5rem;
`;

export const TextField = styled(DefaultTextField)`
  width: 100%;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.pad.sm};
  }
`;

export const SaveLinkContainer = styled.div`
  display: none;

  ${media.tabletPortrait} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
`;

export const SaveLink = styled(HeaderLink)`
  display: inline-block;
  font-size: 1rem;
  margin-top: ${({ theme }) => theme.margin.xl};
  text-align: center;
`;
