import { useMemo, useCallback } from 'react';
import { FormikHelpers } from 'formik';
import { validationSchema } from './constants';
import { Props, FormFields } from './types';

const useConnect = ({ getPin }: { getPin: Props['getPin'] }) => {
  const initialValues: FormFields = useMemo(
    () => ({ email: '', submitError: '' }),
    [],
  );

  const submit = useCallback(
    async (
      { email }: { email: FormFields['email'] },
      { setErrors }: { setErrors: FormikHelpers<FormFields>['setErrors'] },
    ) => {
      try {
        await getPin(email);
      } catch (error) {
        setErrors({ submitError: error.message });
      }
    },
    [getPin],
  );

  return {
    initialValues,
    submit,
    validationSchema,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
