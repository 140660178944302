/* eslint-disable import/prefer-default-export */
import stringToEnum from 'lib/stringToEnum';
import { SelfTimerInterval } from 'models/SelfTimerInterval';
import { DailyMeditationDuration } from 'models/DailyMeditationDuration';
import { GetPlayer_player } from 'graphql/generated/GetPlayer';
import {
  PlayerAudioStatus,
  PlayerDisplayStatus,
} from 'graphql/generated/globalTypes';

export const normalizePlayer = (player?: GetPlayer_player) => {
  return {
    audioStatus: player?.audioStatus || PlayerAudioStatus.PLAYING,
    autoplayType: player?.autoplayType || null,
    courseId: player?.courseId || null,
    dailyMeditationDuration: player?.dailyMeditationDuration
      ? (player.dailyMeditationDuration as DailyMeditationDuration)
      : null,
    displayStatus: player?.displayStatus || PlayerDisplayStatus.MAXIMIZED,
    endPosition: player?.endPosition || null,
    endPositionPaused: player?.endPositionPaused || null,
    initialPosition: player?.initialPosition || null,
    selftimerDuration: player?.selftimerDuration || 0,
    selftimerInterval:
      stringToEnum(SelfTimerInterval, player?.selftimerInterval) ||
      SelfTimerInterval.NONE,
    selftimerStartTime: player?.selftimerStartTime || 0,
    type: player?.type || null,
  };
};

export type Player = ReturnType<typeof normalizePlayer>;
