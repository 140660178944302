import { gql } from '@apollo/client';
import { USER_DATA_FRAGMENT } from '../fragments';

export const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $appsflyer_id: String
    $avatar: Upload
    $city: String
    $daily_duration_type: Int
    $first_name: String
    $hasAvatar: Boolean!
    $last_name: String
    $settings: String
    $unlocked_content_status: String
  ) {
    updateMe(
      data: {
        appsflyer_id: $appsflyer_id
        city: $city
        daily_duration_type: $daily_duration_type
        first_name: $first_name
        last_name: $last_name
        settings: $settings
        unlocked_content_status: $unlocked_content_status
      }
    ) {
      ...UserData
    }
    uploadAvatar(avatar: $avatar) @include(if: $hasAvatar) {
      avatar_urls {
        thumb
        thumb_v2
      }
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings($settings: String!) {
    updateSettings(data: { settings: $settings }) {
      message
    }
  }
`;

export const ADD_PROGRESS = gql`
  mutation AddProgress(
    $duration_seconds: Int!
    $finished_at: Date!
    $progress_id: Int!
    $progress_type: String!
    $active_session_id: ID
  ) {
    addProgress(
      data: {
        duration_seconds: $duration_seconds
        finished_at: $finished_at
        progress_id: $progress_id
        progress_type: $progress_type
        active_session_id: $active_session_id
      }
    ) {
      message
    }
  }
`;

export const ADD_PROGRESS_START = gql`
  mutation AddProgressStart($media_duration: Int!, $course_id: Int) {
    addProgressStart(
      data: { media_duration: $media_duration, course_id: $course_id }
    ) {
      id
    }
  }
`;

export const SELF_SERVE_ACCOUNT = gql`
  mutation SelfServe(
    $email: String!
    $firstName: String!
    $lastName: String!
    $source: String!
  ) {
    subscribeSelfServe(
      input: {
        email: $email
        first_name: $firstName
        last_name: $lastName
        referral_source: $source
      }
    ) {
      message
    }
  }
`;
