import {
  PlayerAudioStatus,
  PlayerDisplayStatus,
} from 'graphql/generated/globalTypes';
import usePlayer from 'graphql/hooks/usePlayer';

const useConnect = () => {
  const { audioStatus, displayStatus } = usePlayer();

  return {
    isMaximized: displayStatus === PlayerDisplayStatus.MAXIMIZED,
    isPlaying: audioStatus === PlayerAudioStatus.PLAYING,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
