import { apolloClient } from 'services/api';
import { GetPlayer } from 'graphql/generated/GetPlayer';
import { GET_PLAYER } from 'graphql/queries';

function cacheReadPlayer() {
  try {
    const cachePlayer = apolloClient.readQuery<GetPlayer>({
      query: GET_PLAYER,
    });
    return cachePlayer?.player || null;
  } catch (e) {
    return null;
  }
}

export default cacheReadPlayer;
