import React, { FC } from 'react';
import { Route } from 'react-router';
import {
  BackButton,
  ChevronLeftIcon,
  Logo,
  Modal,
  NewSubscription,
} from './styles';
import useConnect from './connect';

const NewSubscriptionModal: FC = () => {
  const { code, email, handleClose, period } = useConnect();

  return (
    <Route path={['*/new-subscription/:code', '*/new-subscription']}>
      {({ match }) => (
        <Modal
          disableEnforceFocus
          hideClose
          hideLogo
          open={!!match}
          onClose={handleClose}
        >
          <BackButton
            // TODO: Re-enable this when monthly plans are activated.
            // onClick={handleBack}
            onClick={handleClose}
          >
            <ChevronLeftIcon />
            Back
          </BackButton>
          <Logo />
          <NewSubscription code={code} email={email} period={period} />
        </Modal>
      )}
    </Route>
  );
};

export default NewSubscriptionModal;
