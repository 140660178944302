import React, { FC } from 'react';
import { Container, Content, Link, Overlay, LockIcon } from './styles';
import { Props } from './types';

const Lock: FC<Props> = ({
  children,
  className,
  hideLock,
  isLocked,
  linkUrl,
  onClick,
}) => {
  if (!isLocked) {
    return <>{children}</>;
  }

  const overlay = <Overlay>{!hideLock && <LockIcon />}</Overlay>;

  return (
    <Container className={className}>
      <Content>{children}</Content>
      {linkUrl ? (
        <Link to={linkUrl} onClick={onClick}>
          {overlay}
        </Link>
      ) : (
        overlay
      )}
    </Container>
  );
};

export default Lock;
