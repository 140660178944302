import React, { FC, memo } from 'react';
import HeartFull from 'components/Icons/HeartFull';
import HeartHollow from 'components/Icons/HeartHollow';
import { Container } from './styles';
import { Props } from './types';

const FavoriteButton: FC<Props> = ({
  isFavorite,
  light = false,
  size = 24,
  ...rest
}) => (
  <Container $isFavorite={isFavorite} $light={light} {...rest}>
    {isFavorite ? <HeartFull size={size} /> : <HeartHollow size={size} />}
  </Container>
);

export default memo(FavoriteButton);
