import styled from 'styled-components';
import { media } from 'styles/mixins';

export const DescriptionContainer = styled.div`
  margin-top: -${({ theme }) => theme.margin.md};
  ${media.phonePortrait} {
    margin-top: 0;
  }
`;

export const Email = styled.p`
  color: ${({ theme }) => theme.textSuperFaint};
  font-size: ${({ theme }) => theme.font.textSmall};
`;

export const Description = styled.div``;
