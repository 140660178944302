import { Link as DefaultLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { darkBackground, media } from 'styles/mixins';
import DefaultModal from 'components/Modal';
import { Content as ModalContent } from 'components/Modal/styles';
import { ChevronButton } from './SectionLink/styles';

const SECTION_WIDTH_REM = 26;

const oneColumn = css`
  grid-template-columns: 100%;
  width: ${SECTION_WIDTH_REM}rem;
`;

export const Modal = styled(DefaultModal)<{ isTimeSectionOpen: boolean }>`
  ${ModalContent} {
    ${darkBackground};
    background-position-y: center;
    background-size: auto 100.5%;
    color: ${({ theme }) => theme.colors.snow};
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 100vw;
    min-height: 589px;
    outline: none;
    overflow: hidden;
    padding: ${({ theme }) => theme.pad.md} ${({ theme }) => theme.pad.xxl};
    width: ${SECTION_WIDTH_REM * 2}rem;

    ${media.tabletPortrait} {
      ${oneColumn};
    }

    ${media.phonePortrait} {
      min-height: 549px;
    }

    ${({ isTimeSectionOpen }) => !isTimeSectionOpen && oneColumn};
  }
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
`;

export const Actions = styled.div`
  ${Link}:not(:last-child) {
    ${ChevronButton} {
      margin-bottom: ${({ theme }) => theme.margin.sm};
    }
  }
`;
