import React, { FC, memo } from 'react';
import {
  Container,
  CourseNumber,
  Description,
  Image,
  ImageLoader,
  ImageWrapper,
  StretchedImage,
  Text,
  Title,
} from './styles';
import { Props } from './types';
import useConnect from './connect';

const TrackInfo: FC<Props> = ({
  className,
  courseNumber,
  imageUrl,
  isDaily = false,
  isIntro = false,
  isLoadingTrack,
  trackBackgroundColor,
  trackDisplayTitle,
  trackDisplaySubtitle,
}) => {
  const { isMaximized, isPlaying } = useConnect();

  const isLoading = isLoadingTrack;
  const hasImage = !isLoadingTrack && imageUrl;
  const hasCourseNumber = !isLoadingTrack && !imageUrl && courseNumber;

  return (
    <Container $isMaximized={isMaximized} className={className}>
      {isLoading && (
        <ImageWrapper $isPlaying={isPlaying}>
          <ImageLoader />
        </ImageWrapper>
      )}
      {hasImage && (
        <ImageWrapper $isPlaying={isPlaying}>
          {isDaily ? (
            <StretchedImage
              $backgroundColor={trackBackgroundColor}
              alt={trackDisplayTitle}
              src={imageUrl!}
            />
          ) : (
            <Image $isIntro={isIntro} alt={trackDisplayTitle} src={imageUrl!} />
          )}
        </ImageWrapper>
      )}
      {hasCourseNumber && <CourseNumber>{courseNumber}</CourseNumber>}
      <Text>
        <Title>{trackDisplayTitle}</Title>
        <Description>{trackDisplaySubtitle}</Description>
      </Text>
    </Container>
  );
};

export default memo(TrackInfo);
