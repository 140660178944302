import React, { FC, memo } from 'react';
import range from 'lodash/range';
import { MAX_DURATION_MINS } from 'models/SelfTimerInterval';
import {
  Container,
  DurationQuantity,
  DurationSlider,
  DurationUnit,
  DurationWrapper,
  VerticalBar,
} from './styles';
import { Props } from './types';
import useConnect from './connect';

const VERTICAL_BAR_RANGE = range(MAX_DURATION_MINS);

const DurationSelector: FC<Props> = ({
  decrementDuration,
  duration,
  incrementDuration,
  setDuration,
}) => {
  const {
    containerRef,
    onKeyDown,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onScroll,
  } = useConnect(decrementDuration, duration, incrementDuration, setDuration);

  return (
    <Container>
      <DurationWrapper>
        <DurationQuantity data-cypress-id="self-timer-duration-indicator">
          {duration}
        </DurationQuantity>
        <DurationUnit data-cypress-id="self-timer-duration-unit">
          Minute{duration > 1 ? 's' : ''}
        </DurationUnit>
      </DurationWrapper>
      <DurationSlider
        data-cypress-id="self-timer-duration-slider"
        ref={containerRef}
        tabIndex={0}
        onKeyDown={onKeyDown}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseUp}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onScroll={onScroll}
      >
        {VERTICAL_BAR_RANGE.map((i) => (
          <VerticalBar key={i} index={i - duration + 1} />
        ))}
      </DurationSlider>
    </Container>
  );
};

export default memo(DurationSelector);
