import React, { FunctionComponent } from 'react';

import ChevronLeft from 'components/Icons/ChevronLeft';
import { LinkProps } from '..';

// UI
import { BackLinkBase, StyleProps } from './styles';

type Props = LinkProps & StyleProps;

const BackLink: FunctionComponent<Props> = ({ title, children, ...props }) => (
  <BackLinkBase {...props}>
    <ChevronLeft />
    {title || children}
  </BackLinkBase>
);

export default BackLink;
