import React, { FC } from 'react';
import { Formik } from 'formik';
import {
  AvatarEditor,
  Container,
  Content,
  Form,
  SaveLink,
  SaveLinkContainer,
  SectionHeader,
  TextField,
} from './styles';
import useConnect from './connect';

const AccountEditPage: FC = () => {
  const {
    backHref,
    changeAvatar,
    initialValues,
    submit,
    validationSchema,
  } = useConnect();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        return (
          <Container>
            <SectionHeader
              actionOnClick={handleSubmit}
              actionTitle="Save"
              backHref={backHref}
              testId="account-header"
              title="Cancel"
            />
            <Content>
              <Form>
                <AvatarEditor
                  data-jest-id="avatar-editor"
                  firstName={values.firstName}
                  lastName={values.lastName}
                  email={values.email}
                  src={values.avatar}
                  name="avatar"
                  onChangeSrc={changeAvatar(setFieldValue)}
                />
                <TextField
                  data-cypress-id="account-edit-first-name"
                  data-jest-id="first-name"
                  label="First Name"
                  maxLength={150}
                  name="firstName"
                  placeholder="First Name"
                />
                <TextField
                  data-cypress-id="account-edit-last-name"
                  data-jest-id="last-name"
                  label="Last Name"
                  maxLength={150}
                  name="lastName"
                  placeholder="Last Name"
                />
                <TextField
                  data-cypress-id="account-edit-city"
                  data-jest-id="city"
                  label="City"
                  maxLength={150}
                  name="city"
                  placeholder="City"
                />
                <TextField
                  data-cypress-id="account-edit-email"
                  data-jest-id="email"
                  label="Email"
                  maxLength={150}
                  name="email"
                  placeholder="Email"
                  readOnly
                />
              </Form>
            </Content>
            <SaveLinkContainer>
              <SaveLink title="Save" onClick={handleSubmit} />
            </SaveLinkContainer>
          </Container>
        );
      }}
    </Formik>
  );
};

export default AccountEditPage;
