import { useMemo } from 'react';
import { media } from 'styles/mixins';
import useMediaQuery from 'lib/useMediaQuery';
import {
  ROW_HEIGHT_MOBILE_PX,
  ROW_MARGIN_MOBILE_PX,
  ROW_HEIGHT_DESKTOP_PX,
  ROW_MARGIN_DESKTOP_PX,
} from './constants';

const useLogic = () => {
  const isMobile = useMediaQuery(media.phoneLandscape);

  const rowHeight = useMemo(
    () =>
      isMobile
        ? ROW_HEIGHT_MOBILE_PX + ROW_MARGIN_MOBILE_PX * 2
        : ROW_HEIGHT_DESKTOP_PX + ROW_MARGIN_DESKTOP_PX * 2,
    [isMobile],
  );

  return { rowHeight };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
