import { useCallback, useState } from 'react';

const useLogic = () => {
  const [isLoading, setLoading] = useState(true);

  const onLoad = useCallback(() => setLoading(false), []);
  const onError = useCallback(() => setLoading(false), []);

  return { isLoading, onLoad, onError };
};

export default useLogic;
