import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Home: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={(size * 22) / 20}
    viewBox="0 0 20 22"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="nonzero"
      d="M6 20v-9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v9h3a1 1 0 0 0 1-1V8.49l-8-6.223-8 6.222V19a1 1 0 0 0 1 1h3zm2 0h4v-8H8v8zM.386 7.21l9-7a1 1 0 0 1 1.228 0l9 7A1 1 0 0 1 20 8v11a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V8a1 1 0 0 1 .386-.79z"
    />
  </svg>
);

export default memo(Home);
