import { getCourse, getSettings } from 'graphql/requests';
import {
  setAmplitudeUserProperties,
  setUpAmplitude,
  registerAmplitudeUser,
  unregisterAmplitudeUser,
  trackAmplitudeEvent,
} from './amplitude';
import {
  setBrazeUserProperties,
  setUpBraze,
  registerBrazeUser,
  trackBrazeEvent,
} from './braze';
import { AnalyticsEvent } from './types';

export const setUpAnalytics = () => {
  setUpAmplitude();
  setUpBraze();
};

export const setAnalyticsUserProperties = (
  userProps: Record<string, string>,
) => {
  setBrazeUserProperties(userProps);
  setAmplitudeUserProperties(userProps);
};

export const registerAnalyticsUser = (userId: string) => {
  registerAmplitudeUser(userId);
  registerBrazeUser(userId);
};

export const unregisterAnalyticsUser = () => {
  unregisterAmplitudeUser();
};

export const trackEvent = (
  event: AnalyticsEvent,
  data?: Record<string, unknown>,
) => {
  trackAmplitudeEvent(event, data);

  if (event === 'Onboard Home') {
    trackBrazeEvent(event, data);
  }
};

export const trackMediaEvent = async (
  event: AnalyticsEvent,
  courseId: string,
  data: Record<string, unknown> = {},
) => {
  const course = await getCourse(courseId);
  const { player } = await getSettings();
  const playerCode = player?.code;
  const playerCourseId = player?.courseId;

  const code =
    playerCode && playerCourseId === courseId
      ? playerCode
      : `${event.replace(/\s/g, '')}-${courseId}-${new Date().getTime()}`;

  if (course && code) {
    let type: string = 'course';
    if (course.packTitle === 'Conversations') {
      type = 'conversation';
    } else if (course.courseType === 'lesson') {
      type = 'lesson';
    } else if (course.courseType === 'meditation') {
      type = course.meditation_type || 'course';
    }

    const payload = {
      trackID: course.id,
      name: course.title,
      category: course.courseType === 'lesson' ? 'theory' : 'practice',
      hash: course.courseHash,
      type,
      packID: !course.isDaily ? course.packId : 'noPackID',
      packName: course.packTitle || 'noPackID',
      duration: course.mp3_length,
      platform: 'web',
      code,
      ...data,
    };

    trackAmplitudeEvent(event, payload);

    if (event !== 'Media Notes View') {
      trackBrazeEvent(event, payload);
    }
  }
};
