import React, { FC, memo } from 'react';
import { AnimatedBackground, LoaderContainer } from './styles';
import { Props } from './types';

const LoaderBox: FC<Props> = (props) => (
  <LoaderContainer {...props}>
    <AnimatedBackground />
  </LoaderContainer>
);

export default memo(LoaderBox);
