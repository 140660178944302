import styled from 'styled-components';
import MaterialSlider from '@material-ui/core/Slider';
import IconButton from 'components/IconButton';
import DefaultVolumeIcon from 'components/Icons/Volume';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 100%;
  justify-content: flex-end;
`;

export const Volume = styled(IconButton)`
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
`;

export const VolumeIcon = styled(DefaultVolumeIcon).attrs(() => ({
  size: 16,
}))``;

export const Slider = styled(MaterialSlider)`
  &.MuiSlider-root {
    color: ${({ theme }) => theme.colors.snow};
    display: block;
    margin-left: ${({ theme }) => theme.margin.xsm};
    padding: 5px 0;
  }
  & .MuiSlider-rail,
  & .MuiSlider-track,
  & .MuiSlider-thumb {
    background-color: ${({ theme }) => theme.colors.snow};
  }
`;
