import styled from 'styled-components';
import Switch from 'components/Switch';
import DefaultNoElements from './NoElements';

export const NoFavoritesContainer = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  left: 1.6rem;
  position: absolute;
  right: 1.6rem;
  top: 0;
`;

export const SectionHeader = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.margin.xl};
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.font.textSuperSmall};
  font-weight: bold;
  text-transform: uppercase;
`;

export const AutoPlay = styled(Switch)`
  transform: scale(0.9);
`;

export const NoElements = styled(DefaultNoElements)`
  margin-top: ${({ theme }) => theme.margin.sm};
`;
