import React, { FC, memo } from 'react';
import {
  Container,
  Description,
  DiscountTag,
  Info,
  Price,
  Select,
  Sup,
  Title,
} from './styles';
import { Props } from './types';

const SubscriptionPlan: FC<Props> = ({
  className,
  description,
  discount,
  onClick,
  price,
  title,
  to,
}) => (
  <Container className={className} onClick={onClick} to={to}>
    <Info>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Price>
        <Sup>$</Sup>
        {price}
      </Price>
      {discount && <DiscountTag>SAVE {discount}%</DiscountTag>}
    </Info>
    <Select>Select</Select>
  </Container>
);

export default memo(SubscriptionPlan);
