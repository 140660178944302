/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const SUBSCRIPTION_DATA_FRAGMENT = gql`
  fragment SubscriptionData on Subscription {
    billing_cycle
    corporate_plan_id
    currency
    expires_at
    external_id
    external_plan_id
    id
    is_eligible_for_sharing
    offer_id
    offer_type
    original_sub_type
    original_time
    plan_change_pending
    price
    price_usd
    renew_intent
    source
    start_time
    status
    sub_type
    subscription_plan_id
    token
  }
`;
