import { apolloClient } from 'services/api';
import { normalizeUser } from 'models/User';
import { ME } from 'graphql/queries';
import { Me } from 'graphql/generated/Me';

const getProfile = async () => {
  const { data: meData } = await apolloClient.query<Me>({
    query: ME,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  });

  return normalizeUser(meData?.me);
};

export default getProfile;
