import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { DEFAULT_SETTINGS, normalizeSettings } from 'models/Settings';
import { GetSettings } from 'graphql/generated/GetSettings';
import { GET_SETTINGS } from 'graphql/queries';
import { HookOptions } from '../types';

const useSettings = (options?: HookOptions) => {
  const { data: settingsData, loading: isLoadingSettings } = useQuery<
    GetSettings
  >(GET_SETTINGS, {
    fetchPolicy: options?.fetchPolicy || 'cache-first',
  });

  const settings = useMemo(() => {
    const rawSettings = settingsData?.me?.settings;
    return rawSettings ? normalizeSettings(rawSettings) : DEFAULT_SETTINGS;
  }, [settingsData]);

  return {
    loading: !settingsData && isLoadingSettings,
    settings,
  };
};

export default useSettings;

export type UseSetting = ReturnType<typeof useSettings>;
