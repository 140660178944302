import React, { FC, memo } from 'react';
import {
  ChevronRight,
  CloseButton,
  CloseIcon,
  Container,
  Info,
  Link,
  Links,
  Timer,
  UserInfo,
  UserStats,
} from './styles';
import { Props, Route } from './types';
import useConnect from './connect';

const LINKS: Route[] = [
  {
    title: 'Home',
    path: '/',
    exact: true,
  },
  {
    title: 'Theory',
    path: '/theory',
  },
  {
    title: 'Practice',
    path: '/practice',
  },
  {
    title: 'Favorites',
    path: '/favorites',
  },
  {
    title: 'Settings',
    path: '/account',
  },
];

const MobileNav: FC<Props> = ({ isOpen, onClose }) => {
  const { profile, openSelfTimer } = useConnect(onClose);

  return (
    <Container open={isOpen}>
      <CloseButton onClick={onClose}>
        <CloseIcon />
      </CloseButton>
      <Info>
        <UserInfo
          avatarUrl={profile.avatarUrl}
          city={profile.city}
          email={profile.email}
          firstName={profile.firstName}
          inline
          joinedAt={profile.joinedAt}
          lastName={profile.lastName}
        />
        <UserStats
          bigValues
          currentStreak={profile.activityStreakDays}
          forceRows
          hideSeparators
          longestStreak={profile.activityLongestStreak}
          mindfulDays={profile.activityMindfulDays}
          mindfulMinutes={profile.activityMindfulMinutes}
          reverseColors
          shares={profile.activityRedeemedCount}
          titlePosition="bottom"
          totalSessions={profile.activityTotalSessions}
        />
      </Info>
      <Links>
        {LINKS.map(({ path, title, exact }) => (
          <Link
            exact={exact}
            key={path}
            onClick={onClose}
            to={path}
            title={title}
            id={`nav-mobile-${title.toLowerCase()}`}
          >
            {title}
            <ChevronRight />
          </Link>
        ))}
      </Links>
      <Timer onClick={openSelfTimer} />
    </Container>
  );
};

export default memo(MobileNav);
