import styled from 'styled-components';
import { Container as Child } from './Element/styles';

export const Container = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.pad.xl};
`;

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.textSuperBig};
  margin-bottom: ${({ theme }) => theme.margin.md};
  text-align: center;
  text-transform: uppercase;
  width: 100%;
`;

export const ElementList = styled.div`
  display: flex;
  flex-direction: column;

  ${Child}:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.margin.sm};
  }
`;
