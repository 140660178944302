import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const HeartFull: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={size * (448.12192 / 512.00037)}
    viewBox="0 0 512.00037 448.12192"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M462.31227 30.633199c-54.8-46.7-136.3-38.3000001-186.6 13.6l-19.7 20.3-19.7-20.3c-50.2-51.9000001-131.8-60.3-186.600001-13.6-62.8 53.6-66.1 149.800001-9.9 207.900001l193.500001 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.300001-9.8-207.900001z"
    />
  </svg>
);

export default memo(HeartFull);
