import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { trackEvent } from 'services/analytics';
import {
  CourseStatus,
  CoursePermission,
  getNextIntroCourse,
  MeditationType,
} from 'models/Course';
import useMe from 'graphql/hooks/useMe';
import usePlayer from 'graphql/hooks/usePlayer';
import useDailyMeditation from 'graphql/hooks/useDailyMeditation';
import usePacks from 'graphql/hooks/usePacks';
import { INTRO_COURSES_BACKGROUND_COLORS } from './constants';

const useConnect = () => {
  const { isDailyUnlocked } = useMe();
  const { openCourse } = usePlayer();
  const {
    dailyMeditation: daily,
    loading: isLoadingDaily,
  } = useDailyMeditation();
  const { introPack, loading: isLoadingIntroPack } = usePacks();
  const { push } = useHistory();

  const isDaily = isDailyUnlocked && !!daily;

  const {
    backgroundColor,
    nextMeditationId,
    isAvailable,
    image,
    title,
    subtitle,
    isCompleted,
  } = useMemo(() => {
    if (isDaily) {
      return {
        backgroundColor: daily!.squareImageColor,
        nextMeditationId: daily!.id,
        isAvailable: daily!.permission === CoursePermission.UNLOCKED,
        image: daily!.squareImageThumb,
        title: 'Daily Meditation',
        subtitle: daily!.displaySubtitle,
        isCompleted: daily!.status === CourseStatus.FINISHED,
      };
    }

    const introCourses = introPack?.courses.filter(
      (v) => v.meditationType !== MeditationType.SPECIAL_INTRO,
    );
    const nextIntroCourse = introCourses
      ? getNextIntroCourse(introCourses)
      : undefined;
    return {
      backgroundColor: nextIntroCourse?.title
        ? INTRO_COURSES_BACKGROUND_COLORS[nextIntroCourse.title]
        : '',
      nextMeditationId: nextIntroCourse?.id || '',
      isAvailable: nextIntroCourse?.permission === CoursePermission.UNLOCKED,
      image: nextIntroCourse?.imageUrl || '',
      title: nextIntroCourse?.title || '',
      subtitle: '',
      isCompleted: nextIntroCourse?.status === CourseStatus.FINISHED,
    };
  }, [daily, introPack, isDaily]);

  const play = useCallback(async () => {
    if (nextMeditationId) {
      if (isAvailable) {
        await openCourse({ id: nextMeditationId });
      } else {
        push('/account/subscription');
      }
    }
  }, [nextMeditationId, isAvailable, openCourse, push]);

  const lockClick = useCallback(async () => {
    if (nextMeditationId) {
      trackEvent('Purchase Initiate Checkout', {
        source: 'home',
        trackID: nextMeditationId,
        type: 'content',
      });
    }
  }, [nextMeditationId]);

  return {
    actionHref: isDaily
      ? '/practice/daily-meditation'
      : '/practice/introductory-course',
    backgroundColor,
    image,
    isAvailable,
    isCompleted,
    isDaily,
    isLoading: !nextMeditationId || isLoadingDaily || isLoadingIntroPack,
    lockClick,
    play,
    subtitle,
    title,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
