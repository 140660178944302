import { useMemo, useCallback } from 'react';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { ModalType } from 'graphql/generated/globalTypes';
import useSettings from 'graphql/hooks/useSettings';
import useSettingsActions from 'graphql/hooks/useSettingsActions';
import useModal from 'graphql/hooks/useModal';
import { validationSchema } from './constants';
import { Props, FormFields } from './types';

const useConnect = (addProgress: Props['addProgress']) => {
  const {
    settings: { lastSessionAddDuration },
  } = useSettings();
  const { updateSettings } = useSettingsActions();
  const { close, type } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: FormFields = useMemo(
    () => ({
      date: new Date(),
      duration: lastSessionAddDuration,
      submitError: '',
    }),
    [lastSessionAddDuration],
  );

  const submit = useCallback(
    async (
      {
        date,
        duration,
      }: {
        date: FormFields['date'];
        duration: FormFields['duration'];
      },
      { setErrors }: { setErrors: FormikHelpers<FormFields>['setErrors'] },
    ) => {
      const finishedAt = new Date(date);
      // Reset to start of the day
      finishedAt.setHours(0, 0);

      try {
        await addProgress({
          duration_seconds: duration * 60,
          finished_at: finishedAt,
          progress_id: '0',
          progress_type: 'SelfTimer',
        });
        updateSettings({ lastSessionAddDuration: duration });

        enqueueSnackbar('Meditation time has been added', {
          variant: 'success',
        });

        close();
      } catch (error) {
        setErrors({ submitError: error.message });
      }
    },
    [addProgress, close, enqueueSnackbar, updateSettings],
  );

  return {
    closeModal: close,
    initialValues,
    isOpen: type === ModalType.ADD_MEDITATION_TIME,
    submit,
    validationSchema,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
