import { useEffect, useMemo, useCallback } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router';
import kebabCase from 'lodash/kebabCase';
import { apolloClient } from 'services/api';
import { PackType, PackPermission } from 'models/Pack';
import { CoursePermission } from 'models/Course';
import { GetSettings } from 'graphql/generated/GetSettings';
import { PlayerAutoplayType } from 'graphql/generated/globalTypes';
import { GET_SETTINGS } from 'graphql/queries';
import useModal from 'graphql/hooks/useModal';
import useMe from 'graphql/hooks/useMe';
import useSettings from 'graphql/hooks/useSettings';
import useFavorites from 'graphql/hooks/useFavorites';
import usePacks from 'graphql/hooks/usePacks';
import useDailyMeditation from 'graphql/hooks/useDailyMeditation';
import useCourseActions from 'graphql/hooks/useCourseActions';

const useConnect = () => {
  const { isAllContentUnlocked } = useMe();
  const {
    loading: isLoadingSettings,
    settings: { unlockedGuestPacks, packsAutoPlay },
  } = useSettings();
  const { toggle: toggleFavorite } = useFavorites();
  const {
    openCourseActions,
    openCourseNotes,
    openResetCourseConfirm,
    openShareLink,
  } = useModal();
  const { packs, loading: isLoadingPacks } = usePacks();
  const { play, toggleAutoPlay } = useCourseActions();
  const { dailyPack, loading: isLoadingDaily } = useDailyMeditation();
  const match = useRouteMatch<{ pack?: string; subpack?: string }>();
  const location = useLocation();
  const { push, replace } = useHistory();

  const currentBaseRoute = match.url;
  const packParam = match.params.pack;
  const subpackParam = match.params.subpack;
  const currentUrl = location.pathname;

  const { defaultPackTitle, practicePacks } = useMemo(() => {
    const practicePacksWithPermission = packs
      .filter(
        ({ type }) => type === PackType.MEDITATION || type === PackType.GUEST,
      )
      .map((pack) => {
        if (
          !isAllContentUnlocked &&
          pack.permission === PackPermission.UNLOCKED &&
          pack.type === PackType.GUEST &&
          !unlockedGuestPacks?.includes(pack.id)
        ) {
          return {
            ...pack,
            permission: PackPermission.LOCKED,
            courses: pack.courses.map((course) => ({
              ...course,
              permission: CoursePermission.LOCKED,
            })),
          };
        }

        return pack;
      });

    let defaultTitle = '';
    if (dailyPack) {
      defaultTitle = dailyPack.title || '';
    } else if (
      practicePacksWithPermission &&
      practicePacksWithPermission.length > 0
    ) {
      defaultTitle = practicePacksWithPermission[0].title || '';
    }

    return {
      defaultPackTitle: defaultTitle,
      practicePacks: dailyPack
        ? [dailyPack, ...practicePacksWithPermission]
        : practicePacksWithPermission,
    };
  }, [dailyPack, isAllContentUnlocked, packs, unlockedGuestPacks]);

  useEffect(() => {
    if (!packParam && defaultPackTitle) {
      apolloClient
        .query<GetSettings>({
          query: GET_SETTINGS,
          fetchPolicy: 'network-only',
        })
        .catch(() => true);

      replace(`${currentBaseRoute}/${kebabCase(defaultPackTitle)}`);
    }
  }, [currentBaseRoute, defaultPackTitle, packParam, replace]);

  const {
    backUrl,
    filteredPacks,
    selectedPackId,
    selectedPackCourses,
    selectedPackSubpacks,
  } = useMemo(() => {
    const selectedPack = practicePacks.find(({ title }) =>
      subpackParam
        ? kebabCase(title) === subpackParam
        : kebabCase(title) === packParam,
    );
    const filteredPacksList = subpackParam
      ? practicePacks.filter(
          (p) => p.parentPackId === selectedPack?.parentPackId,
        )
      : practicePacks.filter((p) => p.category !== 'sub_pack');
    const subpacks =
      !!selectedPack && selectedPack.category === 'master_pack'
        ? practicePacks.filter(
            ({ category, parentPackId }) =>
              category === 'sub_pack' &&
              parentPackId &&
              parentPackId === selectedPack.id,
          )
        : [];

    return {
      backUrl: subpackParam
        ? `${selectedPack?.urlBase}/${packParam}`
        : undefined,
      filteredPacks: filteredPacksList,
      selectedPackId: selectedPack?.id,
      selectedPackCourses: selectedPack?.courses,
      selectedPackSubpacks: subpacks,
    };
  }, [packParam, practicePacks, subpackParam]);

  const selectedPackAutoPlay = useMemo(
    () => (selectedPackId ? packsAutoPlay.includes(selectedPackId) : false),
    [selectedPackId, packsAutoPlay],
  );

  const loadTrack = useCallback(
    async (courseId: string) => {
      await play(courseId, PlayerAutoplayType.PACK);
    },
    [play],
  );

  const togglePackAutoPlay = useCallback(
    async (packId: string) => {
      await toggleAutoPlay(PlayerAutoplayType.PACK, packId);
    },
    [toggleAutoPlay],
  );

  return {
    backUrl,
    currentUrl,
    isLoading: isLoadingSettings || isLoadingPacks || isLoadingDaily,
    loadTrack,
    openCourseActions,
    openCourseNotes,
    openResetCourseConfirm,
    openShareLink,
    packs: filteredPacks,
    packParam,
    push,
    selectedPackAutoPlay,
    selectedPackCourses,
    selectedPackId,
    selectedPackSubpacks,
    subpackParam,
    toggleFavorite,
    togglePackAutoPlay,
  };
};

export default useConnect;

export type UseConnectReturnType = ReturnType<typeof useConnect>;
