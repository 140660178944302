import styled from 'styled-components';
import { buttonHover } from 'styles/mixins';
import { ZIndex, SIDEBAR_WIDTH } from 'styles/metrics';
import DefaultNavLink from './NavLink';
import DefaultTimer from '../Timer';

export const Container = styled.div`
  align-items: stretch;
  background-color: white;
  border-radius: ${({ theme }) => theme.radius.md};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 90vh;
  overflow-y: auto;
  padding: ${({ theme }) => `${theme.pad.lg} 0 ${theme.pad.xxxl} 0`};
  position: fixed;
  scrollbar-width: none;
  top: 1.6rem;
  width: ${SIDEBAR_WIDTH};
  z-index: ${ZIndex.SIDEBAR};
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const LogoImg = styled.img`
  align-self: center;
  display: block;
  width: 70%;
`;

export const Links = styled.div`
  padding: ${({ theme }) =>
    `${theme.pad.xxl} ${theme.pad.xxxl} 0 ${theme.pad.xxxl}`};
`;

export const NavLink = styled(DefaultNavLink)``;

export const Separator = styled.div`
  border-top: 1px solid rgba(44, 62, 80, 0.3);
  height: ${({ theme }) => theme.margin.xlg};
`;

export const Timer = styled(DefaultTimer)`
  margin: ${({ theme }) => `0 ${theme.pad.xxl}`};

  ${buttonHover};
`;
