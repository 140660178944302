import DefaultHeartHollow from 'components/Icons/HeartHollow';
import styled from 'styled-components';

export const Container = styled.div`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: ${({ theme }) => theme.font.text};
`;

export const HeartHollow = styled(DefaultHeartHollow)`
  width: ${({ theme }) => theme.font.textBig};
  margin: 0 ${({ theme }) => theme.margin.xxxsm} -0.3rem ${({ theme }) => theme.margin.xxxsm};
`;
