import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GetShopperToken } from '../generated/GetShopperToken';
import { GET_SHOPPER_TOKEN } from '../queries';

const useShopperToken = () => {
  const { data, loading } = useQuery<GetShopperToken>(GET_SHOPPER_TOKEN);

  const shopperToken = useMemo(() => data?.getShopperToken?.token, [data]);

  return {
    loading,
    shopperToken,
  };
};

export default useShopperToken;
