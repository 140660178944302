import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Close: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    height={size}
    viewBox="0 0 16 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M1.14693 15.972866c-.27972 0-.55946-.111453-.78379-.335628-.44692-.419603-.44692-1.147364 0-1.566968l6.04264-6.0699903L.33601 1.9574242C-.112 1.5105181-.112.81117825.33601.36315318c.44689-.44802506 1.1462-.44802506 1.59421 0L8 6.4060087 14.06978.3360188c.44689-.44802507 1.1462-.44802507 1.59421 0 .44801.44690612.44801 1.1462459 0 1.594271L9.59422 8.0002797l6.04264 6.0699903c.44801.446906.44801 1.146246 0 1.594271-.22401.224001-.50373.335459-.78346.335459-.27972 0-.55946-.111452-.78345-.335459L8.00017 9.5945507 1.93039 15.637406c-.22401.224002-.50373.33546-.78346.33546z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default memo(Close);
