import { gql } from '@apollo/client';
import {
  COURSE_DATA_FRAGMENT,
  FEATURE_CONTENT_DATA_FRAGMENT,
} from '../fragments';

// TODO: Remove this when we have the new course query implemented.
export const GET_FEATURES = gql`
  query GetFeatures {
    me {
      id
      is_subscribed
    }
    features {
      total
      data {
        id
        course {
          ...CourseData
        }
        created_at
        deep_link
        updated_at
      }
    }
  }
  ${COURSE_DATA_FRAGMENT}
`;

export const GET_FEATURE_CONTENT = gql`
  query GetFeatureContent {
    feature_content {
      ...FeatureContentData
    }
  }
  ${FEATURE_CONTENT_DATA_FRAGMENT}
`;
