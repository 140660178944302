import SectionHeader from 'components/SectionHeader';
import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div`
  color: ${({ theme }) => theme.textLightBg.text};
  display: flex;
  flex-direction: column;
  min-height: calc(
    100vh - 80px - ${({ theme }) => theme.pad.xxxl} -
      ${({ theme }) => theme.margin.md}
  );

  ${media.tabletPortrait} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${({ theme }) => theme.pad.xl};
    min-height: calc(
      100vh - 80px - ${({ theme }) => theme.pad.xl} -
        ${({ theme }) => theme.margin.md}
    );
  }

  ${media.phoneLandscape} {
    min-height: calc(
      100vh - 80px - ${({ theme }) => theme.pad.lg} -
        ${({ theme }) => theme.margin.md}
    );
    padding: ${({ theme }) => theme.pad.lg};
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 32rem;

  p {
    color: inherit !important;
  }

  button {
    margin-bottom: ${({ theme }) => theme.margin.md};
  }
`;

export const Title = styled.h2`
  font-size: 18px;
  text-align: center;
  margin: 20px;
  text-transform: uppercase;
`;

export const Header = styled(SectionHeader)`
  align-self: start;
`;
