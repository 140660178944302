import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/mixins';
import LogoBase from 'components/Logo';
import DefaultButton from 'components/Button';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}))`
  background-color: ${({ theme }) => theme.colors.grey};
  display: flex;
  height: 100%;
  min-height: 100vh;
`;

export const Left = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem;
  width: 700px;

  ${media.tabletLandscape} {
    align-self: center;
    width: 100%;
  }
`;

export const Right = styled.div`
  flex: 1;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue500};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.tabletLandscape} {
    display: none;
  }
`;

export const Logo = styled(LogoBase).attrs(() => ({
  lightBg: true,
}))`
  margin-bottom: ${({ theme }) => theme.margin.xl};

  ${media.phoneLandscape} {
    padding: ${({ theme }) => theme.pad.sm};
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: 1.85rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.25;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1;
  padding-top: 1rem;
  padding-bottom: 2rem;
  white-space: pre-wrap;
`;

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: 1.45rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.25;
  margin-bottom: 1rem;
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Button = styled(DefaultButton)`
  &&& {
    background-color: transparent;
    color: black;
    margin-top: 0.6rem;
    max-width: 16rem;

    span {
      font-size: 0.8rem;
      color: black;
    }
  }
`;
