/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { Props } from './types';

const ErrorStyle = css`
  color: ${({ theme }) => theme.colors.error};
  a {
    color: #8294a5;
  }
`;

export const Text = styled.p<Props>`
  backface-visibility: hidden;
  color: ${({ theme }) => theme.colors.snow};
  ${({ error }) => error && ErrorStyle};
  display: block;
  font-size: ${({ theme, large }) =>
    large ? theme.font.textBig : theme.font.text};
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1.55;
  margin-bottom: ${({ theme }) => theme.pad.md};
  text-align: ${({ center }) => (center ? 'center' : 'left')};

  b,
  strong {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`;
