import React, { FC, memo } from 'react';
import { DAILY_MEDITATION_PACK_ID, PackPermission } from 'models/Pack';
import {
  Container,
  Image,
  Info,
  Title,
  Description,
  Status,
  Circle,
  LockIcon,
  StarIcon,
  StretchedImage,
} from './styles';
import { Props } from './types';

const Item: FC<Props> = ({
  backgroundColor,
  className,
  description,
  displayTitle,
  id,
  image,
  isSelected,
  permission,
  title,
}) => {
  const isDailyPack = id === DAILY_MEDITATION_PACK_ID;
  const isNotDailyPack = id !== DAILY_MEDITATION_PACK_ID;

  const info = (
    <>
      {displayTitle && (
        <Info>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </Info>
      )}
      {permission !== PackPermission.UNLOCKED && (
        <Status>
          <Circle>
            {permission === PackPermission.LOCKED ? <StarIcon /> : <LockIcon />}
          </Circle>
        </Status>
      )}
    </>
  );

  return (
    <Container $isSelected={isSelected} className={className}>
      {isDailyPack && (
        <StretchedImage
          $backgroundColor={backgroundColor}
          alt={title}
          src={image}
        >
          {info}
        </StretchedImage>
      )}
      {isNotDailyPack && (
        <Image alt={title} src={image}>
          {info}
        </Image>
      )}
    </Container>
  );
};

export default memo(Item);
