import styled, { css } from 'styled-components';
import { buttonHover, media } from 'styles/mixins';
import { Props } from './types';

export const Container = styled.div<{
  $isTimeSectionOpen: Props['isTimeSectionOpen'];
}>`
  height: 100%;
  width: 100%;

  ${({ $isTimeSectionOpen }) =>
    $isTimeSectionOpen &&
    css`
      ${media.tabletPortrait} {
        display: none;
      }
    `}
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.snow};
  font-size: ${({ theme }) => theme.font.heading};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.margin.xxxsm};
  margin-top: ${({ theme }) => theme.margin.sm};
`;

export const FinishTime = styled.h2`
  color: ${({ theme }) => theme.colors.snow};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 1.15rem;
  margin-bottom: ${({ theme }) => theme.margin.xsm};
`;

export const Begin = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.blue500};
  border-radius: ${({ theme }) => theme.radius.md};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-top: ${({ theme }) => theme.margin.xsm};
  padding: ${({ theme }) => theme.pad.md};
  text-align: center;
  width: 100%;

  ${buttonHover}
`;
