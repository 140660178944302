import { useEffect } from 'react';
import { apolloClient } from 'services/api';
import { media } from 'styles/mixins';
import useMediaQuery from 'lib/useMediaQuery';
import { GetSettings } from 'graphql/generated/GetSettings';
import { GET_SETTINGS } from 'graphql/queries';

const useConnect = () => {
  const isTablet = useMediaQuery(media.tabletPortrait);

  useEffect(() => {
    apolloClient
      .query<GetSettings>({
        query: GET_SETTINGS,
        fetchPolicy: 'network-only',
      })
      .catch(() => true);
  }, []);

  return { isTablet };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
