import { QueryOptions } from '@apollo/client';
import { apolloClient } from 'services/api';
import { normalizeFeatureContent } from 'models/FeatureContent';
import { GetFeatureContent } from '../generated/GetFeatureContent';
import { GET_FEATURE_CONTENT } from '../queries';

const getFeatureContent = async (
  fetchPolicy: QueryOptions<GetFeatureContent>['fetchPolicy'] = 'cache-first',
) => {
  const { data: featureContentData } = await apolloClient.query<
    GetFeatureContent
  >({
    query: GET_FEATURE_CONTENT,
    errorPolicy: 'all',
    fetchPolicy,
  });

  return featureContentData?.feature_content
    ? normalizeFeatureContent(featureContentData.feature_content)
    : undefined;
};

export default getFeatureContent;
