import styled, { css } from 'styled-components';
import { media } from 'styles/mixins';
import DefaultLoaderBox from 'components/LoaderBox';
import DefaultImage from 'components/Image';
import DefaultStretchedImage from 'components/StretchedImage';

export const ImageLoader = styled(DefaultLoaderBox)`
  height: 100%;
  width: 100%;
`;

export const Image = styled(DefaultImage)<{ $isIntro: boolean }>`
  border-radius: inherit;
  display: block;
  object-fit: contain;

  ${({ $isIntro }) =>
    $isIntro &&
    css`
      background-size: contain;
      background-color: #ffffff;
    `}
`;

export const StretchedImage = styled(DefaultStretchedImage)<{
  $backgroundColor?: string;
}>`
  border-radius: inherit;
  height: 100%;
  width: 100%;

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};
`;

export const ImageWrapper = styled.div<{ $isPlaying: boolean }>`
  transform: ${({ $isPlaying }) => ($isPlaying ? 'scale(1)' : 'scale(0.9)')};
  transition: transform 0.1s linear;
`;

export const CourseNumber = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 0.8;

  ${media.tabletPortrait} {
    margin-bottom: 2rem;
  }
`;

export const Text = styled.div``;

export const Title = styled.div`
  flex-shrink: 0;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.2;
  overflow: inherit;
  text-overflow: inherit;
`;

export const Description = styled.div`
  line-height: 1.2;
  overflow: inherit;
  text-overflow: inherit;
`;

export const Container = styled.div<{ $isMaximized: boolean }>`
  align-items: center;
  display: flex;
  flex-flow: ${({ $isMaximized }) =>
    $isMaximized ? 'column nowrap' : 'row nowrap'};
  justify-content: ${({ $isMaximized }) =>
    $isMaximized ? 'center' : 'flex-start'};
  position: ${({ $isMaximized }) => ($isMaximized ? 'relative' : 'absolute')};
  right: ${({ $isMaximized }) => ($isMaximized ? 'auto' : '73.5%')};
  top: ${({ $isMaximized }) => ($isMaximized ? 'auto' : 'calc(50% - 21px)')};
  left: ${({ $isMaximized }) => ($isMaximized ? 'auto' : '3.4rem')};

  ${ImageWrapper} {
    border-radius: ${({ $isMaximized }) => ($isMaximized ? '1rem' : '0.3rem')};
    height: ${({ $isMaximized }) => ($isMaximized ? '35vh' : '42px')};
    margin-bottom: ${({ $isMaximized }) => ($isMaximized ? '1rem' : '0')};
    margin-right: ${({ $isMaximized }) => ($isMaximized ? '0' : '0.8rem')};
    width: ${({ $isMaximized }) =>
      $isMaximized ? 'calc(3 / 2 * 35vh)' : 'calc(3 / 2 * 42px)'};
  }

  ${CourseNumber} {
    color: ${({ $isMaximized }) =>
      $isMaximized ? 'rgba(27, 43, 85, 0.3)' : 'rgba(27, 43, 85, 0.8)'};
    font-size: ${({ $isMaximized }) => ($isMaximized ? '22rem' : '3.2rem')};
    margin-bottom: ${({ $isMaximized }) => ($isMaximized ? '2rem' : '0')};
    margin-right: ${({ $isMaximized }) => ($isMaximized ? '0' : '0.8rem')};
  }

  ${Text} {
    overflow: ${({ $isMaximized }) => ($isMaximized ? 'visible' : 'hidden')};
    text-overflow: ${({ $isMaximized }) =>
      $isMaximized ? 'clip' : 'ellipsis'};
    white-space: ${({ $isMaximized }) => ($isMaximized ? 'normal' : 'nowrap')};
  }

  ${Title} {
    font-size: ${({ $isMaximized }) => ($isMaximized ? '1.5rem' : '0.8rem')};
    text-align: ${({ $isMaximized }) => ($isMaximized ? 'center' : 'left')};
  }

  ${Description} {
    font-size: ${({ $isMaximized }) => ($isMaximized ? '1rem' : '0.7rem')};
    margin-top: ${({ $isMaximized }) => ($isMaximized ? '0.6rem' : '0.3rem')};
    text-align: ${({ $isMaximized }) => ($isMaximized ? 'center' : 'left')};
    font-weight: ${({ $isMaximized, theme }) =>
      $isMaximized ? theme.font.weight.medium : 'normal'};
  }

  ${media.tabletLandscape} {
    right: ${({ $isMaximized }) => ($isMaximized ? 'auto' : '71%')};

    ${CourseNumber} {
      font-size: ${({ $isMaximized }) => ($isMaximized ? '18rem' : '3.2rem')};
      margin-bottom: ${({ $isMaximized }) => ($isMaximized ? '1.2rem' : '0')};
    }

    ${ImageWrapper} {
      left: ${({ $isMaximized }) =>
        $isMaximized ? 'auto' : 'calc(30% - 65px)'};
    }
  }

  ${media.tabletPortrait} {
    justify-content: center;
    left: auto;
    max-width: 70vw;
    right: auto;
    top: ${({ $isMaximized }) => ($isMaximized ? 'auto' : '0.6rem')};
    ${ImageWrapper} {
      display: ${({ $isMaximized }) => ($isMaximized ? 'block' : 'none')};
      height: 30vh;
      width: calc(3 / 2 * 30vh);
    }
    ${CourseNumber} {
      display: ${({ $isMaximized }) => ($isMaximized ? 'block' : 'none')};
    }
    ${Title} {
      font-size: ${({ $isMaximized }) => ($isMaximized ? '1.5rem' : '1rem')};
      text-align: center;
    }
    ${Description} {
      display: ${({ $isMaximized }) => ($isMaximized ? 'block' : 'none')};
    }
  }

  ${media.phonePortrait} {
    top: ${({ $isMaximized }) => ($isMaximized ? 'auto' : '0.8rem')};
    ${ImageWrapper} {
      height: 80vw;
      max-height: 42vh;
      width: 80vw;
    }
  }

  @media (max-height: 500px) {
    ${ImageWrapper} {
      display: none;
    }
    ${CourseNumber} {
      display: none;
    }
  }
`;
