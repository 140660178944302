import React, { FC, useCallback } from 'react';
import { BaseLinkStyle } from './styles';

export interface LinkProps {
  title?: string;
  href?: string;
  onClick?: () => void;
  className?: string;
}

// ToDo: Get rid of this component
export const BaseLink: FC<LinkProps> = ({
  className,
  href,
  onClick,
  ...props
}) => (
  <BaseLinkStyle
    className={className}
    to={href || '#'}
    onClick={useCallback(
      (event) => {
        if (onClick) {
          event.preventDefault();
          onClick();
        }
      },
      [onClick],
    )}
    {...props}
  />
);
