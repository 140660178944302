import React, { FC } from 'react';
import { Formik } from 'formik';
import Form from 'components/Form';
import Button from 'components/Button';
import Text from 'components/Text';
import { LoginCodeField, Resend } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Email: FC<Props> = ({ login, resend }) => {
  const { initialValues, submit, validationSchema } = useConnect({ login });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submit}
    >
      {({ errors, handleSubmit, isSubmitting, setErrors, touched, values }) => {
        const error =
          (touched.pin && errors.pin) ||
          errors.submitError ||
          errors.resendError;

        return (
          <Form onSubmit={handleSubmit}>
            <LoginCodeField name="pin" />
            {error && (
              <Text error center>
                {error}
              </Text>
            )}
            <Button
              data-cypress-id="login-email-next"
              disabled={!values.pin.join('')}
              isLoading={isSubmitting}
              type="submit"
            >
              NEXT
            </Button>
            <Resend resend={resend} setErrors={setErrors} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default Email;
