import useAuth from 'graphql/hooks/useAuth';
import { useEffect } from 'react';
import { Me } from 'graphql/generated/Me';
import { ME } from 'graphql/queries';
import { setToken, deleteToken, setAnonymousUserToken } from 'services/storage';
import { initializeCacheLocalState } from 'graphql/cache';
import {
  registerAnalyticsUser,
  unregisterAnalyticsUser,
} from 'services/analytics';
import { useRouteMatch } from 'react-router';
import { useApolloClient } from '@apollo/client';

const useConnect = () => {
  const apolloClient = useApolloClient();
  const { init, token, setInit } = useAuth();
  const isVisitor = !!useRouteMatch('/visitor');

  useEffect(() => {
    const handleAuth = async () => {
      try {
        setInit(false);

        if (isVisitor) {
          setAnonymousUserToken();
        }

        const { data } = await apolloClient.query<Me>({ query: ME });
        if (!data?.me) {
          throw new Error();
        }

        if (data.me.token) {
          setToken(data.me.token);
        } else {
          deleteToken();
        }

        initializeCacheLocalState();

        if (!isVisitor) {
          registerAnalyticsUser(data.me.uuid);
        }
      } catch (e) {
        deleteToken();
        unregisterAnalyticsUser();
      } finally {
        setInit(true);
      }
    };

    handleAuth();
  }, [apolloClient, isVisitor, setInit]);

  return { init, isAuthenticated: !!token };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
