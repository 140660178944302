import React, { FC, memo } from 'react';
import { BaseIconProps } from '.';

type Props = BaseIconProps & {
  volume?: number;
};

const Volume: FC<Props> = ({ size = 24, volume = 10, ...rest }) => (
  <svg
    height={(size * 16) / 17}
    viewBox="0 0 17 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g fill="currentColor">
      <path d="M0 11h8V5.001H0z" />
      <path d="M9.9999-.0002l-6 5v6l6 5z" />
      <path
        d="M12.00585938 1.31445312a.50005.50005 0 0 0-.16015626.97851568C14.253626 3.0716227 16 5.3269177 16 8c0 2.6741577-1.7461164 4.927371-4.15429688 5.707031a.5009185.5009185 0 1 0 .30859376.953125C14.9661164 13.749816 17 11.109842 17 8c0-3.1089177-2.033626-5.7488571-4.84570312-6.65820312a.50005.50005 0 0 0-.1484375-.0273438z"
        opacity={0.02 * volume - 1}
      />
      <path
        d="M12.00585938 4.6289062a.50005.50005 0 0 0-.29296876.9140626C12.49024341 6.0866862 13 6.9789473 13 8c0 1.0199121-.51029187 1.9145465-1.28710938 2.4570312a.50005.50005 0 1 0 .57421876.8183598C13.3202919 10.5538753 14 9.3540879 14 8c0-1.3549473-.6802434-2.5550612-1.71289062-3.2773438a.50005.50005 0 0 0-.28125-.09375z"
        opacity={0.02 * volume}
      />
    </g>
  </svg>
);

export default memo(Volume);
