import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from 'styles/mixins';
import DefaultLock from 'components/Icons/Lock';
import DefaultStar from 'components/Icons/Star';
import DefaultCopyIcon from 'components/Icons/Copy';
import DefaultImage from 'components/Image';

const commonStyles = css`
  border-radius: ${({ theme }) => theme.radius.md};
  cursor: pointer;
  position: relative;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.9;
  }
`;

export const ContainerDiv = styled.div`
  ${commonStyles}
`;

export const ContainerLink = styled(Link)`
  ${commonStyles}
  color: inherit;
`;

export const PermissionOverlay = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: inherit;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const LockIcon = styled(DefaultLock)`
  color: ${({ theme }) => theme.colors.snow};
`;

export const StarIcon = styled(DefaultStar)`
  fill: ${({ theme }) => theme.colors.snow};
`;

export const CopyIcon = styled(DefaultCopyIcon)`
  opacity: 0.75;
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  transform: rotate(180deg);

  ${media.phonePortrait} {
    right: 0.6rem;
    top: 0.6rem;
    transform: rotate(180deg) scale(0.8);
  }
`;

export const Image = styled(DefaultImage)`
  border-radius: ${({ theme }) => theme.radius.md};
  display: block;
  height: 100%;
  width: 100%;
`;

export const Title = styled.div`
  bottom: 1.4rem;
  font-size: 1.1rem;
  left: 1.2rem;
  line-height: 1;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: pre-wrap;

  ${media.tabletPortrait} {
    font-size: 1.3rem;
  }

  ${media.phonePortrait} {
    bottom: 1rem;
    font-size: 0.95rem;
    left: 0.8rem;
  }
`;
