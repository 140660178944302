import React, { FunctionComponent } from 'react';

import { Link } from './styles';

interface Props {
  href?: string;
  onClick?: () => void;
}

const LogoutLink: FunctionComponent<Props> = (props) => (
  <Link {...props}>Log Out</Link>
);

export default LogoutLink;
