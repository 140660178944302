import { Howl } from 'howler';
import { createSelfTimerAsset } from 'models/SelfTimerAsset';

export const AMBIENT_NOISES = [
  {
    name: 'Room',
    url: require('./room_tone.mp3'),
  },
].map(createSelfTimerAsset);

export const ambientRoomTone = new Howl({
  src: [AMBIENT_NOISES[0].url],
  loop: true,
});
