import styled from 'styled-components';

export const Field = styled.div`
  margin-bottom: 1.5rem;
`;

export const Label = styled.label``;

export const Input = styled.div`
  background-color: ${({ theme }) => theme.colors.snow};
  margin-top: 0.5rem;
  padding: 1rem;
  border-radius: 3px;
`;
