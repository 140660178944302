import { gql } from '@apollo/client';

export const INIT = gql`
  query Init {
    init @client
  }
`;

export const GET_LOGIN_EMAIL = gql`
  query GetLoginEmail {
    loginEmail @client
  }
`;
