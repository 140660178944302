import styled from 'styled-components';
import DefaultTextFileIcon from 'components/Icons/TextFile';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
`;

export const NotesIcon = styled(DefaultTextFileIcon).attrs(() => ({
  size: 17,
}))`
  cursor: pointer;
`;

export const Title = styled.div`
  font-size: 0.75rem;
  line-height: 1;
  margin-top: 0.6rem;
`;
