import React from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from 'react-stripe-elements';
import { Field, Input, Label } from './styles';

const CardSection = () => (
  <>
    <Field>
      <Label htmlFor="card-number">Card number</Label>
      <Input>
        <CardNumberElement id="card-number" />
      </Input>
    </Field>
    <Field>
      <Label htmlFor="card-expiry">Expiry</Label>
      <Input>
        <CardExpiryElement id="card-expiry" />
      </Input>
    </Field>
    <Field>
      <Label htmlFor="card-cvc">CVC number</Label>
      <Input>
        <CardCvcElement id="card-cvc" />
      </Input>
    </Field>
  </>
);

export default CardSection;
