import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultFeatured from './Featured';
import DefaultPractice from './Practice';
import DefaultShareFreeMonth from './ShareFreeMonth';

export const SectionGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;

  ${media.tabletLandscape} {
    flex-direction: column;
    margin-bottom: 0;
  }

  ${media.tabletPortrait} {
    margin-bottom: 2.5rem;
  }
`;

export const SectionColumn = styled.div`
  &:first-child {
    flex: 0 1 50%;
    margin-right: 0.8rem;
    margin-bottom: 0;
  }
  &:last-child {
    flex: 1 0 50%;
    margin-left: 0.8rem;
    margin-top: 0;
  }

  ${media.tabletLandscape} {
    &:first-child {
      margin-right: 0;
      margin-bottom: 0.8rem;
    }
    &:last-child {
      margin-left: 0;
      margin-top: 0.8rem;
    }
  }
`;

export const Section = styled.div`
  margin-top: 3rem;
  ${media.tabletPortrait} {
    margin-top: 0;
  }
`;

export const Featured = styled(DefaultFeatured)``;

export const Practice = styled(DefaultPractice)``;

export const ShareFreeMonth = styled(DefaultShareFreeMonth)`
  ${media.tabletPortrait} {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }
`;
