import React, { FC, memo } from 'react';
import Theory from './Theory';
import Favorites from './Favorites';
import {
  Featured,
  Practice,
  Section,
  SectionColumn,
  SectionGroup,
  ShareFreeMonth,
} from './styles';
import useConnect from './connect';

export const HomePage: FC = () => {
  const { isTablet } = useConnect();

  return (
    <>
      <SectionGroup>
        <SectionColumn>
          <Featured />
        </SectionColumn>
        <SectionColumn>
          {isTablet && <ShareFreeMonth />}
          <Practice />
        </SectionColumn>
      </SectionGroup>
      {!isTablet && (
        <Section>
          <ShareFreeMonth />
        </Section>
      )}
      <Section>
        <Theory />
      </Section>
      <Section>
        <Favorites />
      </Section>
    </>
  );
};

export default memo(HomePage);
