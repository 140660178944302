import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Avatar: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="none"
    height={(size * 35) / 31}
    stroke="currentColor"
    viewBox="0 0 31 35"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3.482"
      transform="translate(1 2)"
    >
      <path d="M28.184 30.895v-3.421a6.842 6.842 0 0 0-6.842-6.842H7.658a6.842 6.842 0 0 0-6.842 6.842v3.42" />
      <circle cx="14.5" cy="6.947" r="6.842" />
    </g>
  </svg>
);

export default memo(Avatar);
