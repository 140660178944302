import { useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { GetStripeSource } from '../generated/GetStripeSource';
import { GET_STRIPE_SOURCE } from '../queries';
import { UPDATE_STRIPE_SOURCE } from '../mutations';
import {
  UpdateStripeSource,
  UpdateStripeSourceVariables,
} from '../generated/UpdateStripeSource';

const useStripeSource = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data, loading: loadingSource } = useQuery<GetStripeSource>(
    GET_STRIPE_SOURCE,
    { errorPolicy: 'all', fetchPolicy: 'cache-and-network' },
  );

  const [updateSource, { loading: loadingUpdate }] = useMutation<
    UpdateStripeSource,
    UpdateStripeSourceVariables
  >(UPDATE_STRIPE_SOURCE);

  const source = data?.getStripeSources
    ? {
        brand: data?.getStripeSources?.brand!,
        expMonth: data?.getStripeSources?.exp_month!,
        expYear: data?.getStripeSources?.exp_year!,
        last4: data?.getStripeSources?.last4!,
      }
    : undefined;

  const update = useCallback(
    async (sourceId: string) => {
      try {
        const response = await updateSource({
          variables: { source_id: sourceId },
        });
        if (response.data) {
          enqueueSnackbar('Your payment source has been updated', {
            variant: 'success',
          });
        }
      } catch (e) {
        enqueueSnackbar('Unable to update your payment source right now', {
          variant: 'error',
        });
      }
    },
    [updateSource, enqueueSnackbar],
  );

  return {
    source,
    update,
    loading: loadingUpdate || loadingSource,
  };
};

export default useStripeSource;
