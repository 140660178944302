import React, { FC } from 'react';
import Slider from 'react-slick';
import Item from './Item';
import { AutoPlay, Container, SliderLoader } from './styles';
import { Props } from './types';
import useLogic from './logic';
import {
  SLIDER_RESPONSIVE_CONFIG,
  DEFAULT_SLIDER_CENTER_PADDING,
} from './dimensions';

const Packs: FC<Props> = ({
  className,
  isLoading,
  items,
  packParam,
  push,
  selectedPackAutoPlay,
  selectedPackId,
  subpackParam,
  togglePackAutoPlay,
}) => {
  const {
    hasAutoPlay,
    itemsIds,
    onAutoPlayChange,
    onSlideChange,
    selectedSlideIndex,
    sliderRef,
  } = useLogic({
    items,
    packParam,
    push,
    selectedPackId,
    subpackParam,
    togglePackAutoPlay,
  });

  if (isLoading || !items || items.length === 0) {
    return <SliderLoader />;
  }

  return (
    <Container key={itemsIds} className={className}>
      <Slider
        afterChange={onSlideChange}
        arrows={false}
        centerMode
        centerPadding={DEFAULT_SLIDER_CENTER_PADDING}
        focusOnSelect
        infinite
        initialSlide={selectedSlideIndex}
        slidesToShow={1}
        slidesToScroll={1}
        ref={sliderRef}
        responsive={SLIDER_RESPONSIVE_CONFIG}
      >
        {items.map(
          ({
            backgroundColor,
            description,
            displayTitle,
            id,
            imageUrl,
            permission,
            title,
          }) => (
            <Item
              key={id}
              backgroundColor={backgroundColor}
              description={description}
              displayTitle={displayTitle}
              id={id}
              image={imageUrl}
              isSelected={id === selectedPackId}
              permission={permission}
              title={title}
            />
          ),
        )}
      </Slider>
      {hasAutoPlay && (
        <AutoPlay
          label="Auto-Play"
          checked={selectedPackAutoPlay}
          onChange={onAutoPlayChange}
        />
      )}
    </Container>
  );
};

export default Packs;
