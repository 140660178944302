import styled from 'styled-components';
import DefaultMenu from '@material-ui/core/Menu';
import DefaultMenuItem from '@material-ui/core/MenuItem';
import DefaultFavoriteButton from 'components/FavoriteButton';
import DefaultUnplayedIcon from 'components/Icons/Unplayed';
import DefaultNotesIcon from 'components/Icons/TextFile';
import DefaultPaperPlaneIcon from 'components/Icons/PaperPlaneBasic';

export const Container = styled(DefaultMenu).attrs(() => ({ elevation: 5 }))`
  &&& {
    color: ${({ theme }) => theme.textLightBg.text};
  }
`;

export const Item = styled(DefaultMenuItem)`
  &&& {
    color: ${({ theme }) => theme.textLightBg.text};
  }
`;

export const Favorite = styled(DefaultFavoriteButton).attrs(() => ({
  size: 20,
}))``;

export const UnplayedIcon = styled(DefaultUnplayedIcon).attrs(() => ({
  size: 18,
}))`
  width: 20px;
`;

export const NotesIcon = styled(DefaultNotesIcon).attrs(() => ({
  size: 15,
}))`
  color: ${({ theme }) => theme.textLightBg.text};
  width: 20px;
`;

export const ShareIcon = styled(DefaultPaperPlaneIcon).attrs(() => ({
  size: 20,
}))``;

export const Text = styled.div`
  margin-left: 0.8rem;
`;
