import { useEffect, useCallback } from 'react';
import { apolloClient } from 'services/api';
import { trackEvent } from 'services/analytics';
import useMediaQuery from 'lib/useMediaQuery';
import { media } from 'styles/mixins';
import { GetSettings } from 'graphql/generated/GetSettings';
import { GET_SETTINGS } from 'graphql/queries';
import useMe from 'graphql/hooks/useMe';
import useMeActions from 'graphql/hooks/useMeActions';
import useAuth from 'graphql/hooks/useAuth';
import useModal from 'graphql/hooks/useModal';

const useConnect = () => {
  const { isAnonymous, loading: isLoadingMe, profile } = useMe({
    fetchPolicy: 'cache-and-network',
  });
  const { addProgress, updateProfile } = useMeActions();
  const { logout } = useAuth();
  const {
    openAddMeditationTime,
    openDailyDuration,
    openOnboarding,
    openResetAllContentConfirm,
    openResetContent,
    openResetIntroCoursesConfirm,
    openStripePayment,
  } = useModal();
  const isMobile = useMediaQuery(media.phoneLandscape);

  useEffect(() => {
    apolloClient
      .query<GetSettings>({
        query: GET_SETTINGS,
        fetchPolicy: 'network-only',
      })
      .catch(() => true);
  }, []);

  const clickSubscription = useCallback(() => {
    trackEvent('Purchase Initiate Checkout', {
      source: 'account',
      trackID: 'none',
      type: 'button',
    });
  }, []);

  return {
    addProgress,
    clickSubscription,
    isAnonymous,
    isMobile,
    logOut: logout,
    loading: isLoadingMe,
    openAddMeditationTime,
    openDailyDuration,
    openOnboarding,
    openResetAllContentConfirm,
    openResetContent,
    openResetIntroCoursesConfirm,
    openStripePayment,
    profile,
    updateProfile,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
