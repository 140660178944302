import React, { FC } from 'react';
import Modal from 'components/Modal';
import { CopyButton, LinkBox, TextArea } from './styles';
import useConnect from './connect';

const ShareLinkModal: FC = () => {
  const {
    closeModal,
    copyLink,
    description,
    isOpen,
    link,
    linkRef,
    title,
  } = useConnect();

  return (
    <Modal
      title={title}
      description={description}
      onClose={closeModal}
      open={isOpen}
    >
      <LinkBox>
        <TextArea readOnly ref={linkRef} rows={1} value={link} />
        <CopyButton onClick={copyLink}>Copy</CopyButton>
      </LinkBox>
    </Modal>
  );
};

export default ShareLinkModal;
