import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const HeartHollow: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={size * (448.12192 / 512.00037)}
    viewBox="0 0 512.00037 448.12192"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M458.40012 32.321914c-57.8-48.6-147.1-41.3000003-202.4 15-55.3-56.3000003-144.6-63.7-202.400004-15-75.2 63.3-64.2 166.499996-10.6 221.199996l175.400004 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8l175.4-178.7c53.5-54.7 64.7-157.899996-10.6-221.299996zm-23.6 187.499996l-175.4 178.7c-2.4 2.4-4.4 2.4-6.8 0l-175.400004-178.7c-36.5-37.2-43.9-107.6 7.3-150.699996 38.900004-32.7 98.900004-27.8 136.500004 10.5l35 35.699996 35-35.699996c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.099996 43.5 113.899996 7.3 150.799996z" />
  </svg>
);

export default memo(HeartHollow);
