import React, { FC } from 'react';
import { Formik } from 'formik';
import Form from 'components/Form';
import Text from 'components/Text';
import { Button, TextField } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Email: FC<Props> = ({ getPin, loading }) => {
  const { initialValues, submit, validationSchema } = useConnect({
    getPin,
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={submit}
    >
      {({ errors, handleSubmit, values }) => (
        <Form onSubmit={handleSubmit}>
          <TextField
            autoFocus
            data-cypress-id="login-email"
            isClearable
            maxLength={150}
            name="email"
            placeholder="Email"
            type="email"
          />
          {errors.submitError && (
            <Text error center>
              {errors.submitError}
            </Text>
          )}
          <Button
            data-cypress-id="login-email-next"
            disabled={!values.email}
            isLoading={loading}
            type="submit"
          >
            NEXT
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default Email;
