import styled from 'styled-components';
import IconButton from 'components/IconButton';
import SendEmailIcon from 'components/Icons/SendEmail';

export const Container = styled(IconButton)`
  display: flex;
  align-items: center;
  line-height: normal;
  transition: all 200ms ease-in;

  &:hover {
    transform: scale(1.1);
  }
`;

export const ShareIcon = styled(SendEmailIcon)`
  width: 1rem;
`;
