import React, { FC, memo } from 'react';
import { Button, Container, Description, Modal, Options } from './styles';
import useConnect from './connect';

const OnboardingModal: FC = () => {
  const {
    closeModal,
    handelClickNoExperience,
    handleClickMoreExperienced,
    handleClickSomePractice,
    isOpen,
  } = useConnect();
  return (
    <Modal
      disableBackdropClick
      hideClose
      hideLogo
      onClose={closeModal}
      open={isOpen}
    >
      <Container>
        <Description>
          Select the option that best describes your level of experience.
        </Description>
        <Options>
          <Button onClick={handelClickNoExperience}>STARTING OUT</Button>
          <Button onClick={handleClickSomePractice}>SOME PRACTICE</Button>
          <Button onClick={handleClickMoreExperienced}>MORE EXPERIENCED</Button>
        </Options>
      </Container>
    </Modal>
  );
};
export default memo(OnboardingModal);
