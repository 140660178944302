import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Pause: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    height={(size * 9.6) / 7.2}
    viewBox="0 0 7.2 9.6"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g
      transform="translate(-8.4 -7.1999998)"
      fill="currentColor"
      fillRule="evenodd"
    >
      <rect
        x="8.3999996"
        y="7.1999998"
        width="2.4000001"
        height="9.6000004"
        rx="1.2"
      />
      <path d="M14.4 7.2c.662742 0 1.2.5372583 1.2 1.2v7.2c0 .662742-.537258 1.2-1.2 1.2-.662742 0-1.2-.537258-1.2-1.2V8.4c0-.6627417.537258-1.2 1.2-1.2z" />
    </g>
  </svg>
);

export default memo(Pause);
