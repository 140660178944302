import { useCallback, MouseEvent } from 'react';
import { LogicProps } from './types';

const useLogic = ({ courseId, onPlay }: LogicProps) => {
  const play = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (courseId) {
        onPlay(courseId);
      }
    },
    [courseId, onPlay],
  );

  return { play };
};

export default useLogic;
