import styled from 'styled-components';
import DefaultModal from 'components/Modal';
import { Content as ModalContent } from 'components/Modal/styles';
import DefaultCloseIcon from 'components/Icons/Close';
import DefaultIconButton from 'components/IconButton';
import DefaultHeartFull from 'components/Icons/HeartFull';
import DefaultHeartHollow from 'components/Icons/HeartHollow';
import DefaultNotesIcon from 'components/Icons/TextFile';
import DefaultUnplayedIcon from 'components/Icons/Unplayed';
import DefaultPaperPlaneIcon from 'components/Icons/PaperPlaneBasic';

export const Modal = styled(DefaultModal)`
  border: none !important;
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.whiteblue};
    margin-bottom: 40vh;
  }
  ${ModalContent} {
    background-color: ${({ theme }) => theme.colors.whiteblue};
    border-radius: ${({ theme }) => theme.radius.md};
    padding: 1.8rem 1.6rem;
    width: 90vh;
  }
`;

export const HeartFullIcon = styled(DefaultHeartFull).attrs(() => ({
  size: 24,
}))`
  color: #f4477c;
`;

export const HeartHollowIcon = styled(DefaultHeartHollow).attrs(() => ({
  size: 24,
}))``;

export const NotesIcon = styled(DefaultNotesIcon).attrs(() => ({
  size: 18,
}))`
  width: 24px;
`;

export const UnplayedIcon = styled(DefaultUnplayedIcon).attrs(() => ({
  size: 20,
}))`
  width: 24px;
`;

export const ShareIcon = styled(DefaultPaperPlaneIcon).attrs(() => ({
  size: 22,
}))`
  width: 24px;
`;

export const CloseIcon = styled(DefaultCloseIcon)`
  color: ${({ theme }) => theme.textLightBg.textStrong};
  height: 2rem;
  width: 1rem;
`;

export const CloseButton = styled(DefaultIconButton)`
  margin: 0;
  padding: 0;
  position: absolute;
  right: 1.1rem;
  top: 0.6rem;
`;

export const Menu = styled.div`
  color: ${({ theme }) => theme.textLightBg.textStrong};
  font-size: 1.4rem;
`;

export const MenuOption = styled.div`
  align-items: center;
  display: flex;
  flex: row nowrap;
  font-size: inherit;
  justify-content: flex-start;
  margin-bottom: 1.4rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  flex: 1 1 auto;
  font-size: inherit;
  font-weight: bold;
  line-height: 1;
  margin-left: 1.2rem;
`;
