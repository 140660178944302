import DefaultAvatar from 'components/Avatar';
import DefaultHeaderLink from 'components/Links/HeaderLink';
import styled from 'styled-components';

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

export const Avatar = styled(DefaultAvatar)`
  height: 6rem;
  width: 6rem;
  font-size: 2.5rem;
`;

export const HeaderLink = styled(DefaultHeaderLink)`
  font-size: ${({ theme }) => theme.font.textSuperSmall};
`;
