import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { oneLine } from 'common-tags';
import { trackMediaEvent } from 'services/analytics';
import { Course } from 'models/Course';
import { ModalType } from 'graphql/generated/globalTypes';
import { updateProfile, updateSettings, getSettings } from 'graphql/requests';
import useModal from 'graphql/hooks/useModal';
import useCourse from 'graphql/hooks/useCourse';

const useConnect = () => {
  const { close, unlockContentPayload, type } = useModal();
  const { course } = useCourse(unlockContentPayload?.courseId);
  const { enqueueSnackbar } = useSnackbar();

  const unlockDaily = useCallback(
    async (courseData: Course) => {
      const { id } = courseData;

      try {
        await updateProfile({ unlocked_content_status: 'unlocked' });
        enqueueSnackbar('Daily Meditations have been unlocked', {
          variant: 'success',
        });
        trackMediaEvent('Media Special Content Unlock', id);
      } catch (e) {
        enqueueSnackbar('Unable to unlock Daily Meditations', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar],
  );

  const unlockGuestPack = useCallback(
    async (courseData: Course) => {
      const { id, packId, packTitle } = courseData;
      const { unlockedGuestPacks } = await getSettings();

      if (packId) {
        try {
          updateSettings({
            unlockedGuestPacks: [...unlockedGuestPacks, packId],
          });
          enqueueSnackbar(`'${packTitle}' has been unlocked`, {
            variant: 'success',
          });
          trackMediaEvent('Media Special Content Unlock', id);
        } catch (e) {
          enqueueSnackbar(`Unable to unlock '${packTitle}'`, {
            variant: 'error',
          });
        }
      }
    },
    [enqueueSnackbar],
  );

  const accept = useCallback(() => {
    close();

    if (course) {
      if (course.isDaily) {
        unlockDaily(course);
      } else {
        unlockGuestPack(course);
      }
    }
  }, [close, course, unlockDaily, unlockGuestPack]);

  const closeModal = useCallback(() => {
    close();
  }, [close]);

  return {
    accept,
    closeModal,
    description: oneLine`Sam highly recommends that you complete the Introductory Course before proceeding with ${
      course?.isDaily ? 'Daily Mediations' : 'this content'
    }. Are you sure you’d like to continue?`,
    isOpen: type === ModalType.UNLOCK_CONTENT,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
