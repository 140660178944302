import { useCallback } from 'react';
import { useMutation, useQuery, QueryHookOptions } from '@apollo/client';
import { Token } from 'react-stripe-checkout';
import { format } from 'date-fns';
import { trackEvent } from 'services/analytics';
import { useSnackbar } from 'notistack';
import { normalizeUser } from 'models/User';
import {
  SubscriptionPlans,
  SubscriptionPlans_subscriptionPlans,
  SubscriptionPlansVariables,
} from '../generated/SubscriptionPlans';
import {
  UpdateSubscription,
  UpdateSubscriptionVariables,
} from '../generated/UpdateSubscription';
import { CancelSubscription } from '../generated/CancelSubscription';
import {
  CANCEL_SUBSCRIPTION,
  CREATE_SUBSCRIPTION,
  REACTIVATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION,
} from '../mutations';
import { SUBSCRIPTION_PLANS, GET_PACKS, GET_DAILY } from '../queries';
import {
  CreateSubscription,
  CreateSubscriptionVariables,
} from '../generated/CreateSubscription';
import { ReactivateSubscription } from '../generated/ReactivateSubscription';
import useAuth from './useAuth';
import useModal from './useModal';

const useSubscription = (options: QueryHookOptions<SubscriptionPlans> = {}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { token: currentToken } = useAuth();
  const { openDialog } = useModal();

  const { data, loading: loadingPlans, ...rest } = useQuery<
    SubscriptionPlans,
    SubscriptionPlansVariables
  >(SUBSCRIPTION_PLANS, {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const [cancelSubscription, { loading: loadingCancel }] = useMutation<
    CancelSubscription
  >(CANCEL_SUBSCRIPTION);

  const [reactivateSubscription, { loading: loadingReactivate }] = useMutation<
    ReactivateSubscription
  >(REACTIVATE_SUBSCRIPTION, {
    refetchQueries: currentToken
      ? [
          { query: GET_PACKS },
          {
            query: GET_DAILY,
            variables: { date: format(new Date(), 'yyyy-MM-dd') },
          },
        ]
      : undefined,
  });

  const [updateSubscription, { loading: loadingUpdate }] = useMutation<
    UpdateSubscription,
    UpdateSubscriptionVariables
  >(UPDATE_SUBSCRIPTION, {
    refetchQueries: currentToken
      ? [
          { query: GET_PACKS },
          {
            query: GET_DAILY,
            variables: { date: format(new Date(), 'yyyy-MM-dd') },
          },
        ]
      : undefined,
  });

  const [createSubscription, { loading: loadingCreate }] = useMutation<
    CreateSubscription,
    CreateSubscriptionVariables
  >(CREATE_SUBSCRIPTION, {
    refetchQueries: currentToken
      ? [
          { query: GET_PACKS },
          {
            query: GET_DAILY,
            variables: { date: format(new Date(), 'yyyy-MM-dd') },
          },
        ]
      : undefined,
  });

  const plans =
    data?.subscriptionPlans
      ?.filter((x): x is SubscriptionPlans_subscriptionPlans => !!x)
      .map(({ amount, id, interval, nickname, originalAmount }) => ({
        amount,
        id,
        interval,
        nickname,
        originalAmount,
      })) ?? [];

  const reactivate = useCallback(async () => {
    try {
      await reactivateSubscription();
      enqueueSnackbar('Your subscription has been reactivated', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Unable to reactivate the subscription', {
        variant: 'error',
      });
    }
  }, [reactivateSubscription, enqueueSnackbar]);

  const cancel = useCallback(async () => {
    try {
      const response = await cancelSubscription();
      const profile = response?.data?.cancelStripeSubscription || null;
      if (profile) {
        enqueueSnackbar('Your subscription has been canceled', {
          variant: 'success',
        });
        trackEvent('Purchase Cancel Subscription');
      }
    } catch (e) {
      enqueueSnackbar('Unable to cancel the current subscription', {
        variant: 'error',
      });
    }
  }, [cancelSubscription, enqueueSnackbar]);

  const update = useCallback(
    async (planId: string) => {
      try {
        await updateSubscription({ variables: { plan_id: planId } });
        enqueueSnackbar('Your subscription has been updated', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar('Unable to update the current subscription', {
          variant: 'error',
        });
      }
    },
    [updateSubscription, enqueueSnackbar],
  );

  const create = useCallback(
    async (
      token: Token,
      planId: string,
      isPublic: boolean = false,
      promoCode?: string,
    ) => {
      try {
        const response = await createSubscription({
          variables: {
            plan_id: planId,
            token,
            email: isPublic ? token.email : undefined,
            promocode: promoCode,
          },
        });
        const profile = response?.data?.subscribeStripe || null;
        const selectedPlan = plans.find((x) => x.id === planId);
        const value = (selectedPlan?.amount || 0) / 100;

        window.fbq('track', 'Purchase', { value, currency: 'USD' });

        if (profile) {
          /* Only update profile & show message when it is not public (e.g. fastcheckout ) */
          if (!isPublic) {
            openDialog({
              title: 'You are subscribed!',
              description:
                'Thank you for subscribing to Waking Up! You have now unlocked all exclusive content!',
            });
          }

          return normalizeUser(profile);
        }
      } catch (e) {
        enqueueSnackbar('Unable to subscribe', { variant: 'error' });
        throw new Error(e);
      }
    },
    [createSubscription, enqueueSnackbar, openDialog, plans],
  );

  return {
    plans,
    cancel,
    reactivate,
    update,
    create,
    loading:
      (plans.length === 0 && loadingPlans) ||
      loadingCancel ||
      loadingReactivate ||
      loadingCreate ||
      loadingUpdate,
    ...rest,
  };
};

export default useSubscription;
