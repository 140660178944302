import { useCallback } from 'react';
import useSettingsActions from 'graphql/hooks/useSettingsActions';
import useModal from 'graphql/hooks/useModal';
import { ModalType } from 'graphql/generated/globalTypes';

const useConnect = () => {
  const { updateSettings } = useSettingsActions();
  const { type, close, openGiveFreeMonth } = useModal();

  const accept = useCallback(() => {
    updateSettings({ hasSeenPopupWarningSharingFreeMonth: true });
    close();
    setTimeout(() => {
      openGiveFreeMonth();
    }, 600);
  }, [updateSettings, close, openGiveFreeMonth]);

  return {
    accept,
    closeModal: close,
    isOpen: type === ModalType.SHARE_MONTH_WARNING,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
