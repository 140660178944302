import React, { FC } from 'react';
import newIntroPack from 'assets/images/new_intro_pack.gif';
import { Button, Content, Description, Image, Modal, Title } from './styles';
import useConnect from './connect';

const NewIntroPackModal: FC = () => {
  const { accept, isOpen, closeModal } = useConnect();

  return (
    <Modal
      disableBackdropClick
      disableEscapeKeyDown
      hideLogo
      hideClose
      onClose={closeModal}
      open={isOpen}
    >
      <Content>
        <Image alt="New intro pack" src={newIntroPack} />
        <Title>Our refined 28-Day Introductory Course is now available</Title>
        <Description>Tap OK to experience the new course</Description>
        <Button onClick={accept}>OK</Button>
      </Content>
    </Modal>
  );
};

export default NewIntroPackModal;
