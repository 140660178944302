import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { DailyMeditationDuration } from 'models/DailyMeditationDuration';
import { ANNIVERSARY_COURSE } from 'models/Course';
import { GetCoursesVariables } from '../generated/GetCourses';
import { AddProgress, AddProgressVariables } from '../generated/AddProgress';
import { CourseData } from '../generated/CourseData';
import { COURSE_DATA_FRAGMENT } from '../fragments';
import { ADD_PROGRESS } from '../mutations';
import { cacheUnlockAllContent, cacheUpdateFragment } from '../cache';
import { getPlayer } from '../requests';
import useCourses from './useCourses';

const useCourse = (courseId: GetCoursesVariables['id']) => {
  const { courses, loading: isLoadingCourses } = useCourses();
  const { enqueueSnackbar } = useSnackbar();

  const [addUserProgressMutation] = useMutation<
    AddProgress,
    AddProgressVariables
  >(ADD_PROGRESS);

  const course = useMemo(
    () => [...courses, ANNIVERSARY_COURSE].find(({ id }) => id === courseId),
    [courseId, courses],
  );

  const markAsListened = useCallback(
    async (activeSessionId?: string | null) => {
      if (course) {
        try {
          let duration = course.mp3_length;
          if (course.isDaily) {
            const { dailyMeditationDuration } = await getPlayer();
            if (dailyMeditationDuration === DailyMeditationDuration.MIN_20) {
              duration = course.mp3_2_length;
            }
          }

          await addUserProgressMutation({
            variables: {
              duration_seconds: duration,
              finished_at: new Date().toISOString(),
              progress_id: Number(course.id),
              progress_type: 'course',
              active_session_id: activeSessionId || undefined,
            },
          });

          cacheUpdateFragment<CourseData>(COURSE_DATA_FRAGMENT, {
            id: course.id,
            __typename: 'Course',
            status: 'finished',
          });

          // Unlock all content after listening last intro course.
          if (course.isLastIntro) {
            cacheUnlockAllContent();
            enqueueSnackbar('All content has been unlocked', {
              variant: 'success',
            });
          }
        } catch (error) {
          enqueueSnackbar('Unable to mark track as listened', {
            variant: 'error',
          });
        }
      }
    },
    [addUserProgressMutation, course, enqueueSnackbar],
  );

  return {
    course,
    markAsListened,
    loading: isLoadingCourses,
  };
};

export default useCourse;

export type UseCourse = ReturnType<typeof useCourse>;
