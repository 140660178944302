import { useMemo } from 'react';
import flatMap from 'lodash/flatMap';
import notEmpty from 'lib/notEmpty';
import usePacks from './usePacks';
import useDailyMeditation from './useDailyMeditation';
import useFeatured from './useFeatured';

const useCourses = () => {
  const { packs, loading: isLoadingPacks } = usePacks();
  const {
    dailyMeditation,
    loading: isLoadingDailyMeditation,
  } = useDailyMeditation();
  // TODO: Remove this when we have the new course query implemented.
  const { featured, loading: isLoadingFeatured } = useFeatured();

  const courses = useMemo(() => {
    const allCourses =
      packs.length > 0
        ? flatMap(packs, (pack) => pack?.courses).filter(notEmpty)
        : [];

    if (dailyMeditation) {
      allCourses.push(dailyMeditation);
    }

    // TODO: Remove this when we have the new course query implemented.
    if (featured.length > 0) {
      featured.forEach((v) => {
        if (!allCourses.some((c) => c.id === v.id)) {
          allCourses.push(v);
        }
      });
    }

    return allCourses;
  }, [dailyMeditation, featured, packs]);

  return {
    courses,
    loading: isLoadingPacks || isLoadingDailyMeditation || isLoadingFeatured,
  };
};

export default useCourses;

export type UseCourses = ReturnType<typeof useCourses>;
