import { apolloClient } from 'services/api';
import { ModalType } from 'graphql/generated/globalTypes';
import {
  GetModal,
  GetModal_modal_courseActionsPayload,
  GetModal_modal_courseNotesPayload,
  GetModal_modal_dialogPayload,
  GetModal_modal_resetCoursePayload,
  GetModal_modal_shareLinkPayload,
  GetModal_modal_unlockContentPayload,
  GetModal_modal_updatePaymentCardPayload,
} from 'graphql/generated/GetModal';
import { GET_MODAL } from 'graphql/queries';

function cacheSetModal({
  type,
  courseActionsPayload = null,
  courseNotesPayload = null,
  dialogPayload = null,
  resetCoursePayload = null,
  shareLinkPayload = null,
  unlockContentPayload = null,
  updatePaymentCardPayload = null,
}: {
  type: ModalType | null;
  courseActionsPayload?: GetModal_modal_courseActionsPayload | null;
  courseNotesPayload?: GetModal_modal_courseNotesPayload | null;
  dialogPayload?: GetModal_modal_dialogPayload | null;
  resetCoursePayload?: GetModal_modal_resetCoursePayload | null;
  shareLinkPayload?: GetModal_modal_shareLinkPayload | null;
  unlockContentPayload?: GetModal_modal_unlockContentPayload | null;
  updatePaymentCardPayload?: GetModal_modal_updatePaymentCardPayload | null;
}) {
  apolloClient.writeQuery<GetModal>({
    query: GET_MODAL,
    data: {
      modal: {
        __typename: 'Modal',
        type,
        courseActionsPayload,
        courseNotesPayload,
        dialogPayload,
        resetCoursePayload,
        shareLinkPayload,
        unlockContentPayload,
        updatePaymentCardPayload,
      },
    },
  });
}

export default cacheSetModal;
