import React, { FC, memo, useCallback } from 'react';
import { useField } from 'formik';
import { Container, Error, ReactSelect, Wrapper } from './styles';
import { Props } from './types';

const Select: FC<Props> = ({
  className,
  id,
  isClearable = false,
  isSearchable = false,
  label,
  name,
  options,
  placeholder,
  readOnly = false,
}) => {
  const [, meta, helpers] = useField(name);
  const { touched, error } = meta;
  const { setValue, setTouched } = helpers;

  const onChange = useCallback(
    (data: { value: string } | null) => {
      setValue(data?.value || '');
    },
    [setValue],
  );

  return (
    <Container className={className}>
      <Wrapper id={id || name} label={label} readOnly={readOnly}>
        <ReactSelect
          $hasLabel={!!label}
          classNamePrefix="react-select"
          isClearable={isClearable}
          isSearchable={isSearchable}
          onFocus={setTouched}
          options={options}
          onChange={onChange}
          placeholder={placeholder}
          inputId={id}
        />
      </Wrapper>
      {touched && error && <Error>{error}</Error>}
    </Container>
  );
};

export default memo(Select);
