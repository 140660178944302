import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import DefaultUserInfo from 'components/UserInfo';
import DefaultUserStats from 'components/UserStats';
import ChevronButton from 'components/ChevronButton';
import DefaultLogoutLink from 'components/Links/LogoutLink';
import CircularProgress from '@material-ui/core/CircularProgress';
import { media } from 'styles/mixins';

export const Content = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  margin-top: ${({ theme }) => theme.margin.xxl};
  width: 100%;

  ${media.tabletLandscape} {
    margin-top: ${({ theme }) => theme.margin.md};
  }
`;

export const Loader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 2rem 0;
`;

export const ProgressText = styled.div`
  color: ${({ theme }) => theme.textLightBg.text};
  font-weight: bold;
  font-size: 1rem;
  padding-top: 0.6rem;
  text-align: center;
`;

export const Progress = styled(CircularProgress).attrs(() => ({
  size: 50,
}))``;

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.grey};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 600px;

  ${media.phonePortrait} {
    width: 100%;
  }
`;

export const UserInfo = styled(DefaultUserInfo)`
  color: ${({ theme }) => theme.textLightBg.textStrong};
`;

export const UserStats = styled(DefaultUserStats)`
  margin-top: ${({ theme }) => theme.margin.xlg};

  ${media.phonePortrait} {
    width: 100%;
  }
`;

export const Links = styled.div`
  margin-top: ${({ theme }) => theme.margin.xl};
  width: 100%;

  &:nth-last-child(2) {
    margin-top: ${({ theme }) => theme.margin.lg};
  }
`;

export const GroupHeader = styled.h2`
  color: ${({ theme }) => theme.textLightBg.textFaint};
  font-size: ${({ theme }) => theme.font.textSmall};
  margin-bottom: ${({ theme }) => theme.margin.xxsm};
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;

  &:not(:last-child) {
    div {
      margin-bottom: ${({ theme }) => theme.margin.xsm};
    }
  }
`;

export const LogoutLink = styled(DefaultLogoutLink)`
  margin: ${({ theme }) => `${theme.margin.xxl} 0 ${theme.margin.xl} 0`};
`;

export const ChevronButtonWithMargin = styled(ChevronButton)`
  margin-bottom: ${({ theme }) => theme.margin.xsm};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
