import styled from 'styled-components';
import MaterialSlider from '@material-ui/core/Slider';
import { ZIndex } from 'styles/metrics';
import { media } from 'styles/mixins';
import IconButton from 'components/IconButton';
import DefaultRewindIcon from 'components/Icons/Rewind';
import DefaultForwardIcon from 'components/Icons/Forward';
import DefaultControlButton from './ControlButton';
import { Button as AudioStatusButton } from './ControlButton/styles';
import DefaultVolumeControls from './VolumeControls';

export const JumpBackIcon = styled(DefaultRewindIcon)``;

export const JumpForwardIcon = styled(DefaultForwardIcon)``;

export const JumpButton = styled(IconButton)`
  &:hover {
    svg {
      color: inherit;
    }
  }
`;

export const ControlButton = styled(DefaultControlButton)``;

export const Slider = styled(MaterialSlider)`
  &.MuiSlider-root {
    color: ${({ theme }) => theme.colors.snow};
    display: block;
    min-width: 10rem;
    padding: 5px 0;
  }
  & .MuiSlider-rail,
  & .MuiSlider-track,
  & .MuiSlider-thumb {
    background-color: ${({ theme }) => theme.colors.snow};
  }
`;

export const ProgressContainer = styled.div`
  flex: 1 1 auto;
`;

export const Time = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  font-size: 0.8rem;
  justify-content: space-between;
  line-height: 1;
  white-space: nowrap;
`;

export const TimePlayed = styled.div`
  font-size: inherit;
`;

export const TimeSeparator = styled.div`
  font-size: inherit;
  padding: 0 0.4rem;
`;

export const TimePending = styled.div`
  font-size: inherit;
`;

export const TimeDuration = styled.div`
  font-size: inherit;
`;

export const VolumeControls = styled(DefaultVolumeControls)`
  margin-left: 1.6rem;
  min-width: 100px;
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
`;

export const Buttons = styled(Row)``;

export const Status = styled(Row)`
  align-items: flex-end;
`;

export const Container = styled.div<{
  $basic: boolean;
}>`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: ${({ $basic }) => ($basic ? 'space-between' : 'center')};
  width: 100%;

  ${Buttons} {
    position: ${({ $basic }) => ($basic ? 'absolute' : 'relative')};
    top: ${({ $basic }) => ($basic ? '0.6rem' : 'auto')};
    z-index: ${({ $basic }) => ($basic ? ZIndex.PLAYER_CONTROLS : 'auto')};
  }

  ${Status} {
    width: 100%;
    margin-top: ${({ $basic }) => ($basic ? '0' : '1.2rem')};
  }

  ${JumpButton} {
    & svg {
      height: ${({ $basic }) => ($basic ? '1.1rem' : '2rem')};
      width: ${({ $basic }) => ($basic ? '1.1rem' : '2rem')};
    }
  }

  ${ControlButton} {
    height: ${({ $basic }) => ($basic ? '1.6rem' : '5rem')};
    margin: ${({ $basic }) => ($basic ? '0 2rem' : '0 3.75rem')};
    min-height: ${({ $basic }) => ($basic ? '1.6rem' : '5rem')};
    min-width: ${({ $basic }) => ($basic ? '1.6rem' : '5rem')};
    width: ${({ $basic }) => ($basic ? '1.6rem' : '5rem')};
    ${AudioStatusButton} {
      & svg {
        height: ${({ $basic }) => ($basic ? '120%' : '40%')};
        width: ${({ $basic }) => ($basic ? '120%' : '40%')};
      }
    }
  }

  ${Time} {
    font-size: ${({ $basic }) => ($basic ? '0.75rem' : '0.9rem')};
    justify-content: space-between;
    opacity: ${({ $basic }) => ($basic ? '0.7' : '1')};
  }

  ${TimeSeparator} {
    display: none;
  }

  ${TimeDuration} {
    display: none;
  }

  ${Slider} {
    & .MuiSlider-thumb {
      height: ${({ $basic }) => ($basic ? '8px' : '10px')};
      margin-left: ${({ $basic }) => ($basic ? '-4px' : '-5px')};
      margin-top: ${({ $basic }) => ($basic ? '-3px' : '-4px')};
      width: ${({ $basic }) => ($basic ? '8px' : '10px')};
    }
  }

  ${VolumeControls} {
    position: ${({ $basic }) => ($basic ? 'absolute' : 'relative')};
    right: ${({ $basic }) => ($basic ? '2rem' : 'auto')};
    top: ${({ $basic }) => ($basic ? 'calc(50% - 29px)' : 'auto')};
  }

  ${media.tabletLandscape} {
    ${Status} {
      margin-top: ${({ $basic }) => ($basic ? '0' : '0.6rem')};
    }
    ${VolumeControls} {
      display: none;
    }
  }

  ${media.tabletPortrait} {
    width: ${({ $basic }) => ($basic ? 'auto' : '100%')};
    ${Buttons} {
      position: ${({ $basic }) => ($basic ? 'absolute' : 'relative')};
      right: ${({ $basic }) => ($basic ? '1rem' : 'auto')};
      top: ${({ $basic }) => ($basic ? 'calc(50% - 1.3rem)' : 'auto')};
    }
    ${JumpButton} {
      display: ${({ $basic }) => ($basic ? 'none' : 'block')};
    }
    ${ControlButton} {
      height: ${({ $basic }) => ($basic ? '2.6rem' : '5rem')};
      margin: ${({ $basic }) => ($basic ? '0' : '0 3.75rem')};
      min-height: ${({ $basic }) => ($basic ? '2.6rem' : '5rem')};
      min-width: ${({ $basic }) => ($basic ? '2.6rem' : '5rem')};
      width: ${({ $basic }) => ($basic ? '2.6rem' : '5rem')};
      ${AudioStatusButton} {
        & svg {
          height: ${({ $basic }) => ($basic ? '60%' : '40%')};
          width: ${({ $basic }) => ($basic ? '60%' : '40%')};
        }
      }
    }
    ${Time} {
      font-size: 0.9rem;
      justify-content: ${({ $basic }) => ($basic ? 'center' : 'space-between')};
      opacity: 1;
    }
    ${TimeSeparator} {
      display: ${({ $basic }) => ($basic ? 'block' : 'none')};
    }
    ${TimePending} {
      display: ${({ $basic }) => ($basic ? 'none' : 'block')};
    }
    ${TimeDuration} {
      display: ${({ $basic }) => ($basic ? 'block' : 'none')};
    }
    ${Slider} {
      display: ${({ $basic }) => ($basic ? 'none' : 'block')};
    }
  }

  ${media.phoneLandscape} {
    ${Slider} {
      margin-bottom: ${({ $basic }) => ($basic ? '0' : '0.4rem')};
    }
  }
`;
