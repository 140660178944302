import { useCallback } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { parse } from 'query-string';
import { SubscriptionPeriod } from 'containers/NewSubscription/types';
import useMe from 'graphql/hooks/useMe';

const useConnect = () => {
  const {
    isAnonymous,
    profile: { email },
  } = useMe();
  const match = useRouteMatch<{ code: string }>([
    '*/new-subscription/:code',
    '*/new-subscription',
  ]);
  const path = match?.url;
  const code = match?.params?.code;
  const { replace } = useHistory();
  const { search } = useLocation();
  const { p } = parse(search);

  const handleClose = useCallback(() => {
    if (path) {
      replace(path.replace(RegExp('(/new-subscription)(\\/.*)?'), ''));
    }
  }, [replace, path]);

  const handleBack = useCallback(() => {
    if (path) {
      if (
        p === SubscriptionPeriod.MENU_MONTH ||
        p === SubscriptionPeriod.MENU_YEAR
      ) {
        replace(`${path}${search.replace(RegExp(`p=${p}`), '')}`);
      } else {
        replace(path.replace(RegExp('(/new-subscription)(\\/.*)?'), ''));
      }
    }
  }, [p, path, replace, search]);

  return {
    code,
    email: !isAnonymous ? email : undefined,
    handleBack,
    handleClose,
    // TODO: Re-enable this when monthly plans are activated.
    // period:
    //   typeof p === 'string' &&
    //   Object.values(SubscriptionPeriod).includes(p as SubscriptionPeriod)
    //     ? (p as SubscriptionPeriod)
    //     : undefined,
    period: SubscriptionPeriod.YEAR,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
