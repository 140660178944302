import { apolloClient } from 'services/api';
import { GetLoginEmail } from 'graphql/generated/GetLoginEmail';
import { GET_LOGIN_EMAIL } from 'graphql/queries';

function cacheUpdateLoginEmail(email: string = '') {
  apolloClient.writeQuery<GetLoginEmail>({
    query: GET_LOGIN_EMAIL,
    data: { loginEmail: email },
  });
}

export default cacheUpdateLoginEmail;
