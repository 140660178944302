import React, { FC } from 'react';
import { openPurchaseDialog } from 'services/lightrail';
import { Container, Button } from './styles';
import useConnect from './connect';

const Payments: FC = () => {
  const { isLightrailReady, isLightrailLoading } = useConnect();

  return (
    <Container>
      <Button to="/checkout">Purchase a subscription</Button>
      <Button
        disabled={!isLightrailReady}
        isLoading={isLightrailLoading}
        onClick={openPurchaseDialog}
      >
        Purchase Gift Card
      </Button>
      <Button to="/gifts/redeem">Redeem Gift Card</Button>
      <Button to="/promotions/redeem">Redeem Promo Code</Button>
      <Button to="/visitor">Try the Web App!</Button>
    </Container>
  );
};

export default Payments;
