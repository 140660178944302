import styled from 'styled-components';
import DefaultButton from 'components/Button';

export const Container = styled(DefaultButton)`
  &&& {
    align-items: stretch;
    border-radius: 0.48rem;
    display: flex;
    flex-flow: row nowrap;
    height: 3.625rem;
    justify-content: flex-start;
    min-height: auto;
    padding: 0;
    width: 20.625rem;
    .MuiButton-label {
      font-size: 0.875rem;
      line-height: 1.125rem;
      text-transform: none;
    }
  }
`;

export const Info = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.subscription.white};
  border-bottom-left-radius: 0.48rem;
  border-top-left-radius: 0.48rem;
  color: ${({ theme }) => theme.colors.subscription.darkblue};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0 1rem 0 1.125rem;
  position: relative;
  width: 15.4375rem;
`;

export const Title = styled.div`
  font-size: 1.375rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.75rem;
`;

export const Description = styled.div`
  flex: 1 1 auto;
  font-size: 0.5rem;
  line-height: 0.625rem;
  text-align: right;
`;

export const Price = styled.div`
  font-size: 1.4375rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.8125rem;
  margin-left: 1.125rem;
`;

export const Sup = styled.sup`
  font-size: 0.6rem;
  margin-right: 0.1rem;
`;

export const DiscountTag = styled.div`
  background-color: ${({ theme }) => theme.colors.subscription.orange};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.colors.subscription.white};
  font-size: 0.425rem;
  line-height: 0.5625rem;
  padding: 0.25rem 0.75rem;
  position: absolute;
  right: 0.75rem;
  top: -0.5rem;
  white-space: nowrap;
`;

export const Select = styled.div`
  align-self: center;
  flex: 1 1 auto;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
`;
