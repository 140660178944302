import getCourses from './getCourses';
import getPack from './getPack';

const getCoursePack = async (key: string) => {
  const courses = await getCourses();
  const course = courses.find(
    ({ courseHash, id }) => id === key || courseHash === key,
  );

  const packId = course?.packId;
  return packId ? getPack(packId) : undefined;
};

export default getCoursePack;
