import styled from 'styled-components';
import { buttonHover } from 'styles/mixins';
import LoaderBox from 'components/LoaderBox';
import DefaultLock from 'components/Lock';
import DefaultHeader from 'components/SectionHeader';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

export const Header = styled(DefaultHeader)`
  margin-bottom: ${({ theme }) => theme.margin.md};
`;

export const Loader = styled(LoaderBox)`
  height: 0;
  padding-bottom: 65%;
`;

export const Lock = styled(DefaultLock)`
  flex: 1 1 auto;
  ${buttonHover};
`;

export const Image = styled.img`
  border-radius: 0.5rem;
  display: block;
  height: auto;
  width: 100%;
  ${buttonHover};
  cursor: pointer;
  &:hover {
    filter: brightness(60%);
  }
`;
