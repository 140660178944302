import React, { FC, memo } from 'react';
import { kebabCase } from 'lodash';
import {
  ContainerDiv,
  ContainerLink,
  CopyIcon,
  Image,
  LockIcon,
  PermissionOverlay,
  StarIcon,
  Title,
} from './styles';
import { Props } from './types';
import useLogic from './logic';

const Item: FC<Props> = ({
  className,
  courseId,
  currentUrl,
  imageUrl = '',
  onPlay,
  status = 'unlocked',
  title = '',
  type = 'course',
}) => {
  const { handlePlay } = useLogic({ courseId, onPlay });

  const permissionOverlay =
    status === 'forbidden' || status === 'locked' ? (
      <PermissionOverlay>
        {status === 'forbidden' ? <LockIcon /> : <StarIcon />}
      </PermissionOverlay>
    ) : null;

  const content = (
    <>
      {type === 'subpack' && <CopyIcon />}
      <Image alt={title} src={imageUrl} />
      {title && <Title>{title}</Title>}
    </>
  );

  return type === 'subpack' ? (
    <ContainerLink
      className={className}
      to={`${currentUrl}/${kebabCase(title)}`}
    >
      {content}
      {permissionOverlay}
    </ContainerLink>
  ) : (
    <ContainerDiv className={className} onClick={handlePlay}>
      {content}
      {permissionOverlay}
    </ContainerDiv>
  );
};

export default memo(Item);
