import { apolloClient } from 'services/api';
import { Settings } from 'models/Settings';
import { UPDATE_SETTINGS } from 'graphql/mutations';
import { cacheUpdateSettings } from 'graphql/cache';
import getSettings from './getSettings';

const updateSettings = async (
  newSettings: Partial<Settings>,
  setOptimisticResponse?: boolean,
) => {
  if (newSettings) {
    const currentSettings = await getSettings();

    try {
      const newFullSettings = JSON.stringify({
        ...currentSettings,
        ...newSettings,
      });

      if (setOptimisticResponse) {
        cacheUpdateSettings(newFullSettings);
      }

      await apolloClient.mutate({
        mutation: UPDATE_SETTINGS,
        variables: { settings: newFullSettings },
        update() {
          if (!setOptimisticResponse) {
            cacheUpdateSettings(newFullSettings);
          }
        },
      });
    } catch (error) {
      if (setOptimisticResponse) {
        cacheUpdateSettings(JSON.stringify(currentSettings));
      }
    }
  }
};

export default updateSettings;
