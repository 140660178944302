import { useCallback } from 'react';
import { trackMediaEvent } from 'services/analytics';
import { ModalType } from 'graphql/generated/globalTypes';
import useModal from 'graphql/hooks/useModal';
import useCopyInputContent from 'lib/useCopyInputContent';

const useConnect = () => {
  const { close, shareLinkPayload, type } = useModal();
  const { ref, copy } = useCopyInputContent();

  const courseId = shareLinkPayload?.courseId;

  const copyLink = useCallback(() => {
    copy();

    if (courseId) {
      trackMediaEvent('Media Share Content', courseId);
    }
  }, [copy, courseId]);

  return {
    closeModal: close,
    copyLink,
    description: shareLinkPayload?.description || '',
    isOpen: type === ModalType.SHARE_LINK,
    link: shareLinkPayload?.link || '',
    linkRef: ref,
    title: shareLinkPayload?.title || '',
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
