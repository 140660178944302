import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled(NavLink)`
  align-items: center;
  color: ${({ theme }) => theme.textLightBg.text};
  display: flex;
  font-size: ${({ theme }) => theme.font.textSuperSmall};
  font-weight: ${({ theme }) => theme.font.weight.book};
  margin-bottom: ${({ theme }) => theme.margin.xlg};
  text-decoration: none;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  &.active {
    opacity: 1;
    &:active {
      opacity: 0.7;
    }
  }

  &:not(.active) {
    opacity: 0.5;
    &:hover {
      transform: translateX(3px);
      opacity: 0.7;
    }
    &:active {
      opacity: 0.3;
    }
  }
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.font.textSuperSmall};
  margin-left: ${({ theme }) => theme.margin.sm};
  text-transform: uppercase;
`;
