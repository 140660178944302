import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { FormikHelpers } from 'formik';
import { parse } from 'query-string';
import useRedeem from 'graphql/hooks/useReedem';
import { trackEvent } from 'services/analytics';
import { validationSchema } from './constants';
import { FormValues } from './types';

const useConnect = () => {
  const { redeem, loading } = useRedeem();
  const { replace } = useHistory();
  const { pathname, search } = useLocation();

  const isPromo = pathname.includes('promotion');
  const { giftCode } = parse(search);

  const initialValues = useMemo(
    () => ({
      code: giftCode && !Array.isArray(giftCode) ? giftCode.toUpperCase() : '',
      submitError: '',
    }),
    [giftCode],
  );

  const { heading, title } = useMemo(
    () => ({
      heading: isPromo
        ? 'Enter your promotional code below.'
        : "Enter your gift code below. If you don't know what this code is you can find it in your email.",
      title: isPromo ? 'Promo Redemption' : 'Gift Redemption',
    }),
    [isPromo],
  );

  const submit = useCallback(
    async (values: FormValues, actions: FormikHelpers<FormValues>) => {
      try {
        const profile = await redeem(values.code);

        trackEvent('Subscription Redeem', {
          code: values.code,
          subscriptionEndTime: profile?.subscription?.expires_at,
          subscriptionStartTime: profile?.subscription?.start_time,
          type: isPromo ? 'promo' : 'gift',
        });
        replace('/account/subscription');
      } catch (e) {
        const error =
          (e.graphQLErrors?.length > 0 && e.graphQLErrors[0].message) ||
          e.message;
        actions.setErrors({ submitError: error });
      }
    },
    [isPromo, redeem, replace],
  );

  return {
    heading,
    initialValues,
    loading,
    submit,
    title,
    validationSchema,
  };
};

export default useConnect;
