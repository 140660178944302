import styled from 'styled-components';
import DefaultLoaderBox from 'components/LoaderBox';
import { Props } from './types';

export const Container = styled.div`
  border-radius: ${({ theme }) => theme.radius.md};
  background: transparent;
  overflow: hidden;
  position: relative;

  & > * {
    z-index: 1;
  }
`;

export const Loader = styled(DefaultLoaderBox)`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: unset;
`;

export const Image = styled.img<{
  $position: Props['position'];
}>`
  border-radius: inherit;
  bottom: 0;
  display: block;
  height: 100%;
  left: ${({ $position }) => ($position === 'left' ? '0' : 'auto')};
  position: absolute;
  right: ${({ $position }) => ($position === 'right' ? '0' : 'auto')};
  z-index: unset;
`;
