import React, { FC, memo } from 'react';
import { CourseStatus } from 'models/Course';
import useLogic from './logic';
import { Container, CourseThumbnail, Description, Info, Title } from './styles';
import { Props } from './types';

const Item: FC<Props> = ({
  backgroundColor = 'rgb(100, 137, 255)',
  courseId,
  courseNumber,
  coursePermission,
  courseStatus,
  description,
  imageURL,
  isDaily,
  isIntro,
  onPlay,
  title,
  ...rest
}) => {
  const { play } = useLogic({ courseId, onPlay });

  return (
    <Container onClick={play} {...rest}>
      <CourseThumbnail
        alt={title}
        backgroundColor={backgroundColor}
        courseNumber={courseNumber}
        coursePermission={coursePermission}
        imageUrl={imageURL}
        isSquareImage={isDaily || isIntro}
        showPlayOnHover
      />
      <Info $isFinished={courseStatus === CourseStatus.FINISHED}>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Info>
    </Container>
  );
};

export default memo(Item);
