import { useCallback } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { parse } from 'query-string';
import { User } from 'models/User';
import { SubscriptionPeriod } from 'containers/NewSubscription/types';

const useConnect = () => {
  const match = useRouteMatch<{ code: string }>([
    '*/checkout/:code',
    '*/checkout',
  ]);
  const path = match?.url;
  const code = match?.params?.code;
  const { push, replace } = useHistory();
  const { search } = useLocation();
  const { p } = parse(search);

  const handleBack = useCallback(() => {
    if (
      p === SubscriptionPeriod.MENU_MONTH ||
      p === SubscriptionPeriod.MENU_YEAR
    ) {
      replace(`${path}${search.replace(RegExp(`p=${p}`), '')}`);
    }
  }, [p, path, replace, search]);

  const handleSubscribed = useCallback(
    (user?: User) => {
      if (user) push('/subscription/success', { user });
      else push('/subscription/error');
    },
    [push],
  );

  return {
    code,
    handleBack,
    handleSubscribed,
    // TODO: Re-enable this when monthly plans are activated.
    // period:
    //   typeof p === 'string' &&
    //   Object.values(SubscriptionPeriod).includes(p as SubscriptionPeriod)
    //     ? (p as SubscriptionPeriod)
    //     : undefined,
    period: SubscriptionPeriod.YEAR,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
