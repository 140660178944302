import { useMemo, useCallback } from 'react';
import {
  MAX_DURATION_MINS,
  SELF_TIMER_INTERVALS,
  getValidIntervalTimes,
} from 'models/SelfTimerInterval';
import useSettingsActions from 'graphql/hooks/useSettingsActions';
import useModal from 'graphql/hooks/useModal';
import usePlayer from 'graphql/hooks/usePlayer';
import { Props } from './types';

const useConnect = ({
  duration,
  intervalTime,
  setDuration,
  setIsTimeSectionOpen,
}: {
  duration: Props['duration'];
  intervalTime: Props['intervalTime'];
  setDuration: Props['setDuration'];
  setIsTimeSectionOpen: Props['setIsTimeSectionOpen'];
}) => {
  const { updateSettings } = useSettingsActions();
  const { close } = useModal();
  const { openSelfTimer } = usePlayer();

  const { validIntervalTime, validIntervalTimeText } = useMemo(() => {
    const _validIntervalTime = getValidIntervalTimes(duration, intervalTime);
    return {
      validIntervalTime: _validIntervalTime,
      validIntervalTimeText: SELF_TIMER_INTERVALS[_validIntervalTime].text,
    };
  }, [duration, intervalTime]);

  const begin = useCallback(async () => {
    close();
    updateSettings({
      lastTimerInterval: validIntervalTime,
      lastTimerDuration: duration,
    });
    // We need to allow time for Material-ui to end its animation
    setTimeout(() => openSelfTimer(duration * 60, validIntervalTime), 250);
  }, [close, updateSettings, duration, validIntervalTime, openSelfTimer]);

  const decrementDuration = useCallback(() => {
    setDuration((value) => (value > 1 ? value - 1 : value));
  }, [setDuration]);

  const incrementDuration = useCallback(() => {
    setDuration((value) => (value < MAX_DURATION_MINS ? value + 1 : value));
  }, [setDuration]);

  const toogleTimeSection = useCallback(() => {
    setIsTimeSectionOpen((v) => !v);
  }, [setIsTimeSectionOpen]);

  return {
    duration,
    begin,
    closeModal: close,
    decrementDuration,
    incrementDuration,
    setDuration,
    toogleTimeSection,
    validIntervalTimeText,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
