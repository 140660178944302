import React, { FC } from 'react';
import { PlayerDisplayStatus } from 'graphql/generated/globalTypes';
import usePlayer from 'graphql/hooks/usePlayer';
import { Button, CloseIcon } from './styles';
import { Props } from './types';

const CloseButton: FC<Props> = ({ className, onClick }) => {
  const { displayStatus } = usePlayer();

  return (
    <Button
      $isMaximized={displayStatus === PlayerDisplayStatus.MAXIMIZED}
      className={className}
      onClick={onClick}
    >
      <CloseIcon />
    </Button>
  );
};

export default CloseButton;
