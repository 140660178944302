import neverReRender from 'lib/neverReRender';
import React, { FC } from 'react';
import { Content, HeartFullIcon, Root, Subtitle, Title } from './styles';

const NoFavorites: FC = () => (
  <Root>
    <Content>
      <HeartFullIcon />
      <Title>You don't have any favorites</Title>
      <Subtitle>
        Tap the heart icon on any Lesson, Conversation or Meditation to add it
        to your favorites list.
      </Subtitle>
    </Content>
  </Root>
);

export default neverReRender(NoFavorites);
