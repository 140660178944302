import React, { FC } from 'react';
import { Formik } from 'formik';
import { subYears } from 'date-fns';
import DatePicker from 'components/Inputs/DatePicker';
import TimeField from 'components/Inputs/TimeField';
import ActionButtons from 'components/FormActionButtonsWithFormik';
import { Errors, Info, Form, Modal, Title } from './styles';
import { Props } from './types';
import useConnect from './connect';

const AddMeditationTimeModal: FC<Props> = ({ addProgress }) => {
  const {
    closeModal,
    initialValues,
    isOpen,
    submit,
    validationSchema,
  } = useConnect(addProgress);

  return (
    <Modal hideLogo open={isOpen} onClose={closeModal}>
      <Title>Add Meditation Time</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {({
          dirty,
          errors,
          handleSubmit,
          isSubmitting,
          isValid,
          resetForm,
          values,
        }) => {
          const { duration } = values;

          return (
            <Form onSubmit={handleSubmit}>
              <DatePicker
                name="date"
                label="Meditation Date"
                minDate={subYears(new Date(), 5)}
                maxDate={new Date()}
              />
              <TimeField
                name="duration"
                label="Meditation Time"
                min={0}
                max={120}
              />
              <Info>
                You will add
                <span> {duration} minutes</span> to your total meditated time.
              </Info>
              {errors.submitError && <Errors>{errors.submitError}</Errors>}
              <ActionButtons
                closeModal={closeModal}
                invalid={!isValid || values.duration === 0}
                isSubmitting={isSubmitting}
                onReset={resetForm}
                pristine={!dirty && values.duration === 0}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddMeditationTimeModal;
