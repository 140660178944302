import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ACTIVE_SESSIONS_COUNT } from 'graphql/queries';
import { GetActiveSessionsCount } from 'graphql/generated/GetActiveSessionsCount';
import { HookOptions } from '../types';

const useActiveSessions = (options?: HookOptions) => {
  const fetchPolicy = options?.fetchPolicy || 'cache-first';
  const pollInterval =
    options?.pollInterval &&
    !['cache-first', 'cache-only'].includes(fetchPolicy)
      ? options?.pollInterval
      : 0;

  const { data, loading } = useQuery<GetActiveSessionsCount>(
    GET_ACTIVE_SESSIONS_COUNT,
    { fetchPolicy, pollInterval },
  );

  const activeSessionsCount = useMemo(
    () => data?.activeSessionsCount?.total || 0,
    [data],
  );

  return { activeSessionsCount, loading: !data && loading };
};

export default useActiveSessions;

export type UseActiveSessions = ReturnType<typeof useActiveSessions>;
