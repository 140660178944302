import React, { FC, memo, useCallback } from 'react';
import { useField } from 'formik';
import addYears from 'date-fns/addYears';
import { DateType } from '@date-io/type';
import {
  Container,
  Error,
  Picker,
  PickersUtilsProvider,
  Wrapper,
} from './styles';
import { Props } from './types';

const DatePicker: FC<Props> = ({
  className,
  id,
  isClearable = false,
  label,
  maxDate = addYears(new Date(), 2),
  minDate = new Date(),
  name,
  readOnly = false,
}) => {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { touched, error } = meta;
  const { setValue } = helpers;

  const onChange = useCallback(
    (date: DateType | null, inputValue?: string | null | undefined) => {
      try {
        setValue(date);
      } catch (e) {
        setValue(inputValue);
      }
    },
    [setValue],
  );

  return (
    <Container className={className}>
      <Wrapper id={id || name} label={label} readOnly={readOnly}>
        <PickersUtilsProvider>
          <Picker
            $hasLabel={!!label}
            clearable={isClearable}
            hiddenLabel
            minDate={minDate}
            maxDate={maxDate}
            format="MM/dd/yyyy"
            helperText={touched && error}
            value={value}
            onChange={onChange}
          />
        </PickersUtilsProvider>
      </Wrapper>
      {touched && error && <Error>{error}</Error>}
    </Container>
  );
};

export default memo(DatePicker);
