import React, { FC, memo } from 'react';
import {
  Card,
  ChevronRightIcon,
  Link,
  LinkContainer,
  PaperPlaneIcon,
  Title,
} from './styles';
import { Props } from './types';
import useConnect from './connect';

const ShareFreeMonth: FC<Props> = ({ className }) => {
  const { canShareFreeMonth, checkoutClick, openShareMonth } = useConnect();

  if (!canShareFreeMonth) {
    return (
      <LinkContainer className={className}>
        <Link to="/checkout" onClick={checkoutClick}>
          <Card $canShareFreeMonth={canShareFreeMonth}>
            <Title $canShareFreeMonth={canShareFreeMonth}>Subscribe now</Title>
            <ChevronRightIcon />
          </Card>
        </Link>
      </LinkContainer>
    );
  }

  return (
    <Card
      $canShareFreeMonth={canShareFreeMonth}
      className={className}
      onClick={openShareMonth}
    >
      <Title $reduceInMobile $canShareFreeMonth={canShareFreeMonth}>
        Give a free month of Waking Up
      </Title>
      <PaperPlaneIcon />
    </Card>
  );
};

export default memo(ShareFreeMonth);
