import React, { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import AccountEditPage from './Edit';
import InfoPage from './InfoPage';
import ProfilePage from './Profile';
import Subscription from './Subscription';
import ChangePaymentCard from './ChangePaymentCard';
import { Props } from './types';

const Account: FC<Props> = ({ isAnonymous }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {!isAnonymous && (
        <Route exact path={`${path}/edit`} component={AccountEditPage} />
      )}
      {!isAnonymous && (
        <Route
          exact
          path={`${path}/subscription/change_payment_card`}
          component={ChangePaymentCard}
        />
      )}
      <Route
        exact
        path={[
          `${path}/subscription/:company`,
          `${path}/corporate/:company`,
          `${path}/subscription`,
        ]}
        component={Subscription}
      />
      <Route
        path={[
          `${path}/help`,
          `${path}/sam`,
          `${path}/privacy`,
          `${path}/course`,
          `${path}/terms`,
        ]}
        component={InfoPage}
      />
      <Route path={path} component={ProfilePage} />
    </Switch>
  );
};

export default Account;
