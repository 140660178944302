import React, { FC, useCallback } from 'react';
import { ListRowProps, WindowScroller } from 'react-virtualized';
import Item from './Item';
import Loader from './Loader';
import Separator from './Separator';
import { List } from './styles';
import { Props } from './types';
import useLogic from './logic';

const CourseList: FC<Props> = ({
  courses = [],
  isLoading,
  openCourseActions,
  openCourseNotes,
  openResetCourseConfirm,
  openShareLink,
  setTrack,
  toggleFavorite,
}) => {
  const { rowHeight } = useLogic();

  const rowRenderer: FC<ListRowProps> = useCallback(
    ({ index: i, style }) => {
      const course = courses[i];

      return (
        <div key={course.id} style={style}>
          <Item
            backgroundColor={
              course.isDaily ? course.squareImageColor : course.color
            }
            courseHash={course.courseHash}
            courseId={course.id}
            courseNumber={course.course_number}
            coursePermission={course.permission}
            courseStatus={course.status}
            description={course.displayDescription}
            hasNotes={course.hasNotes}
            imageUrl={course.displayThumbnail}
            isDaily={course.is_daily}
            isFavorite={course.is_favorite}
            isIntro={course.isIntroCourse}
            isShareable={course.shareable}
            onMarkAsUnplayed={openResetCourseConfirm}
            onOpenActions={openCourseActions}
            onOpenNotes={openCourseNotes}
            onOpenShareLink={openShareLink}
            onPlay={setTrack}
            onToggleFavorite={toggleFavorite}
            title={course.displayFullTitle}
          />
          {i + 1 !== courses.length && <Separator />}
        </div>
      );
    },
    [
      courses,
      openCourseActions,
      openCourseNotes,
      openResetCourseConfirm,
      openShareLink,
      setTrack,
      toggleFavorite,
    ],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <WindowScroller>
      {({ onChildScroll, ...scrollInfo }) => (
        <List
          autoHeight
          autoWidth
          onScroll={onChildScroll}
          rowCount={courses.length}
          rowHeight={rowHeight}
          rowRenderer={rowRenderer}
          {...scrollInfo}
        />
      )}
    </WindowScroller>
  );
};

export default CourseList;
