import { useMemo, useCallback } from 'react';
import useFavorites from 'graphql/hooks/useFavorites';
import { Props } from './types';

const useConnect = (courseId: Props['courseId']) => {
  const { favorites, toggle } = useFavorites();

  const isFavorite = useMemo(
    () => !!favorites && favorites.some((v) => v.id === courseId),
    [courseId, favorites],
  );

  const toggleFavorite = useCallback(() => {
    toggle(courseId);
  }, [courseId, toggle]);

  return { isFavorite, toggleFavorite };
};

export default useConnect;

export type UseLogic = ReturnType<typeof useConnect>;
