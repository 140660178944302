import { useState, useEffect, useCallback } from 'react';
import { Props } from './types';

const SHOW_RESEND_TIMEOUT = 30;

const useConnect = ({
  resend,
  setErrors,
}: {
  resend: Props['resend'];
  setErrors: Props['setErrors'];
}) => {
  const [status, setStatus] = useState('dont-show');

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      setStatus('show-resend');
    }, SHOW_RESEND_TIMEOUT * 1000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, []);

  const resendCode = useCallback(async () => {
    try {
      await resend();
      setStatus('show-resent');
    } catch (error) {
      setErrors({ resendError: error.message });
    }
  }, [resend, setErrors]);

  return { resendCode, status };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
