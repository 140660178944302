import useFeatureContent from 'graphql/hooks/useFeatureContent';

const useConnect = () => {
  const { featureContent, loading } = useFeatureContent();

  const imageUrl = featureContent?.imageUrl || '';
  const isUnlocked = featureContent?.isUnlocked || false;
  const isPublicDeeplink = !!featureContent?.deepLink.includes('http');
  const deepLink = isUnlocked
    ? featureContent?.deepLink || ''
    : '/account/subscription';

  return {
    deepLink,
    imageUrl,
    isLoading: loading,
    isPublicDeeplink,
    isUnlocked,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
