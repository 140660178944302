import React, { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { Elements } from 'react-stripe-elements';
import PaymentSource from 'components/PaymentSource';
import Form from './Form';
import { Content, Header, Container, Title } from './styles';
import useConnect from './connect';

const ChangePaymentCard: FC = () => {
  const { firstName, lastName, loading, source, submit, upUrl } = useConnect();

  if (!loading && !source) {
    return <Redirect to={upUrl} />;
  }

  return (
    <Container>
      <Header
        testId="info-header"
        title="Change Payment Card"
        backHref={upUrl}
      />
      <Content>
        <Title>Change Payment Card</Title>
        {source && <PaymentSource source={source} />}
        <Elements>
          <Form
            firstName={firstName}
            handleSubmit={submit}
            isLoading={loading}
            lastName={lastName}
          />
        </Elements>
      </Content>
    </Container>
  );
};

export default ChangePaymentCard;
