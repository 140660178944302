import { useState, useEffect } from 'react';
import gtag from 'lib/gtag';

function useGoogleOptimize(
  experimentId: string,
  timeout = Infinity,
): number | null {
  const [variant, setVariant] = useState<null | number>(null);

  useEffect(() => {
    // Sets a timeout
    let optimizeTimedOut: ReturnType<typeof setTimeout>;

    // Sets the variant returned by Optimize and clears the timeout check
    const callback = (value: number) => {
      if (optimizeTimedOut) clearTimeout(optimizeTimedOut);
      setVariant(Number(value));
    };

    // Cleans up the optimize callback if the request times out
    const removeCallback = () =>
      // @ts-ignore
      gtag('event', 'optimize.callback', {
        name: experimentId,
        callback,
        remove: true,
      });

    if (timeout !== Infinity) {
      optimizeTimedOut = setTimeout(() => {
        // Clears the callback just in case this was a network timeout
        removeCallback();
        // Defaults to the 'Original'
        setVariant(0);
      }, timeout);
    }

    // Documented here:
    // https://support.google.com/optimize/answer/9059383?hl=en
    // @ts-ignore
    gtag('event', 'optimize.callback', { name: experimentId, callback });

    // Unregisters the event when the parent is unmounted or the experiment
    // id is changed
    return removeCallback;
  }, [experimentId, timeout]);

  // When testing functions you should use null checks. No special treatment
  // is necessary with React components.
  return variant === null ? null : variant;
}

export default useGoogleOptimize;
