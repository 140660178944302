import { apolloClient } from 'services/api';
import { Settings } from 'models/Settings';
import { GetSettings } from 'graphql/generated/GetSettings';
import { GET_SETTINGS } from 'graphql/queries';

function cacheReadSettings(): Settings | null {
  try {
    const cacheSettings = apolloClient.readQuery<GetSettings>({
      query: GET_SETTINGS,
    });

    return cacheSettings?.me?.settings
      ? JSON.parse(cacheSettings.me.settings)
      : null;
  } catch (e) {
    return null;
  }
}

export default cacheReadSettings;
