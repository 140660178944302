import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';
import { Content as ModalContent } from 'components/Modal/styles';
import Text from 'components/Text';

export const Modal = styled(DefaultModal)`
  ${ModalContent} {
    padding: ${({ theme }) => theme.pad.xxl};
    ${media.phonePortrait} {
      padding: ${({ theme }) => `${theme.pad.xxl} ${theme.pad.xl}`};
    }
  }
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.heading};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.margin.lg};
`;

export const Form = styled.form.attrs(() => ({ noValidate: true }))`
  > div:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.margin.sm};
  }
`;

export const Info = styled.div`
  text-align: center;
  font-size: ${({ theme }) => theme.font.textBig};
  margin-top: ${({ theme }) => theme.margin.lg};
  margin-bottom: ${({ theme }) => theme.margin.lg};
  span {
    color: ${({ theme }) => theme.colors.blue500};
    font-size: ${({ theme }) => theme.font.textSuperBig};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`;

export const Errors = styled(Text)`
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
`;
