import { useState, useEffect, useCallback } from 'react';
import { ModalType } from 'graphql/generated/globalTypes';
import { SelfTimerInterval } from 'models/SelfTimerInterval';
import useSettings from 'graphql/hooks/useSettings';
import useModal from 'graphql/hooks/useModal';

const useConnect = () => {
  const {
    settings: { lastTimerInterval, lastTimerDuration },
  } = useSettings();
  const { type, close } = useModal();
  const [duration, setDuration] = useState<number>(lastTimerDuration);
  const [intervalTime, setIntervalTime] = useState<SelfTimerInterval>(
    SelfTimerInterval.NONE,
  );
  const [isTimeSectionOpen, setIsTimeSectionOpen] = useState(false);

  const isOpen = type === ModalType.SELF_TIMER;

  useEffect(() => {
    setIntervalTime(lastTimerInterval);
    setDuration(lastTimerDuration);
  }, [lastTimerInterval, lastTimerDuration]);

  useEffect(() => {
    if (!isOpen) {
      setIsTimeSectionOpen(false);
    }
  }, [isOpen, isTimeSectionOpen]);

  const closeModal = useCallback(() => {
    close();
    setTimeout(() => {
      setIntervalTime(lastTimerInterval);
      setDuration(lastTimerDuration);
    }, 250);
  }, [close, lastTimerInterval, lastTimerDuration]);

  return {
    closeModal,
    duration,
    setDuration,
    intervalTime,
    isOpen,
    isTimeSectionOpen,
    setIntervalTime,
    setIsTimeSectionOpen,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
