import styled from 'styled-components';
import DefaultFavoriteButton from 'components/FavoriteButton';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
`;

export const FavoriteButton = styled(DefaultFavoriteButton).attrs(() => ({
  size: 24,
}))`
  display: block;
`;

export const Title = styled.div`
  font-size: 0.75rem;
  line-height: 1;
  margin-top: 0.6rem;
`;
