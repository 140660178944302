import { InputHTMLAttributes, RefObject } from 'react';
import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultWrapper from '../Wrapper';

const DefaultInput = styled.input<
  InputHTMLAttributes<HTMLInputElement> & {
    ref: RefObject<HTMLInputElement>;
  }
>`
  background: transparent;
  border-radius: ${({ theme }) => theme.radius.sm};
  border: none;
  color: ${({ theme }) => theme.textInput};
  font-size: 0.9375rem;
  line-height: 1;
  width: 100%;

  &::placeholder {
    color: ${({ theme }) => theme.textPlaceholder};
    font-size: 0.9375rem;
    font-weight: 300;
    line-height: 1.3;
    opacity: 1;
  }

  &:focus {
    outline: 0;
  }

  ${media.tabletPortrait} {
    &::placeholder {
      line-height: 1.4;
    }
  }
`;

export const Container = styled.div``;

export const Wrapper = styled(DefaultWrapper)<{ readOnly: boolean }>`
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'default')};
  user-select: ${({ readOnly }) => (readOnly ? 'none' : 'default')};
`;

export const Input = styled(DefaultInput)<
  InputHTMLAttributes<HTMLInputElement> & {
    $hasLabel: boolean;
    $isClearable: boolean;
    ref: RefObject<HTMLInputElement>;
  }
>`
  padding: ${({ $hasLabel, $isClearable, theme }) =>
    `${$hasLabel ? '0' : theme.pad.md} ${
      $isClearable ? theme.pad.xxxl : theme.pad.xl
    } ${$hasLabel ? '0' : theme.pad.md} ${theme.pad.xl}`};
`;

export const ClearContainer = styled.div`
  position: relative;
`;

export const ClearButtonContainer = styled.div<{ $hasValue: boolean }>`
  bottom: 0;
  display: ${({ $hasValue }) => ($hasValue ? 'flex' : 'none')};
  align-items: center;
  position: absolute;
  right: 10px;
  top: 0;
`;

export const ClearButton = styled.button`
  background: transparent;
  border: none;
  border-radius: 50%;
  box-sizing: content-box;
  color: ${({ theme }) => theme.textSuperFaint};
  cursor: pointer;
  display: block;
  line-height: 1;
  outline: none;
  padding: 0.4rem;
  transition: background-color 0.3s ease;
  width: 1rem;

  &::before {
    content: '\u2715';
  }

  &:hover {
    background: #ececec;
  }
  &:active {
    background: #e4e4e4;
    transition: none;
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 0.9rem;
  margin: 0.4rem 0.4rem 0 0.4rem;
`;
