import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Lesson: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={(size * 20) / 22}
    viewBox="0 0 22 20"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="nonzero"
      d="M11 2a4.992 4.992 0 0 1 4-2h6a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1h-7a2 2 0 0 0-2 2c0 1.333-2 1.333-2 0a2 2 0 0 0-2-2H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h6c1.636 0 3.088.785 4 2zm1 3v10.535A3.982 3.982 0 0 1 14 15h6V2h-5a3 3 0 0 0-3 3zm-2 0a3 3 0 0 0-3-3H2v13h6c.729 0 1.412.195 2 .535V5z"
    />
  </svg>
);

export default memo(Lesson);
