import { useCallback, useEffect } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router';
import kebabCase from 'lodash/kebabCase';
import { parse } from 'query-string';
import { trackEvent, AnalyticsEvent } from 'services/analytics';
import { PackType } from 'models/Pack';
import { CoursePermission } from 'models/Course';
import { PlayerAudioStatus } from 'graphql/generated/globalTypes';
import {
  getProfile,
  getPack,
  getCourse,
  getNextMeditation,
} from 'graphql/requests';
import useProcessOffer from 'graphql/hooks/useProcessOffer';
import usePlayer from 'graphql/hooks/usePlayer';
import useFreeMonth from 'graphql/hooks/useFreeMonth';
import useModal from 'graphql/hooks/useModal';

const OPENVIEW_TYPES: Record<string, { description: string; link: string }> = {
  practice: { description: 'Practice', link: '/practice' },
  theory: { description: 'Theory', link: '/theory' },
  favorites: { description: 'Favorites', link: '/favorites' },
  settings: { description: 'Settings', link: '/account' },
  profile: { description: 'Profile', link: '/account/edit' },
  subscriptions: { description: 'Subscriptions', link: '/checkout' },
  timer: { description: 'Timer', link: '/timer' },
  aboutCourse: { description: 'About Course', link: '/account/course' },
  aboutSam: { description: 'About Sam', link: '/account/sam' },
  faq: { description: 'Faq', link: '/account/help' },
  terms: { description: 'Terms', link: '/account/terms' },
  privacy: { description: 'Privacy', link: '/account/privacy' },
  onboarding: { description: 'Onboarding', link: '/onboarding' },
  dailyMeditationDuration: {
    description: 'Daily Meditation Duration',
    link: '/dailyMeditationDuration',
  },
  share: { description: 'Share', link: '/share' },
  playNextMeditation: {
    description: 'Play Next',
    link: '/playNextMeditation',
  },
};

const useConnect = () => {
  const { processOffer } = useProcessOffer();
  const { openCourse } = usePlayer();
  const {
    openDailyDuration,
    openOnboarding,
    openSelfTimer,
    openShareMonth,
  } = useModal();
  const { validate } = useFreeMonth();
  const { replace } = useHistory();
  const { search } = useLocation();

  const matchesSelfTimer = !!useRouteMatch(['/open-self-timer', '/timer']);
  const matchesOpenPack = useRouteMatch<{ id: string }>('/pack/:id');
  const packId = matchesOpenPack?.params?.id;
  const matchesSetTrack = useRouteMatch<{ id: string }>('/course/:id');
  const courseId = matchesSetTrack?.params?.id;
  const { behavior, endTime, playback, time } = parse(search);
  const matchesCheckout = !!useRouteMatch({
    path: '/checkout',
    strict: true,
  });
  const matchesCheckoutWithCode = useRouteMatch<{ code: string }>({
    path: '/checkout/:code',
    strict: true,
  });
  const checkoutCode = matchesCheckoutWithCode?.params?.code;
  const matchesOfferWithCode = useRouteMatch<{ code: string }>({
    path: '/offer/:code',
    strict: true,
  });
  const offerCode = matchesOfferWithCode?.params?.code;
  const matchesOnboarding = !!useRouteMatch(['/onboarding']);
  const matchesDailyMeditationDuration = !!useRouteMatch([
    '/dailyMeditationDuration',
  ]);
  const matchesShare = !!useRouteMatch({ path: '/share', exact: true });
  const matchesShareRedeemWithCode = useRouteMatch<{ token: string }>({
    path: '/share/redeem/:token',
    strict: true,
    exact: true,
  });
  const redeemToken = matchesShareRedeemWithCode?.params?.token;
  const matchesPlayNextMeditation = !!useRouteMatch(['/playNextMeditation']);
  const matchesOpenview = useRouteMatch<{ type: string }>(['/openview/:type']);
  const openviewType = matchesOpenview?.params?.type;
  const matchesRedeemFreeMonth = useRouteMatch<{ code: string }>(
    '/redeemFreeMonth/:code',
  );
  const freeMonthCode = matchesRedeemFreeMonth?.params?.code;

  const handleRedeemFreeMonth = useCallback(
    (code: string) => {
      validate(code);
      replace('/');
    },
    [validate, replace],
  );

  const handleOpenSelfTimer = useCallback(() => {
    openSelfTimer();
    replace('/');
  }, [openSelfTimer, replace]);

  const handleSetTrack = useCallback(
    async (
      id: string,
      initialAudioStatus?: string,
      initialPosition?: number,
      endPosition?: number,
      endPositionBehavior?: string,
    ) => {
      replace('/');

      const course = await getCourse(id);
      const courseDuration = course?.mp3_length || 0;

      if (!course) {
        return;
      }

      if (course.permission === CoursePermission.FORBIDDEN) {
        replace(`/account/subscription`);
      } else {
        const payload = {
          id: course.id,
          initialAudioStatus:
            initialAudioStatus === 'paused'
              ? PlayerAudioStatus.STOP
              : PlayerAudioStatus.PLAYING,
          initialPosition:
            initialPosition && initialPosition < courseDuration
              ? initialPosition
              : undefined,
          endPosition:
            endPosition && endPosition < courseDuration
              ? endPosition
              : undefined,
          endPositionPaused:
            endPosition && endPositionBehavior
              ? endPositionBehavior === 'pausePlayer'
              : undefined,
        };

        await openCourse(payload);

        trackEvent('Deep Link Course Link', {
          description: 'Course Link',
          parameters: id,
          url: window.location.href,
        });
      }
    },
    [openCourse, replace],
  );

  const handleOpenPack = useCallback(
    async (id: string) => {
      const pack = await getPack(id);

      if (!pack) {
        return replace('/');
      }

      const { category, parentPackId, title, type } = pack;
      const packType = type === PackType.LESSON ? 'theory' : 'practice';

      trackEvent('Deep Link Pack', {
        description: 'Pack',
        parameters: id,
        url: window.location.href,
      });

      if (title) {
        if (category === 'sub_pack' && parentPackId) {
          const parentPack = await getPack(parentPackId);
          const parentTitle = parentPack?.title;

          if (parentTitle) {
            replace(
              `/${packType}/${kebabCase(parentTitle)}/${kebabCase(title)}`,
            );
          }
        } else {
          replace(`/${packType}/${kebabCase(title)}`);
        }
      }
    },
    [replace],
  );

  const handleCheckout = useCallback(() => {
    replace(`/account/subscription`);
  }, [replace]);

  const handleCheckoutWithCode = useCallback(
    (code: string) => {
      replace(`/account/subscription/${code}`);
    },
    [replace],
  );

  const handleOfferWithCode = useCallback(
    async (code: string) => {
      const { isAnonymous } = await getProfile();

      if (isAnonymous) {
        replace('/');
      } else {
        replace('/account');
        await processOffer(code);
      }
    },
    [processOffer, replace],
  );

  const handleOpenOnboarding = useCallback(() => {
    openOnboarding();
    replace('/');
  }, [openOnboarding, replace]);

  const handleOpenDailyMeditationDuration = useCallback(() => {
    openDailyDuration();
    replace('/account');
  }, [openDailyDuration, replace]);

  const handleOpenShare = useCallback(async () => {
    const { canShareFreeMonth } = await getProfile();

    if (canShareFreeMonth) {
      openShareMonth();
    }
    replace('/');
  }, [openShareMonth, replace]);

  const handleShareRedeemWithCode = useCallback(
    async (token: string) => {
      validate(token);
      replace('/');
    },
    [replace, validate],
  );

  const handlePlayNextMeditation = useCallback(async () => {
    replace('/');

    const nextPractice = await getNextMeditation();
    if (nextPractice) {
      await openCourse({ id: nextPractice.id });
    } else {
      replace('/checkout');
    }
  }, [openCourse, replace]);

  const handleOpenview = useCallback(
    (type: string) => {
      if (OPENVIEW_TYPES[type]) {
        const { description, link } = OPENVIEW_TYPES[type];
        const eventType = `Deep Link ${description}` as AnalyticsEvent;

        if (description && link) {
          trackEvent(eventType, { description, url: window.location.href });
          replace(link);
        }
      }
    },
    [replace],
  );

  useEffect(() => {
    if (matchesSelfTimer) {
      handleOpenSelfTimer();
    }
  }, [matchesSelfTimer, handleOpenSelfTimer]);

  useEffect(() => {
    if (courseId) {
      const initialAudioStatus =
        playback && !Array.isArray(playback) ? playback : undefined;
      const initialPosition =
        time && !Array.isArray(time) ? Number(time) : undefined;
      const endPosition =
        endTime && !Array.isArray(endTime) ? Number(endTime) : undefined;
      const endPositionBehavior =
        behavior && !Array.isArray(behavior) ? behavior : undefined;
      handleSetTrack(
        courseId,
        initialAudioStatus,
        initialPosition,
        endPosition,
        endPositionBehavior,
      );
    }
  }, [behavior, courseId, endTime, handleSetTrack, playback, time]);

  useEffect(() => {
    if (packId) {
      handleOpenPack(packId);
    }
  }, [packId, handleOpenPack]);

  useEffect(() => {
    if (matchesCheckout) {
      handleCheckout();
    }
  }, [matchesCheckout, handleCheckout]);

  useEffect(() => {
    if (checkoutCode) {
      handleCheckoutWithCode(checkoutCode);
    }
  }, [checkoutCode, handleCheckoutWithCode]);

  useEffect(() => {
    if (offerCode) {
      handleOfferWithCode(offerCode);
    }
  }, [handleOfferWithCode, offerCode]);

  useEffect(() => {
    if (matchesOnboarding) {
      handleOpenOnboarding();
    }
  }, [matchesOnboarding, handleOpenOnboarding]);

  useEffect(() => {
    if (matchesDailyMeditationDuration) {
      handleOpenDailyMeditationDuration();
    }
  }, [matchesDailyMeditationDuration, handleOpenDailyMeditationDuration]);

  useEffect(() => {
    if (matchesShare) {
      handleOpenShare();
    }
  }, [matchesShare, handleOpenShare]);

  useEffect(() => {
    if (redeemToken) {
      handleShareRedeemWithCode(redeemToken);
    }
  }, [handleShareRedeemWithCode, redeemToken]);

  useEffect(() => {
    if (matchesPlayNextMeditation) {
      handlePlayNextMeditation();
    }
  }, [matchesPlayNextMeditation, handlePlayNextMeditation]);

  useEffect(() => {
    if (openviewType) {
      handleOpenview(openviewType);
    }
  }, [openviewType, handleOpenview]);

  useEffect(() => {
    if (freeMonthCode) {
      handleRedeemFreeMonth(freeMonthCode);
    }
  }, [freeMonthCode, handleRedeemFreeMonth]);

  return null;
};

export default useConnect;
