import React, { FC } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider as MaterialUIProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { ApiConfig } from 'config';
import { ThemeProvider } from 'styled-components';
import { apolloClient } from 'services/api';
import Routes from 'containers/Routes';
import GlobalStyle from 'styles/globalStyle';
import themes, { materialUITheme, notistackConfig } from 'styles/themes';

const App: FC = () => (
  <ApolloProvider client={apolloClient}>
    <StripeProvider apiKey={ApiConfig.STRIPE_KEY}>
      <Router>
        <MaterialUIProvider theme={materialUITheme}>
          <ThemeProvider theme={themes.light}>
            <SnackbarProvider {...notistackConfig}>
              <>
                <Routes />
                <GlobalStyle />
              </>
            </SnackbarProvider>
          </ThemeProvider>
        </MaterialUIProvider>
      </Router>
    </StripeProvider>
  </ApolloProvider>
);

export default App;
