import { useCallback, useMemo } from 'react';
import { ModalType } from 'graphql/generated/globalTypes';
import useModal from 'graphql/hooks/useModal';
import useCourse from 'graphql/hooks/useCourse';
import useFavorites from 'graphql/hooks/useFavorites';
import { CourseStatus } from 'models/Course';

const useConnect = () => {
  const { toggle } = useFavorites();
  const {
    close,
    courseActionsPayload,
    openCourseNotes,
    openResetCourseConfirm,
    openShareLink,
    type,
  } = useModal();
  const { course } = useCourse(courseActionsPayload?.courseId);

  const {
    courseHash,
    courseId,
    isCompleted,
    isFavorite,
    isShareable,
    hasNotes,
  } = useMemo(
    () => ({
      courseHash: course?.course_hash,
      courseId: course?.id,
      isCompleted: course?.status === CourseStatus.FINISHED,
      isFavorite: course?.is_favorite || false,
      isShareable: course?.shareable || false,
      hasNotes: course?.hasNotes || false,
    }),
    [course],
  );

  const handleCloseModal = useCallback(() => {
    close(250);
  }, [close]);

  const handleToggleFavorite = useCallback(() => {
    if (courseId) {
      toggle(courseId);
    }
  }, [courseId, toggle]);

  const handleOpenNotes = useCallback(() => {
    if (courseId && hasNotes) {
      close();
      setTimeout(() => {
        openCourseNotes({ courseId });
      }, 500);
    }
  }, [close, courseId, hasNotes, openCourseNotes]);

  const handleMarkAsUnplayed = useCallback(() => {
    if (isCompleted && courseId) {
      close();
      openResetCourseConfirm({ courseId });
    }
  }, [close, courseId, isCompleted, openResetCourseConfirm]);

  const handleShare = useCallback(() => {
    if (isShareable && courseId && courseHash) {
      close();
      setTimeout(() => {
        openShareLink({
          courseId,
          title: 'Share this course',
          description:
            'Share this link with anyone you feel would benefit from this Waking Up course',
          link: `https://dynamic.wakingup.com/course/${courseHash}`,
        });
      }, 500);
    }
  }, [close, courseHash, courseId, isShareable, openShareLink]);

  return {
    handleCloseModal,
    handleMarkAsUnplayed,
    handleOpenNotes,
    handleShare,
    handleToggleFavorite,
    hasNotes,
    isCompleted,
    isFavorite,
    isOpen: type === ModalType.COURSE_ACTIONS,
    isShareable,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
