import React, { forwardRef, Ref } from 'react';
import Slide from '@material-ui/core/Slide';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import { TransitionProps } from '@material-ui/core/transitions';

const SlideUp = forwardRef((props: TransitionProps, ref: Ref<unknown>) => (
  <Slide direction="up" ref={ref} {...props} />
));

const animations = {
  slideUp: SlideUp,
  zoom: Zoom,
  fade: Fade,
};

export default animations;
