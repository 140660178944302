import { useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { Howl } from 'howler';
import { trackMediaEvent } from 'services/analytics';
import { normalizePlayer } from 'models/Player';
import { DailyMeditationDuration } from 'models/DailyMeditationDuration';
import { GetPlayer } from 'graphql/generated/GetPlayer';
import {
  PlayerAudioStatus,
  PlayerDisplayStatus,
  PlayerType,
} from 'graphql/generated/globalTypes';
import { GET_PLAYER } from 'graphql/queries';
import {
  getAutoplayTrackList,
  getCourse,
  getFeatureContent,
  getFeatured,
  getPlayer,
  getSettings,
  updatePlayerCode,
  updateSettings,
} from 'graphql/requests';
import {
  cacheReadSettings,
  cacheReadPlayer,
  cacheResetPlayer,
  cacheUpdatePlayer,
} from 'graphql/cache';

const usePlayerCourse = () => {
  const { data: playerData } = useQuery<GetPlayer>(GET_PLAYER, {
    fetchPolicy: 'cache-only',
  });

  const {
    audioStatus,
    dailyMeditationDuration,
    displayStatus,
    endPosition,
    endPositionPaused,
    initialPosition,
  } = useMemo(() => normalizePlayer(playerData?.player), [playerData]);

  const setAudioStatus = useCallback((newAudioStatus: PlayerAudioStatus) => {
    cacheUpdatePlayer({ audioStatus: newAudioStatus });
  }, []);

  const setDailyMeditationDuration = useCallback(
    (duration: DailyMeditationDuration) => {
      cacheUpdatePlayer({ dailyMeditationDuration: duration });
    },
    [],
  );

  const close = useCallback(
    async (position?: number | Howl, duration?: number) => {
      const cachePlayer = cacheReadPlayer();
      const currentCourseId = cachePlayer?.courseId;

      if (currentCourseId && typeof position === 'number' && duration) {
        const tPosition = Math.floor(position);
        const tDuration = Math.floor(duration);
        const percentComplete = Math.floor(100 * (tPosition / tDuration));
        const quartileComplete = Math.floor(percentComplete / 25) + 1;
        await trackMediaEvent('Media Close', currentCourseId, {
          position: tPosition,
          percentComplete,
          quartileComplete,
        });

        const settings = cacheReadSettings();
        if (settings) {
          updateSettings({
            player: null,
            resumePlayingList: {
              ...settings.resumePlayingList,
              [currentCourseId]: tPosition,
            },
          });
        }
      }

      return cacheResetPlayer();
    },
    [],
  );

  const completeTrack = useCallback(async () => {
    const { courseId } = await getPlayer();

    if (courseId) {
      const { resumePlayingList } = await getSettings();
      await updateSettings({
        player: null,
        resumePlayingList: { ...resumePlayingList, [courseId]: 0 },
      });

      const featured = await getFeatureContent();
      const course = await getCourse(courseId);
      if (
        featured &&
        course &&
        (featured.deepLink.includes(`/course/${courseId}`) ||
          featured.deepLink
            .toLowerCase()
            .includes(`/course/${course.courseHash.toLowerCase()}`))
      ) {
        await getFeatureContent('network-only');
        // TODO: Remove this when we have the new course query implemented.
        await getFeatured('network-only');
      }

      const autoplayTrackList = await getAutoplayTrackList();
      if (autoplayTrackList) {
        const index = autoplayTrackList.indexOf(courseId);
        if (index >= 0 && index < autoplayTrackList.length - 1) {
          if (!document.hidden) {
            cacheUpdatePlayer({
              audioStatus: PlayerAudioStatus.STOP,
              type: null,
            });
          }

          setTimeout(() => {
            updatePlayerCode(courseId, true);

            cacheUpdatePlayer({
              audioStatus: PlayerAudioStatus.PLAYING,
              courseId: autoplayTrackList[index + 1],
              displayStatus: PlayerDisplayStatus.MAXIMIZED,
              type: PlayerType.COURSE,
            });
          }, 750);

          return;
        }
      }
    }

    return cacheResetPlayer();
  }, []);

  return {
    audioStatus,
    close,
    completeTrack,
    dailyMeditationDuration,
    displayStatus,
    endPosition,
    endPositionPaused,
    initialPosition,
    setAudioStatus,
    setDailyMeditationDuration,
  };
};

export default usePlayerCourse;

export type UsePlayerCourse = ReturnType<typeof usePlayerCourse>;
