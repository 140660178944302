import React, { FC, memo } from 'react';
import {
  Container,
  EndMessage,
  Loader,
  LoaderContainer,
  PlayButton,
  ProgressBar,
  Video,
} from './styles';
import { AudioStatus, Props } from './types';
import useLogic from './logic';

const VideoPlayer: FC<Props> = ({ className, onVideoEnd }) => {
  const {
    audioStatus,
    handleTogglePlay,
    hasStarted,
    isLoading,
    progress,
    videoRef,
    videoUrl,
  } = useLogic({ onVideoEnd });

  const showLoading = isLoading;
  const showProgressBar =
    !isLoading && hasStarted && audioStatus !== AudioStatus.FINISHED;
  const showPlayButton = !isLoading && audioStatus === AudioStatus.PAUSED;
  const showEndMessage = !isLoading && audioStatus === AudioStatus.FINISHED;

  return (
    <Container
      $isFinished={audioStatus === AudioStatus.FINISHED}
      className={className}
      onClick={!isLoading ? handleTogglePlay : undefined}
    >
      <Video
        autoPlay={false}
        controls={false}
        playsInline
        preload="auto"
        ref={videoRef}
        src={videoUrl}
      />
      {showLoading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      {showPlayButton && <PlayButton />}
      {showEndMessage && <EndMessage>Thank you</EndMessage>}
      {showProgressBar && <ProgressBar value={progress} />}
    </Container>
  );
};

export default memo(VideoPlayer);
