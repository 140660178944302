import React, { FC, useEffect } from 'react';
import lottieAnimation from 'assets/animations/confirmation-success.json';
import lottie from 'lottie-web/build/player/lottie_light';
import { Container, Message, Animation } from './styles';
import { Props } from './types';

const animationName = 'lottie-confirmation-success';

const AnimatedConfirmation: FC<Props> = ({ message }) => {
  useEffect(() => {
    const element = document.getElementById(animationName);
    lottie.loadAnimation({
      container: element!,
      renderer: 'svg',
      animationData: lottieAnimation,
      name: animationName,
      autoplay: true,
      loop: false,
    });
  }, []);

  return (
    <Container>
      <Animation id={animationName} />
      <Message>{message}</Message>
    </Container>
  );
};

export default AnimatedConfirmation;
