import React, { FC } from 'react';
import {
  CloseButton,
  CloseIcon,
  HeartFullIcon,
  HeartHollowIcon,
  Menu,
  MenuOption,
  Modal,
  NotesIcon,
  ShareIcon,
  Text,
  UnplayedIcon,
} from './styles';
import useConnect from './connect';

const CourseNotesModal: FC = () => {
  const {
    handleCloseModal,
    handleMarkAsUnplayed,
    handleOpenNotes,
    handleShare,
    handleToggleFavorite,
    hasNotes,
    isCompleted,
    isFavorite,
    isOpen,
    isShareable,
  } = useConnect();

  return (
    <Modal
      disableRestoreFocus
      hideClose
      onClose={handleCloseModal}
      open={isOpen}
    >
      <CloseButton onClick={handleCloseModal}>
        <CloseIcon />
      </CloseButton>
      <Menu>
        <MenuOption onClick={handleToggleFavorite}>
          {isFavorite ? <HeartFullIcon /> : <HeartHollowIcon />}
          <Text>{isFavorite ? 'Remove From Favorites' : 'Favorite'}</Text>
        </MenuOption>
        {isShareable && (
          <MenuOption onClick={handleShare}>
            <ShareIcon />
            <Text>Share Content</Text>
          </MenuOption>
        )}
        {isCompleted && (
          <MenuOption onClick={handleMarkAsUnplayed}>
            <UnplayedIcon />
            <Text>Mark Unplayed</Text>
          </MenuOption>
        )}
        {hasNotes && (
          <MenuOption onClick={handleOpenNotes}>
            <NotesIcon />
            <Text>More Info</Text>
          </MenuOption>
        )}
      </Menu>
    </Modal>
  );
};

export default CourseNotesModal;
