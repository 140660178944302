import styled from 'styled-components';
import { media } from 'styles/mixins';
import { contentWidth, SEPARATOR_HEIGHT_PX } from '../styles';

const Divider = styled.hr`
  border: 0;
  background-color: #e1e1e5;
  color: #e1e1e5;
  height: ${SEPARATOR_HEIGHT_PX}px;
  ${contentWidth};

  ${media.tabletLandscape} {
    width: 100%;
  }
`;

export default Divider;
