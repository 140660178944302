import React, { FC, memo, useCallback } from 'react';
import { useField } from 'formik';
import pluralize from 'lib/pluralize';
import {
  Arrow,
  Button,
  ButtonsGroup,
  Container,
  Error,
  Input,
  Suffix,
  TimeContainer,
  Value,
  Wrapper,
} from './styles';
import { Props } from './types';

const TimeField: FC<Props> = ({
  className,
  id,
  label,
  max = 1440,
  min = 0,
  name,
  readOnly = false,
}) => {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { error, touched } = meta;
  const { setValue } = helpers;

  const updateValue = useCallback(
    (newValue: number) => {
      if (newValue >= min && newValue <= max) {
        setValue(Number(newValue));
      }
    },
    [min, max, setValue],
  );

  const incrementValue = useCallback(() => updateValue(value + 1), [
    value,
    updateValue,
  ]);

  const decrementValue = useCallback(() => updateValue(value - 1), [
    value,
    updateValue,
  ]);

  const onChange = useCallback(
    (e) => {
      updateValue(Number(e.target.value));
    },
    [updateValue],
  );

  return (
    <Container className={className}>
      <Wrapper id={id || name} label={label} readOnly={readOnly}>
        <TimeContainer $hasLabel={!!label}>
          <Value>
            <Input value={value} onChange={onChange} />
            <Suffix>{pluralize(value, 'min', 'mins')}</Suffix>
          </Value>
          <ButtonsGroup>
            <Button onClick={incrementValue}>
              <Arrow $increment />
            </Button>
            <Button onClick={decrementValue}>
              <Arrow />
            </Button>
          </ButtonsGroup>
        </TimeContainer>
      </Wrapper>
      {touched && error && <Error>{error}</Error>}
    </Container>
  );
};

export default memo(TimeField);
