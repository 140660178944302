import { CourseType } from 'models/Course';
import getCourses from './getCourses';

const getFavorites = async (courseType?: CourseType) => {
  const courses = await getCourses();

  const favorites = courses
    .filter((course) =>
      courseType
        ? course.courseType === courseType && course.isFavorite
        : course.isFavorite,
    )
    .sort((a, b) => a.course_number - b.course_number);

  return favorites;
};

export default getFavorites;
