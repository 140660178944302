import React, { FC, memo } from 'react';
import { Container, HeartHollow } from './styles';
import { Props } from './types';

const NoElements: FC<Props> = ({ elementType, className }) => (
  <Container className={className}>
    You don't have any favorite {elementType} yet! Add some by clicking on the{' '}
    <HeartHollow /> icon.
  </Container>
);

export default memo(NoElements);
