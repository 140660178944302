import React, { FC } from 'react';
import { Button, Container } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Resend: FC<Props> = ({ className, resend, setErrors }) => {
  const { resendCode, status } = useConnect({ resend, setErrors });

  if (status === 'dont-show') {
    return null;
  }

  return (
    <Container className={className}>
      {status === 'show-resend' ? (
        <>
          Didn't receive one? <Button onClick={resendCode}>Resend code</Button>
        </>
      ) : (
        'New code sent'
      )}
    </Container>
  );
};

export default Resend;
