import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { Token } from 'react-stripe-checkout';
import { ApiConfig } from 'config';
import { trackEvent } from 'services/analytics';
import capitalize from 'lib/capitalize';
import gtag from 'lib/gtag';
import useGoogleOptimize from 'lib/useGoogleOptimize';
import useSubscription from 'graphql/hooks/useSubscription';
import {
  LogicProps,
  SubscriptionMode,
  SubscriptionModeNames,
  SubscriptionPeriod,
} from './types';

const useConnect = ({ code, isPublic, onSubscribed, period }: LogicProps) => {
  const goVariant = useGoogleOptimize(ApiConfig.GO_LAMPVSCLOUD_ID, 1000);
  const { create, loading, plans } = useSubscription({ variables: { code } });
  const { pathname, search } = useLocation();

  const subscriptionPlans = plans.map((plan) => {
    if (plan.interval === 'month') {
      return {
        color: 'blue',
        description: 'pay monthly',
        link: `${pathname}${search ? `${search}&` : '?'}p=${
          SubscriptionPeriod.MENU_MONTH
        }`,
        price: Math.floor(plan.amount / 100),
        title: 'Monthly',
      };
    }

    return {
      color: 'orange',
      description: `only $${Math.floor(plan.amount / 12) / 100}/month`,
      link: `${pathname}${search ? `${search}&` : '?'}p=${
        SubscriptionPeriod.MENU_YEAR
      }`,
      price: Math.floor(plan.amount / 100),
      title: 'Annual',
    };
  });

  const plan = plans.find((v) => {
    if (
      period === SubscriptionPeriod.MONTH ||
      period === SubscriptionPeriod.MENU_MONTH
    ) {
      return v.interval === 'month';
    }
    return v.interval === 'year';
  });
  const planId = plan?.id || '';
  const planAmount = plan?.amount || 0;
  const planOriginalAmount = plan?.originalAmount || 0;
  const planInterval = plan?.interval || 'year';
  const planPeriod = `${capitalize(plan?.interval || 'year')}ly`;

  const handleToken = useCallback(
    async (token: Token) => {
      if (planId) {
        try {
          const user = await create(token, planId, isPublic, code);
          if (user) {
            // @ts-ignore
            gtag('event', 'subscriptionCreation', {
              event_category: 'subscription',
              value: goVariant,
            });
            trackEvent(
              'Purchase Completed',
              goVariant !== null
                ? { variant: SubscriptionModeNames[goVariant] }
                : undefined,
            );
          }
          if (onSubscribed) onSubscribed(user);
        } catch (error) {
          if (onSubscribed) onSubscribed();
        }
      }
    },
    [code, create, goVariant, isPublic, onSubscribed, planId],
  );

  return {
    handleToken,
    isLoading: loading,
    isLoadingGOVariant: goVariant === null,
    mode:
      goVariant !== null && Object.values(SubscriptionMode).includes(goVariant)
        ? (goVariant as SubscriptionMode)
        : undefined,
    planAmount,
    planInterval,
    planOriginalAmount,
    planPeriod,
    subscriptionPlans,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
