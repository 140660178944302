import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultLogo from 'components/Logo';
import DefaultIconButton from 'components/IconButton';
import DefaultChevronLeftIcon from 'components/Icons/ChevronLeft';
import DefaultNewSubscription from 'containers/NewSubscription';

export const Container = styled.div`
  bottom: 0;
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
`;

export const Logo = styled(DefaultLogo).attrs(() => ({ lightBg: true }))`
  align-self: center;
  display: block;
  margin-bottom: 3rem;
  user-select: none;
  width: 230px;

  ${media.phonePortrait} {
    align-self: center;
    display: block;
    left: 50%;
    position: absolute;
    top: 1.6875rem;
    transform: translateX(-50%);
    user-select: none;
    width: 93px;
    & * {
      fill: ${({ theme }) => theme.colors.subscription.white} !important;
    }
  }
`;

export const Card = styled.div`
  border-radius: ${({ theme }) => theme.radius.lg};
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  height: 720px;
  position: relative;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 436px;

  ${media.phonePortrait} {
    border-radius: 0;
    height: 100%;
    width: 100%;
  }
`;

export const BackButton = styled(DefaultIconButton)<{
  $hasSubscriptionPlansMenu: boolean;
}>`
  align-items: center;
  color: ${({ theme }) => theme.colors.subscription.grey100};
  display: ${({ $hasSubscriptionPlansMenu }) =>
    $hasSubscriptionPlansMenu ? 'flex' : 'none'};
  font-size: 0.8125rem;
  left: 0.9375rem;
  line-height: 1rem;
  position: absolute;
  top: 1.5rem;
`;

export const ChevronLeftIcon = styled(DefaultChevronLeftIcon).attrs(() => ({
  size: 10,
}))`
  display: block;
  margin-right: 0.2rem;
`;

export const NewSubscription = styled(DefaultNewSubscription)`
  border-radius: inherit;
  height: 100%;
  padding-top: 1rem;
  width: 100%;

  ${media.phonePortrait} {
    padding-top: 2rem;
  }
`;
