import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { normalizeCourse, getDailyPermission } from 'models/Course';
import { normalizeDailyPack, getDailyPackPermission } from 'models/Pack';
import { GetDaily, GetDailyVariables } from '../generated/GetDaily';
import { GET_DAILY } from '../queries';

const useDailyMeditation = () => {
  const { data: dailyData, loading: isLoadingGetDaily } = useQuery<
    GetDaily,
    GetDailyVariables
  >(GET_DAILY, {
    variables: { date: format(new Date(), 'yyyy-MM-dd') },
    fetchPolicy: 'cache-first',
  });

  const { dailyMeditation, dailyPack } = useMemo(() => {
    const rawDaily = dailyData?.daily;

    if (!rawDaily) {
      return { dailyMeditation: undefined, dailyPack: undefined };
    }

    const isSubscribed = dailyData?.me?.is_subscribed || false;
    const isDailyUnlocked =
      dailyData?.me?.unlocked_content_status === 'unlocked';

    const daily = normalizeCourse(rawDaily);
    const dailyPermission = getDailyPermission(isSubscribed, isDailyUnlocked);
    if (daily) {
      daily.permission = dailyPermission;
    }

    const pack = normalizeDailyPack(rawDaily);
    if (pack) {
      pack.permission = getDailyPackPermission(isSubscribed, isDailyUnlocked);
      pack.courses[0].permission = dailyPermission;
    }

    return { dailyMeditation: daily, dailyPack: pack };
  }, [dailyData]);

  return {
    dailyMeditation,
    dailyPack,
    loading: !dailyData && isLoadingGetDaily,
  };
};

export default useDailyMeditation;
