import { Howl } from 'howler';
import { createSelfTimerAsset } from 'models/SelfTimerAsset';

export const SELF_TIMER_CHIMES = [
  {
    name: 'Gong',
    url: require('./gong.mp3'),
  },
  {
    name: 'Bell',
    url: require('./bell.mp3'),
  },
  {
    name: 'Meditation Bell',
    url: require('./meditation_bell.mp3'),
  },
].map(createSelfTimerAsset);

export const initialChime = new Howl({
  src: [SELF_TIMER_CHIMES[2].url],
});

export const intervalChime = new Howl({
  src: [SELF_TIMER_CHIMES[1].url],
});

export const finalChime = new Howl({
  src: [SELF_TIMER_CHIMES[2].url],
});
