import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { PROCESS_OFFER } from '../mutations';
import { ProcessOffer, ProcessOfferVariables } from '../generated/ProcessOffer';
import { GET_PACKS, GET_DAILY } from '../queries';
import useAuth from './useAuth';

const useProcessOffer = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();

  const [processOfferMutation] = useMutation<
    ProcessOffer,
    ProcessOfferVariables
  >(PROCESS_OFFER, {
    refetchQueries: token
      ? [
          { query: GET_PACKS },
          {
            query: GET_DAILY,
            variables: { date: format(new Date(), 'yyyy-MM-dd') },
          },
        ]
      : undefined,
  });

  const processOffer = useCallback(
    async (code: string) => {
      try {
        const response = await processOfferMutation({ variables: { code } });
        const approved = response.data?.processOffer?.approved || false;
        const message = response.data?.processOffer?.message;

        if (approved) {
          enqueueSnackbar('Your code has been processed', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(message || 'Unable to process offer code', {
            variant: 'error',
          });
        }
      } catch (e) {
        const error =
          (e.graphQLErrors?.length > 0 && e.graphQLErrors[0].message) ||
          e.message ||
          'Unable to process offer code';

        enqueueSnackbar(error, { variant: 'error' });
      }
    },
    [processOfferMutation, enqueueSnackbar],
  );

  return { processOffer };
};

export default useProcessOffer;

export type UseProcessOffer = ReturnType<typeof useProcessOffer>;
