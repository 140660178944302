import React, { FC } from 'react';
import useQueryParams from 'lib/useQueryParams';
import SubscriptionPlans from 'components/SubscriptionPlans';
import useConnect from './connect';
import { Props } from './types';
import { Features, Title } from './styles';

const FastCheckout: FC<Props> = ({ match }) => {
  const promoCode = match.params?.code;
  const { email, plan } = useQueryParams({ ignoreDuplicates: true });
  const { handleToken, isLoading, isShort, plans } = useConnect(promoCode);

  return (
    <>
      {!isShort && <Title>Become a Subscriber</Title>}
      <Features>
        <li>Access to all Meditations</li>
        <li>Access to all Lessons</li>
        <li>Access to all Conversations</li>
        <li>Other exclusive content</li>
      </Features>
      <SubscriptionPlans
        isPublic
        email={email}
        onToken={handleToken}
        isLoading={isLoading}
        plans={plans}
        planId={plan || undefined}
        promoCode={promoCode}
        hideFeaturesList
      />
    </>
  );
};

export default FastCheckout;
