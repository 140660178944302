import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';
import { Content as ModalContent } from 'components/Modal/styles';
import DefaultCloseIcon from 'components/Icons/Close';
import DefaultIconButton from 'components/IconButton';

export const Modal = styled(DefaultModal)`
  border: none !important;
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.blue900};
  }
  ${ModalContent} {
    background-color: ${({ theme }) => theme.colors.blue900};
    color: ${({ theme }) => theme.colors.snow};
    padding: ${({ theme }) => theme.pad.xxl};
  }

  ${media.tabletPortrait} {
    .MuiPaper-root {
      border-radius: ${({ theme }) =>
        `${theme.radius.lg} ${theme.radius.lg} 0 0`};
      height: 100vh;
      left: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 10px;
    }
    ${ModalContent} {
      padding: 1.4rem 0.3rem 1rem 0.3rem;
      font-size: 0.9rem;
      width: auto;
    }
  }
`;

export const CloseIcon = styled(DefaultCloseIcon)`
  height: 2rem;
  width: 1rem;
`;

export const CloseButton = styled(DefaultIconButton)`
  margin: 0 0 1rem 0;
  padding: 0;
`;

export const Title = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.3rem;
  text-transform: uppercase;
`;

export const Subtitle = styled.div`
  font-size: inherit;
  margin-bottom: 1.8rem;
`;

export const Text = styled.div`
  font-size: inherit;
  line-height: 1.3;
`;
