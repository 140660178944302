import { apolloClient } from 'services/api';
import { UpdateUserProfileVariables } from 'graphql/generated/UpdateUserProfile';
import { UPDATE_USER_PROFILE } from 'graphql/mutations';

const updateProfile = async (
  userData: Omit<
    UpdateUserProfileVariables & { avatarFile?: File },
    'hasAvatar'
  >,
) => {
  const { avatar, avatarFile, ...restUserData } = userData;
  const avatarVars = avatarFile
    ? { hasAvatar: true, avatar: avatarFile }
    : { hasAvatar: false };

  return apolloClient.mutate({
    mutation: UPDATE_USER_PROFILE,
    variables: { ...avatarVars, ...restUserData },
  });
};

export default updateProfile;
