import { matchPath, RouteProps } from 'react-router';

export interface BackLevelRule {
  levels: number;
  route: string | RouteProps;
}

export const createGetBackLevels = (rules: BackLevelRule[]) => (
  path: string,
): number =>
  (rules.find(({ route }) => matchPath(path, route)) || { levels: 1 }).levels;
