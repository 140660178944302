import { linkStyle } from 'components/Links/styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: ${({ theme }) => theme.margin.lg};
`;

export const Submit = styled.button`
  ${linkStyle};
  color: ${({ theme }) => theme.colors.blue500};
  background: none;
  border: none;
  font-size: ${({ theme }) => theme.font.text};
  padding: 0;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.darkGrey};
      cursor: initial;
      opacity: 0.7;

      :hover {
        :before {
          display: none;
        }
      }
    `};
`;

export const Reset = styled.button`
  ${linkStyle};
  background: none;
  border: none;
  color: ${({ theme }) => theme.darkGrey};
  font-size: ${({ theme }) => theme.font.text};
  padding: 0;

  :before {
    background-color: ${({ theme }) => theme.darkGrey};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.darkGrey};
      cursor: initial;
      opacity: 0.7;

      :hover {
        :before {
          display: none;
        }
      }
    `};
`;

export const Close = styled.button`
  ${linkStyle};
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.font.text};
  padding: 0;

  :before {
    background-color: ${({ theme }) => theme.colors.error};
  }
`;
