import React, { FC, memo } from 'react';
import CloseButton from '../CloseButton';
import { PlayerControls, Title } from './styles';
import { Props } from './types';
import useConnect from './connect';

const SelfTimerPlayer: FC<Props> = ({ isMaximized }) => {
  const {
    audioStatus,
    closePlayer,
    duration,
    position,
    setAudioStatus,
  } = useConnect();

  return (
    <>
      <Title $isMaximized={isMaximized}>Timer</Title>
      <PlayerControls
        $isMaximized={isMaximized}
        audioStatus={audioStatus}
        basic={!isMaximized}
        seekDisabled
        duration={duration}
        onChangeAudioStatus={setAudioStatus}
        position={position}
      />
      <CloseButton onClick={closePlayer} />
    </>
  );
};

export default memo(SelfTimerPlayer);
