import React, { FC } from 'react';
import logo from 'assets/images/SquaredLogo.png';
import animations from './animations';
import {
  CloseButton,
  Container,
  Content,
  Description,
  Header,
  ImgLogo,
  LogoBox,
  TextBox,
  Title,
} from './styles';
import { Props } from './types';

const Modal: FC<Props> = ({
  animation = 'slideUp',
  className,
  description,
  disableBackdropClick = false,
  disableEnforceFocus = false,
  disableEscapeKeyDown = false,
  disableRestoreFocus = false,
  hideClose,
  hideLogo,
  children,
  onClose,
  open,
  reverseClose,
  title,
}) => {
  return (
    <Container
      TransitionComponent={animations[animation]}
      open={open}
      onClose={onClose}
      closeAfterTransition
      disableBackdropClick={disableBackdropClick}
      disableEnforceFocus={disableEnforceFocus}
      disableEscapeKeyDown={disableEscapeKeyDown}
      disableRestoreFocus={disableRestoreFocus}
      className={className}
    >
      <Content>
        {!hideLogo && (title || description) && (
          <Header>
            {!hideLogo && (
              <LogoBox>
                <ImgLogo src={logo} />
              </LogoBox>
            )}
            {(title || description) && (
              <TextBox>
                {title && <Title>{title}</Title>}
                {description && <Description>{description}</Description>}
              </TextBox>
            )}
          </Header>
        )}
        {children}
      </Content>
      {!hideClose && (
        <CloseButton $reverseClose={reverseClose} onClick={onClose} />
      )}
    </Container>
  );
};

export default Modal;
