import React, { FC, memo } from 'react';
import AndroidIcon from 'components/Icons/Android';
import AppleIcon from 'components/Icons/Apple';
import { AppConfig } from 'config';
import { Link, Text } from './styles';
import { Props } from './types';

const DownloadButton: FC<Props> = ({ type, ...rest }) => {
  const href = type === 'ios' ? AppConfig.appStoreURL : AppConfig.playStoreURL;
  const text = `Download for ${type === 'ios' ? 'iPhone' : 'Android'}`;

  return (
    <Link href={href} component="a" target="__blank" {...rest}>
      {type === 'ios' ? (
        <AppleIcon fontSize="inherit" />
      ) : (
        <AndroidIcon fontSize="inherit" />
      )}
      <Text>{text}</Text>
    </Link>
  );
};

export default memo(DownloadButton);
