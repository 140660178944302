import { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { trackEvent } from 'services/analytics';
import { media } from 'styles/mixins';
import useMediaQuery from 'lib/useMediaQuery';
import { AudioStatus, LogicProps } from './types';

const useLogic = ({ onVideoEnd }: LogicProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery(media.phoneLandscape);
  const isTablet = useMediaQuery(media.tabletLandscape);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [audioStatus, setAudioStatus] = useState<AudioStatus>(
    AudioStatus.PAUSED,
  );
  const [duration, setDuration] = useState<number>(0);
  const [position, setPosition] = useState<number>(0);

  const videoRef = useRef<HTMLVideoElement>(null);
  const isVideoStarted = useRef<boolean>(false);

  const videoUrl = useMemo(() => {
    if (isMobile) {
      return 'https://d2uk1wgjryl0y1.cloudfront.net/free-account-video-480.mp4';
    }
    if (!isMobile && isTablet) {
      return 'https://d2uk1wgjryl0y1.cloudfront.net/free-account-video-720.mp4';
    }
    return 'https://d2uk1wgjryl0y1.cloudfront.net/free-account-video-1080.mp4';
  }, [isMobile, isTablet]);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleCanPlay = () => {
        setDuration(video.duration);
        setIsLoading(false);
      };

      const handleTimeUpdate = () => {
        setPosition(video.currentTime);
      };

      video.addEventListener('canplay', handleCanPlay);
      video.addEventListener('timeupdate', handleTimeUpdate);
      video.load();
      return () => {
        video.removeEventListener('canplay', handleCanPlay);
        video.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, []);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      const handleEnded = () => {
        setAudioStatus(AudioStatus.FINISHED);
        trackEvent('Free Account Video End');
        if (onVideoEnd) {
          onVideoEnd();
        }
      };

      video.addEventListener('ended', handleEnded);
      return () => {
        video.removeEventListener('ended', handleEnded);
      };
    }
  }, [onVideoEnd]);

  const handleTogglePlay = useCallback(async () => {
    const video = videoRef.current;
    if (video && !video.ended) {
      if (video.paused) {
        try {
          await video.play();
          setAudioStatus(AudioStatus.PLAYING);

          if (!isVideoStarted.current) {
            isVideoStarted.current = true;
            trackEvent('Free Account Video Start');
          }
        } catch (error) {
          video.pause();
          setAudioStatus(AudioStatus.PAUSED);
          enqueueSnackbar('Unable to play video', { variant: 'error' });
        }
      } else {
        video.pause();
        setAudioStatus(AudioStatus.PAUSED);
      }
    }
  }, [enqueueSnackbar]);

  return {
    audioStatus,
    handleTogglePlay,
    hasStarted: position > 0,
    isLoading,
    progress: duration > 0 ? (position / duration) * 100 : 0,
    videoRef,
    videoUrl,
  };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
