import React, { FC } from 'react';
import { ButtonContent, ChevronButton, SelectedText } from './styles';
import { Props } from './types';

const SectionLink: FC<Props> = ({
  active,
  children,
  disabled = false,
  path,
  selected,
  ...rest
}) => (
  <ChevronButton active={active} disabled={disabled} {...rest}>
    <ButtonContent>
      {children}
      {selected && <SelectedText>{selected}</SelectedText>}
    </ButtonContent>
  </ChevronButton>
);

export default SectionLink;
