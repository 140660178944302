import React, { FC } from 'react';
import { Elements } from 'react-stripe-elements';
import Modal from 'components/Modal';
import CheckoutForm from './CheckoutForm';
import { DescriptionContainer, Description, Email } from './styles';
import { Props } from './types';
import useConnect from './connect';

const StripePayment: FC<Props> = ({ email }) => {
  const { isOpen, closeModal } = useConnect();

  const description = (
    <DescriptionContainer>
      <Email>{email}</Email>
      <Description>Subscribe for US$14.99/month</Description>
    </DescriptionContainer>
  );

  return (
    <Modal
      title="Monthly Subscription"
      description={description}
      open={isOpen}
      onClose={closeModal}
    >
      <Elements>
        <CheckoutForm />
      </Elements>
    </Modal>
  );
};

export default StripePayment;
