/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { COURSE_DATA_FRAGMENT } from './course';

export const PACK_DATA_FRAGMENT = gql`
  fragment PackData on Pack {
    auto_play
    category
    courses {
      ...CourseData
    }
    display_title
    hide_pack_from_master_pack
    id
    image_url
    pack_hash
    pack_number
    parent_pack_id
    pack_type
    player_sub_title
    subtitle
    thumbnail_url {
      thumb_x1
      thumb_x2
    }
    title
  }
  ${COURSE_DATA_FRAGMENT}
`;
