import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultCourseThumbnail from 'components/CourseThumbnail';

export const Container = styled.div`
  cursor: pointer;
  height: 100%;
  width: 100%;
`;

export const CourseThumbnail = styled(DefaultCourseThumbnail)`
  height: 11.3125rem;

  ${media.phoneLandscape} {
    height: 5.625rem;
  }
`;

export const Info = styled.div<{ $isFinished: boolean }>`
  color: ${({ $isFinished, theme }) =>
    $isFinished ? theme.textLightBg.textFaint : theme.textLabel};
  margin-top: ${({ theme }) => theme.margin.sm};

  ${media.tabletPortrait} {
    margin-top: ${({ theme }) => theme.margin.xsm};
  }
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.text};
  line-height: 1.25rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  white-space: pre-wrap;

  ${media.phoneLandscape} {
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
`;

export const Description = styled.div`
  font-size: ${({ theme }) => theme.font.textSuperSmall};
  line-height: 1rem;
  margin-top: ${({ theme }) => theme.margin.xxxsm};
`;
