/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_MODAL = gql`
  query GetModal {
    modal @client {
      type
      courseActionsPayload {
        courseId
      }
      courseNotesPayload {
        courseId
      }
      dialogPayload {
        description
        title
      }
      resetCoursePayload {
        courseId
      }
      shareLinkPayload {
        title
        description
        link
        courseId
      }
      unlockContentPayload {
        courseId
      }
      updatePaymentCardPayload {
        tokenId
      }
    }
  }
`;
