import styled from 'styled-components';

export const Logo = styled.img`
  width: 128px;
  height: 128px;
  margin-right: 1rem;
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.textLightBg.text};

  h3 {
    font-weight: bold;
  }
`;

export const Container = styled.div`
  display: flex;
  box-shadow: 0 0 0.25rem grey;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: ${({ theme }) => theme.margin.md} 0;
`;

export const Item = styled.div`
  opacity: 0.5;
`;
