import React, { FC, memo } from 'react';
import Element from './Element';
import { ElementList, Container, Title } from './styles';
import { Props } from './types';
import useConnect from './connect';

const SelectInterval: FC<Props> = ({
  duration,
  intervalTime,
  setIntervalTime,
  setIsTimeSectionOpen,
}) => {
  const {
    intervalTimesOptions,
    selectIntervalTime,
    validIntervalTime,
  } = useConnect({
    duration,
    intervalTime,
    setIntervalTime,
    setIsTimeSectionOpen,
  });

  return (
    <Container>
      <Title data-cypress-id="self-timer-interval-time-section-title">
        Interval Time
      </Title>
      <ElementList data-cypress-id="self-timer-interval-time-section-element-list">
        {intervalTimesOptions.map(({ type, text }) => (
          <Element
            key={type}
            text={text}
            type={type}
            onClick={selectIntervalTime}
            isSelected={validIntervalTime === type}
          />
        ))}
      </ElementList>
    </Container>
  );
};

export default memo(SelectInterval);
