import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultPlayerControls from '../Controls';

export const Title = styled.div<{ $isMaximized: boolean }>`
  flex: 1 1 auto;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ $isMaximized }) => ($isMaximized ? '3rem' : '1rem')};
  left: ${({ $isMaximized }) => ($isMaximized ? 'auto' : '3.4rem')};
  line-height: ${({ $isMaximized }) => ($isMaximized ? '1.5' : '1')};
  margin-top: ${({ $isMaximized }) => ($isMaximized ? '1rem' : '0')};
  position: ${({ $isMaximized }) => ($isMaximized ? 'relative' : 'absolute')};
  top: ${({ $isMaximized }) => ($isMaximized ? 'auto' : 'calc(50% - 0.5rem)')};

  ${media.tabletPortrait} {
    font-size: ${({ $isMaximized }) => ($isMaximized ? '3rem' : '0.9rem')};
    left: auto;
    top: ${({ $isMaximized }) => ($isMaximized ? 'auto' : '0.6rem')};
  }
`;

export const PlayerControls = styled(DefaultPlayerControls)<{
  $isMaximized: boolean;
}>`
  margin-bottom: ${({ $isMaximized }) => ($isMaximized ? '2rem' : '0')};
  width: ${({ $isMaximized }) => ($isMaximized ? '60%' : '45%')};

  ${media.tabletPortrait} {
    margin-bottom: ${({ $isMaximized }) => ($isMaximized ? '6rem' : '0')};
    width: ${({ $isMaximized }) => ($isMaximized ? '75%' : 'auto')};
  }

  ${media.phoneLandscape} {
    margin-bottom: ${({ $isMaximized }) => ($isMaximized ? '2rem' : '0')};
    width: ${({ $isMaximized }) => ($isMaximized ? '90%' : 'auto')};
  }

  ${media.phonePortrait} {
    margin-bottom: ${({ $isMaximized }) => ($isMaximized ? '6rem' : '0')};
  }
`;
