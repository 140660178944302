/* eslint-disable import/prefer-default-export */
export const getInitials = (
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
): string => {
  if (firstName || lastName) {
    const firstLetter = firstName ? firstName[0] : '';
    const secondLetter = lastName ? lastName[0] : '';
    return firstLetter + secondLetter;
  }
  return email ? email[0] : '';
};
