import React, { FC, memo } from 'react';
import ClockIcon from 'components/Icons/Clock';
import { Container, Title } from './styles';
import { Props } from './types';

const Timer: FC<Props> = ({ className, onClick }) => {
  return (
    <Container className={className} onClick={onClick}>
      <ClockIcon size={22} />
      <Title>Timer</Title>
    </Container>
  );
};

export default memo(Timer);
