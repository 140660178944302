import { User } from 'models/User';

export enum SubscriptionStatus {
  success = 'success',
  error = 'error',
}

export type RouteParams = {
  status: SubscriptionStatus;
};

export type RouteState = {
  user: User;
};
