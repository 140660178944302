import { apolloClient } from 'services/api';
import { Me } from '../generated/Me';
import { ME } from '../queries';

function cacheUnlockAllContent() {
  const response = apolloClient.readQuery<Me>({ query: ME });
  const cacheMe = response?.me;

  if (cacheMe && cacheMe.activity) {
    const currentIntroCourseCompletedCount =
      cacheMe.activity?.intro_course_completed_count || 0;

    apolloClient.writeQuery<Me>({
      query: ME,
      data: {
        me: {
          ...cacheMe,
          unlocked_content_status: 'unlocked',
          activity: {
            ...cacheMe.activity,
            intro_course_completed_count: currentIntroCourseCompletedCount + 1,
          },
        },
      },
    });
  }
}

export default cacheUnlockAllContent;
