import React, { FC } from 'react';
import { format, parseISO } from 'date-fns/esm';
import LogoImage from 'assets/images/SquaredLogo.png';
import { Container, Content, Item, Logo } from './styles';
import { Props } from './types';

const legacySubscriptionMessage =
  'You have a lifetime subscription due to your support of Sam Harris prior to September 18, 2018.';

const subscriptionMessages = {
  ADMIN: 'Subscription granted by Sam Harris',
  apple: 'Subscribed via Apple',
  apple_xact: 'Subscribed via Apple',
  EFL: legacySubscriptionMessage,
  EMAIL: legacySubscriptionMessage,
  gpl: 'Subscribed via Google',
  lightrail: 'Subscribed via gift or promotion',
  stripe: 'Subscribed via website',
};

const SubscriptionInfo: FC<Props> = ({
  plan,
  joinedAt,
  renewIntent,
  endTime,
  isSubscribed,
  source,
}) => {
  const willRenew = renewIntent === 'WILL_RENEW';

  const joinedAtText =
    isSubscribed && joinedAt
      ? `Joined on ${format(parseISO(joinedAt), 'MMMM do yyyy')}`
      : '';
  const willRenewText =
    isSubscribed && endTime
      ? `${willRenew ? 'Will renew ' : 'Ends '} ${format(
          parseISO(endTime),
          'MMMM do yyyy',
        )}`
      : '';
  // @ts-ignore
  const subscriptionMessage = subscriptionMessages[source!];

  return (
    <Container>
      <Logo src={LogoImage} />
      <Content>
        <h3>Waking Up with Sam Harris</h3>
        {subscriptionMessage}
        <Item>{joinedAtText}</Item>
        <Item>{willRenewText}</Item>
        {plan && (
          <Item>
            {plan.interval} plan (${(plan.amount / 100).toFixed(2)}/
            {plan.interval})
          </Item>
        )}
      </Content>
    </Container>
  );
};

export default SubscriptionInfo;
