import styled from 'styled-components';

export const Container = styled.button.attrs(() => ({
  type: 'button',
}))`
  align-items: center;
  background: transparent none;
  border: none;
  box-shadow: none;
  color: ${({ theme }) => theme.colors.snow};
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  margin: 0;
  outline: none;
  padding: 0;
  user-select: none;
`;

export const Value = styled.div`
  font-size: 1.25rem;
  line-height: 1;
`;

export const Title = styled.div`
  font-size: 0.75rem;
  line-height: 1;
  margin-top: 0.6rem;
`;
