import styled from 'styled-components';
import Text from 'components/Text';

export const Container = styled(Text)`
  color: ${({ theme }) => theme.textLightBg.text};
  text-align: center;
  margin-bottom: 0;
`;

export const Button = styled.button.attrs(() => ({
  type: 'button',
}))`
  background-color: transparent;
  color: ${({ theme }) => theme.textLightBg.text};
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.textLightBg.text};
    text-decoration: underline;
  }
`;
