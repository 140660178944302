import { useEffect, useState, useMemo } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import { trackEvent } from 'services/analytics';
import { initializePurchaseDialog } from 'services/lightrail';
import useLogin from 'graphql/hooks/useLogin';
import useShopperToken from 'graphql/hooks/useShopperToken';

const useConnect = () => {
  const { loginEmail, getPin, login, resend, loading } = useLogin();
  const { shopperToken } = useShopperToken();
  const matchGuestRoute = !!useRouteMatch('/guest');
  const { state } = useLocation<{ title?: string }>();
  const title = state?.title || 'Sign In';

  const [isLightrailReady, setLightrailReady] = useState(false);
  const [isLightrailLoading, setLightrailLoading] = useState(false);

  const description = useMemo(
    () =>
      !loginEmail
        ? 'Enter your email & we will send you a code to sign in to the app'
        : `We have sent a four digit verification code to your email.\nPlease enter it below.`,
    [loginEmail],
  );

  useEffect(() => {
    if (!isLightrailReady && shopperToken) {
      const initLightrail = async () => {
        try {
          setLightrailLoading(true);
          await initializePurchaseDialog(shopperToken);
          setLightrailReady(true);
        } catch (error) {
          setLightrailReady(false);
        } finally {
          setLightrailLoading(false);
        }
      };

      initLightrail();
    }
  }, [isLightrailReady, shopperToken]);

  useEffect(() => {
    trackEvent('Login Begin');
  }, []);

  return {
    description,
    getPin,
    isLightrailLoading,
    isLightrailReady,
    loading,
    login,
    loginEmail,
    matchGuestRoute,
    resend,
    title,
  };
};

export default useConnect;
