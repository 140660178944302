import React, { FC } from 'react';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import parseISO from 'date-fns/parseISO';
import { useLocation } from 'react-router';
import { toggleHelpScout } from 'services/helpscout';
import {
  Confirmation,
  Content,
  Button,
  Container,
  Email,
  StoreButton,
} from './styles';
import { RouteState } from './types';

const RedeemSuccess: FC = () => {
  const { state } = useLocation<RouteState>();
  const email = state?.email;
  const expiresAt = differenceInCalendarDays(
    parseISO(state?.expiresAt),
    new Date(),
  );
  const confirmationMessage = `Your code has been redeemed successfully!\n It expires in ${expiresAt} days`;

  return (
    <Container>
      <Confirmation message={confirmationMessage} />
      <Content>
        <Email>Account: {email}</Email>
        <StoreButton type="ios" />
        <StoreButton type="android" />
        <Button to="/" replace>
          Sign In
        </Button>
        <Button onClick={toggleHelpScout}>Contact Support</Button>
      </Content>
    </Container>
  );
};

export default RedeemSuccess;
