import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Content, Logo } from './styles';
import SubscriptionSuccess from './SubscriptionSuccess';
import FastCheckout from './FastCheckout';
import Redeem from './Redeem';
import Payments from './Payments';
import RedeemSuccess from './RedeemSuccess';

const Subscriptions: FC = () => (
  <Container>
    <Content>
      <Logo />
      <Switch>
        <Route
          component={FastCheckout}
          exact
          path={['/checkout/:code', '/checkout']}
        />
      </Switch>
      <Route
        component={SubscriptionSuccess}
        exact
        path="/subscription/:status"
      />
      <Route
        component={Redeem}
        exact
        path={['/gifts/redeem', '/promotions/redeem']}
      />
      <Route component={Payments} exact path="/payments" />
      <Route component={RedeemSuccess} exact path="/redeem/:status" />
    </Content>
  </Container>
);

export default Subscriptions;
