import { QueryOptions } from '@apollo/client';
import { apolloClient } from 'services/api';
import { Course, normalizeCourse, CoursePermission } from 'models/Course';
import { GetFeatures } from 'graphql/generated/GetFeatures';
import { GET_FEATURES } from 'graphql/queries';

const getFeatured = async (
  fetchPolicy: QueryOptions<GetFeatures>['fetchPolicy'] = 'cache-first',
) => {
  const { data: featuresData } = await apolloClient.query<GetFeatures>({
    query: GET_FEATURES,
    errorPolicy: 'all',
    fetchPolicy,
  });

  if (!featuresData?.me || !featuresData?.features) {
    return undefined;
  }

  const isSubscribed = featuresData.me.is_subscribed || false;

  const featuredCourses =
    featuresData.features.data?.reduce<Course[]>((t, feat) => {
      if (feat?.course) {
        const course = normalizeCourse(feat.course);
        course.deep_link = feat.deep_link || '';

        if (!isSubscribed && !course.is_free) {
          course.permission = CoursePermission.FORBIDDEN;
        }

        t.push(course);
      }
      return t;
    }, []) || [];

  return featuredCourses;
};

export default getFeatured;
