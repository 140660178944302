import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { ModalType } from 'graphql/generated/globalTypes';
import useModal from 'graphql/hooks/useModal';
import useStripeSource from 'graphql/hooks/useStripeSource';

const useConnect = () => {
  const { close, updatePaymentCardPayload, type } = useModal();
  const { update } = useStripeSource();
  const { push } = useHistory();

  const tokenId = updatePaymentCardPayload?.tokenId;

  const accept = useCallback(() => {
    close();

    if (tokenId) {
      update(tokenId).then(() => {
        push('/account/subscription');
      });
    }
  }, [close, push, tokenId, update]);

  const closeModal = useCallback(() => {
    close();
  }, [close]);

  return {
    accept,
    closeModal,
    isOpen: type === ModalType.UPDATE_PAYMENT_CARD,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
