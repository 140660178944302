import React, { FC } from 'react';
import { SubscriptionPeriod } from 'containers/NewSubscription/types';
import {
  BackButton,
  Card,
  ChevronLeftIcon,
  Container,
  Logo,
  NewSubscription,
} from './styles';
import useConnect from './connect';

const NewSubscriptionPage: FC = () => {
  const { code, handleSubscribed, period } = useConnect();

  const hasSubscriptionPlansMenu =
    period === SubscriptionPeriod.MENU_MONTH ||
    period === SubscriptionPeriod.MENU_YEAR;

  return (
    <Container>
      <Logo />
      <Card>
        <BackButton
          $hasSubscriptionPlansMenu={hasSubscriptionPlansMenu}
          // TODO: Re-enable this when monthly plans are activated.
          // onClick={handleBack}
        >
          <ChevronLeftIcon />
          Back
        </BackButton>
        <NewSubscription
          code={code}
          isPublic
          onSubscribed={handleSubscribed}
          period={period}
        />
      </Card>
    </Container>
  );
};

export default NewSubscriptionPage;
