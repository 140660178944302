import React, { FC } from 'react';
import {
  BackButton,
  ChevronLeft,
  ChevronRight,
  MenuItem,
  Modal,
  Text,
  Title,
} from './styles';
import useConnect from './connect';

const ResetContentModal: FC = () => {
  const {
    closeModal,
    isMobile,
    isOpen,
    openResetAllContentConfirm,
    openResetIntroCoursesConfirm,
  } = useConnect();

  return (
    <Modal
      disableRestoreFocus
      hideClose={isMobile}
      onClose={closeModal}
      open={isOpen}
    >
      {isMobile && (
        <BackButton onClick={closeModal}>
          <ChevronLeft />
        </BackButton>
      )}
      <Title>RESET CONTENT</Title>
      <MenuItem onClick={openResetIntroCoursesConfirm}>
        <Text>Reset Introductory Course</Text>
        <ChevronRight />
      </MenuItem>
      <MenuItem onClick={openResetAllContentConfirm}>
        <Text>Reset All Content as unplayed</Text>
        <ChevronRight />
      </MenuItem>
    </Modal>
  );
};

export default ResetContentModal;
