import useModal from 'graphql/hooks/useModal';

const useConnect = () => {
  const { openSelfTimer } = useModal();

  return { openSelfTimer };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
