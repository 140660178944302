import styled from 'styled-components';
import Checklist from 'components/Checklist';

export const Title = styled.h1`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: 1.85rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.25;
  text-align: center;
`;

export const Features = styled(Checklist)`
  display: flex;
  flex-direction: column;
  width: auto;
  padding-left: 0;
  margin: 1rem auto 1.5rem;
`;
