import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { stringify } from 'query-string';
import FreeAccount from './FreeAccount';
import Auth from './Auth';
import Subscriptions from './Subscriptions';
import NewSubscription from './NewSubscription';

const Guest: FC = () => {
  const { pathname, search } = useLocation();

  return (
    <Switch>
      <Route component={FreeAccount} exact path="/free-account" />
      <Route
        component={Auth}
        exact
        path={['/', '/guest', '/request-free-account']}
      />
      <Route
        component={NewSubscription}
        exact
        path={['/checkout/:code', '/checkout']}
      />
      <Route
        component={Subscriptions}
        exact
        path={[
          '/subscription/:status',
          '/gifts/redeem',
          '/promotions/redeem',
          '/payments',
          '/redeem/:status',
        ]}
      />
      <Redirect exact from="/account/subscription" to="/checkout" />
      <Redirect exact from="/subscription" to="/checkout" />
      <Redirect exact from="/openview/subscriptions" to="/checkout" />
      <Redirect exact from="/corporate" to="/checkout" />
      <Redirect exact from="/corporate/:code" to="/checkout/:code" />
      <Redirect from="/visitor" to="/" />
      <Redirect from="/login" to="/" />
      <Redirect
        to={{
          pathname: '/',
          search: stringify({
            return_to: pathname === '/' ? undefined : `${pathname}${search}`,
          }),
        }}
      />
    </Switch>
  );
};

export default Guest;
