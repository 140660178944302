import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import noop from 'lodash/noop';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import {
  ValidateSharedToken,
  ValidateSharedTokenVariables,
} from '../generated/ValidateSharedToken';
import { CREATE_SHARED_TOKEN, VALIDATE_SHARED_TOKEN } from '../mutations';
import { CreateSharedToken } from '../generated/CreateSharedToken';
import { GET_PACKS, GET_DAILY } from '../queries';

const useFreeMonth = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [createToken, { data }] = useMutation<CreateSharedToken>(
    CREATE_SHARED_TOKEN,
  );

  const [validateToken] = useMutation<
    ValidateSharedToken,
    ValidateSharedTokenVariables
  >(VALIDATE_SHARED_TOKEN, {
    refetchQueries: [
      { query: GET_PACKS },
      {
        query: GET_DAILY,
        variables: { date: format(new Date(), 'yyyy-MM-dd') },
      },
    ],
  });

  const validate = useCallback(
    async (token: string) => {
      try {
        const response = await validateToken({ variables: { token } });
        if (response?.data?.validateSharedToken) {
          enqueueSnackbar('Your subscription has been updated', {
            variant: 'success',
          });
        } else {
          enqueueSnackbar('Unable to validate the token', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar(error.message || 'Unable to validate the token', {
          variant: 'error',
        });
      }
    },
    [validateToken, enqueueSnackbar],
  );

  const create = useCallback(() => {
    createToken().catch(noop);
  }, [createToken]);

  return {
    data: data?.createSharedToken || undefined,
    create,
    validate,
  };
};

export default useFreeMonth;
