import { FC, memo, useState, useEffect } from 'react';
import { useRouteMatch, useLocation } from 'react-router';
import { parse } from 'query-string';
import {
  initializePurchaseDialog,
  openPurchaseDialog,
  unmountPurchaseDialog,
} from 'services/lightrail';
import useShopperToken from 'graphql/hooks/useShopperToken';

const Lightrail: FC = () => {
  const { shopperToken } = useShopperToken();
  const { search } = useLocation();
  const isAccountRoute = !!useRouteMatch('/account');
  const { 'open-gift': openGift } = parse(search);

  const [isLightrailInitialized, setIsLightrailInitialized] = useState(false);

  useEffect(() => {
    if (!isLightrailInitialized && shopperToken) {
      const initLightrail = async () => {
        try {
          await initializePurchaseDialog(shopperToken);
          setIsLightrailInitialized(true);
        } catch (error) {
          setIsLightrailInitialized(false);
        }
      };

      initLightrail();
    }
  }, [isLightrailInitialized, shopperToken]);

  useEffect(() => {
    return () => {
      unmountPurchaseDialog();
    };
  }, []);

  useEffect(() => {
    if (isLightrailInitialized && isAccountRoute && openGift) {
      openPurchaseDialog();
    }
  }, [isAccountRoute, isLightrailInitialized, openGift]);

  return null;
};

export default memo(Lightrail);
