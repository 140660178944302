import React, { FC, memo } from 'react';
import { Container, NotesIcon, Title } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Notes: FC<Props> = ({ className, courseId, hasNotes }) => {
  const { openNotes } = useConnect({ courseId });

  if (!hasNotes) {
    return null;
  }

  return (
    <Container className={className}>
      <NotesIcon onClick={openNotes} />
      <Title>More Info</Title>
    </Container>
  );
};

export default memo(Notes);
