import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { isAfter, parseISO } from 'date-fns';
import { parse } from 'query-string';
import useMe from 'graphql/hooks/useMe';
import useSettingsActions from 'graphql/hooks/useSettingsActions';
import usePlayer from 'graphql/hooks/usePlayer';
import useModal from 'graphql/hooks/useModal';
import { PlayerDisplayStatus } from 'graphql/generated/globalTypes';
import { getSettings } from 'graphql/requests';
import { trackMediaEvent } from 'services/analytics';

const useConnect = () => {
  const { updatePlayerCode } = useSettingsActions();
  const { isAnonymous, introPackId } = useMe();
  const { displayStatus } = usePlayer();
  const { openNewIntroPack } = useModal();
  const location = useLocation();

  const { return_to: returnTo } = parse(location.search);

  useEffect(() => {
    if (
      introPackId === '1' &&
      isAfter(new Date(), parseISO('2020-08-11T00:00'))
    ) {
      setTimeout(() => openNewIntroPack(), 1000);
    }
  }, [introPackId, openNewIntroPack]);

  useEffect(() => {
    const checkPlayerInterrupted = async () => {
      const { player } = await getSettings();
      if (player) {
        const { code, courseId } = player;
        await trackMediaEvent('Media Interrupted', courseId, { code });
        updatePlayerCode(null);
      }
    };
    checkPlayerInterrupted();
  }, [updatePlayerCode]);

  return {
    isAnonymous,
    isMinimizedPlayerOpen: displayStatus === PlayerDisplayStatus.MINIMIZED,
    location,
    returnTo,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
