import { useLocation } from 'react-router-dom';
import {
  parse,
  ParseOptions as QueryParseOptions,
  ParsedQuery,
} from 'query-string';

export type ParseOptions = QueryParseOptions & { ignoreDuplicates?: boolean };

export interface NoDuplicatesParsedQuery<T = string> {
  [key: string]: T | null | undefined;
}

function useQueryParams(
  options: { ignoreDuplicates: true } & ParseOptions,
): NoDuplicatesParsedQuery;

function useQueryParams(
  options: { ignoreDuplicates: false } & ParseOptions,
): ParsedQuery;

function useQueryParams(options?: ParseOptions): ParsedQuery;

function useQueryParams(
  options?: ParseOptions,
): NoDuplicatesParsedQuery | ParsedQuery {
  const location = useLocation();
  const params = parse(location.search, options);

  if (options?.ignoreDuplicates) {
    return Object.keys(params).reduce(
      (acc: NoDuplicatesParsedQuery, key): NoDuplicatesParsedQuery => {
        const value = params[key];
        acc[key] = Array.isArray(value) ? value[0] : value;

        return acc;
      },
      {},
    );
  }
  return params;
}

export default useQueryParams;
