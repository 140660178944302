import React, { FC } from 'react';
import { Container, Subtitle, Title } from './styles';
import { Props } from './types';

const Thanks: FC<Props> = ({ darkBg = false }) => (
  <Container darkBg={darkBg}>
    <Title>You qualify for our introductory pricing!</Title>
    <Subtitle>Thanks for all of your support.</Subtitle>
  </Container>
);

export default Thanks;
