import React, { FC } from 'react';
import { media } from 'styles/mixins';
import useMediaQuery from 'lib/useMediaQuery';
import BackLink from 'components/Links/BackLink';
import { Control } from 'components/HorizontalCarousel';
import { Expander, HeaderLink, SectionHeader as Base, Title } from './styles';
import { Props } from './types';

// / This component has a title and two optional actions.
// /
// / Title is mandatory, and displayed on the left side. The optional 'back'
// / action converts this title in a link with a chevron. Useful to convey a
// / navigation intent.
// /
// / The optional 'action' action, displays on the right side with a blue tint.

const SectionHeader: FC<Props> = ({
  title,
  darkBg,
  backHref,
  backOnClick,
  actionTitle,
  actionHref,
  actionOnClick,
  onControlPress,
  testId,
  ...rest
}) => {
  const isMobile = useMediaQuery(media.phoneLandscape);

  return (
    <Base {...rest}>
      {backHref || backOnClick ? (
        <BackLink
          data-cypress-id={`${testId}-back`}
          data-jest-id={`${testId}-back`}
          darkBg={darkBg}
          title={title}
          href={backHref}
          onClick={backOnClick}
        />
      ) : (
        <Title
          data-cypress-id={`${testId}-title`}
          data-jest-id={`${testId}-title`}
          darkBg={darkBg}
        >
          {title}
        </Title>
      )}
      <Expander />

      {actionTitle && (
        <HeaderLink
          data-cypress-id={`${testId}-action`}
          data-jest-id={`${testId}-action`}
          title={actionTitle}
          href={actionHref}
          onClick={actionOnClick}
        />
      )}

      {actionTitle && onControlPress && !isMobile && (
        <>
          <Control onPress={onControlPress} type="back" />
          <Control onPress={onControlPress} type="forward" />
        </>
      )}
    </Base>
  );
};

export default SectionHeader;
