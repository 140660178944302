import { apolloClient } from 'services/api';
import { GetPlayer, GetPlayer_player } from 'graphql/generated/GetPlayer';
import { GET_PLAYER } from 'graphql/queries';
import {
  PlayerAudioStatus,
  PlayerDisplayStatus,
} from 'graphql/generated/globalTypes';

function cacheResetPlayer() {
  const newData: GetPlayer_player = {
    __typename: 'Player',
    audioStatus: PlayerAudioStatus.PLAYING,
    autoplayType: null,
    courseId: null,
    dailyMeditationDuration: null,
    displayStatus: PlayerDisplayStatus.MAXIMIZED,
    endPosition: null,
    endPositionPaused: null,
    initialPosition: null,
    selftimerDuration: null,
    selftimerInterval: null,
    selftimerStartTime: null,
    type: null,
  };

  apolloClient.writeQuery<GetPlayer>({
    query: GET_PLAYER,
    data: { player: newData },
  });

  return newData;
}

export default cacheResetPlayer;
