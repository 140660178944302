import neverReRender from 'lib/neverReRender';
import React, { FC } from 'react';
import Page from '../Page';

const AboutTheCourse: FC = neverReRender(() => (
  <Page title="FAQ">
    <section>
      <strong>What are the benefits of a subscription?</strong>
      <p>
        With a subscription, you will have full access to all guided
        meditations, lessons, and other exclusive content.
      </p>
    </section>
    <section>
      <strong>What do I have access to if I’m not a paying subscriber?</strong>
      <p>
        You can use the app for free if you are not a paid subscriber, you’ll
        still have access to the podcasts, and some lessons and meditation. Any
        content not accessible will display with a lock symbol.&nbsp;
      </p>
    </section>
    <section>
      <strong>How do I become a subscriber?</strong>
      <p>
        You can become a subscriber by accessing the account menu (top right)
        and accessing the subscriptions section, or tapping on any of the locked
        content.
      </p>
      <p>
        If you are not logged in, you will be asked to set up an account.
        Provide your email address and an access code will be sent to you. Once
        your account is setup, go to the subscriptions section and select your
        subscription type. You’ll then need to login to your Apple account and
        go through the in-app purchase steps.
      </p>
      <p>
        If you are logged in, go to the account section, select subscriptions
        and then your subscription type. You’ll need to have a valid Apple
        account and progress through the in-app purchase steps.
      </p>
    </section>
    <section>
      <strong>How much does a subscription cost?</strong>
      <p>
        You will be charged monthly or yearly, depending on the subscription
        plan you have chosen.
      </p>
    </section>
    <section>
      <strong>Does my subscription expire?</strong>
      <p>No, your subscription does not expire.</p>
    </section>
    <section>
      <strong>Does my subscription automatically renew?</strong>
      <p>Yes it does.</p>
    </section>
    <section>
      <strong>Can I update my subscription from monthly to yearly?</strong>
      <p>
        Yes, you can change your subscription in the Account section by tapping
        on Subscriptions. You will redirected to iTunes &amp; App store, where
        you’ll be able to select the yearly option.
      </p>
    </section>
    <section>
      <strong>How do I download a podcast?</strong>
      <div>
        On the podcast detail screen, top right there is a download link. Tap it
        and the podcast will download in app. We recommend downloading over WiFi
        connection.&nbsp; You may delete a download at any time.
      </div>
    </section>
    <section>
      <strong>How do I download a lesson or meditation?</strong>
      <p>
        A download icon will appear next to each of the meditations and lessons.
        Tap the download icon to download in app.&nbsp; You may delete a
        download at any time. We recommend downloading over WiFi connection.
      </p>
    </section>
    <section>
      <strong>How do I cancel my subscription?</strong>
      <p>
        You can cancel your subscription at any time by going to Account &gt;
        Subscription &gt; you’ll be redirected to iTunes &amp; App store&nbsp;
        &gt; In your Waking Up Subscription &gt; tap Cancel Subscription.&nbsp;
      </p>
      <p>
        You can also access your subscription&nbsp; via&nbsp; iTunes &amp; App
        Store &gt; Apple ID &gt; View Apple ID &gt; Subscriptions. Tap your
        Waking Up subscription then Cancel Subscription.
      </p>
    </section>
    <section>
      <strong>
        I need help troubleshooting, how do I contact customer service?
      </strong>
      <p>
        You can contact our customer service team by sending an email to{' '}
        <a
          href="mailto:support@wakingup.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          support@wakingup.com
        </a>
        .
      </p>
    </section>
  </Page>
));

export default AboutTheCourse;
