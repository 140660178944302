export enum ZIndex {
  MAIN = 0,
  OVERLAY = 1,
  HEADER = 2,
  BACKGROUND = 3,
  SIDEBAR = 4,
  PLAYER_OVERLAY = 5,
  PLAYER = 6,
  PLAYER_FULL_SCREEN_OVERLAY = 5,
  PLAYER_DEFAULT = 6,
  PLAYER_FULL_SCREEN = 6,
  PLAYER_CONTROLS = 7,
  COURSE_ACTIONS_OVERLAY = 8,
  COURSE_ACTIONS = 9,
  COURSE_NOTES_OVERLAY = 10,
  COURSE_NOTES = 11,
  FULLSCREEN_LOADER = 20,
  COOKIES_CONSENT = 21,
}

// DESKTOP CONTENT WIDTH
export const MAX_CONTENT_WIDTH = '1240px';
export const MIN_CONTENT_WIDTH = '970px';

// SIDEBAR
export const SIDEBAR_WIDTH = '16.25rem';
export const SIDEBAR_PORTRAIT_WIDTH = '75vw';

// HEADER
export const HEADER_HEIGHT = '4rem';

// Screen sizes
export const screenSizes = {
  tabletLandscape: 1279.98,
  tabletPortrait: 991.98,
  phoneLandscape: 767.98,
  phonePortrait: 575.98,
};
