import { apolloClient } from 'services/api';
import { GetPlayer, GetPlayer_player } from 'graphql/generated/GetPlayer';
import { GET_PLAYER } from 'graphql/queries';
import cacheReadQuery from './cacheReadQuery';

function cacheUpdatePlayer(data: Partial<GetPlayer_player>) {
  const queryResult = cacheReadQuery<GetPlayer>(GET_PLAYER);
  const player = queryResult?.player;

  if (!player) {
    return null;
  }

  const newData = { ...player, ...data };

  apolloClient.writeQuery<GetPlayer>({
    query: GET_PLAYER,
    data: { player: newData },
  });

  return newData;
}

export default cacheUpdatePlayer;
