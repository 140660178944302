import { useRef, useCallback, useMemo } from 'react';
import Slider from 'react-slick';
import { kebabCase } from 'lodash';
import { LogicProps } from './types';

const useLogic = ({
  items,
  packParam,
  push,
  selectedPackId,
  subpackParam,
  togglePackAutoPlay,
}: LogicProps) => {
  const sliderRef = useRef<Slider>(null);

  const itemsIds = useMemo(
    () => (items ? items.map((v) => v.id).join('-') : '0'),
    [items],
  );

  const { hasAutoPlay, selectedSlideIndex } = useMemo(() => {
    const index =
      selectedPackId && items
        ? items.findIndex((item) => item.id === selectedPackId)
        : -1;
    return {
      hasAutoPlay: items && index >= 0 ? items[index].autoPlay : false,
      selectedSlideIndex: index >= 0 ? index : 0,
    };
  }, [items, selectedPackId]);

  const onSlideChange = useCallback(
    (index: number) => {
      if (items) {
        const item = items[index];
        if (subpackParam) {
          push(`${item.urlBase}/${packParam}/${kebabCase(item.title)}`);
        } else if (packParam) {
          push(`${item.urlBase}/${kebabCase(item.title)}`);
        }
      }
    },
    [items, packParam, push, subpackParam],
  );

  const onAutoPlayChange = useCallback(() => {
    if (selectedPackId) {
      togglePackAutoPlay(selectedPackId);
    }
  }, [togglePackAutoPlay, selectedPackId]);

  return {
    hasAutoPlay,
    itemsIds,
    onAutoPlayChange,
    onSlideChange,
    selectedSlideIndex,
    sliderRef,
  };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
