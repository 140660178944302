import React, { FC, memo } from 'react';
import { PlayerDisplayStatus } from 'graphql/generated/globalTypes';
import CloseButton from '../../CloseButton';
import { Container, DurationSwitcher, PlayerControls } from './styles';
import { Props } from './types';
import useConnect from './connect';

const TrackPlayer: FC<Props> = ({
  activeSessionIdRef,
  className,
  courseId,
  isLoadingTrack,
  markTrackAsListened,
  trackHasSpeedControl,
  trackIsDaily,
  trackIsMeditation,
  trackStatus,
  trackUrl10,
  trackUrl20,
}) => {
  const {
    audioStatus,
    closePlayer,
    dailyMeditationDuration,
    displayStatus,
    duration,
    isSeeking,
    onChangeAudioStatus,
    onChangeDuration,
    onChangePosition,
    position,
  } = useConnect({
    activeSessionIdRef,
    courseId,
    isLoadingTrack,
    markTrackAsListened,
    trackHasSpeedControl,
    trackIsDaily,
    trackIsMeditation,
    trackStatus,
    trackUrl10,
    trackUrl20,
  });

  return (
    <Container className={className}>
      <PlayerControls
        audioStatus={audioStatus}
        basic={displayStatus === PlayerDisplayStatus.MINIMIZED}
        duration={duration}
        isLoading={isLoadingTrack}
        isSeeking={isSeeking}
        onChangeAudioStatus={onChangeAudioStatus}
        onChangePosition={onChangePosition}
        position={position}
      />
      {trackIsDaily && displayStatus === PlayerDisplayStatus.MAXIMIZED && (
        <DurationSwitcher
          $position={position}
          duration={dailyMeditationDuration}
          onChangeDuration={onChangeDuration}
        />
      )}
      <CloseButton onClick={closePlayer} />
    </Container>
  );
};

export default memo(TrackPlayer);
