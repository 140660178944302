import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const SendEmail: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    height={(size * 23) / 22}
    viewBox="0 0 22 23"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fill="currentColor"
      d="M21.985.638a.5.5 0 00-.719-.559L.783 10.818a1.462 1.462 0 00-.1 2.528l4.56 2.882c.094.06.217.05.3-.025L17.7 5.335a.25.25 0 01.362.343L8.346 17.864a.252.252 0 00.062.37L14.84 22.3a1.453 1.453 0 002.19-.895L21.985.638zm-16.1 17.544a.25.25 0 00-.384.211v3.584a1 1 0 001.707.708l2.017-2.017a.251.251 0 00-.043-.388l-3.297-2.098z"
    />
  </svg>
);

export default memo(SendEmail);
