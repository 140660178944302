/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/mixins';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3, ease: 'easeInOut' },
}))`
  background: #a2a9b1;
  border-bottom-left-radius: 0.5rem;
  clip-path: polygon(0% 0%, 100% 0%, 95% 50%, 100% 100%, 0% 100%);
  color: ${({ theme }) => theme.colors.snow};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  padding: 0.15rem 1.8rem 0.15rem 1.2rem;
  pointer-events: none;
  user-select: none;
  cursor: default;

  ${media.tabletLandscape} {
    font-size: 0.9rem;
    padding: 0.1rem 1.6rem 0.1rem 1rem;
  }
`;
