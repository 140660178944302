import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Forward: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    height={(size * 17.776377) / 19.564528}
    viewBox="0 0 19.564528 17.776377"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g fill="none" fillRule="evenodd" stroke="currentColor">
      <path
        strokeWidth="1.778"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M18.66628392 1.77630252v5.33333333h-5.3333334"
      />
      <path
        d="M16.43480112 11.55234263c-1.1872 3.359744-4.453794 5.52941-8.0116294 5.32131-3.5578354-.208099-6.54910871-2.74379-7.33637993-6.2190221-.78727122-3.4752317.81927283-7.0521158 3.94020093-8.77264588 3.1209281-1.72053006 7.0037754-1.16988158 9.5230504 1.35051738l4.116241 3.8754812"
        strokeWidth="1.778"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g fill="currentColor">
      <path d="M7.48761722 11.44296963h-.8391111v-3.1573338h-1.1164444v-.5831111c.6613333-.014222 1.1164444-.384 1.216-.8675555h.7395555zm.7466667-1.2728893l.8035555-.2062223c.035556.4906667.3982223.8604445.9315556.8604445.4835555 0 .8746667-.3271111.8746667-.8391111 0-.5688889-.4195556-.8533334-.8888889-.8533334-.3271111 0-.6044445.1351112-.768.32l-.8035556-.2915555.5688889-2.3253333h2.524444v.7466666h-1.948444l-.2844444 1.1662222c.1991111-.2062222.5546666-.32.8817777-.32.9173337 0 1.5786667.5688889 1.5786667 1.536 0 .8675556-.668444 1.5928886-1.7351111 1.5928886-.9955556 0-1.6782222-.661333-1.7351111-1.3866663z" />
    </g>
  </svg>
);

export default memo(Forward);
