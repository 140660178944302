import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Course, CoursePermission, normalizeCourse } from 'models/Course';
import { GET_FEATURES } from 'graphql/queries';
import { GetFeatures } from 'graphql/generated/GetFeatures';

const useFeatured = () => {
  const { data: featuresData, loading: isLoadingGetFeatures } = useQuery<
    GetFeatures
  >(GET_FEATURES, { fetchPolicy: 'cache-first' });

  const featured = useMemo(() => {
    if (!featuresData) {
      return undefined;
    }

    const isSubscribed = featuresData.me?.is_subscribed || false;

    const featuredCourses =
      featuresData?.features?.data?.reduce<Course[]>((t, feat) => {
        if (feat?.course) {
          const course = normalizeCourse(feat.course);
          course.deep_link = (feat.deep_link || '').replace('dynamic', 'app');

          if (!isSubscribed && !course.is_free) {
            course.permission = CoursePermission.FORBIDDEN;
          }

          t.push(course);
        }
        return t;
      }, []) || [];

    return featuredCourses;
  }, [featuresData]);

  return {
    featured: featured || [],
    loading: !featuresData && isLoadingGetFeatures,
  };
};

export default useFeatured;

export type UseFeatured = ReturnType<typeof useFeatured>;
