import SubscriptionPlans from 'components/SubscriptionPlans';
import React, { FC, useCallback } from 'react';
import { Props } from './types';
import { Button } from './styles';

const SubscriptionModify: FC<Props> = ({
  cancel,
  reactivate,
  plans,
  url,
  isLoading,
  onToken,
  email,
  source,
  renewIntent,
}) => {
  const isApple = source === 'apple' || source === 'apple_xact';
  const isStripe = source === 'stripe';
  const isGoogle = source === 'gpl';
  const isPromoOrGift = !isStripe && !isApple && !isGoogle;

  const onCancel = useCallback(() => {
    cancel();
  }, [cancel]);

  const onReactivate = useCallback(() => {
    reactivate();
  }, [reactivate]);

  if (isStripe) {
    return (
      <>
        <Button isLoading={isLoading} to={`${url}/change_payment_card`}>
          Change Payment Card
        </Button>
        {renewIntent === 'WILL_RENEW' ? (
          <Button type="button" isLoading={isLoading} onClick={onCancel}>
            Cancel Subscription
          </Button>
        ) : (
          <Button type="button" isLoading={isLoading} onClick={onReactivate}>
            Reactivate Subscription
          </Button>
        )}
      </>
    );
  }

  if (isPromoOrGift) {
    return (
      <SubscriptionPlans
        hideFeaturesList
        isLoading={isLoading}
        email={email}
        onToken={onToken}
        plans={plans}
      />
    );
  }

  return null;
};

export default SubscriptionModify;
