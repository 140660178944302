import { useRef, useEffect, useCallback, DependencyList } from 'react';

const useCallbackRef = (
  callback: (...args: any[]) => any,
  dependencies: DependencyList,
) => {
  const callbackFunction = useCallback<typeof callback>(callback, dependencies);
  const ref = useRef<typeof callbackFunction>(callbackFunction);

  useEffect(() => {
    ref.current = callbackFunction;
  }, [callbackFunction]);

  return ref;
};

export default useCallbackRef;

export type UseStateRef = ReturnType<typeof useCallbackRef>;
