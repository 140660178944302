import styled from 'styled-components';
import DefaultTextField from 'components/Inputs/TextField';
import DefaultButton from 'components/Button';

export const TextField = styled(DefaultTextField)`
  margin-bottom: ${({ theme }) => theme.margin.md};
`;

export const Button = styled(DefaultButton)`
  .MuiButton-label {
    text-transform: none !important;
  }
`;
