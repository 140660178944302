import { format } from 'date-fns';
import { apolloClient } from 'services/api';
import { GET_INITIAL_DATA } from 'graphql/queries';
import {
  GetInitialData,
  GetInitialDataVariables,
} from 'graphql/generated/GetInitialData';

const getInitialData = async () => {
  await apolloClient.query<GetInitialData, GetInitialDataVariables>({
    query: GET_INITIAL_DATA,
    variables: { date: format(new Date(), 'yyyy-MM-dd') },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });
};

export default getInitialData;
