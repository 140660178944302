import React, { FC } from 'react';
import { openPurchaseDialog } from 'services/lightrail';
import EmailForm from './Email';
import CodeForm from './Code';
import {
  Title,
  Description,
  Subtitle,
  Button,
  Actions,
  Container,
} from './styles';
import useConnect from './connect';

const Login: FC = () => {
  const {
    description,
    getPin,
    isLightrailLoading,
    isLightrailReady,
    loading,
    login,
    loginEmail,
    matchGuestRoute,
    resend,
    title,
  } = useConnect();

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {!loginEmail ? (
        <EmailForm getPin={getPin} loading={loading.getPin} />
      ) : (
        <CodeForm
          login={login}
          resend={resend}
          loading={loading.getLoginEmail || loading.login}
        />
      )}
      {matchGuestRoute && (
        <>
          <Subtitle>Looking for something else?</Subtitle>
          <Actions>
            <Button to="/checkout">Purchase a subscription</Button>
            <Button
              disabled={!isLightrailReady}
              isLoading={isLightrailLoading}
              onClick={openPurchaseDialog}
            >
              Purchase Gift Card
            </Button>
            <Button to="/gifts/redeem">Redeem Gift Card</Button>
            <Button to="/promotions/redeem">Redeem Promo Code</Button>
            <Button to="/visitor">Try the Web App!</Button>
          </Actions>
        </>
      )}
    </Container>
  );
};

export default Login;
