declare global {
  interface Window {
    gtag: any;
    dataLayer: any[];
  }
}

let didWarn = false;

function gtag() {
  if (typeof window !== 'undefined') {
    if (typeof window.gtag === 'function') {
      // eslint-disable-next-line prefer-rest-params
      window.gtag.apply(null, arguments as any);
    } else if (Array.isArray(window.dataLayer)) {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    } else if (
      typeof process !== 'undefined' &&
      process.env.NODE_ENV !== 'production' &&
      !didWarn
    ) {
      didWarn = true;
      // eslint-disable-next-line no-console
      console.warn(
        '[lib/gtag] Google Tag Manager was not found on your site. Neither "gtag()" or "dataLayer[]" could be located on the "window". If you are not using Google Tag Manager in dev you can ignore this warning. Otherwise, see the Google Tag Manager dev guide for examples: https://developers.google.com/tag-manager/devguide',
      );
    }
  }
}

export default gtag;
