import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import deskBackground from 'assets/images/DeskBackground.jpg';
import cloudBackground from 'assets/images/CloudBackground.jpg';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import DefaultLoaderBox from 'components/LoaderBox';
import DefaultIconButton from 'components/IconButton';
import DefaultChevronRightIcon from 'components/Icons/ChevronRight';
import DefaultCheckmarkIcon from 'components/Icons/Checkmark';
import DefaultButton from 'components/Button';
import DefaultPrice from './Price';
import DefauleSubscriptionPlan from './SubscriptionPlan';
import { SubscriptionMode } from './types';

export const LoaderBox = styled(DefaultLoaderBox)`
  border-radius: inherit;
  height: 100%;
  width: 100%;
`;

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.2 },
}))<{
  $hasCode: boolean;
  $hasPeriod: boolean;
  $mode?: SubscriptionMode;
}>`
  align-items: center;
  background-color: transparent;
  background-image: ${({ $mode }) =>
    $mode === SubscriptionMode.CLOUD
      ? `url('${cloudBackground}')`
      : `url('${deskBackground}')`};
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  ${({ $hasCode, $hasPeriod, $mode }) =>
    $hasCode &&
    $hasPeriod &&
    $mode !== SubscriptionMode.CLOUD &&
    css`
      background-color: #8494a4;
      background-position-y: calc(100% + 55px);
      background-size: auto;
    `};
`;

export const Content = styled.div<{
  $hasCode: boolean;
  $hasPeriod: boolean;
}>`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  margin-top: ${({ $hasPeriod }) => (!$hasPeriod ? '3.6875rem' : '2rem')};
`;

export const Title = styled.div<{ $hasCode: boolean; $hasPeriod: boolean }>`
  border-bottom: ${({ $hasPeriod, theme }) =>
    $hasPeriod ? `1px solid ${theme.colors.subscription.grey100}` : 'none'};
  color: ${({ theme }) => theme.colors.subscription.white};
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 2.375rem;
  margin-bottom: ${({ $hasCode, $hasPeriod }) => {
    if (!$hasPeriod) return '3.4375rem';
    return $hasCode ? '1.2475rem' : '1.81rem';
  }};
  padding: 0 1.375rem 0.75rem 1.375rem;
  text-align: center;
  user-select: none;
  width: 19.375rem;
`;

export const LoaderCircular = styled(MuiCircularProgress).attrs(() => ({
  size: 35,
}))`
  margin-top: 2.8rem;
`;

export const StepContent = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 0.25 },
}))`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
`;

export const SubscriptionPlan = styled(DefauleSubscriptionPlan)<{
  $color: string;
}>`
  &&& {
    background-color: ${({ $color, theme }) =>
      theme.colors.subscription[$color]};
    border: 1px solid
      ${({ $color, theme }) => theme.colors.subscription[$color]};
    &:disabled,
    .Mui-disabled {
      background-color: ${({ $color, theme }) =>
        theme.colors.subscription[$color]};
    }

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }
  }
`;

export const FeatureList = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.subscription.grey100};
  margin-top: 2.8125rem;
  padding-top: 0.8rem;
  text-align: center;
  width: 19.375rem;
`;

export const FeatureListButton = styled(DefaultIconButton)`
  align-items: center;
  color: ${({ theme }) => theme.colors.subscription.darkblue};
  display: inline-flex;
  font-size: 0.85rem;
  line-height: 1.125rem;
  padding: 0;
`;

export const ChevronRightIcon = styled(DefaultChevronRightIcon).attrs(() => ({
  size: 6.5,
}))`
  color: ${({ theme }) => theme.colors.subscription.darkblue};
  display: block;
  margin-left: 0.6rem;
`;

export const List = styled.div`
  color: ${({ theme }) => theme.colors.subscription.white};
  line-height: 1.125rem;
  padding: 0 1.8rem 0 2.25rem;
  width: 19.375rem;
`;

export const ListItem = styled.div`
  align-items: flex-start;
  display: flex;
  flex-flow: row nowrap;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  justify-content: flex-start;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const CheckmarkIcon = styled(DefaultCheckmarkIcon).attrs(() => ({
  size: 13.5,
}))`
  display: block;
  margin-top: 0.3rem;
`;

export const ListText = styled.div`
  flex: 1 1 0;
  font-size: 0.875rem;
  margin-left: 1rem;
`;

export const StrikeThrough = styled.span`
  font-size: inherit;
  padding-right: 0.6rem;
  position: relative;
  &:before {
    border-top: 2px solid ${({ theme }) => theme.colors.subscription.red};
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 40%;
    transform: rotate(8deg);
  }
`;

export const OldPrice = styled.div`
  color: ${({ theme }) => theme.colors.subscription.white};
  font-size: 0.9rem;
  line-height: 1.42rem;
  margin-top: 1.25rem;
`;

export const OldPriceNumber = styled(DefaultPrice)`
  font-size: 1.3625rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

export const Price = styled.div<{ $hasCode: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.colors.subscription.grey100};
  color: ${({ $hasCode, theme }) =>
    $hasCode
      ? theme.colors.subscription.white
      : theme.colors.subscription.darkblue};
  font-size: ${({ $hasCode }) => ($hasCode ? '1.17rem' : '0.975rem')};
  line-height: ${({ $hasCode }) => ($hasCode ? '1.5rem' : '1.25rem')};
  margin-bottom: ${({ $hasCode }) => ($hasCode ? '1.3125rem' : '0.78125rem')};
  margin-top: ${({ $hasCode }) => ($hasCode ? '0.8125rem' : '1.8125rem')};
  padding-top: ${({ $hasCode }) => ($hasCode ? '0.6rem' : '1rem')};
  text-align: center;
  width: 19.375rem;
`;

export const PriceNumber = styled(DefaultPrice)<{ $hasCode: boolean }>`
  font-size: ${({ $hasCode }) => ($hasCode ? '1.45rem' : '1.2rem')};
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;

export const Button = styled(DefaultButton)<{ $hasCode: boolean }>`
  &&& {
    background-color: ${({ $hasCode, theme }) =>
      $hasCode
        ? theme.colors.subscription.blue
        : theme.colors.subscription.white};
    box-shadow: none;
    color: ${({ $hasCode, theme }) =>
      $hasCode
        ? theme.colors.subscription.white
        : theme.colors.subscription.black};
    min-height: auto;
    padding: 0.6rem;
    width: ${({ $hasCode }) => ($hasCode ? '16.6875rem' : '18.75rem')};
    .MuiButton-label {
      font-size: 0.875rem;
      line-height: 1.125rem;
      text-transform: none;
    }
    &:disabled,
    .Mui-disabled {
      background-color: ${({ $hasCode, theme }) =>
        $hasCode
          ? theme.colors.subscription.blue
          : theme.colors.subscription.grey100};
    }
  }
`;

export const MonthlyPrice = styled.div`
  color: ${({ theme }) => theme.colors.subscription.white};
  font-size: 0.5625rem;
  line-height: 0.6875rem;
  margin-top: 0.4375rem;
`;
