import { useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import {
  SelfTimerInterval,
  SELF_TIMER_INTERVALS,
} from 'models/SelfTimerInterval';
import { GetPlayer } from 'graphql/generated/GetPlayer';
import {
  PlayerAudioStatus,
  PlayerDisplayStatus,
} from 'graphql/generated/globalTypes';
import { GET_PLAYER } from 'graphql/queries';
import { cacheResetPlayer, cacheUpdatePlayer } from 'graphql/cache';

const usePlayerSelfTimer = () => {
  const { data: playerData } = useQuery<GetPlayer>(GET_PLAYER, {
    fetchPolicy: 'cache-only',
  });

  const {
    audioStatus,
    displayStatus,
    duration,
    interval,
    intervalMinutes,
    middle,
    selftimerStartTime,
  } = useMemo(() => {
    const currentInterval =
      (playerData?.player.selftimerInterval as SelfTimerInterval) ||
      SelfTimerInterval.NONE;
    const currentDuration = playerData?.player.selftimerDuration || 0;

    return {
      audioStatus: playerData?.player.audioStatus || PlayerAudioStatus.PLAYING,
      displayStatus:
        playerData?.player.displayStatus || PlayerDisplayStatus.MAXIMIZED,
      duration: currentDuration,
      interval: currentInterval,
      intervalMinutes: SELF_TIMER_INTERVALS[currentInterval].minutes || 0,
      middle: Math.ceil(currentDuration / 2),
      selftimerStartTime: playerData?.player.selftimerStartTime || 0,
    };
  }, [playerData]);

  const setAudioStatus = useCallback((newAudioStatus: PlayerAudioStatus) => {
    cacheUpdatePlayer({ audioStatus: newAudioStatus });
  }, []);

  const close = useCallback(() => {
    cacheResetPlayer();
  }, []);

  return {
    audioStatus,
    close,
    displayStatus,
    duration,
    interval,
    intervalMinutes,
    middle,
    setAudioStatus,
    selftimerStartTime,
  };
};

export default usePlayerSelfTimer;

export type UsePlayerSelfTimer = ReturnType<typeof usePlayerSelfTimer>;
