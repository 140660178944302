import { useCallback } from 'react';
import { ModalType } from 'graphql/generated/globalTypes';
import useModal from 'graphql/hooks/useModal';
import useCoursesActions from 'graphql/hooks/useCoursesActions';

const useConnect = () => {
  const { close, type } = useModal();
  const { resetAllContent } = useCoursesActions();

  const accept = useCallback(() => {
    close();
    resetAllContent();
  }, [close, resetAllContent]);

  const closeModal = useCallback(() => {
    close();
  }, [close]);

  return {
    accept,
    closeModal,
    isOpen: type === ModalType.RESET_ALL_CONTENT,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
