import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';
import { Content as ModalContent } from 'components/Modal/styles';
import DefaultLogo from 'components/Logo';
import DefaultIconButton from 'components/IconButton';
import DefaultChevronLeftIcon from 'components/Icons/ChevronLeft';
import DefaultNewSubscription from 'containers/NewSubscription';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    background: transparent;
    height: 772px;
    width: 436px;
  }
  ${ModalContent} {
    align-items: center;
    background: #aaaaaa;
    justify-content: center;
    height: 100%;
    max-width: 100%;
    padding: 0;
    position: relative;
    width: 100%;
  }

  ${media.phonePortrait} {
    .MuiPaper-root {
      border-radius: 0;
      height: 100%;
      width: 100%;
    }
  }
`;

export const BackButton = styled(DefaultIconButton)`
  align-items: center;
  color: ${({ theme }) => theme.colors.subscription.grey100};
  display: flex;
  font-size: 0.8125rem;
  left: 0.9375rem;
  line-height: 1rem;
  position: absolute;
  top: 1.5rem;
`;

export const ChevronLeftIcon = styled(DefaultChevronLeftIcon).attrs(() => ({
  size: 10,
}))`
  display: block;
  margin-right: 0.2rem;
`;

export const Logo = styled(DefaultLogo).attrs(() => ({ lightBg: true }))`
  align-self: center;
  display: block;
  left: 50%;
  position: absolute;
  top: 1.6875rem;
  transform: translateX(-50%);
  user-select: none;
  width: 93px;
  & * {
    fill: ${({ theme }) => theme.colors.subscription.white} !important;
  }
`;

export const NewSubscription = styled(DefaultNewSubscription)`
  height: 100%;
  padding-top: 3rem;
  width: 100%;
`;
