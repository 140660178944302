import { useCallback } from 'react';
import { ModalType } from 'graphql/generated/globalTypes';
import useModal from 'graphql/hooks/useModal';
import useCoursesActions from 'graphql/hooks/useCoursesActions';

const useConnect = () => {
  const { close, resetCoursePayload, type } = useModal();
  const { resetCourse } = useCoursesActions();

  const courseId = resetCoursePayload?.courseId;

  const accept = useCallback(() => {
    close();
    if (courseId) {
      resetCourse(courseId);
    }
  }, [close, courseId, resetCourse]);

  const closeModal = useCallback(() => {
    close();
  }, [close]);

  return {
    accept,
    closeModal,
    isOpen: type === ModalType.RESET_COURSE,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
