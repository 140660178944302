import { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import flatMap from 'lodash/flatMap';
import { trackMediaEvent } from 'services/analytics';
import {
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  REMOVE_ALL_FAVORITES,
} from 'graphql/mutations';
import {
  AddFavorite,
  AddFavoriteVariables,
} from 'graphql/generated/AddFavorite';
import {
  RemoveFavorite,
  RemoveFavoriteVariables,
} from 'graphql/generated/RemoveFavorite';
import { RemoveAllFavorites } from 'graphql/generated/RemoveAllFavorites';
import { getCourse } from 'graphql/requests';
import usePacks from './usePacks';

const useFavorites = () => {
  const { packs, loading: isLoadingPacks } = usePacks();
  const { enqueueSnackbar } = useSnackbar();

  const [addFavorite] = useMutation<AddFavorite, AddFavoriteVariables>(
    ADD_FAVORITE,
  );

  const [removeFavorite] = useMutation<RemoveFavorite, RemoveFavoriteVariables>(
    REMOVE_FAVORITE,
  );

  const [removeAllFavorites] = useMutation<RemoveAllFavorites>(
    REMOVE_ALL_FAVORITES,
  );

  const favorites = useMemo(
    () =>
      flatMap(packs, (pack) => pack?.courses)
        .filter((v) => v?.is_favorite)
        .sort((a, b) => a.course_number - b.course_number),
    [packs],
  );

  const toggle = useCallback(
    async (id) => {
      try {
        const course = await getCourse(id);
        const isFavorite = course?.is_favorite || false;
        if (!course) return;

        if (isFavorite) {
          await removeFavorite({ variables: { course_id: Number(id) } });
          trackMediaEvent('Media UnFavorited', id);
        } else {
          await addFavorite({ variables: { course_id: Number(id) } });
          trackMediaEvent('Media Favorited', id);
        }
      } catch (error) {
        enqueueSnackbar('Unable to toggle favorite', { variant: 'error' });
      }
    },
    [addFavorite, removeFavorite, enqueueSnackbar],
  );

  const removeAll = useCallback(async () => {
    try {
      await removeAllFavorites();
    } catch (error) {
      enqueueSnackbar('Unable to remove all favorites', { variant: 'info' });
    }
  }, [removeAllFavorites, enqueueSnackbar]);

  return {
    favorites,
    loading: isLoadingPacks,
    removeAll,
    toggle,
  };
};

export type UseFavoritesReturnType = ReturnType<typeof useFavorites>;

export default useFavorites;
