import React, { FC, memo } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import useConnect from './connect';

const Mobile: FC = () => {
  const { isSidebarOpen, toggleSidebar } = useConnect();

  return (
    <>
      <Header onToggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isSidebarOpen} onClose={toggleSidebar} />
    </>
  );
};

export default memo(Mobile);
