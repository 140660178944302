import {
  useMemo,
  useCallback,
  createRef,
  ClipboardEvent,
  KeyboardEvent,
} from 'react';
import { useField } from 'formik';
import { LogicProps } from './types';
import { N_PIN_INPUTS_ARRAY } from './constants';

const useLogic = ({ name }: LogicProps) => {
  const [{ value: code }, , { setValue: setCode }] = useField(name);

  const digitRefs = useMemo(
    () => N_PIN_INPUTS_ARRAY.map(() => createRef<HTMLInputElement>()),
    [],
  );

  const setFocus = useCallback(
    (index: number) => {
      const digitRef = digitRefs[index];
      if (digitRef?.current) {
        digitRef.current.focus();
      }
    },
    [digitRefs],
  );

  const handleDigitChange = useCallback(
    (userInput: string, index: number) => {
      const userInputArray = userInput ? userInput.split('') : [''];
      const newCode = [
        ...code.slice(0, index),
        ...userInputArray,
        ...code.slice(index + userInputArray.length),
      ];
      setCode(newCode);

      const nextIndex = index + userInput.length;
      setFocus(Math.min(code.length - 1, nextIndex));
    },
    [code, setCode, setFocus],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>, index: number) => {
      if (e.keyCode === 8) {
        const prevIndex = Math.max(0, index - 1);

        // If the cursor was in input that had value then stay put.
        if (!code[index]) {
          setFocus(prevIndex);
        }
      }
    },
    [code, setFocus],
  );

  const handleKeyPress = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    const keyCode = e.keyCode || e.which;
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 13) {
      e.preventDefault();
    }
  }, []);

  const handlePaste = useCallback(
    (e: ClipboardEvent, index: number) => {
      // Fill in multiple inputs on paste.
      const values = e.clipboardData.getData('Text');
      handleDigitChange(values, index);
    },
    [handleDigitChange],
  );

  return {
    code,
    digitRefs,
    handleDigitChange,
    handleKeyDown,
    handleKeyPress,
    handlePaste,
  };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
