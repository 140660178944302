import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Container, Loader } from './styles';
import { Props } from './types';

const Button: FC<Props> = ({
  onClick,
  disabled,
  isLoading,
  to,
  children,
  ...rest
}) => (
  <Container
    $isLoading={isLoading}
    component={to ? Link : undefined}
    to={to}
    onClick={onClick}
    disabled={isLoading || disabled}
    {...rest}
  >
    {isLoading ? <Loader /> : children}
  </Container>
);

export default Button;
