import styled from 'styled-components';
import DefaultLoaderBox from 'components/LoaderBox';
import { media } from 'styles/mixins';

export const COMPONENT_HEIGHT_DESKTOP_PX = 128;
export const COMPONENT_MARGIN_DESKTOP_PX = 20;
export const COMPONENT_HEIGHT_MOBILE_PX = 63.75;
export const COMPONENT_MARGIN_MOBILE_PX = 10.5;

export const Container = styled.div`
  display: flex;
  flex: 0;
  margin: ${COMPONENT_MARGIN_DESKTOP_PX}px 0px;
  overflow: hidden;

  & > div:not(:last-child) {
    margin-right: 15px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 340px;

  ${media.phoneLandscape} {
    min-width: 118px;
  }
`;

export const Thumbnail = styled(DefaultLoaderBox)`
  height: 180px;
  margin-bottom: 10px;

  ${media.phoneLandscape} {
    height: 90px;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled(DefaultLoaderBox)`
  height: ${({ theme }) => theme.font.text};
  width: 100%;
`;

export const Duration = styled(DefaultLoaderBox)`
  height: ${({ theme }) => theme.font.textSmall};
  width: 80%;
  margin-top: ${({ theme }) => theme.margin.xxxsm};
`;
