import { ComponentType } from 'react';
import { Link as DefaultRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const commonStyles = css`
  color: ${({ theme }) => theme.textLink};
`;

export const RouterLink = styled(DefaultRouterLink)`
  ${commonStyles};
`;

export const Anchor = (styled.a`
  ${commonStyles};
` as unknown) as ComponentType;
