import React, { FC } from 'react';
import Input from './Input';
import { Container } from './styles';
import { Props } from './types';
import { N_PIN_INPUTS_ARRAY } from './constants';
import useLogic from './logic';

const LoginCodeField: FC<Props> = ({ className, name }) => {
  const {
    code,
    digitRefs,
    handleDigitChange,
    handleKeyDown,
    handleKeyPress,
    handlePaste,
  } = useLogic({ name });

  return (
    <Container className={className}>
      {N_PIN_INPUTS_ARRAY.map((v, i) => (
        <Input
          key={v}
          autoComplete="off"
          autoCorrect="off"
          autoFocus={i === 0}
          data-cypress-id={`code-digit-${i}`}
          index={i}
          inputRef={digitRefs[i]}
          name={`Code digit ${i}/4`}
          onChange={handleDigitChange}
          onKeyDown={handleKeyDown}
          onKeyPress={handleKeyPress}
          onPaste={handlePaste}
          pattern="[0-9]"
          spellCheck={false}
          type="number"
          value={code[i]}
        />
      ))}
    </Container>
  );
};

export default LoginCodeField;
