/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ModalType {
  ADD_MEDITATION_TIME = "ADD_MEDITATION_TIME",
  COURSE_ACTIONS = "COURSE_ACTIONS",
  COURSE_NOTES = "COURSE_NOTES",
  DAILY_DURATION = "DAILY_DURATION",
  DIALOG = "DIALOG",
  GIVE_FREE_MONTH = "GIVE_FREE_MONTH",
  NEW_INTRO_PACK = "NEW_INTRO_PACK",
  ONBOARDING = "ONBOARDING",
  RESET_ALL_CONTENT = "RESET_ALL_CONTENT",
  RESET_CONTENT = "RESET_CONTENT",
  RESET_COURSE = "RESET_COURSE",
  RESET_INTRO_COURSES = "RESET_INTRO_COURSES",
  SELF_TIMER = "SELF_TIMER",
  SHARE_LINK = "SHARE_LINK",
  SHARE_MONTH_WARNING = "SHARE_MONTH_WARNING",
  STRIPE_PAYMENT = "STRIPE_PAYMENT",
  UNLOCK_CONTENT = "UNLOCK_CONTENT",
  UPDATE_PAYMENT_CARD = "UPDATE_PAYMENT_CARD",
}

export enum PlayerAudioStatus {
  PLAYING = "PLAYING",
  STOP = "STOP",
}

export enum PlayerAutoplayType {
  FAVORITES_PRACTICE = "FAVORITES_PRACTICE",
  FAVORITES_THEORY = "FAVORITES_THEORY",
  PACK = "PACK",
}

export enum PlayerDisplayStatus {
  MAXIMIZED = "MAXIMIZED",
  MINIMIZED = "MINIMIZED",
}

export enum PlayerType {
  COURSE = "COURSE",
  SELFTIMER = "SELFTIMER",
}

export interface SubscribeCardInput {
  id: string;
  object?: string | null;
  address_city?: string | null;
  address_country?: string | null;
  address_line1?: string | null;
  address_line1_check?: string | null;
  address_line2?: string | null;
  address_state?: string | null;
  address_zip?: string | null;
  address_zip_check?: string | null;
  brand?: string | null;
  country?: string | null;
  cvc_check?: string | null;
  dynamic_last4?: string | null;
  exp_month?: number | null;
  exp_year?: number | null;
  funding?: string | null;
  last4?: string | null;
  metadata?: SubscribeMetaDataInput | null;
  name?: string | null;
  tokenization_method?: string | null;
}

export interface SubscribeMetaDataInput {
  _?: boolean | null;
}

export interface SubscribeTokenInput {
  id: string;
  object?: string | null;
  card: SubscribeCardInput;
  client_ip?: string | null;
  created?: number | null;
  email?: string | null;
  livemode?: boolean | null;
  type?: string | null;
  used?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
