import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Check: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={(size * 12.234669) / 12.23467}
    viewBox="0 0 12.23467 12.234669"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      d="M3.728709 10.742307L.33267097 7.864924c-.450307-.493031-.442706-1.284646.01822-1.767096.460183-.483263 1.19905703-.474307 1.64936303.01953l2.446863 1.838398 5.688885-6.341051c.387283-.550785 1.118555-.662255 1.633403-.246515.514848.414927.618132 1.198399.230849 1.749997l-6.505945 7.501981c-.204275.291253-.517142.47187-.856563.495467-.02506.0016-.05012.0024-.07518.0024-.31293.000111-.613627-.134129-.833863-.375765z"
    />
  </svg>
);

export default memo(Check);
