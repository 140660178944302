import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { formatTime } from 'lib/formatTime';
import { Props } from './types';

const JUMP_SIZE = 15;

const useLogic = ({
  duration,
  onChangePosition = () => null,
  position = 0,
}: {
  duration: Props['duration'];
  onChangePosition: Props['onChangePosition'];
  position: Props['position'];
}) => {
  const [localPosition, setLocalPosition] = useState<number>(position);

  const isDragging = useRef<boolean>(false);
  const displayedPosition = Math.floor(
    isDragging.current ? localPosition : position,
  );
  const { isDurationOverOneHour, timeDuration } = useMemo(() => {
    const durationOverOneHour = !!duration && duration > 3600;
    return {
      isDurationOverOneHour: durationOverOneHour,
      timeDuration: duration
        ? formatTime(Math.round(duration), durationOverOneHour)
        : '--:--',
    };
  }, [duration]);

  const { timePending, timePlayed } = !duration
    ? { timePending: '--:--', timePlayed: '--:--' }
    : {
        timePending: formatTime(
          duration - displayedPosition,
          isDurationOverOneHour,
        ),
        timePlayed: formatTime(displayedPosition, isDurationOverOneHour),
      };

  useEffect(() => {
    if (!isDragging.current) {
      setLocalPosition(position);
    }
  }, [position]);

  const onSliderChange = useCallback((e, value: number | number[]) => {
    isDragging.current = true;
    const newValue = Array.isArray(value) ? value[0] : value;
    setLocalPosition(newValue);
  }, []);

  const onSliderChangeCommitted = useCallback(
    (e, value: number | number[]) => {
      isDragging.current = false;
      const newValue = Array.isArray(value) ? value[0] : value;
      onChangePosition(newValue);
    },
    [onChangePosition],
  );

  const onJumpBackwards = useCallback(() => {
    const jumpDestination = position - JUMP_SIZE;
    const newPosition = jumpDestination <= 0 ? 0 : jumpDestination;
    setLocalPosition(newPosition);
    onChangePosition(newPosition);
  }, [onChangePosition, position]);

  const onJumpForward = useCallback(() => {
    if (duration) {
      const jumpDestination = position + JUMP_SIZE;
      const newPosition =
        jumpDestination >= duration ? duration : jumpDestination;
      setLocalPosition(newPosition);
      onChangePosition(newPosition);
    }
  }, [duration, onChangePosition, position]);

  return {
    localPosition,
    onJumpBackwards,
    onJumpForward,
    onSliderChange,
    onSliderChangeCommitted,
    timeDuration,
    timePending,
    timePlayed,
  };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
