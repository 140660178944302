import { useCallback, useState } from 'react';
import { trackEvent } from 'services/analytics';

const useConnect = () => {
  const [isVideoPlayed, setIsVideoPlayed] = useState<boolean>(false);

  const handleVideoEnd = useCallback(() => {
    setIsVideoPlayed(true);
  }, []);

  const handleRequestFreeAccount = useCallback(() => {
    trackEvent('Free Account - Request a Free Year of Waking Up');
  }, []);

  const handleStartTrial = useCallback(() => {
    trackEvent('Free Account - Let Me Try Waking Up First');
  }, []);

  return {
    handleRequestFreeAccount,
    handleStartTrial,
    handleVideoEnd,
    isVideoPlayed,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
