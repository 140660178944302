import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const GiftBox: FC<Props> = ({ size = 24, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 64 64" {...rest}>
    <path
      fill="#0074D3"
      d="M50.005 2.183c2.478 2.832 1.497 6.704-2.213 9.844A23.146 23.146 0 0143.364 15L60 15a2 2 0 012 2v11a2 2 0 01-2 2v30a2 2 0 01-2 2H6a2 2 0 01-2-2V30a2 2 0 01-2-2V17a2 2 0 012-2l16.635.001a23.147 23.147 0 01-4.427-2.974c-3.633-3.074-4.65-6.85-2.364-9.666l.15-.178.142-.154c2.67-2.804 7.55-1.195 12.461 3.264l.426.394.347.342c1.19 1.205 2.725 3.065 4.63 5.6l.585-.774c1.817-2.38 3.27-4.094 4.389-5.164l.165-.157.264-.241c5-4.538 9.965-6.124 12.602-3.11zM24 30H8v28h16V30zm12 0h-8v28h8V30zm20 0H40v28h16V30zM24 19H6v7h18v-7zm12 0h-8v7h8v-7zm22 0H40v7h18v-7zM17.347 4.68c-.194.025-.283.07-.342.137-.765.875-.403 2.303 1.787 4.156 2.757 2.333 5.695 3.682 8.86 4.066l.413.044.018.001-.606-.78-.487-.617c-.949-1.188-1.75-2.115-2.397-2.777l-.321-.319-.133-.125c-1.986-1.844-3.865-3.064-5.364-3.563-.602-.2-1.088-.265-1.428-.222zm27.878.223c-1.499.5-3.378 1.72-5.364 3.563-.708.657-1.657 1.727-2.835 3.2l-.519.657-.591.761.433-.045c2.894-.352 5.6-1.51 8.15-3.49l.363-.289.346-.287c2.19-1.853 2.552-3.281 1.787-4.156-.059-.067-.148-.112-.342-.136-.34-.043-.826.022-1.428.222z"
    />
  </svg>
);

export default memo(GiftBox);
