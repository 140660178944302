import styled from 'styled-components';
import Select from 'react-select';
import { prettyScrollbar } from 'styles/mixins';
import DefaultWrapper from '../Wrapper';

export const Container = styled.div``;

export const Wrapper = styled(DefaultWrapper)<{ readOnly: boolean }>`
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'default')};
  user-select: ${({ readOnly }) => (readOnly ? 'none' : 'default')};
`;

export const ReactSelect = styled(Select)<{ $hasLabel: boolean }>`
  color: ${({ theme }) => theme.textLightBg.text};
  .react-select__control {
    background: transparent;
    min-height: 0;
    padding: ${({ $hasLabel, theme }) =>
      `${$hasLabel ? '0' : theme.pad.md} ${theme.pad.xsm} ${
        $hasLabel ? '0' : theme.pad.md
      } ${theme.pad.xl}`};
  }
  .react-select__control,
  .react-select__control--is-focused,
  .react-select__control--menu-is-open {
    border: none;
    box-shadow: none;
  }
  .react-select__value-container,
  .react-select__value-container--has-value {
    margin: 0;
    padding: 0;
  }
  .react-select__placeholder,
  .react-select__single-value {
    margin: 0;
    padding: 0;
  }
  .react-select__single-value {
    font-size: 0.9375rem;
    font-weight: 400;
  }
  .react-select__placeholder {
    color: ${({ theme }) => theme.textPlaceholder};
    opacity: 1;
  }
  .react-select__indicator {
    cursor: pointer;
    padding-bottom: 0;
    padding-top: 0;
  }
  .react-select__indicator-separator {
    margin-bottom: 1px;
    margin-top: 1px;
  }
  .react-select__input {
    input {
      padding: 0;
      margin: 0;
    }
  }
  .react-select__menu {
    margin-top: ${({ $hasLabel }) => ($hasLabel ? '0.6rem' : '0')};
  }
  .react-select__menu-list {
    ${prettyScrollbar({})}
  }
  .react-select__option {
    padding-left: ${({ theme }) => `${theme.pad.xl}`};
    padding-right: ${({ theme }) => `${theme.pad.xl}`};
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 0.9rem;
  margin: 0.4rem 0.4rem 0 0.4rem;
`;
