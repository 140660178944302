import React, { FC } from 'react';
import { Formik } from 'formik';
import toUpperCase from 'lodash/toUpper';
import Button from 'components/Button';
import {
  Contact,
  Container,
  Error,
  Form,
  Header,
  TextField,
  Title,
} from './styles';
import useConnect from './connect';

const Redeem: FC = () => {
  const {
    heading,
    initialValues,
    loading,
    submit,
    title,
    validationSchema,
  } = useConnect();

  return (
    <Container>
      <Header backHref="/account" title="account" />
      <Title>{title}</Title>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {({ handleSubmit, isSubmitting, values, errors }) => {
          return (
            <Form onSubmit={handleSubmit} heading={heading}>
              <TextField isClearable name="code" normalize={toUpperCase} />
              <Button
                isLoading={loading}
                type="submit"
                disabled={isSubmitting || !values.code}
              >
                Redeem
              </Button>
              {errors.submitError && (
                <>
                  <Error>{errors.submitError}</Error>
                  <Contact>
                    Please contact{' '}
                    <a href="mailto:support@wakingup.com">
                      support@wakingup.com
                    </a>{' '}
                    for help.
                  </Contact>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default Redeem;
