/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Props } from './types';

export const StyledSVG = styled.svg<Props>`
  max-height: 78px;
  #WAKING-UP * {
    fill: ${({ theme, lightBg }) =>
      lightBg ? theme.textLightBg.text : theme.colors.snow};
  }

  #Sam-Harris * {
    fill: ${({ theme, lightBg }) =>
      lightBg ? theme.textLightBg.textFaint : theme.textFaint};
  }
`;
