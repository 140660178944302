import styled from 'styled-components';
import DefaultCloseIcon from 'components/Icons/Close';
import DefaultIconButton from 'components/IconButton';

export const CloseIcon = styled(DefaultCloseIcon)`
  height: 2rem;
  width: 1rem;
`;

export const Button = styled(DefaultIconButton)<{ $isMaximized: boolean }>`
  align-items: center;
  display: ${({ $isMaximized }) => ($isMaximized ? 'flex' : 'none')};
  height: auto;
  left: 1.8rem;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1rem;
`;
