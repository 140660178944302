import { useCallback } from 'react';
import useModal from 'graphql/hooks/useModal';
import { Props } from './types';

const useConnect = ({ courseId }: { courseId: Props['courseId'] }) => {
  const { openCourseNotes } = useModal();

  const openNotes = useCallback(() => {
    openCourseNotes({ courseId });
  }, [openCourseNotes, courseId]);

  return { openNotes };
};

export default useConnect;

export type UseLogic = ReturnType<typeof useConnect>;
