import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';
import { Content as ModalContent } from 'components/Modal/styles';
import DefaultChevronLeft from 'components/Icons/ChevronLeft';
import DefaultChevronRight from 'components/Icons/ChevronRight';
import DefaultIconButton from 'components/IconButton';

export const Modal = styled(DefaultModal)`
  border: none !important;
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.snow};
  }
  ${ModalContent} {
    background-color: ${({ theme }) => theme.colors.snow};
    color: ${({ theme }) => theme.textLightBg.text};
    padding: 1.4rem;
  }

  ${media.tabletPortrait} {
    .MuiPaper-root {
      border-radius: ${({ theme }) =>
        `${theme.radius.lg} ${theme.radius.lg} 0 0`};
      height: 100vh;
      left: 0;
      padding: 0;
      position: absolute;
      right: 0;
      top: 10px;
    }
    ${ModalContent} {
      padding: 1.4rem 0 0 0;
      font-size: 0.9rem;
    }
  }
`;

export const ChevronLeft = styled(DefaultChevronLeft).attrs(() => ({
  size: 20,
}))``;

export const ChevronRight = styled(DefaultChevronRight).attrs(() => ({
  size: 7,
}))``;

export const BackButton = styled(DefaultIconButton)`
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1.7rem;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 1.2rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  margin-bottom: 1rem;
`;

export const MenuItem = styled.div`
  align-items: center;
  border-bottom: 1px solid #dddddd;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0.2rem 0;

  &:hover {
    color: #000000;
  }
`;

export const Text = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
`;
