import { apolloClient } from 'services/api';
import { GetModal, GetModal_modal } from 'graphql/generated/GetModal';
import { GET_MODAL } from 'graphql/queries';
import cacheReadQuery from './cacheReadQuery';

function cacheUpdateModal(data: Partial<GetModal_modal>) {
  const queryResult = cacheReadQuery<GetModal>(GET_MODAL);
  const modal = queryResult?.modal;

  if (!modal) {
    return null;
  }

  const newData = { ...modal, ...data };

  apolloClient.writeQuery<GetModal>({
    query: GET_MODAL,
    data: { modal: newData },
  });

  return newData;
}

export default cacheUpdateModal;
