import styled from 'styled-components';
import DefaultChevronLeft from 'components/Icons/ChevronLeft';
import DefaultChevronRight from 'components/Icons/ChevronRight';

export const Button = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue800};
  border: none;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.snow};
  cursor: pointer;
  display: inline-flex;
  height: 24px;
  justify-content: center;
  margin-left: 4px;
  width: 24px;

  &:hover {
    opacity: 0.7;
    transition: opacity 0.5s ease-in-out;
  }
`;

export const ChevronLeft = styled(DefaultChevronLeft).attrs(() => ({
  size: 14,
}))`
  margin-left: 3px;
`;

export const ChevronRight = styled(DefaultChevronRight).attrs(() => ({
  size: 8.5,
}))`
  margin-top: 0.7px;
`;
