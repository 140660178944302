import styled from 'styled-components';
import DefaultSectionHeader from 'components/SectionHeader';
import DefaultPacks from 'components/Packs';

export const SectionHeader = styled(DefaultSectionHeader)`
  margin-bottom: ${({ theme }) => theme.margin.md};
`;

export const Packs = styled(DefaultPacks)`
  margin-bottom: ${({ theme }) => theme.margin.sm};
`;
