import DefaultLoaderBox from 'components/LoaderBox';
import styled from 'styled-components';

interface RootProps {
  alt?: string;
  src: string;
}

export const LoaderBox = styled(DefaultLoaderBox)`
  height: 100%;
  width: 100%;
`;

export const Root = styled.div`
  border-radius: ${({ theme }) => theme.radius.md};
  height: 100%;
  width: 100%;
`;

export const ImageRoot = styled(Root)<RootProps>`
  background-image: url('${({ src }) => src}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Failed = styled(Root)`
  background-color: rgba(0, 0, 0, 0.1);
`;
