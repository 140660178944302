import styled, { css } from 'styled-components';
import { media } from 'styles/mixins';
import DefaultCourseThumbnail from 'components/CourseThumbnail';
import DefaultHeartFull from 'components/Icons/HeartFull';
import DefaultIconButton from 'components/IconButton';
import DefaultMoreIcon from 'components/Icons/More';
import {
  ROW_HEIGHT_DESKTOP_PX,
  ROW_MARGIN_DESKTOP_PX,
  IMAGE_WIDTH_DESKTOP_PX,
  ROW_HEIGHT_MOBILE_PX,
  ROW_MARGIN_MOBILE_PX,
  IMAGE_WIDTH_MOBILE_PX,
} from '../constants';

export const Container = styled.div`
  align-items: center;
  border-radius: ${({ theme }) => theme.radius.md};
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  height: ${ROW_HEIGHT_DESKTOP_PX}px;
  justify-content: flex-start;
  margin: ${ROW_MARGIN_DESKTOP_PX}px 0px;

  &:hover {
    background-color: rgba(46, 107, 236, 0.1);
  }

  ${media.phoneLandscape} {
    height: ${ROW_HEIGHT_MOBILE_PX}px;
    margin: ${ROW_MARGIN_MOBILE_PX}px 0;
  }
`;

export const CourseThumbnail = styled(DefaultCourseThumbnail)<{
  $isIntro: boolean;
}>`
  border-radius: ${({ theme }) => theme.radius.md};
  height: 100%;
  min-width: ${IMAGE_WIDTH_DESKTOP_PX}px;
  width: ${IMAGE_WIDTH_DESKTOP_PX}px;

  ${({ $isIntro }) =>
    $isIntro &&
    css`
      background-size: contain;
    `}

  ${media.phoneLandscape} {
    height: 80%;
    min-width: ${IMAGE_WIDTH_MOBILE_PX}px;
    width: ${IMAGE_WIDTH_MOBILE_PX}px;
  }
`;

export const Info = styled.div<{ $showAsFinished?: boolean }>`
  color: ${({ $showAsFinished, theme }) =>
    $showAsFinished ? theme.textLightBg.textFaint : theme.textLabel};
  flex: 1 1 auto;
  margin-left: 3rem;

  ${media.phoneLandscape} {
    margin-left: 1rem;
  }
`;

export const Title = styled.div`
  font-size: ${({ theme }) => theme.font.text};
  line-height: 1.25rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  white-space: pre-wrap;
`;

export const Description = styled.div`
  font-size: ${({ theme }) => theme.font.textSmall};
  line-height: 1rem;
  margin-top: ${({ theme }) => theme.margin.xxxsm};
  display: flex;
  align-items: center;
`;

export const FavoriteMiniIcon = styled(DefaultHeartFull)`
  color: #f4477c;
  display: inline-block;
  height: 0.9rem;
  width: 0.9rem;
  margin-right: 0.5rem;
  padding: 0;
`;

export const ActionsIcon = styled(DefaultMoreIcon).attrs(() => ({
  size: 23,
}))`
  color: ${({ theme }) => theme.textLightBg.text};
`;

export const ActionsButton = styled(DefaultIconButton)`
  color: ${({ theme }) => theme.textLightBg.text};
  margin-left: 0.6rem;
  margin-right: 1.2rem;
  opacity: 0.5;
  transition: opacity 0.2s ease, transform 0.2s ease;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }

  ${media.phonePortrait} {
    margin-right: 0;
  }
`;
