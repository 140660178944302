import CourseList from 'components/CourseList';
import React, { FC } from 'react';
import useConnect from './connect';
import DeleteButton from './DeleteButton';
import NoFavorites from './NoFavorites';
import {
  AutoPlay,
  NoElements,
  NoFavoritesContainer,
  SectionHeader,
  Title,
} from './styles';

const FavoritesPage: FC = () => {
  const {
    emptyFavorites,
    favoriteLessons,
    favoriteMeditations,
    favoritesMeditationAutoPlay,
    favoritesTheoriesAutoPlay,
    loading,
    loadFavoriteMeditationTrack,
    loadFavoriteTheoryTrack,
    openCourseActions,
    openCourseNotes,
    openResetCourseConfirm,
    openShareLink,
    removeAllFavorites,
    toggleFavorite,
    toggleFavoritesMeditationAutoPlay,
    toggleFavoritesTheoriesAutoPlay,
  } = useConnect();

  if (emptyFavorites && !loading)
    return (
      <NoFavoritesContainer>
        <NoFavorites />
      </NoFavoritesContainer>
    );

  return (
    <>
      <SectionHeader>
        <Title>PRACTICE</Title>
        {loading ||
          (favoriteMeditations?.length && (
            <AutoPlay
              label="Auto-Play"
              checked={favoritesMeditationAutoPlay}
              onChange={toggleFavoritesMeditationAutoPlay}
            />
          ))}
      </SectionHeader>
      {loading || favoriteMeditations?.length ? (
        <CourseList
          courses={favoriteMeditations}
          isLoading={loading}
          openCourseActions={openCourseActions}
          openCourseNotes={openCourseNotes}
          openResetCourseConfirm={openResetCourseConfirm}
          openShareLink={openShareLink}
          setTrack={loadFavoriteMeditationTrack}
          toggleFavorite={toggleFavorite}
        />
      ) : (
        <NoElements elementType="meditation" />
      )}

      <SectionHeader>
        <Title>THEORY</Title>
        {loading ||
          (favoriteLessons?.length && (
            <AutoPlay
              label="Auto-Play"
              checked={favoritesTheoriesAutoPlay}
              onChange={toggleFavoritesTheoriesAutoPlay}
            />
          ))}
      </SectionHeader>
      {loading || favoriteLessons?.length ? (
        <CourseList
          courses={favoriteLessons}
          isLoading={loading}
          openCourseActions={openCourseActions}
          openCourseNotes={openCourseNotes}
          openResetCourseConfirm={openResetCourseConfirm}
          openShareLink={openShareLink}
          setTrack={loadFavoriteTheoryTrack}
          toggleFavorite={toggleFavorite}
        />
      ) : (
        <NoElements elementType="lesson" />
      )}

      {!loading && <DeleteButton onClick={removeAllFavorites} />}
    </>
  );
};

export default FavoritesPage;
