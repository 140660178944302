import { useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { setToken } from 'services/storage';
import { registerAnalyticsUser, trackEvent } from 'services/analytics';
import { normalizeUser } from 'models/User';
import { Login, LoginVariables } from '../generated/Login';
import { GetPin, GetPinVariables } from '../generated/GetPin';
import { GetLoginEmail } from '../generated/GetLoginEmail';
import { GET_LOGIN_EMAIL } from '../queries';
import { LOGIN, GET_PIN } from '../mutations';
import { formatErrorMessage } from '../helpers';
import {
  initializeCacheLocalState,
  cacheUpdateLoginEmail,
  cacheUpdateMeData,
} from '../cache';
import { getInitialData, getSettings, updateSettings } from '../requests';
import useModal from './useModal';

const useLogin = () => {
  const { openOnboarding } = useModal();

  const { data: loginEmailData, loading: isLoadingGetLoginEmail } = useQuery<
    GetLoginEmail
  >(GET_LOGIN_EMAIL, { fetchPolicy: 'cache-only' });

  const [getPinMutation, { loading: isLoadingGetPin }] = useMutation<
    GetPin,
    GetPinVariables
  >(GET_PIN);

  const [loginMutation, { loading: isLoadingLogin }] = useMutation<
    Login,
    LoginVariables
  >(LOGIN);

  const loginEmail = loginEmailData?.loginEmail || '';

  const getPin = useCallback(
    async (email: string) => {
      try {
        await getPinMutation({ variables: { email } });
        cacheUpdateLoginEmail(email);
        trackEvent('Login Submit', {
          method: 'email',
          credential_type: 'email',
          credential: email,
          private: false,
        });
      } catch (error) {
        cacheUpdateLoginEmail();
        throw new Error(formatErrorMessage(error, 'Failed to send code'));
      }
    },
    [getPinMutation],
  );

  const login = useCallback(
    async (pin: string) => {
      try {
        if (!loginEmail) {
          throw new Error('No email available');
        }

        const response = await loginMutation({
          variables: { email: loginEmail, pin },
        });
        const userData = response?.data?.login;

        if (!userData) {
          throw new Error('No user data found');
        }

        const token = userData?.token;
        if (!token) {
          throw new Error('No token found');
        }
        setToken(token);

        initializeCacheLocalState();

        const user = normalizeUser(userData);
        await getInitialData();

        registerAnalyticsUser(user.uuid);

        trackEvent('Login Complete', {
          method: 'email',
          user_type: user.isNew ? 'new' : 'existing',
        });

        if (user.isNew) {
          window.fbq('track', 'CompleteRegistration');

          const settings = await getSettings();
          if (!settings.hasPresentedHome) {
            trackEvent('Onboard Home');
            trackEvent('Prompt Experience', {
              name: 'Experience',
              action: 'display',
            });
            await updateSettings({ hasPresentedHome: true }, true);
          }

          openOnboarding();
        }

        cacheUpdateLoginEmail();
        cacheUpdateMeData(userData);
      } catch (error) {
        throw new Error(formatErrorMessage(error, 'Failed to validate code'));
      }
    },
    [loginMutation, loginEmail, openOnboarding],
  );

  const resend = useCallback(async () => {
    try {
      await getPinMutation({ variables: { email: loginEmail } });
    } catch (error) {
      throw new Error(formatErrorMessage(error, 'Failed to resend code'));
    }
  }, [getPinMutation, loginEmail]);

  return {
    loginEmail,
    getPin,
    login,
    resend,
    loading: {
      getLoginEmail: isLoadingGetLoginEmail,
      getPin: isLoadingGetPin,
      login: isLoadingLogin,
    },
  };
};

export default useLogin;

export type UseLogin = ReturnType<typeof useLogin>;
