import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const More: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={size}
    viewBox="0 0 515.555 515.555"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M496.679 212.208c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138 65.971-25.167 91.138 0m-193.332 0c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138 65.971-25.167 91.138 0m-193.333 0c25.167 25.167 25.167 65.971 0 91.138s-65.971 25.167-91.138 0-25.167-65.971 0-91.138 65.971-25.167 91.138 0" />
  </svg>
);

export default memo(More);
