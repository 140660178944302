import React, { FC, memo, useCallback, useRef } from 'react';
import { handleOpenFileSelector, handleSelectFile } from './logic';
import { Avatar, HeaderLink, Root } from './styles';
import { Props } from './types';

const AvatarEditor: FC<Props> = ({
  onChangeSrc,
  src,
  firstName,
  lastName,
  email,
  name,
  ...rest
}) => {
  const input = useRef<HTMLInputElement>(null);

  const onOpenFileSelector = useCallback(handleOpenFileSelector({ input }), [
    input,
  ]);

  const onFileChange = useCallback(handleSelectFile({ name, onChangeSrc }), [
    name,
    onChangeSrc,
  ]);

  return (
    <Root {...rest}>
      <Avatar
        shadow
        src={src}
        firstName={firstName}
        lastName={lastName}
        email={email}
      />
      <HeaderLink data-jest-id="change-photo" onClick={onOpenFileSelector}>
        Change profile photo
      </HeaderLink>
      <input
        data-cypress-id="avatar-editor-input"
        accept="image/*"
        data-jest-id="avatar-input"
        hidden
        multiple={false}
        onChange={onFileChange}
        ref={input}
        type="file"
      />
    </Root>
  );
};

export default memo(AvatarEditor);
