import { useRouteMatch, useLocation } from 'react-router';
import { createGetBackLevels } from 'lib/createGetBackLevels';
import { up } from 'lib/paths';

/*
 * We can use getBackLevels to define custom "back levels" that will be passed
 * to "Paths.up" based on the current path.
 *
 * For example, we want the back button to achieve the following:
 *
 * /theory                  --> /
 * /practice/intro-course --> /
 *
 * This is because if '/practice/intro-course' redirected to '/practice',
 * '/practice' would redirect back to '/intro-course', therefore we must be
 * able to explicitly specify that we want to get back two levels instead of
 * one.
 *
 * If the path doesn't match any rule, then it will go back one level by
 * default.
 */
const getBackLevels = createGetBackLevels([
  {
    levels: 2,
    route: '/practice/:pack',
  },
  {
    levels: 2,
    route: '/theory/:pack',
  },
]);

const NO_BACK_ROUTES = ['/theory', '/practice', '/favorites'];
const NO_BACK_ROUTES_EXACT = ['/account', '/'];

const useConnect = () => {
  const { pathname } = useLocation();
  const match = useRouteMatch(NO_BACK_ROUTES);
  const matchExact = useRouteMatch({
    path: NO_BACK_ROUTES_EXACT,
    strict: true,
  });

  return {
    backToUrl: up(pathname, getBackLevels(pathname)),
    showBackButton: !match && !matchExact?.isExact,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
