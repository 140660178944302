import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { trackEvent, trackMediaEvent } from 'services/analytics';
import { ResetIntroCourses } from '../generated/ResetIntroCourses';
import { ChangeIntroCourse } from '../generated/ChangeIntroCourse';
import { ResetAllContent } from '../generated/ResetAllContent';
import { ResetCourse, ResetCourseVariables } from '../generated/ResetCourse';
import { CourseData } from '../generated/CourseData';
import { COURSE_DATA_FRAGMENT } from '../fragments';
import { ME, GET_PACKS, GET_DAILY } from '../queries';
import {
  CHANGE_INTRO_COURSE,
  RESET_ALL_CONTENT,
  RESET_COURSE,
  RESET_INTRO_COURSES,
} from '../mutations';
import { getIntroCourses, getSettings } from '../requests';
import { cacheUpdateFragment } from '../cache';
import useSettingsActions from './useSettingsActions';

const useCoursesActions = () => {
  const { updateCourseResume, updateSettings } = useSettingsActions();
  const { enqueueSnackbar } = useSnackbar();

  const [resetAllContentMutation] = useMutation<ResetAllContent>(
    RESET_ALL_CONTENT,
  );

  const [resetCourseMutation] = useMutation<ResetCourse, ResetCourseVariables>(
    RESET_COURSE,
  );

  const [resetIntroCoursesMutation] = useMutation<ResetIntroCourses>(
    RESET_INTRO_COURSES,
  );

  const [changeIntroCourseMutation] = useMutation<ChangeIntroCourse>(
    CHANGE_INTRO_COURSE,
    {
      refetchQueries: [
        { query: ME },
        { query: GET_PACKS },
        {
          query: GET_DAILY,
          variables: { date: format(new Date(), 'yyyy-MM-dd') },
        },
      ],
    },
  );

  const resetAllContent = useCallback(async () => {
    try {
      await resetAllContentMutation();

      // Keep intro courses resumes in settings.
      const { resumePlayingList } = await getSettings();
      const newResumePlayingList = (await getIntroCourses()).reduce<
        Record<string, number>
      >((t, { id }) => {
        if (resumePlayingList[id]) {
          t[id] = resumePlayingList[id];
        }
        return t;
      }, {});
      await updateSettings({ resumePlayingList: newResumePlayingList });

      trackEvent('Settings Reset All Content');

      enqueueSnackbar('All content has been reset successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Unable to reset content', { variant: 'error' });
    }
  }, [resetAllContentMutation, enqueueSnackbar, updateSettings]);

  const resetCourse = useCallback(
    async (courseId: string) => {
      try {
        await resetCourseMutation({ variables: { course_id: courseId } });
        await updateCourseResume(courseId, 0);

        cacheUpdateFragment<CourseData>(COURSE_DATA_FRAGMENT, {
          id: courseId,
          __typename: 'Course',
          status: 'unstarted',
        });

        trackMediaEvent('Media Mark Content as Unplayed', courseId);

        enqueueSnackbar('Course has been reset successfully', {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar('Unable to reset course', { variant: 'error' });
      }
    },
    [resetCourseMutation, enqueueSnackbar, updateCourseResume],
  );

  const resetIntroCourses = useCallback(async () => {
    try {
      await resetIntroCoursesMutation();

      // Reset intro courses resumes in settings.
      const { resumePlayingList } = await getSettings();
      const resetedIntroCourses = (await getIntroCourses()).reduce<
        Record<string, 0>
      >((t, { id }) => {
        t[id] = 0;
        return t;
      }, {});
      await updateSettings({
        resumePlayingList: { ...resumePlayingList, ...resetedIntroCourses },
      });

      trackEvent('Settings Reset Intro Course');

      enqueueSnackbar('Introductory course has been reset successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Unable to reset introductory course', {
        variant: 'error',
      });
    }
  }, [resetIntroCoursesMutation, enqueueSnackbar, updateSettings]);

  const changeIntroCourse = useCallback(async () => {
    try {
      await changeIntroCourseMutation();

      enqueueSnackbar('Introductory course has been changed successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Unable to change introductory course', {
        variant: 'error',
      });
    }
  }, [changeIntroCourseMutation, enqueueSnackbar]);

  return {
    resetAllContent,
    resetCourse,
    resetIntroCourses,
    changeIntroCourse,
  };
};

export default useCoursesActions;

export type UseCourses = ReturnType<typeof useCoursesActions>;
