import styled from 'styled-components';
import DefaultCheckmark from 'components/Icons/Checkmark';

export const Container = styled.ul`
  margin-top: ${({ theme }) => theme.margin.lg};
  margin-bottom: ${({ theme }) => theme.margin.lg};
  padding-left: ${({ theme }) => theme.pad.xxl};
  list-style-type: none;
  width: 100%;
`;

export const Checkmark = styled(DefaultCheckmark).attrs(() => ({ size: 18 }))`
  color: ${({ theme }) => theme.colors.teal};
  margin-right: 0.8rem;
`;

export const ListItem = styled.li`
  margin-top: 0.625em;
`;
