import notEmpty from 'lib/notEmpty';
import stringToEnum from 'lib/stringToEnum';
import interpolateColors from 'lib/interpolateColors';
import { normalizeCourse, CourseStatus, MeditationType } from 'models/Course';
import { GetPacks_packs_data } from 'graphql/generated/GetPacks';
import { GetDaily_daily } from 'graphql/generated/GetDaily';

const START_COLOR = 'rgb(100, 137, 255)';
const END_COLOR = 'rgb(0, 16, 61)';

export const PACK_CATEGORIES_ALLOWED = [
  'daily',
  'intro',
  'master_pack',
  'sub_pack',
  'pack',
];
export const PACKS_WITH_SPEED_CONTROL = ['5', '6'];
export const DAILY_MEDITATION_PACK_ID = '-12345';

export enum PackType {
  GUEST = 'guest',
  LESSON = 'lesson',
  MEDITATION = 'meditation',
}

export enum PackPermission {
  UNLOCKED = 'unlocked',
  LOCKED = 'locked',
  FORBIDDEN = 'forbidden',
}

export const normalizePack = (pack: GetPacks_packs_data) => {
  const coursesTotal =
    pack.category === 'intro'
      ? pack.courses?.filter((v) => v?.meditation_type !== 'special_intro')
          .length || 0
      : pack.courses?.length || 0;
  const colorsArray = interpolateColors(START_COLOR, END_COLOR, coursesTotal);

  const courses = pack.courses
    ? pack.courses
        .filter(notEmpty)
        .sort((a, b) => {
          if (!a.course_number) return 1;
          if (!b.course_number) return -1;
          return a.course_number - b.course_number;
        })
        .map((v, i) => ({
          ...normalizeCourse(v),
          color: colorsArray[i],
          isIntroCourse: pack.category === 'intro',
        }))
    : [];

  const countableCourses =
    pack.category === 'intro'
      ? courses.filter((v) => v.meditationType !== MeditationType.SPECIAL_INTRO)
      : courses;
  const nCoursesCompleted = countableCourses.filter(
    (course) => course.status === CourseStatus.FINISHED,
  ).length;
  const hasFreeCourses = courses.some((course) => course.is_free);

  const subtitle = pack.subtitle || '';
  const title = pack.title || '';
  const type = stringToEnum(PackType, pack.pack_type) || PackType.MEDITATION;
  const urlBase = `/${type === PackType.LESSON ? 'theory' : 'practice'}`;

  return {
    autoPlay: pack.auto_play || false,
    backgroundColor: '',
    category: pack.category || '',
    courses,
    description:
      pack.category === 'intro'
        ? `${nCoursesCompleted} of ${coursesTotal} complete`
        : subtitle,
    displayTitle: pack.display_title || false,
    hasFreeCourses,
    hasSpeedControl: PACKS_WITH_SPEED_CONTROL.includes(pack.id),
    hash: pack.pack_hash || '',
    hidePackFromMasterPack: pack.hide_pack_from_master_pack || false,
    id: pack.id,
    imageUrl: pack.image_url || '',
    number: pack.pack_number || -1,
    parentPackId: pack.parent_pack_id || '',
    permission: PackPermission.UNLOCKED,
    subtitle,
    thumbnailUrl:
      pack.thumbnail_url?.thumb_x1 || pack.thumbnail_url?.thumb_x2 || '',
    title,
    type,
    urlBase,
  };
};

export type Pack = ReturnType<typeof normalizePack>;

export const normalizeDailyPack = (course: GetDaily_daily) => {
  const daily = normalizeCourse(course);
  daily.packId = DAILY_MEDITATION_PACK_ID;
  daily.packHash = 'daily';
  daily.packTitle = 'Daily Meditation';

  return {
    autoPlay: false,
    backgroundColor: daily.squareImageColor || '',
    category: 'daily',
    courses: [daily],
    description: 'Refreshes daily',
    displayTitle: true,
    hasFreeCourses: false,
    hasSpeedControl: false,
    hash: 'daily',
    hidePackFromMasterPack: false,
    id: DAILY_MEDITATION_PACK_ID,
    imageUrl: daily.squareImageOriginal || '',
    number: 0,
    parentPackId: '',
    permission: PackPermission.UNLOCKED,
    subtitle: 'Refreshes Daily',
    thumbnailUrl: daily.squareImageThumb || '',
    title: 'Daily Meditation',
    type: PackType.MEDITATION,
    urlBase: '/practice',
  };
};

export const getPackPermission = (
  isSubscribed: boolean,
  hasFreeCourses: boolean,
) => {
  if (!isSubscribed && !hasFreeCourses) {
    return PackPermission.FORBIDDEN;
  }
  return PackPermission.UNLOCKED;
};

export const getDailyPackPermission = (
  isSubscribed: boolean,
  isDailyUnlocked: boolean,
) => {
  if (!isSubscribed) {
    return PackPermission.FORBIDDEN;
  }
  if (!isDailyUnlocked) {
    return PackPermission.LOCKED;
  }
  return PackPermission.UNLOCKED;
};

export const getLastIntroCourseId = (
  packs: (GetPacks_packs_data | null)[] | null,
) => {
  if (!packs) return null;

  const introPack = packs.find((pack) => pack?.category === 'intro');
  const introCoursesSortedDesc = introPack?.courses
    ?.filter(notEmpty)
    .map(normalizeCourse)
    .filter((v) => v.meditationType !== MeditationType.SPECIAL_INTRO)
    .sort((a, b) => b.courseNumber - a.courseNumber);

  return introCoursesSortedDesc?.[0]?.id || null;
};
