import React, { FC, memo } from 'react';
import ClockIcon from 'components/Icons/Clock';
import SectionLink from '../SectionLink';
import { Actions } from '../styles';
import DurationSelector from './DurationSelector';
import { Begin, Container, FinishTime, Header, Title } from './styles';
import { Props } from './types';
import { useFinishTime } from './logic';
import useConnect from './connect';

const MainSection: FC<Props> = ({
  duration,
  intervalTime,
  isTimeSectionOpen,
  setDuration,
  setIsTimeSectionOpen,
}) => {
  const {
    begin,
    decrementDuration,
    incrementDuration,
    toogleTimeSection,
    validIntervalTimeText,
  } = useConnect({ duration, intervalTime, setDuration, setIsTimeSectionOpen });
  const finishTime = useFinishTime({ duration });

  return (
    <Container $isTimeSectionOpen={isTimeSectionOpen}>
      <Header>
        <Title data-cypress-id="self-timer-title">Timer</Title>
        <FinishTime data-cypress-id="self-timer-finishes-at">
          Finishes at {finishTime}
        </FinishTime>
      </Header>
      <DurationSelector
        decrementDuration={decrementDuration}
        duration={duration}
        incrementDuration={incrementDuration}
        setDuration={setDuration}
      />
      <Actions>
        <SectionLink
          active={isTimeSectionOpen}
          data-cypress-id="self-timer-interval-time-section-link"
          onClick={toogleTimeSection}
          path="interval-time"
          selected={validIntervalTimeText}
        >
          <ClockIcon />
          Interval Time
        </SectionLink>
        <Begin data-cypress-id="self-timer-begin-button" onClick={begin}>
          Begin
        </Begin>
      </Actions>
    </Container>
  );
};

export default memo(MainSection);
