import Checklist from 'components/Checklist';
import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Container = styled.div<{ darkBg?: boolean }>`
  color: ${({ darkBg, theme }) =>
    darkBg ? theme.textLight : theme.textLightBg.text};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  color: inherit;
  font-size: ${({ theme }) => theme.font.heading};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: ${({ theme }) => theme.margin.lg};
  margin-top: ${({ theme }) => theme.margin.xxl};
  text-align: center;

  ${media.tabletPortrait} {
    margin-top: ${({ theme }) => theme.margin.md};
  }

  ${media.phoneLandscape} {
    margin-top: 0;
  }
`;

export const FeatureList = styled(Checklist)`
  display: flex;
  flex-direction: column;
  width: auto;
  padding-left: 0;
`;

export const Plans = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Info = styled.p`
  color: #8294a5;
  font-size: 13px;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 16px;
  text-align: center;
  padding: 0 1rem;
`;
