import { Course, CoursePermission, CourseType } from 'models/Course';
import { PlayerAutoplayType } from 'graphql/generated/globalTypes';
import getPlayer from './getPlayer';
import getSettings from './getSettings';
import getCoursePack from './getCoursePack';
import getFavorites from './getFavorites';

const getAutoplayTrackList = async () => {
  const { autoplayType, courseId } = await getPlayer();

  if (!autoplayType || !courseId) {
    return undefined;
  }

  let autoplayTrackList: Course[] | null = null;
  if (autoplayType === PlayerAutoplayType.PACK) {
    const { packsAutoPlay } = await getSettings();
    const pack = await getCoursePack(courseId);
    if (pack && pack.autoPlay && packsAutoPlay.includes(pack.id)) {
      autoplayTrackList = pack.courses;
    }
  } else if (autoplayType === PlayerAutoplayType.FAVORITES_THEORY) {
    const { favoritesTheoriesAutoPlay } = await getSettings();
    if (favoritesTheoriesAutoPlay) {
      autoplayTrackList = await getFavorites(CourseType.LESSON);
    }
  } else if (autoplayType === PlayerAutoplayType.FAVORITES_PRACTICE) {
    const { favoritesMeditationAutoPlay } = await getSettings();
    if (favoritesMeditationAutoPlay) {
      autoplayTrackList = await getFavorites(CourseType.MEDITATION);
    }
  }

  const unlockedAutoplayTrackList = autoplayTrackList?.filter(
    (course) => course.permission === CoursePermission.UNLOCKED,
  );

  return unlockedAutoplayTrackList && unlockedAutoplayTrackList.length > 0
    ? unlockedAutoplayTrackList.map((course) => course.id)
    : undefined;
};

export default getAutoplayTrackList;
