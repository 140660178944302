import { useMemo, useCallback, ChangeEvent } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { FormikHelpers } from 'formik';
import { useSnackbar } from 'notistack';
import { trackEvent } from 'services/analytics';
import { up } from 'lib/paths';
import useMe from 'graphql/hooks/useMe';
import useMeActions from 'graphql/hooks/useMeActions';
import { validationSchema } from './constants';
import { FormValues } from './types';

const trackProfilePropertyEvent = (
  fields: Partial<Record<keyof FormValues, string>>,
  oldValues: FormValues,
  newValues: FormValues,
) => {
  if (newValues.avatarFile) {
    trackEvent('Setting Update Profile Picture');
  }

  const fieldNames = Object.keys(fields) as Array<keyof FormValues>;
  fieldNames.forEach((fieldName) => {
    const oldValue = oldValues[fieldName];
    const newValue = newValues[fieldName];
    if (oldValue !== newValue)
      trackEvent('Setting Update Profile', {
        fieldName: fields[fieldName],
        oldValue,
        newValue,
      });
  });
};

const useConnect = () => {
  const { profile } = useMe();
  const { updateProfile } = useMeActions();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const { path } = useRouteMatch();

  const initialValues: FormValues = useMemo(
    () => ({
      avatar: profile?.avatarUrl,
      avatarFile: null,
      city: profile?.city,
      email: profile?.email,
      firstName: profile?.firstName,
      lastName: profile?.lastName,
    }),
    [profile],
  );

  const backHref = useMemo(() => up(path), [path]);

  const changeAvatar = useCallback(
    (setFieldValue: FormikHelpers<FormValues>['setFieldValue']) => (
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      const { files, value } = event.target;
      if (value) {
        setFieldValue('avatar', value);
      }
      if (files && files[0]) {
        setFieldValue('avatarFile', files[0]);
      }
    },
    [],
  );

  const submit = useCallback(
    async (values: FormValues) => {
      try {
        await updateProfile({
          avatar: values.avatar,
          avatarFile: values.avatarFile ?? undefined,
          city: values.city ?? undefined,
          first_name: values.firstName,
          last_name: values.lastName,
        });

        enqueueSnackbar('Your profile has been updated successfully', {
          variant: 'success',
        });

        trackProfilePropertyEvent(
          { firstName: 'First', lastName: 'Last', city: 'City' },
          initialValues,
          values,
        );

        push(up(path));
      } catch (e) {
        enqueueSnackbar('Unable to update your profile', { variant: 'error' });
      }
    },
    [enqueueSnackbar, initialValues, path, push, updateProfile],
  );

  return {
    backHref,
    changeAvatar,
    initialValues,
    submit,
    updateProfile,
    validationSchema,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
