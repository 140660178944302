import React, { FC } from 'react';
import Modal from 'components/Modal';
import { Accept } from './styles';
import useConnect from './connect';

const TITLE = 'Please read';
const DESCRIPTION =
  "This feature is designed for subscribers who wish to share Waking Up with others. It's recommended you spend enough time on the app to form an opinion before inviting others to experience it";

const ShareMonthWarningModal: FC = () => {
  const { accept, isOpen, closeModal } = useConnect();

  return (
    <Modal
      title={TITLE}
      description={DESCRIPTION}
      onClose={closeModal}
      open={isOpen}
    >
      <Accept onClick={accept}>OK</Accept>
    </Modal>
  );
};

export default ShareMonthWarningModal;
