import React, { FC } from 'react';
import Main from './Main';
import Interval from './Interval';
import { Modal } from './styles';
import useConnect from './connect';

const SelfTimerModal: FC = () => {
  const {
    closeModal,
    duration,
    setDuration,
    intervalTime,
    isOpen,
    isTimeSectionOpen,
    setIntervalTime,
    setIsTimeSectionOpen,
  } = useConnect();

  return (
    <Modal
      animation="fade"
      hideLogo
      onClose={closeModal}
      open={isOpen}
      reverseClose
      isTimeSectionOpen={isTimeSectionOpen}
    >
      <Main
        duration={duration}
        setDuration={setDuration}
        setIsTimeSectionOpen={setIsTimeSectionOpen}
        intervalTime={intervalTime}
        isTimeSectionOpen={isTimeSectionOpen}
      />
      {isTimeSectionOpen && (
        <Interval
          duration={duration}
          intervalTime={intervalTime}
          setIntervalTime={setIntervalTime}
          setIsTimeSectionOpen={setIsTimeSectionOpen}
        />
      )}
    </Modal>
  );
};

export default SelfTimerModal;
