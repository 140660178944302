import styled, { css } from 'styled-components';
import DefaultCheckmarkIcon from 'components/Icons/Checkmark';
import { Props } from './types';

export const Container = styled.div<{ $isSelected: Props['isSelected'] }>`
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background-color: hsla(186, 100%, 90%, 0.06);
    `};

  align-items: center;
  border-radius: ${({ theme }) => theme.radius.sm};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.pad.md};

  :hover {
    background-color: hsla(186, 100%, 90%, 0.06);
  }
`;

export const CheckmarkIcon = styled(DefaultCheckmarkIcon).attrs(() => ({
  size: 16,
}))`
  color: ${({ theme }) => theme.colors.success};
  margin-left: auto;
`;
