import { ChangeEvent, RefObject } from 'react';
import { Props } from './types';

export const handleOpenFileSelector = ({
  input,
}: {
  input: RefObject<HTMLInputElement>;
}) => () => {
  if (!input.current) {
    return;
  }

  input.current.click();
};

export const handleSelectFile = ({
  name,
  onChangeSrc,
}: {
  name: Props['name'];
  onChangeSrc: Props['onChangeSrc'];
}) => (event: ChangeEvent<HTMLInputElement>) => {
  const { target, ...restEventProps } = event;
  const { files } = target;

  if (!files) {
    return;
  }

  const [file] = Array.from(files);

  if (!file) {
    return;
  }

  const fr = new FileReader();

  fr.onload = () => {
    if (typeof fr.result === 'string') {
      onChangeSrc({
        ...restEventProps,
        target: { ...target, files, name, value: fr.result },
      });
      target.value = '';
    }
  };

  fr.readAsDataURL(file);
};
