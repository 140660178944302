import { apolloClient } from 'services/api';
import { GetModal, GetModal_modal } from 'graphql/generated/GetModal';
import { GET_MODAL } from 'graphql/queries';

function cacheResetModal() {
  const newData: GetModal_modal = {
    __typename: 'Modal',
    type: null,
    courseActionsPayload: null,
    courseNotesPayload: null,
    dialogPayload: null,
    resetCoursePayload: null,
    shareLinkPayload: null,
    unlockContentPayload: null,
    updatePaymentCardPayload: null,
  };

  apolloClient.writeQuery<GetModal>({
    query: GET_MODAL,
    data: { modal: newData },
  });

  return newData;
}

export default cacheResetModal;
