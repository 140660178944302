import React, { FC } from 'react';
import { Switch, Route } from 'react-router';
import { Container, Left, Right, Logo } from './styles';
import Onboarding from './Onboarding';
import Login from './Login';
import Register from './Register';

const Auth: FC = () => (
  <Container>
    <Left>
      <Logo />
      <Switch>
        <Route component={Login} exact path={['/', '/guest']} />
        <Route component={Register} exact path="/request-free-account" />
      </Switch>
    </Left>
    <Right>
      <Onboarding />
    </Right>
  </Container>
);

export default Auth;
