import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/mixins';
import DefaultLoaderBox from 'components/LoaderBox';
import Switch from 'components/Switch';
import {
  DEFAULT_SLIDE_HEIGHT,
  TABLET_SLIDE_HEIGHT,
  TABLET_LANDSCAPE_SLIDE_HEIGHT,
  PHONE_SLIDE_HEIGHT,
} from './dimensions';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
}))`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
`;

export const SliderLoader = styled(DefaultLoaderBox)`
  height: ${DEFAULT_SLIDE_HEIGHT};

  ${media.tabletLandscape} {
    height: ${TABLET_LANDSCAPE_SLIDE_HEIGHT};
  }

  ${media.tabletPortrait} {
    height: ${TABLET_SLIDE_HEIGHT};
  }

  ${media.phonePortrait} {
    height: ${PHONE_SLIDE_HEIGHT};
  }
`;

export const AutoPlay = styled(Switch)`
  align-self: flex-end;
  margin-top: 1.2rem;
`;
