import { useMemo, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { trackEvent, trackMediaEvent } from 'services/analytics';
import { normalizePlayer } from 'models/Player';
import {
  SelfTimerInterval,
  SELF_TIMER_INTERVALS,
} from 'models/SelfTimerInterval';
import { GetPlayer } from '../generated/GetPlayer';
import {
  PlayerDisplayStatus,
  PlayerAudioStatus,
  PlayerType,
  PlayerAutoplayType,
} from '../generated/globalTypes';
import { GET_PLAYER } from '../queries';
import { getProfile, updatePlayerCode } from '../requests';
import { cacheReadPlayer, cacheResetPlayer, cacheUpdatePlayer } from '../cache';

const usePlayer = () => {
  const { data: playerData } = useQuery<GetPlayer>(GET_PLAYER, {
    fetchPolicy: 'cache-only',
  });

  const {
    audioStatus,
    autoplayType,
    courseId,
    displayStatus,
    type,
  } = useMemo(() => normalizePlayer(playerData?.player), [playerData]);

  const toggleDisplayStatus = useCallback(() => {
    const cachePlayer = cacheReadPlayer();
    const currentDisplayStatus = cachePlayer?.displayStatus;

    cacheUpdatePlayer({
      displayStatus:
        currentDisplayStatus === PlayerDisplayStatus.MAXIMIZED
          ? PlayerDisplayStatus.MINIMIZED
          : PlayerDisplayStatus.MAXIMIZED,
    });
  }, []);

  const openCourse = useCallback(
    async ({
      id,
      autoplayType: newAutoplayType,
      initialAudioStatus,
      initialPosition,
      endPosition,
      endPositionPaused,
    }: {
      id: string;
      autoplayType?: PlayerAutoplayType | null;
      initialAudioStatus?: PlayerAudioStatus;
      initialPosition?: number;
      endPosition?: number;
      endPositionPaused?: boolean;
    }) => {
      if (id) {
        const cachePlayer = cacheReadPlayer();
        const currentPlayerType = cachePlayer?.type;
        const currentCourseId = cachePlayer?.courseId;

        if (currentPlayerType === PlayerType.COURSE && currentCourseId === id) {
          cacheUpdatePlayer({ displayStatus: PlayerDisplayStatus.MAXIMIZED });
          return;
        }

        if (currentCourseId) {
          trackMediaEvent('Media Close', currentCourseId);
        }

        const { dailyDurationType } = await getProfile();

        cacheUpdatePlayer({
          audioStatus: initialAudioStatus || PlayerAudioStatus.PLAYING,
          autoplayType: newAutoplayType || null,
          courseId: id,
          dailyMeditationDuration: dailyDurationType || null,
          displayStatus: PlayerDisplayStatus.MAXIMIZED,
          endPosition:
            endPosition && (!initialPosition || endPosition > initialPosition)
              ? endPosition
              : null,
          endPositionPaused: endPositionPaused || null,
          initialPosition: initialPosition || null,
          selftimerDuration: null,
          selftimerInterval: null,
          selftimerStartTime: null,
          type: PlayerType.COURSE,
        });

        updatePlayerCode(id, true);
      }
    },
    [],
  );

  const openSelfTimer = useCallback(
    async (
      duration: number,
      interval: SelfTimerInterval = SelfTimerInterval.NONE,
    ) => {
      if (duration > 0) {
        const cachePlayer = cacheReadPlayer();
        const currentCourseId = cachePlayer?.courseId;

        cacheUpdatePlayer({
          audioStatus: PlayerAudioStatus.PLAYING,
          autoplayType: null,
          courseId: null,
          dailyMeditationDuration: null,
          displayStatus: PlayerDisplayStatus.MAXIMIZED,
          initialPosition: null,
          selftimerDuration: duration,
          selftimerInterval: interval,
          selftimerStartTime: new Date().getTime(),
          type: PlayerType.SELFTIMER,
        });

        if (currentCourseId) {
          await trackMediaEvent('Media Close', currentCourseId);
        }

        trackEvent('Self Timer Start', {
          duration: Math.round(duration / 60),
          intervalChime: SELF_TIMER_INTERVALS[interval].trackerValue,
        });
      }
    },
    [],
  );

  return {
    audioStatus,
    autoplayType,
    close: cacheResetPlayer,
    courseId,
    displayStatus,
    openCourse,
    openSelfTimer,
    toggleDisplayStatus,
    type,
  };
};

export default usePlayer;

export type UsePlayer = ReturnType<typeof usePlayer>;
