import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Root = styled.div`
  color: ${({ theme }) => theme.textLightBg.text};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  section,
  p:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  strong {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`;

export const Title = styled.h1`
  color: inherit;
  font-size: ${({ theme }) => theme.font.heading};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: ${({ theme }) => theme.margin.lg};
  margin-top: ${({ theme }) => theme.margin.xxl};

  ${media.tabletPortrait} {
    margin-top: ${({ theme }) => theme.margin.md};
  }

  ${media.phoneLandscape} {
    margin-top: 0;
  }
`;
