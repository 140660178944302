/* eslint-disable import/prefer-default-export */
export const INTRO_COURSES_BACKGROUND_COLORS: Record<string, string> = {
  One: '#82afe6',
  Two: '#7eade5',
  Three: '#79aae5',
  Four: '#6a9fe5',
  Five: '#5791e2',
  Six: '#4384e0',
  Seven: '#3377da',
  Eight: '#2f6ed5',
  Nine: '#2e65cc',
  Ten: '#2e60c3',
  Eleven: '#3258b9',
  Twelve: '#3554b1',
  Thirteen: '#344da7',
  Fourteen: '#33499c',
  Fifteen: '#314491',
  Sixteen: '#2d4188',
  Seventeen: '#2a3d7f',
  Eighteen: '#263a79',
  Nineteen: '#233973',
  Twenty: '#243972',
  'Twenty One': '#243970',
  'Twenty Two': '#24396e',
  'Twenty Three': '#20365f',
  'Twenty Four': '#20335b',
  'Twenty Five': '#1c3051',
  'Twenty Six': '#182c47',
  'Twenty Seven': '#172b46',
  'Twenty Eight': '#162943',
};
