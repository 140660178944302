import React, { FC } from 'react';
import { Checkmark, Container, ListItem } from './styles';
import { Props } from './types';

const Checklist: FC<Props> = ({
  CheckmarkComponent = Checkmark,
  children,
  className,
}) => (
  <Container className={className}>
    {children.map((child) => (
      <ListItem key={child.props.children}>
        <CheckmarkComponent />
        <span {...child.props} />
      </ListItem>
    ))}
  </Container>
);

export default Checklist;
