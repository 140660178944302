import { Me_me } from 'graphql/generated/Me';
import { UpdateUserProfile_updateMe } from 'graphql/generated/UpdateUserProfile';
import { Login_login } from 'graphql/generated/Login';
import { RedeemCode_redeemMe } from 'graphql/generated/RedeemCode';
import { DailyMeditationDuration } from 'models/DailyMeditationDuration';

export const CANNOT_SHARE_FREE_TIME = ['share', 'groups'];

type UserTypesSupported =
  | Me_me
  | UpdateUserProfile_updateMe
  | Login_login
  | RedeemCode_redeemMe
  | null
  | undefined;

const getDailyDurationType = (dailyDuration: number | null | undefined) => {
  switch (dailyDuration) {
    case 1:
      return DailyMeditationDuration.MIN_10;
    case 2:
    default:
      return DailyMeditationDuration.MIN_20;
  }
};

export const normalizeUser = (user: UserTypesSupported) => {
  const isAnonymous =
    !user || !user.email || user.email === 'trytheapp@wakingup.com';
  const isSubscribed = user?.is_subscribed || false;
  const dailyDurationType = getDailyDurationType(user?.daily_duration_type);

  let canShareFreeMonth: boolean = false;
  if (!isAnonymous && isSubscribed) {
    const isNotCode = user?.subscription?.sub_type
      ? !user.subscription.sub_type.includes('code-')
      : false;
    const isNotException = user?.subscription?.source
      ? !CANNOT_SHARE_FREE_TIME.includes(user.subscription.source.toLowerCase())
      : false;
    canShareFreeMonth = isNotCode && isNotException;
  }

  return {
    // NEW
    activityIntroCourseCompletedCount:
      user?.activity?.intro_course_completed_count || 0,
    activityLongestStreak: user?.activity?.longest_streak || 0,
    activityMindfulDays: user?.activity?.mindful_days || 0,
    activityMindfulMinutes: user?.activity?.minutes || 0,
    activityRedeemedCount: user?.activity?.redeemed_count || 0,
    activityStreakDays: user?.activity?.streak_days || 0,
    activityTotalSessions: user?.activity?.total_sessions || 0,
    avatarUrl: user?.avatar_urls?.thumb_v2 || user?.avatar_urls?.thumb || '',
    canShareFreeMonth,
    city: user?.city || '',
    dailyDurationType,
    id: user?.id || '',
    isAllContentUnlocked:
      user?.unlocked_content_status === 'unlocked' &&
      !!user.activity?.intro_course_completed_count &&
      user.activity?.intro_course_completed_count >= 1,
    email: user?.email || '',
    firstName: user?.first_name || '',
    fullName: user?.full_name || '',
    introPackId: (user?.intro_pack_id || '').toString(),
    isAnonymous,
    isDailyUnlocked: user?.unlocked_content_status === 'unlocked',
    isSubscribed,
    isNew: user?.is_new || false,
    joinedAt: (user?.joined_at as string) || '',
    lastName: user?.last_name || '',
    subscriptionExpiresAt: (user?.subscription?.expires_at as string) || '',
    subscriptionPlanId: user?.subscription?.external_plan_id || '',
    subscriptionRenewIntent: user?.subscription?.renew_intent || '',
    subscriptionSource: user?.subscription?.source || '',
    subscriptionSubtype: user?.subscription?.sub_type || '',
    typename: user?.__typename || 'User',
    unlockedContentStatus: user?.unlocked_content_status || '',
    uuid: user?.uuid || '',

    // OLD
    activity_longest_streak: user?.activity?.longest_streak || 0,
    activity_mindful_days: user?.activity?.mindful_days || 0,
    activity_minutes: user?.activity?.minutes || 0,
    activity_redeemed_count: user?.activity?.redeemed_count || 0,
    activity_streak_days: user?.activity?.streak_days || 0,
    activity_total_sessions: user?.activity?.total_sessions || 0,
    avatar_urls_thumb:
      user?.avatar_urls?.thumb_v2 || user?.avatar_urls?.thumb || null,
    daily_duration_type:
      user?.daily_duration_type || DailyMeditationDuration.MIN_10,
    first_name: user?.first_name || null,
    full_name: user?.full_name || null,
    is_subscribed: user?.is_subscribed || false,
    joined_at: user?.joined_at || null,
    last_name: user?.last_name || null,
    subscription_expires_at: user?.subscription?.expires_at || null,
    subscription_external_plan_id: user?.subscription?.external_plan_id || null,
    subscription_renew_intent: user?.subscription?.renew_intent || null,
    subscription_source: user?.subscription?.source || null,
    subscription_sub_type: user?.subscription?.sub_type || null,

    // IS NOT IN USE
    already_redeemed_trial: user?.already_redeemed_trial || false,
    subscription_plan_change_pending:
      user?.subscription?.plan_change_pending || false,
    type: 'User',
    unlocked_content_status: user?.unlocked_content_status || null,
  };
};

export type User = ReturnType<typeof normalizeUser>;
