import styled from 'styled-components';
import { media } from 'styles/mixins';
import { Props } from './types';

export const Row = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;

export const Stat = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: space-between;
  text-align: center;
`;

export const StatTitle = styled.div`
  font-size: ${({ theme }) => theme.font.textSmall};
  font-weight: ${({ theme }) => theme.font.weight.book};
  letter-spacing: ${({ theme }) => theme.letterSpacing.subtle};
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.margin.xxsm};
`;

export const StatValue = styled.div`
  line-height: 1;
`;

export const StatSeparator = styled.div`
  margin-right: 0.5rem;
  width: 0.5rem;
`;

export const RowSeparator = styled(StatSeparator)`
  ${media.phonePortrait} {
    display: none;
  }
`;

export const Container = styled.div<{
  $bigValues: Props['bigValues'];
  $forceRows: Props['forceRows'];
  $hideSeparators: Props['hideSeparators'];
  $reverseColors: Props['reverseColors'];
  $titlePosition: Props['titlePosition'];
}>`
  align-items: stretch;
  color: ${({ $reverseColors, theme }) =>
    $reverseColors ? theme.colors.snow : theme.textLightBg.textStrong};
  display: flex;
  flex-flow: ${({ $forceRows }) =>
    $forceRows ? 'column nowrap' : 'row nowrap'};
  font-size: 1rem;
  justify-content: flex-start;
  ${Row} {
    &:not(:last-child) {
      margin-bottom: ${({ $forceRows }) => ($forceRows ? '1rem' : '0')};
      ${media.phonePortrait} {
        margin-bottom: 1rem;
      }
    }
  }
  ${Stat} {
    border-right-color: ${({ $reverseColors }) =>
      $reverseColors ? `rgba(255, 255, 255, 0.1)` : `rgba(100, 100, 100, 0.1)`};
    flex-direction: ${({ $titlePosition }) =>
      $titlePosition === 'bottom' ? `column-reverse` : `column`};
    flex-basis: ${({ $forceRows }) => ($forceRows ? '0%' : 'auto')};
    ${media.phonePortrait} {
      flex-basis: 0%;
    }
  }
  ${StatTitle} {
    color: ${({ $reverseColors, theme }) =>
      $reverseColors ? theme.colors.snow : theme.textLightBg.textFaint};
    margin-bottom: ${({ $titlePosition, theme }) =>
      $titlePosition === 'bottom' ? '0' : theme.margin.xxsm};
    margin-top: ${({ $titlePosition, theme }) =>
      $titlePosition === 'bottom' ? theme.margin.xxsm : '0'};
  }
  ${StatValue} {
    font-size: ${({ $bigValues }) => ($bigValues ? '2rem' : '1.2rem')};
    font-weight: ${({ $bigValues }) => ($bigValues ? 'normal' : 'bold')};
    ${media.phonePortrait} {
      font-size: ${({ $bigValues }) => ($bigValues ? '1.6rem' : '1.2rem')};
    }
  }
  ${StatSeparator} {
    border-right: ${({ $hideSeparators }) =>
      $hideSeparators ? 'none' : '2px solid rgba(100, 100, 100, 0.1)'};
  }
  ${RowSeparator} {
    border-right: ${({ $forceRows, $hideSeparators }) =>
      $forceRows || $hideSeparators
        ? 'none'
        : '2px solid rgba(100, 100, 100, 0.1)'};
  }

  ${media.phonePortrait} {
    flex-flow: column nowrap;
  }
`;
