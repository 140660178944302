import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultItem from './Item';

export const Container = styled.div`
  display: grid;
  gap: 1rem 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1.8rem;
  place-items: start;

  ${media.tabletLandscape} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Item = styled(DefaultItem)`
  display: flex;
  height: 100%;
  width: 100%;
  &::before {
    content: '';
    display: inline-block;
    height: 0;
    padding-bottom: calc(100% / 1.5);
    width: 0;
  }
`;
