/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import DefaultPlayerControls from '../../Controls';
import DefaultDurationSwitcher from './DurationSwitcher';

export const PlayerControls = styled(DefaultPlayerControls)``;

export const DurationSwitcher = styled(DefaultDurationSwitcher)<{
  $position: number;
}>`
  margin-top: 1.2rem;
  visibility: ${({ $position }) => ($position < 30 ? 'visible' : 'hidden')};
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100%;
`;
