import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const ChevronDown: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={(size * 265.01053) / 436.68649}
    viewBox="0 0 436.68649 265.01053"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M201.37275 257.98152L7.02975 63.637517c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.6670001c9.357-9.357 24.522-9.375 33.901-.04l154.746 154.0210031 154.745-154.0210031c9.379-9.335 24.544-9.317 33.901.04l22.667 22.6670001c9.373 9.373 9.373 24.569 0 33.941l-194.342 194.344003c-9.373 9.372-24.569 9.372-33.942 0z" />
  </svg>
);

export default memo(ChevronDown);
