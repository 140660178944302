import { useCallback } from 'react';
import { media } from 'styles/mixins';
import { ModalType } from 'graphql/generated/globalTypes';
import useMediaQuery from 'lib/useMediaQuery';
import useModal from 'graphql/hooks/useModal';

const useConnect = () => {
  const isMobile = useMediaQuery(media.tabletPortrait);
  const {
    close,
    openResetAllContentConfirm,
    openResetIntroCoursesConfirm,
    type,
  } = useModal();

  const closeModal = useCallback(() => {
    close();
  }, [close]);

  return {
    closeModal,
    isMobile,
    isOpen: type === ModalType.RESET_CONTENT,
    openResetAllContentConfirm,
    openResetIntroCoursesConfirm,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
