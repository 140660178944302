import React, { FC, memo } from 'react';
import { Container, Slider, Volume, VolumeIcon } from './styles';
import { Props } from './types';
import useLogic from './logic';

const VolumeControls: FC<Props> = ({ className }) => {
  const { displayedVolume, onChangeVolume, onToggleMute } = useLogic();

  return (
    <Container className={className}>
      <Volume onClick={onToggleMute}>
        <VolumeIcon volume={displayedVolume} />
      </Volume>
      <Slider
        max={100}
        min={0}
        onChange={onChangeVolume}
        step={1}
        value={displayedVolume}
      />
    </Container>
  );
};

export default memo(VolumeControls);
