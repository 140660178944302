import React, { FC, memo } from 'react';
import {
  Accept,
  Actions,
  Cancel,
  Container,
  Description,
  Modal,
  Title,
} from './styles';
import useConnect from './connect';

const UpdatePaymentCardModal: FC = () => {
  const { accept, closeModal, isOpen } = useConnect();

  return (
    <Modal hideLogo reverseClose open={isOpen} onClose={closeModal}>
      <Container>
        <Title>Update payment card</Title>
        <Description>
          Are you sure you want to update your payment card?
        </Description>
        <Actions>
          <Cancel onClick={closeModal}>Cancel</Cancel>
          <Accept onClick={accept}>Confirm</Accept>
        </Actions>
      </Container>
    </Modal>
  );
};

export default memo(UpdatePaymentCardModal);
