/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { PACK_DATA_FRAGMENT } from '../fragments';

export const GET_PACKS = gql`
  query GetPacks {
    me {
      id
      is_subscribed
    }
    packs {
      total
      data {
        ...PackData
      }
    }
  }
  ${PACK_DATA_FRAGMENT}
`;
