import React, { FC, memo } from 'react';
import Header from 'components/SectionHeader';
import { HorizontalCarousel } from './styles';
import useConnect from './connect';

const Favorites: FC = () => {
  const {
    favorites,
    handleControlPress,
    isLoading,
    listRef,
    loadTrack,
  } = useConnect();

  if (!isLoading && (!favorites || favorites.length === 0)) {
    return null;
  }

  return (
    <>
      <Header
        title="Favorites"
        actionTitle="View All"
        actionHref="/favorites"
        onControlPress={handleControlPress}
      />
      <HorizontalCarousel
        data={favorites}
        isLoading={isLoading}
        itemCount={favorites?.length || 0}
        outerRef={listRef}
        setTrack={loadTrack}
      />
    </>
  );
};

export default memo(Favorites);
