/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import { SUBSCRIPTION_DATA_FRAGMENT } from './subscription';

export const USER_DATA_FRAGMENT = gql`
  fragment UserData on User {
    appsflyer_id
    activity {
      intro_course_completed_count
      longest_streak
      mindful_days
      minutes
      redeemed_count
      streak_days
      total_sessions
    }
    already_redeemed_trial
    avatar_urls {
      thumb
      thumb_v2
    }
    city
    created_at
    daily_duration_type
    email
    first_name
    full_name
    id
    intro_pack_id
    is_subscribed
    is_new
    joined_at
    last_name
    private_profile
    progress {
      progress_id
      progress_type
      finished_at
      duration_seconds
    }
    subscription {
      ...SubscriptionData
    }
    token
    unlocked_content_status
    uuid
  }
  ${SUBSCRIPTION_DATA_FRAGMENT}
`;
