import React, { FC, memo } from 'react';
import useLogic from './logic';
import { Container, Avatar, Info, FullName, MemberSince, City } from './styles';
import { Props } from './types';

const UserInfo: FC<Props> = ({
  avatarUrl,
  city,
  firstName,
  lastName,
  email,
  joinedAt,
  inline,
  shadow,
  className,
}) => {
  const { fullName, joinDate } = useLogic({ firstName, joinedAt, lastName });

  return (
    <Container $inline={inline} className={className}>
      <Avatar
        shadow={shadow}
        src={avatarUrl}
        email={email}
        firstName={firstName}
        lastName={lastName}
      />
      <Info $inline={inline}>
        <FullName
          data-cypress-id="account-view-username"
          data-jest-id="username"
        >
          {fullName}
        </FullName>
        {joinDate && <MemberSince>Member since {joinDate}</MemberSince>}
        {city && <City>{city}</City>}
      </Info>
    </Container>
  );
};

export default memo(UserInfo);
