import { Link as DefaultLink } from 'react-router-dom';
import styled from 'styled-components';
import { buttonHover, media } from 'styles/mixins';
import DefaultGitBox from 'components/Icons/GiftBox';
import DefaultChevronRightIcon from 'components/Icons/ChevronRight';

export const Card = styled.div<{ $canShareFreeMonth: boolean }>`
  ${buttonHover};
  border-radius: ${({ theme }) => theme.radius.md};
  padding: 0 ${({ theme }) => theme.pad.md};
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 4rem;
  background-color: ${({ $canShareFreeMonth, theme }) =>
    $canShareFreeMonth ? theme.colors.darkblue : theme.colors.blue800};
  cursor: pointer;
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
`;

export const LinkContainer = styled.div``;

export const Title = styled.span<{
  $reduceInMobile?: boolean;
  $canShareFreeMonth: boolean;
}>`
  color: ${({ theme, $canShareFreeMonth }) =>
    $canShareFreeMonth ? theme.colors.beige : theme.colors.snow};
  font-size: ${({ theme }) => theme.font.textSuperMegaBig};
  line-height: 1;

  ${media.phonePortrait} {
    font-size: ${({ $reduceInMobile, theme }) =>
      $reduceInMobile ? theme.font.textSuperBig : theme.font.textSuperMegaBig};
  }
`;

export const PaperPlaneIcon = styled(DefaultGitBox).attrs(() => ({
  size: 30,
}))`
  margin-left: 0.5rem;
`;

export const ChevronRightIcon = styled(DefaultChevronRightIcon).attrs(() => ({
  size: 12,
}))`
  color: ${({ theme }) => theme.colors.snow};
  margin-left: 0.7rem;
  transform: translateY(2px);
`;
