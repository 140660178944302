import amplitude from 'amplitude-js';
import { ApiConfig, DevConfig } from 'config';
import { AnalyticsEvent } from '../types';

export const setUpAmplitude = () => {
  amplitude.getInstance().init(ApiConfig.AMPLITUDE_KEY);
};

export const trackAmplitudeEvent = (
  event: AnalyticsEvent,
  data?: Record<string, unknown>,
) => {
  amplitude
    .getInstance()
    .logEvent(event, data, (responseCode, responseBody) => {
      // ToDo: If 'data' is undefined, we should probably show a modal telling the user
      // ToDo: he has to turn off ad-blockers & other anti-tracking tools
      if (DevConfig.amplitudeLogging) {
        // eslint-disable-next-line no-console
        console.log(event, data, responseCode, responseBody);
        /* console.log(
        `Amplitude - Sending Event: ${data.event} · Properties\n`,
        data.properties,
      ); */
      }
    });
};

export const setAmplitudeUserProperties = (
  userProps: Record<string, string>,
) => {
  const client = amplitude.getInstance();
  client.setUserProperties(userProps);
};

export const registerAmplitudeUser = (userId: string) => {
  const client = amplitude.getInstance();
  client.setUserId(userId);
};

export const unregisterAmplitudeUser = () => {
  const client = amplitude.getInstance();
  client.setUserId(null);
};
