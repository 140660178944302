import styled from 'styled-components';
import DefaultSlider from 'react-slick';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const Slider = styled(DefaultSlider)`
  width: 700px;

  .slick-dots {
    li,
    &.slick-active {
      button {
        &::before {
          color: white;
        }
      }
    }
  }
`;
