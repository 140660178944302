import { useCallback, useMemo } from 'react';
import useVolume from 'lib/useVolume';

const useLogic = () => {
  const { isMuted, setIsMuted, setVolume, volume } = useVolume();

  const displayedVolume = useMemo(() => (isMuted ? 0 : volume), [
    isMuted,
    volume,
  ]);

  const onChangeVolume = useCallback(
    (e, vol: number | number[]) => {
      const newVolume = Array.isArray(vol) ? vol[0] : vol;
      setIsMuted(false);
      setVolume(newVolume);
    },
    [setIsMuted, setVolume],
  );

  const onToggleMute = useCallback(() => {
    setIsMuted((v) => !v);
  }, [setIsMuted]);

  return { displayedVolume, onChangeVolume, onToggleMute };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
