import { useEffect, useMemo, useCallback } from 'react';
import { trackMediaEvent } from 'services/analytics';
import { media } from 'styles/mixins';
import { ModalType } from 'graphql/generated/globalTypes';
import useMediaQuery from 'lib/useMediaQuery';
import useModal from 'graphql/hooks/useModal';
import useCourse from 'graphql/hooks/useCourse';

const useConnect = () => {
  const isMobile = useMediaQuery(media.tabletPortrait);
  const { close, courseNotesPayload, type } = useModal();
  const { course } = useCourse(courseNotesPayload?.courseId);

  const { courseId, notesSubtitle, notesText, notesTitle } = useMemo(
    () => ({
      courseId: course?.id,
      notesSubtitle: course?.note_subtitle || '',
      notesText: course?.note_text || '',
      notesTitle: course?.note_title || '',
    }),
    [course],
  );

  useEffect(() => {
    if (courseId) {
      trackMediaEvent('Media Notes View', courseId);
    }
  }, [courseId]);

  const closeModal = useCallback(() => {
    close(200);
  }, [close]);

  return {
    closeModal,
    isMobile,
    isOpen: type === ModalType.COURSE_NOTES,
    subtitle: notesSubtitle,
    text: notesText,
    title: notesTitle,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
