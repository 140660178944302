import { useCallback, useEffect } from 'react';
import { Token } from 'react-stripe-checkout';
import { trackEvent } from 'services/analytics';
import { Props } from './types';

const useLogic = ({
  isPublic,
  onToken,
  planId,
}: {
  isPublic: boolean;
  onToken: Props['onToken'];
  planId: Props['plan']['id'];
}) => {
  useEffect(() => {
    trackEvent('Purchase Display Checkout Options', { ID: planId });
  }, [planId]);

  const handleToken = useCallback(
    async (token: Token) => {
      const user = await onToken(token, planId, isPublic);
      if (user) {
        trackEvent('Purchase Completed');
      }
    },
    [isPublic, onToken, planId],
  );

  const handleSubscriptionClick = useCallback(() => {
    trackEvent('Purchase Entered Subscription Flow');
  }, []);

  return { handleSubscriptionClick, handleToken };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
