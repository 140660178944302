import { useCallback } from 'react';
import useModal from 'graphql/hooks/useModal';
import { Props } from './types';

const useConnect = (
  courseId: Props['courseId'],
  trackHash: Props['trackHash'],
) => {
  const { openShareLink } = useModal();

  const shareLink = useCallback(() => {
    if (trackHash) {
      openShareLink({
        courseId,
        title: 'Share this course',
        description:
          'Share this link with anyone you feel would benefit from this Waking Up course',
        link: `https://dynamic.wakingup.com/course/${trackHash}`,
      });
    }
  }, [courseId, openShareLink, trackHash]);

  return { shareLink };
};

export default useConnect;

export type UseLogic = ReturnType<typeof useConnect>;
