import { useCallback, useEffect, MouseEvent } from 'react';
import { LogicProps } from './types';

const useLogic = ({ anchorEl, closeMenu }: LogicProps) => {
  useEffect(() => {
    const handleScroll = () => {
      if (anchorEl) closeMenu();
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [anchorEl, closeMenu]);

  const handleClose = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      closeMenu();
    },
    [closeMenu],
  );

  return { handleClose };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
