import LogoBase from 'components/Logo';
import styled from 'styled-components';
import { ZIndex } from 'styles/metrics';
import { media } from 'styles/mixins';
import { motion } from 'framer-motion';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { opacity: 0 },
}))`
  background-color: #f4f4f8;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 600px;
`;

export const Logo = styled(LogoBase).attrs(() => ({
  lightBg: true,
}))`
  margin: 0 auto 2rem;
  max-height: initial;
  max-width: 90%;
  width: 23.4375rem;

  ${media.phonePortrait} {
    min-width: auto;
    width: 18.75rem;
  }
`;

export const Content = styled.main`
  color: ${({ theme }) => theme.textLightBg.text};
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: ${({ theme }) => theme.constraints.contentWidth};
  padding: 1rem;
  z-index: ${ZIndex.MAIN};
`;
