import { useState, useEffect } from 'react';
import { ambientRoomTone } from 'assets/audio/ambientnoises';
import { finalChime, initialChime, intervalChime } from 'assets/audio/chimes';
import { trackEvent } from 'services/analytics';
import { SelfTimerInterval } from 'models/SelfTimerInterval';
import { PlayerAudioStatus } from 'graphql/generated/globalTypes';
import useMeActions from 'graphql/hooks/useMeActions';
import usePlayerSelfTimer from 'graphql/hooks/usePlayerSelfTimer';

const useConnect = () => {
  const { addProgress } = useMeActions();
  const {
    audioStatus,
    close,
    duration,
    interval,
    intervalMinutes,
    middle,
    setAudioStatus,
    selftimerStartTime,
  } = usePlayerSelfTimer();

  const [position, setPosition] = useState<number>(0);

  useEffect(() => {
    setPosition(0);
  }, [selftimerStartTime]);

  useEffect(() => {
    if (duration && audioStatus === PlayerAudioStatus.PLAYING) {
      if (position === 0) {
        initialChime.play();
      } else if (position === duration - 5) {
        finalChime.play();
      } else if (position === duration) {
        setAudioStatus(PlayerAudioStatus.STOP);

        trackEvent('Self Timer Complete');

        addProgress({
          duration_seconds: duration,
          finished_at: new Date(),
          progress_id: '0',
          progress_type: 'SelfTimer',
        });
        setTimeout(() => close(), 750);
      } else if (
        (interval === SelfTimerInterval.HALFWAY && position === middle) ||
        (intervalMinutes && position % (intervalMinutes * 60) === 0)
      ) {
        intervalChime.play();
      }
    }
  }, [
    addProgress,
    audioStatus,
    close,
    duration,
    interval,
    intervalMinutes,
    middle,
    position,
    setAudioStatus,
  ]);

  useEffect(() => {
    if (audioStatus === PlayerAudioStatus.PLAYING) {
      ambientRoomTone.play();

      const handleInterval = () => {
        setPosition((prevPosition) => prevPosition + 1);
      };

      const intervalId = setInterval(handleInterval, 1000);
      return () => {
        ambientRoomTone.stop();
        clearInterval(intervalId);
      };
    }
  }, [audioStatus]);

  return {
    audioStatus,
    closePlayer: close,
    duration,
    position,
    setAudioStatus,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
