import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Token } from 'react-stripe-checkout';
import { trackEvent } from 'services/analytics';
import { isShortVersion } from 'models/Plan';
import useSubscription from 'graphql/hooks/useSubscription';

const useConnect = (code?: string) => {
  const variables = code ? { code } : undefined;
  const { plans, loading, create } = useSubscription({ variables });
  const { push } = useHistory();

  const isShort = isShortVersion(code);

  const handleToken = useCallback(
    async (token: Token, planId) => {
      try {
        const user = await create(token, planId, true, code);
        if (user) {
          trackEvent('Purchase Completed');
        }
        push('/subscription/success', { user });
      } catch (error) {
        push('/subscription/error');
      }
    },
    [code, create, push],
  );

  return { handleToken, isLoading: loading, isShort, plans };
};

export default useConnect;
