import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Rewind: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    height={(size * 17.776142) / 19.56431}
    viewBox="0 0 19.56431 17.776142"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g fill="none" fillRule="evenodd" stroke="currentColor">
      <path
        strokeWidth="1.77777779"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.89813925 1.77617859v5.33333333h5.33333333"
      />
      <path
        d="M3.12933055 11.5456517c1.1840483 3.360574 4.4480171 5.533049 8.0053677 5.328317 3.557351-.204731 6.550507-2.737314 7.341158-6.2115352.790651-3.4742213-.811805-7.0525432-3.930262-8.77636577C11.42713625.16224517 7.54445135.70848745 5.02273205 3.225808L.89813925 7.1012907"
        strokeWidth="1.77777779"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g fill="currentColor">
      <path d="M9.27502815 11.4428457h-.8391111V8.2855119h-1.1164445v-.5831111c.6613334-.014222 1.1164445-.384 1.216-.8675555h.7395556zm.7466667-1.2728893l.8035555-.2062223c.035556.4906667.3982219.8604445.9315559.8604445.483555 0 .874666-.3271111.874666-.8391111 0-.5688889-.419555-.8533334-.888889-.8533334-.327111 0-.604444.1351112-.768.32l-.8035551-.2915555.5688889-2.3253333h2.5244442v.7466666h-1.948444l-.284445 1.1662222c.199111-.2062222.554667-.32.881778-.32.917333 0 1.578667.5688889 1.578667 1.536 0 .8675556-.668445 1.5928886-1.735111 1.5928886-.9955559 0-1.6782226-.661333-1.7351114-1.3866663z" />
    </g>
  </svg>
);

export default memo(Rewind);
