import { useRouteMatch } from 'react-router';
import { media } from 'styles/mixins';
import useMediaQuery from 'lib/useMediaQuery';

const NO_NAVIGATION_ROUTES = ['/mobile/subscription'];

const useConnect = () => {
  const isTabletPortrait = useMediaQuery(media.tabletPortrait);
  const matchNoNavigationRoute = !!useRouteMatch(NO_NAVIGATION_ROUTES);

  return {
    isTabletPortrait,
    matchNoNavigationRoute,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
