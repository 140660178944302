import { gql } from '@apollo/client';
import { COURSE_DATA_FRAGMENT } from '../fragments';

export const GET_COURSES = gql`
  query GetCourses($isDaily: Boolean, $dailyDate: String, $id: ID, $ids: [ID]) {
    courses(is_daily: $isDaily, daily_date: $dailyDate, id: $id, ids: $ids) {
      data {
        ...CourseData
      }
    }
  }
  ${COURSE_DATA_FRAGMENT}
`;

export const GET_DAILY = gql`
  query GetDaily($date: String) {
    me {
      id
      is_subscribed
      unlocked_content_status
    }
    daily(date: $date) {
      ...CourseData
    }
  }
  ${COURSE_DATA_FRAGMENT}
`;
