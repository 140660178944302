import styled, { css } from 'styled-components';
import { circularIconDimensions } from 'styles/mixins';
import DefaultStretchedImage from 'components/StretchedImage';
import DefaultImage from 'components/Image';
import DefaultLock from 'components/Icons/Lock';
import DefaultStar from 'components/Icons/Star';
import DefaultPlayButton from 'components/PlayButton';

export const NumberContainer = styled.div<{ $backgroundColor: string }>`
  align-items: center;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({ theme }) => theme.radius.md};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  position: relative;
  & > * {
    z-index: 1;
  }
`;

export const Number = styled.div`
  font-size: 3rem;
  line-height: 1;
`;

export const StretchedImage = styled(DefaultStretchedImage)<{
  $backgroundColor: string;
}>`
  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};
`;

export const Image = styled(DefaultImage)`
  border-radius: ${({ theme }) => theme.radius.md};
  position: relative;
`;

export const PermissionOverlay = styled.div<{ $showOnHover?: boolean }>`
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: inherit;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const PlayOverlay = styled(PermissionOverlay)<{
  $showOnHover?: boolean;
}>`
  opacity: 0;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export const LockIcon = styled(DefaultLock)`
  color: ${({ theme }) => theme.colors.snow};
`;

export const StarIcon = styled(DefaultStar)`
  fill: ${({ theme }) => theme.colors.snow};
`;

export const PlayButton = styled(DefaultPlayButton).attrs(() => ({
  clear: true,
}))`
  ${circularIconDimensions};
`;
