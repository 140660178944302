import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';
import { darkBackground, media } from 'styles/mixins';
import DefaultUserInfo from 'components/UserInfo';
import DefaultUserStats from 'components/UserStats';
import DefaultIconButton from 'components/IconButton';
import DefaultCloseIcon from 'components/Icons/Close';
import DefaultChevronRight from 'components/Icons/ChevronRight';
import DefaultTimer from '../../Timer';

export const Container = styled(Drawer).attrs(() => ({
  anchor: 'right',
  transitionDuration: 300,
}))`
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .MuiDrawer-paperAnchorRight {
    left: 0;
  }
`;

export const CloseButton = styled(DefaultIconButton)`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.snow};
  display: block;
  height: 2.25rem;
  outline: none;
  position: absolute;
  right: 1.5rem;
  top: 1.25rem;
  transition: opacity 0.15s ease 0.05s;
`;

export const CloseIcon = styled(DefaultCloseIcon)`
  height: 1.25rem;
  width: 1.25rem;
`;

export const Info = styled.div`
  ${darkBackground};
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  line-height: 1.5rem;
  padding: ${({ theme }) => `${theme.pad.xl} ${theme.pad.lg}`};
`;

export const UserInfo = styled(DefaultUserInfo)`
  align-self: flex-start;
  color: ${({ theme }) => theme.colors.snow};
  margin-left: 1rem;
`;

export const UserStats = styled(DefaultUserStats)`
  flex: 1 1 auto;
  margin: 1.6rem 0 0.4rem 0;
  ${media.tabletPortrait} {
    width: 100%;
  }
`;

export const Links = styled.div``;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.font.textSuperSmall};
  text-transform: uppercase;
`;

export const Link = styled(NavLink)`
  align-items: center;
  border-bottom: 1px solid #aaaaaa;
  color: ${({ theme }) => theme.textLightBg.text};
  cursor: default;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0.8rem 2.2rem;
  text-decoration: none;

  ${media.phonePortrait} {
    padding: 0.8rem 1.8rem;
  }
`;

export const ChevronRight = styled(DefaultChevronRight).attrs(() => ({
  size: 7.5,
}))`
  color: ${({ theme }) => theme.textLightBg.textLight};
`;

export const Timer = styled(DefaultTimer)`
  align-self: flex-start;
  margin: 1.6rem 0 0 2.2rem;
  padding-left: 1.4rem;
  padding-right: 2rem;

  ${media.phonePortrait} {
    align-self: stretch;
    margin: auto 2.2rem 2rem 2.2rem;
  }
`;
