import React, { FC, memo, useCallback } from 'react';
import { trackEvent } from 'services/analytics';
import { DailyMeditationDuration } from 'models/DailyMeditationDuration';
import { Switcher } from './styles';
import { Props } from './types';

const TrackOptions: FC<Props> = ({
  className,
  duration,
  onChangeDuration = () => null,
}) => {
  const setTo10Min = useCallback(() => {
    onChangeDuration(DailyMeditationDuration.MIN_10);
    trackEvent('Toggle Daily Duration', { value: 10 });
  }, [onChangeDuration]);

  const setTo20Min = useCallback(() => {
    onChangeDuration(DailyMeditationDuration.MIN_20);
    trackEvent('Toggle Daily Duration', { value: 20 });
  }, [onChangeDuration]);

  if (!duration) {
    return null;
  }

  return (
    <Switcher
      className={className}
      dark
      isLeft={duration === DailyMeditationDuration.MIN_10}
      leftText="10 min"
      onClickLeft={setTo10Min}
      onClickRight={setTo20Min}
      rightText="20 min"
    />
  );
};

export default memo(TrackOptions);
