import React, { FC, memo } from 'react';
import { createPortal } from 'react-dom';
import { Props } from './types';
import {
  Container,
  Favorite,
  Item,
  NotesIcon,
  ShareIcon,
  Text,
  UnplayedIcon,
} from './styles';
import useLogic from './logic';

const Menu: FC<Props> = ({
  anchorEl,
  closeMenu,
  isFavorite,
  menuId,
  onMarkAsUnplayed,
  onOpenNotes,
  onOpenShare,
  onToggleFavorite,
  showFavorite,
  showMarkAsUnplayed,
  showNotes,
  showShare,
}) => {
  const { handleClose } = useLogic({ anchorEl, closeMenu });

  return createPortal(
    <Container
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
      disableRestoreFocus
      disableScrollLock
      getContentAnchorEl={null}
      id={menuId}
      onClose={handleClose}
      open={Boolean(anchorEl)}
      transformOrigin={{ vertical: 'center', horizontal: 'right' }}
    >
      {showFavorite && (
        <Item onClick={onToggleFavorite}>
          <Favorite isFavorite={isFavorite} />
          <Text>{isFavorite ? 'Remove From Favorites' : 'Favorite'}</Text>
        </Item>
      )}
      {showShare && (
        <Item onClick={onOpenShare}>
          <ShareIcon />
          <Text>Share Content</Text>
        </Item>
      )}
      {showMarkAsUnplayed && (
        <Item onClick={onMarkAsUnplayed}>
          <UnplayedIcon />
          <Text>Mark Unplayed</Text>
        </Item>
      )}
      {showNotes && (
        <Item onClick={onOpenNotes}>
          <NotesIcon />
          <Text>More Info</Text>
        </Item>
      )}
    </Container>,
    document.body,
  );
};

export default memo(Menu);
