import { gql } from '@apollo/client';
import { STRIPE_SOURCE_FRAGMENT } from '../fragments';

export const GET_SHOPPER_TOKEN = gql`
  query GetShopperToken {
    getShopperToken {
      token
    }
  }
`;

export const GET_STRIPE_SOURCE = gql`
  query GetStripeSource {
    getStripeSources {
      ...StripeSourceData
    }
  }
  ${STRIPE_SOURCE_FRAGMENT}
`;

export const SUBSCRIPTION_PLANS = gql`
  query SubscriptionPlans($code: String) {
    subscriptionPlans(code: $code) {
      amount
      id
      interval
      nickname
      originalAmount
    }
  }
`;
