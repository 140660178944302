import React, { FC, memo, useCallback } from 'react';
import { PlayerAudioStatus } from 'graphql/generated/globalTypes';
import { Button, Container, Loading, PauseIcon, PlayIcon } from './styles';
import { Props } from './types';

// @ts-ignore
window.startPlayer = () => {
  const button = document.getElementById('wu-player-play-button');
  if (button) {
    button.click();
  }
};

const ControlButton: FC<Props> = ({
  audioStatus = PlayerAudioStatus.STOP,
  className,
  isLoading = false,
  onClick = () => null,
}) => {
  const changeAudioStatus = useCallback(() => {
    if (audioStatus === PlayerAudioStatus.PLAYING) {
      onClick(PlayerAudioStatus.STOP);
    } else if (audioStatus === PlayerAudioStatus.STOP) {
      onClick(PlayerAudioStatus.PLAYING);
    }
  }, [onClick, audioStatus]);

  return (
    <Container className={className} $isLoading={isLoading}>
      <Button id="wu-player-play-button" onClick={changeAudioStatus}>
        {audioStatus === PlayerAudioStatus.STOP && <PlayIcon />}
        {audioStatus === PlayerAudioStatus.PLAYING && <PauseIcon />}
      </Button>
      {isLoading && <Loading />}
    </Container>
  );
};

export default memo(ControlButton);
