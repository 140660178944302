import React, { FC, useEffect } from 'react';
import useWindowSize from 'lib/useWindowSize';
import {
  ButtonsBar,
  ChevronButton,
  ChevronIcon,
  Container,
  Content,
  FullScreenOverlay,
} from './styles';
import { Props } from './types';

const PlayerWrapper: FC<Props> = ({
  children,
  className,
  isMaximized,
  toggleDisplayStatus,
}) => {
  const windowSize = useWindowSize();

  useEffect(() => {
    if (isMaximized) {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'auto';
      };
    }
  }, [isMaximized]);

  return (
    <>
      {isMaximized && <FullScreenOverlay />}
      <Container
        $isMaximized={isMaximized}
        $wWidth={windowSize.width}
        $wHeight={windowSize.height}
        className={className}
      >
        <ButtonsBar>
          <ChevronButton onClick={toggleDisplayStatus}>
            <ChevronIcon />
          </ChevronButton>
        </ButtonsBar>
        <Content>{children}</Content>
      </Container>
    </>
  );
};

export default PlayerWrapper;
