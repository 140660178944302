import DefaultButton from 'components/Button';
import DefaultModal from 'components/Modal';
import { Content as ModalContent } from 'components/Modal/styles';
import styled from 'styled-components';
import { darkBackgroundWithGradient, media } from 'styles/mixins';

export const MODAL_WIDTH_DESKTOP = '28rem';
const MODAL_HEIGHT_DESKTOP = '42rem';

export const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${MODAL_WIDTH_DESKTOP};
  padding: ${({ theme }) => `${theme.pad.xxl} ${theme.pad.md}`};
  ${media.phoneLandscape} {
    padding: ${({ theme }) => `${theme.pad.xxl}`};
  }
`;

export const Description = styled.p`
  text-align: center;
  letter-spacing: 0.0625rem;
  line-height: 1.1;
  font-weight: ${({ theme }) => theme.font.weight.book};
  font-size: ${({ theme }) => theme.font.textSuperBig};
  margin-bottom: 2.5rem;
  ${media.phoneLandscape} {
    margin-top: 1rem;
  }
`;

export const Button = styled(DefaultButton)`
  &&& {
    border: solid 1px ${({ theme }) => theme.colors.snow};
    margin-bottom: 2rem;
    padding: 1rem;
    .MuiButton-label {
      letter-spacing: 0.0625rem;
      font-weight: ${({ theme }) => theme.font.weight.book};
    }
  }
`;

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    ${media.phoneLandscape} {
      border-radius: 0;
    }
    ${media.phonePortrait} {
      bottom: 0;
      left: 0;
      position: fixed;
      right: 0;
      top: 0;
    }
  }
  ${ModalContent} {
    ${darkBackgroundWithGradient};
    background-position-y: center;
    background-size: auto 100.5%;

    color: ${({ theme }) => theme.colors.snow};
    height: ${MODAL_HEIGHT_DESKTOP};
    width: ${MODAL_WIDTH_DESKTOP};

    ${media.phoneLandscape} {
      height: 100vh;
      width: 100vw;
      max-height: 100vh;
      max-width: none;
      margin: 0;
      padding: 0;
    }

    @media (max-height: 500px) {
      background-size: cover;
    }
  }
`;

export const Options = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
