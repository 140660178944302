import { gql } from '@apollo/client';
import { USER_DATA_FRAGMENT, STRIPE_SOURCE_FRAGMENT } from '../fragments';

export const CREATE_SUBSCRIPTION = gql`
  mutation CreateSubscription(
    $email: String
    $plan_id: ID!
    $token: SubscribeTokenInput!
    $promocode: String
  ) {
    subscribeStripe(
      email: $email
      data: { stripe_token: $token, plan_id: $plan_id }
      promocode: $promocode
    ) {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($plan_id: String!) {
    updateStripeSubscription(plan_id: $plan_id) {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const CANCEL_SUBSCRIPTION = gql`
  mutation CancelSubscription {
    cancelStripeSubscription {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const REACTIVATE_SUBSCRIPTION = gql`
  mutation ReactivateSubscription {
    reactivateStripeSubscription {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const REDEEM_CODE = gql`
  mutation RedeemCode($code: String!, $email: String) {
    redeemMe(code: $code, email: $email) {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const CREATE_SHARED_TOKEN = gql`
  mutation CreateSharedToken {
    createSharedToken {
      purpose
      redeemed_by
      shared_by
      token
    }
  }
`;

export const VALIDATE_SHARED_TOKEN = gql`
  mutation ValidateSharedToken($token: String!) {
    validateSharedToken(token: $token) {
      token
    }
  }
`;

export const UPDATE_STRIPE_SOURCE = gql`
  mutation UpdateStripeSource($source_id: String!) {
    updateStripeSource(source_id: $source_id) {
      ...StripeSourceData
    }
  }
  ${STRIPE_SOURCE_FRAGMENT}
`;

export const PROCESS_OFFER = gql`
  mutation ProcessOffer($code: String!) {
    processOffer(code: $code) {
      title
      message
      approved
    }
  }
`;
