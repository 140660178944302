import { css } from 'styled-components';
import bg from 'assets/images/background.svg';
import keysOf from 'lib/keysOf';
import { screenSizes } from './metrics';

const maxWidthQuery = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;

export const media = keysOf(screenSizes).reduce(
  (acc, key) => ({
    ...acc,
    [key]: maxWidthQuery(screenSizes[key]),
  }),
  {} as { [key in keyof typeof screenSizes]: string },
);

export const getQuery = (mediaQuery: string) => {
  const matches = mediaQuery.match(/@media\s+(.+)/);
  return matches ? matches[1] : '';
};

export const circularIconDimensions = css`
  height: 2.25rem;
  width: 2.25rem;
`;

export const prettyScrollbar = ({
  margin,
  vertical = true,
  size = '0.4rem',
}: {
  margin?: string;
  vertical?: boolean;
  size?: string;
}) => css`
  scrollbar-width: ${size};
  scrollbar-color: ${({ theme }) => theme.textSuperFaint} transparent;

  &::-webkit-scrollbar {
    ${vertical
      ? css`
          width: ${size};
        `
      : css`
          height: ${size};
        `};
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.textLight};
    ${margin &&
    css`
      margin: ${margin};
    `};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.textSuperFaint};
    border-radius: 4px;
  }
`;

export const darkBackground = css`
  background-color: ${({ theme }) => theme.colors.blue900};
  background-image: url('${bg}');
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const darkBackgroundWithGradient = css`
  background-color: ${({ theme }) => theme.colors.blue900};
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6587009803921569) 0%,
      rgba(0, 0, 0, 0.5578606442577031) 12%,
      rgba(0, 0, 0, 0.3169642857142857) 22%,
      rgba(0, 0, 0, 0) 40%
    ),
    url('${bg}');
  background-position-x: center;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const buttonHover = css`
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 100%;
      height: 2px;
      width: 100%;
    }

    &:active {
      transform: translateY(-0.5px);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }
  }
`;

export const dangerButtonStyles = css`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.error};
  cursor: pointer;
  font-size: ${({ theme }) => theme.font.textNotSoSmall};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1.4;
  outline: none;
  position: relative;
  transition: all 0.2s ease;

  &::before {
    background-color: ${({ theme }) => theme.colors.error};
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    transform-origin: 100% 0;
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
    width: 100%;
  }

  &:hover {
    &::before {
      transform-origin: 0 0;
      transform: scaleX(1);
    }
  }

  &:active {
    transform: scaleX(0.97);
  }
`;
