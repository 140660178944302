import { DocumentNode } from 'graphql';
import { apolloClient } from 'services/api';
import { UserData } from 'graphql/generated/UserData';
import { CourseData } from 'graphql/generated/CourseData';

function cacheUpdateFragment<T extends UserData | CourseData>(
  fragment: DocumentNode,
  data: Pick<T, 'id' | '__typename'> & Partial<Omit<T, 'id'>>,
) {
  const id = `${data.__typename}:${data.id}`;
  const cacheData = apolloClient.readFragment<T>({
    fragment,
    id,
  });

  if (cacheData) {
    apolloClient.writeFragment<T>({
      fragment,
      id,
      data: {
        ...cacheData,
        ...data,
      },
    });
  }
}

export default cacheUpdateFragment;
