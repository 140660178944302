import React, { FC, memo } from 'react';
import { getInitials } from './logic';
import { Container, Letter } from './styles';
import { Props } from './types';

// ToDo: Add a 'size' prop & calculate the 'Letter' size based on it =).
const Avatar: FC<Props> = ({
  src,
  firstName,
  lastName,
  email = 'W',
  ...rest
}) => {
  // If the avatar URL is relative, it means that the avatar is missing
  // We show the initials because relative URLS don't work...
  const avatarMissing = !src || src[0] === '/';

  if (avatarMissing) {
    const letters = getInitials(firstName, lastName, email);
    return (
      <Container {...rest}>
        <Letter>{letters}</Letter>
      </Container>
    );
  }

  return <Container src={src} {...rest} />;
};

export default memo(Avatar);
