import React, { FC } from 'react';
import Loader from './Loader';
import { Container, Item } from './styles';
import { Props } from './types';

const SubpacksList: FC<Props> = ({
  className,
  courses = [],
  currentUrl,
  isLoading,
  setTrack,
  subPacks = [],
}) => {
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container className={className}>
      {subPacks.map((subPack) => (
        <Item
          key={subPack.id}
          courseId={subPack.id}
          currentUrl={currentUrl}
          imageUrl={subPack.imageUrl}
          status={subPack.permission}
          title={subPack.title}
          type="subpack"
        />
      ))}
      {courses.map((course) => (
        <Item
          key={course.id}
          courseId={course.id}
          currentUrl={currentUrl}
          imageUrl={course.imageUrl}
          onPlay={setTrack}
          status={course.permission}
          title={course.title}
          type="course"
        />
      ))}
    </Container>
  );
};

export default SubpacksList;
