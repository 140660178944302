import React, { FC } from 'react';
import { DevConfig } from 'config';
import { openPurchaseDialog } from 'services/lightrail';
import { toggleHelpScout } from 'services/helpscout';
import ChevronButton from 'components/ChevronButton';
import SectionHeader from 'components/SectionHeader';
import DailyDurationModal from 'containers/Modals/DailyDuration';
import AddMeditationTimeModal from 'containers/Modals/AddMeditationTime';
import StripePaymentModal from 'containers/Modals/StripePayment';
import {
  Content,
  Loader,
  Container,
  ChevronButtonWithMargin,
  Link,
  Links,
  LogoutLink,
  GroupHeader,
  Progress,
  ProgressText,
  UserInfo,
  UserStats,
} from './styles';
import { Props } from './types';
import useConnect from './connect';

const ProfilePage: FC<Props> = ({ match: { path } }) => {
  const {
    addProgress,
    clickSubscription,
    isAnonymous,
    isMobile,
    loading,
    logOut,
    openAddMeditationTime,
    openDailyDuration,
    openOnboarding,
    openResetAllContentConfirm,
    openResetContent,
    openResetIntroCoursesConfirm,
    openStripePayment,
    profile,
    updateProfile,
  } = useConnect();

  return (
    <>
      <SectionHeader
        data-jest-id="section-header"
        testId="account-header"
        title="Settings"
        actionTitle={!isAnonymous ? 'Edit Profile' : undefined}
        actionHref={!isAnonymous ? `${path}/edit` : undefined}
      />
      <Content>
        <Container data-jest-id="account-view">
          <UserInfo
            shadow
            avatarUrl={profile.avatarUrl}
            firstName={profile.firstName}
            lastName={profile.lastName}
            email={profile.email}
            joinedAt={profile.joinedAt}
            city={profile.city}
          />
          {profile.isSubscribed && (
            <UserStats
              currentStreak={profile.activityStreakDays}
              longestStreak={profile.activityLongestStreak}
              mindfulDays={profile.activityMindfulDays}
              mindfulMinutes={profile.activityMindfulMinutes}
              shares={profile.activityRedeemedCount}
              totalSessions={profile.activityTotalSessions}
            />
          )}
          {loading && (
            <Loader>
              <Progress />
              <ProgressText>Getting new data ...</ProgressText>
            </Loader>
          )}
          <Links data-cypress-id="account-view-links">
            <GroupHeader>GENERAL</GroupHeader>
            {!isAnonymous && (
              <>
                <ChevronButtonWithMargin
                  onClick={openDailyDuration}
                  title="Daily Meditation Duration"
                />
                <ChevronButtonWithMargin
                  onClick={openAddMeditationTime}
                  title="Add Meditation Time"
                />
                <Link to="/account/subscription">
                  <ChevronButton
                    data-jest-id="subscription"
                    onClick={clickSubscription}
                  >
                    {profile.isSubscribed
                      ? 'Manage Subscription'
                      : 'Subscription'}
                  </ChevronButton>
                </Link>
              </>
            )}
            <ChevronButtonWithMargin
              data-jest-id="gift-card"
              onClick={openPurchaseDialog}
              title="Purchase Gift Card"
            />
            {!isAnonymous && (
              <>
                <Link to="/gifts/redeem">
                  <ChevronButton title="Redeem Gift Card" />
                </Link>
                <Link to="/promotions/redeem">
                  <ChevronButton title="Redeem Promo Code" />
                </Link>
                {isMobile && (
                  <ChevronButtonWithMargin
                    onClick={openResetContent}
                    title="Reset Content"
                  />
                )}
                {!isMobile && (
                  <>
                    <ChevronButtonWithMargin
                      onClick={openResetIntroCoursesConfirm}
                      title="Reset Introductory Course"
                    />
                    <ChevronButtonWithMargin
                      onClick={openResetAllContentConfirm}
                      title="Reset All Content as unplayed"
                    />
                  </>
                )}
              </>
            )}
            <ChevronButtonWithMargin
              onClick={toggleHelpScout}
              title="Contact Support"
            />
          </Links>
          <Links data-cypress-id="account-view-links">
            <GroupHeader>MORE</GroupHeader>
            <Link to="/account/course">
              <ChevronButton title="About the Course" />
            </Link>
            <Link to="/account/sam">
              <ChevronButton title="About Sam Harris" />
            </Link>
            <Link to="/account/help">
              <ChevronButton title="FAQ" />
            </Link>
            <Link to="/account/terms">
              <ChevronButton title="Terms" />
            </Link>
            <Link to="/account/privacy">
              <ChevronButton title="Privacy" />
            </Link>
          </Links>
          {DevConfig.debugSection && (
            <Links data-cypress-id="account-view-links">
              <GroupHeader>DEBUG</GroupHeader>
              <ChevronButtonWithMargin
                onClick={openOnboarding}
                title="Onboarding"
              />
              {!isAnonymous && (
                <ChevronButtonWithMargin
                  onClick={openStripePayment}
                  title="Stripe Payment"
                />
              )}
              {/* TODO: Re-enable this when monthly plans are activated.
              <Link to="/account/new-subscription?p=y">
                <ChevronButtonWithMargin title="New Subscription Flow 1/2" />
              </Link>
              <Link to="/account/new-subscription/GOOGLE?p=y">
                <ChevronButtonWithMargin title="New Subscription Flow 1/2 (code: GOOGLE)" />
              </Link>
              <Link to="/account/new-subscription">
                <ChevronButtonWithMargin title="New Subscription Flow 3" />
              </Link>
              <Link to="/account/new-subscription/GOOGLE">
                <ChevronButtonWithMargin title="New Subscription Flow 3 (code: GOOGLE)" />
              </Link> */}
              <Link to="/account/new-subscription">
                <ChevronButtonWithMargin title="New Subscription Flow 1/2" />
              </Link>
              <Link to="/account/new-subscription/GOOGLE">
                <ChevronButtonWithMargin title="New Subscription Flow 1/2 (code: GOOGLE)" />
              </Link>
            </Links>
          )}
          <LogoutLink
            data-cypress-id="account-view-log-out"
            data-jest-id="logout"
            onClick={logOut}
          />
          {!isAnonymous && (
            <>
              <DailyDurationModal
                dailyDurationType={profile.dailyDurationType}
                updateProfile={updateProfile}
              />
              <AddMeditationTimeModal addProgress={addProgress} />
              <StripePaymentModal email={profile.email} />
            </>
          )}
        </Container>
      </Content>
    </>
  );
};
export default ProfilePage;
