import styled, { css } from 'styled-components';
import { buttonHover } from 'styles/mixins';
import DefaultChevronRight from 'components/Icons/ChevronRight';

export const Container = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.pad.lg} ${({ theme }) => theme.pad.md};
  background-color: ${({ theme }) => theme.colors.snow};
  border-radius: ${({ theme }) => theme.radius.sm};
  border-width: 0;
  color: ${({ theme }) => theme.textLightBg.text};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.font.textNotSoSmall};
  transition: padding 0.2s;
  text-decoration: none;

  cursor: pointer;

  ${buttonHover}

  & > svg {
    color: ${({ theme }) => theme.textFaint};
    height: 1rem;
    width: 1rem;
  }

  ${({ disabled = false }) =>
    disabled
      ? css`
          cursor: unset;
          opacity: 0.9;
        `
      : css`
          &:active {
            padding-left: ${({ theme }) => theme.pad.lg};
            padding-right: ${({ theme }) => theme.pad.lg};
          }
        `};
`;

export const ChevronRight = styled(DefaultChevronRight)``;
