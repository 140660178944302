import { createGlobalStyle } from 'styled-components';
import { darkBackground, prettyScrollbar } from './mixins';

/*
 * This file should be used to add global declarations that depend on the
 * styled-components theme. If a declaration does not depend on the theme, it
 * should be moved to `src/index.css`.
 */
export default createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.grey};
    font-family: "Circular Std", sans-serif;
    font-weight: ${({ theme }) => theme.font.weight.book};
    font-size: 1rem;
    line-height: 1.7;
    color: ${({ theme }) => theme.colors.snow};
  }
  html {
      ${prettyScrollbar({ size: '0.5rem' })}
  }

  ::selection {
    background: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.snow};
  }
`;

export const GlobalBackgroundStyles = createGlobalStyle`
  body {
    ${darkBackground}
  }
`;
