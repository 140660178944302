import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { normalizeUser } from 'models/User';
import { Me } from '../generated/Me';
import { ME } from '../queries';

import { HookOptions } from '../types';

const useMe = (options?: HookOptions) => {
  const { data: meData, loading: isLoadingMe } = useQuery<Me>(ME, {
    fetchPolicy: options?.fetchPolicy || 'cache-first',
  });

  const {
    canShareFreeMonth,
    id,
    introPackId,
    isAllContentUnlocked,
    isAnonymous,
    isDailyUnlocked,
    isSubscribed,
    profile,
  } = useMemo(() => {
    const user = normalizeUser(meData?.me);

    return {
      canShareFreeMonth: user.canShareFreeMonth,
      id: user.id,
      introPackId: user.introPackId,
      isAllContentUnlocked: user.isAllContentUnlocked,
      isAnonymous: user.isAnonymous,
      isDailyUnlocked: user.isDailyUnlocked,
      isSubscribed: user.isSubscribed,
      profile: user,
    };
  }, [meData]);

  return {
    canShareFreeMonth,
    id,
    introPackId,
    isAllContentUnlocked,
    isAnonymous,
    isDailyUnlocked,
    isSubscribed,
    loading: isLoadingMe,
    profile,
  };
};

export default useMe;

export type UseMe = ReturnType<typeof useMe>;
