import styled from 'styled-components';
import DefaultButton from 'components/Button';

export const LinkBox = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.textLightBg.text};
  margin-top: ${({ theme }) => theme.margin.sm};
  display: flex;
  flex-direction: column;
`;

export const TextArea = styled.textarea`
  overflow: hidden;
  color: ${({ theme }) => theme.textInput};
  padding: ${({ theme }) => theme.pad.md};
  border-radius: ${({ theme }) => theme.radius.sm};
  border: none;
  resize: none;
  outline: none;
`;

export const CopyButton = styled(DefaultButton)`
  &&& {
    width: 10rem;
    margin-top: 2rem;
    align-self: center;
  }
`;
