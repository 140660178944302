import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Meditation: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={(size * 23) / 28}
    viewBox="0 0 28 23"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="nonzero"
      d="M9.844 4.555c.731-1.68 1.87-3.13 3.407-4.34A1 1 0 0 1 14.43.17a9.489 9.489 0 0 1 3.535 4.324 11.152 11.152 0 0 1 3.109-1.88 1 1 0 0 1 1.267.476 11.692 11.692 0 0 1 1.28 4.821 11.212 11.212 0 0 1 3.166-.275 1 1 0 0 1 .947.967c.111 3.481-1.19 6.586-3.864 9.26-2.65 2.65-5.922 4.154-9.775 4.496a1.007 1.007 0 0 1-.225-.005c-.073.01-.148.012-.224.005-3.853-.342-7.125-1.845-9.776-4.496C1.196 15.19-.105 12.085.007 8.604a1 1 0 0 1 .947-.967 11.212 11.212 0 0 1 3.166.275 11.692 11.692 0 0 1 1.28-4.82 1 1 0 0 1 1.266-.476 11.142 11.142 0 0 1 3.178 1.939zm-.354 2.48a9.03 9.03 0 0 0-2.702-2.162 9.541 9.541 0 0 0-.677 3.712c1.456.65 2.831 1.623 4.123 2.915.407.407.787.827 1.14 1.26.353.432.679.877.977 1.335a13.904 13.904 0 0 0-.786-3.373c-.53-1.456-1.22-2.68-2.067-3.677a1.003 1.003 0 0 1-.008-.01zm3.551 1.996a16.944 16.944 0 0 1 .829 2.343c.122-.445.263-.89.425-1.336.548-1.504 1.256-2.812 2.124-3.921-.468-1.584-1.3-2.857-2.508-3.843-1.214 1.103-2.058 2.4-2.544 3.902.65.84 1.208 1.793 1.675 2.855zm-.922 9.177c-.635-1.965-1.732-3.727-3.3-5.294-1.278-1.279-2.621-2.18-4.03-2.71a8.995 8.995 0 0 0-2.77-.573c.15 2.522 1.228 4.782 3.265 6.819 2.03 2.03 4.459 3.281 7.312 3.761-.081-.51-.189-1.007-.324-1.492a1 1 0 0 1-.153-.511zm3.514-.034a1 1 0 0 1-.203.685c-.117.44-.213.892-.286 1.352 2.853-.48 5.281-1.73 7.312-3.761 2.037-2.037 3.116-4.297 3.266-6.819a8.993 8.993 0 0 0-2.77.572c-1.41.532-2.753 1.432-4.031 2.711-1.559 1.558-2.652 3.309-3.288 5.26zm.037-5.761c-.13.562-.224 1.122-.28 1.682a16.12 16.12 0 0 1 2.116-2.595c1.292-1.292 2.667-2.266 4.124-2.915a9.541 9.541 0 0 0-.678-3.712 9.026 9.026 0 0 0-2.676 2.132c-.863 1.005-1.564 2.242-2.101 3.717a14.67 14.67 0 0 0-.505 1.69z"
    />
  </svg>
);

export default memo(Meditation);
