import React, { FC, FormEvent, useCallback, useState } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Error, Container, Submit, Title } from './styles';
import { Props } from './types';

const PaymentSourceManageForm: FC<Props> = ({
  firstName,
  handleSubmit,
  isLoading,
  lastName,
  stripe,
}) => {
  const [error, setError] = useState<string | undefined>(undefined);

  const createToken = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      if (!stripe) return false;

      const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';

      const tokenResponse = await stripe.createToken({ name: fullName });
      if (!tokenResponse) {
        setError('Unable to complete transaction. Please try again.');
      } else if (tokenResponse.error) {
        setError(tokenResponse.error.message);
      } else if (tokenResponse) {
        handleSubmit(tokenResponse);
      }
    },
    [stripe, firstName, lastName, handleSubmit],
  );

  return (
    <Container>
      <Title>Fill in the details below to update your payment card.</Title>
      <CardElement />
      {error && <Error>{error}</Error>}
      <Submit isLoading={isLoading} onClick={createToken}>
        Update Payment Source
      </Submit>
    </Container>
  );
};

export default injectStripe(PaymentSourceManageForm);
