import { useCallback, useEffect, useRef } from 'react';
import { trackEvent } from 'services/analytics';
import useSettings from 'graphql/hooks/useSettings';
import useSettingsActions from 'graphql/hooks/useSettingsActions';

const SPEEDS = [1, 1.2, 1.4, 1.6, 1.8, 2];

const useConnect = () => {
  const {
    settings: { playbackSpeed },
  } = useSettings();
  const { updateSettings } = useSettingsActions();
  const speed = Number(playbackSpeed);
  const speedRef = useRef<number>(speed);

  const speedChange = useCallback(() => {
    const currentIndex = SPEEDS.indexOf(speed);
    if (currentIndex >= 0) {
      const newIndex = (currentIndex + 1) % SPEEDS.length;
      const newSpeed = SPEEDS[newIndex];
      updateSettings({ playbackSpeed: newSpeed.toString() }, true);
    }
  }, [speed, updateSettings]);

  useEffect(() => {
    if (speedRef.current !== speed) {
      const timeoutId = setTimeout(() => {
        trackEvent('Toggle Conversation Speed', { value: speed });
        speedRef.current = speed;
      }, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [speed]);

  return { speed, speedChange };
};

export default useConnect;

export type UseLogic = ReturnType<typeof useConnect>;
