import React, { FC, memo } from 'react';
import { Container, Title, Value } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Speed: FC<Props> = ({ className }) => {
  const { speed, speedChange } = useConnect();

  return (
    <Container className={className} onClick={speedChange}>
      <Value>{speed.toFixed(1)}x</Value>
      <Title>Speed</Title>
    </Container>
  );
};

export default memo(Speed);
