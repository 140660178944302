import Cookie from 'js-cookie';
import { ApiConfig } from '../config';

export const TOKEN_KEY = 'token';
export const COOKIE_KEY = 'STYXKEY-token';
export const ONBOARDING_KEY = 'onboarding-finished';
export const PLAYER_VOLUME = 'player-volume';

const cookieOptions = {
  domain: 'wakingup.com',
  expires: 365,
};

export const deleteToken = () => {
  Cookie.remove(COOKIE_KEY, cookieOptions);
  return localStorage.removeItem(TOKEN_KEY);
};

export const getToken = () =>
  Cookie.get(COOKIE_KEY) || localStorage.getItem(TOKEN_KEY);

export const setToken = (token: string) => {
  Cookie.set(COOKIE_KEY, token, cookieOptions);
  return localStorage.setItem(TOKEN_KEY, token);
};

export const isOnboardingFinished = () =>
  localStorage.getItem(ONBOARDING_KEY) === 'true';

export const setOnboardingFinished = () => {
  localStorage.setItem(ONBOARDING_KEY, 'true');
};

export const setAnonymousUserToken = () => setToken(ApiConfig.ANONYMOUS_TOKEN);

export const getPlayerVolume = () => {
  const volume = localStorage.getItem(PLAYER_VOLUME);
  return volume ? Number(volume) : 100;
};
export const setPlayerVolume = (volume: number) => {
  localStorage.setItem(PLAYER_VOLUME, volume.toString());
};
