import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultButton from 'components/Button';
import DefaultModal from 'components/Modal';
import { Content as ModalContent } from 'components/Modal/styles';

export const Modal = styled(DefaultModal)`
  border: none !important;
  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.blue900};
  }
  ${ModalContent} {
    background-color: ${({ theme }) => theme.colors.blue900};
    color: ${({ theme }) => theme.colors.snow};
    padding: ${({ theme }) => theme.pad.xxl};
    width: 30rem;
  }
`;

export const Container = styled.div`
  padding: ${({ theme }) => `${theme.pad.lg} ${theme.pad.md}`};
  ${media.phonePortrait} {
    padding: ${({ theme }) => `${theme.pad.xl} 0 ${theme.pad.sm} 0`};
  }
`;

export const Description = styled.p`
  font-size: ${({ theme }) => theme.font.textNotSoSmall};
  opacity: 0.9;
  padding-bottom: 0.7rem;
  text-align: center;
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.margin.md};
  > *:not(:last-child) {
    margin-right: ${({ theme }) => theme.margin.md};
  }
`;

export const Cancel = styled(DefaultButton)`
  &&& {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.blue500};
    :hover {
      background-color: ${({ theme }) => theme.colors.blue500};
    }
  }
`;

export const Accept = styled(DefaultButton)``;
