import DefaultHeartFullIcon from 'components/Icons/HeartFull';
import styled from 'styled-components';

export const Root = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const Content = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.textLightBg.text};
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const HeartFullIcon = styled(DefaultHeartFullIcon)`
  color: #f4477c;
  height: 4rem;
  width: 4rem;
`;

export const Title = styled.p`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-top: ${({ theme }) => theme.margin.md};
`;

export const Subtitle = styled.p`
  margin-top: ${({ theme }) => theme.margin.xxsm};
  text-align: center;
`;
