import styled, { css } from 'styled-components';
import {
  HEADER_HEIGHT,
  MAX_CONTENT_WIDTH,
  MIN_CONTENT_WIDTH,
  SIDEBAR_WIDTH,
} from 'styles/metrics';
import { media } from 'styles/mixins';
import { motion } from 'framer-motion';
import AnimatedSwitch from 'components/AnimatedSwitch';

export const Container = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { opacity: 0 },
}))``;

export const Content = styled.div`
  margin: 0 auto;
  position: relative;
  width: ${MAX_CONTENT_WIDTH};

  ${media.tabletLandscape} {
    width: ${MIN_CONTENT_WIDTH};
  }

  ${media.tabletPortrait} {
    margin: 0;
    width: 100%;
  }
`;

export const Switch = styled(AnimatedSwitch)<{
  $isMinimizedPlayerOpen: boolean;
}>`
  left: ${SIDEBAR_WIDTH};
  min-height: 100vh;
  padding: ${({ theme }) => theme.pad.xxxl};
  padding-right: 0;
  position: absolute;
  transition: padding-bottom 0.2s ease-in-out;
  width: calc(100% - ${SIDEBAR_WIDTH});

  ${media.tabletLandscape} {
    padding-left: ${({ theme }) => theme.pad.xxl};
  }

  ${media.tabletPortrait} {
    left: auto;
    padding: ${({ theme }) => theme.pad.xl};
    padding-top: ${({ theme }) => `calc(${HEADER_HEIGHT} + ${theme.pad.xl})`};
    position: relative;
    width: 100%;
  }

  ${media.phoneLandscape} {
    padding: ${({ theme }) => theme.pad.lg};
    padding-top: ${({ theme }) => `calc(${HEADER_HEIGHT} + ${theme.pad.lg})`};
  }

  ${({ $isMinimizedPlayerOpen }) =>
    $isMinimizedPlayerOpen &&
    css`
      padding-bottom: 60px;

      ${media.tabletLandscape} {
        padding-bottom: 60px;
      }
    `};
`;
