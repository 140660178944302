import React, { FC, memo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Container, CloseButton, Message, Href } from './styles';
import useLogic from './logic';
import { Props } from './types';

const CookiesNotification: FC<Props> = ({ className }) => {
  const { consentCookies, hasCookiesConsent } = useLogic();

  const message = `We use cookies to enhance your browser experience. By using this website you agree to our `;

  return (
    <AnimatePresence>
      {!hasCookiesConsent && (
        <Container className={className}>
          <Message>
            {message}
            <Href
              target="_blank"
              href="https://www.wakingup.com/privacy-policy#2"
            >
              Cookies Policy
            </Href>
          </Message>
          <CloseButton onClick={consentCookies} />
        </Container>
      )}
    </AnimatePresence>
  );
};

export default memo(CookiesNotification);
