import { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { ModalType } from 'graphql/generated/globalTypes';
import {
  GetModal,
  GetModal_modal_courseActionsPayload,
  GetModal_modal_courseNotesPayload,
  GetModal_modal_dialogPayload,
  GetModal_modal_resetCoursePayload,
  GetModal_modal_shareLinkPayload,
  GetModal_modal_unlockContentPayload,
  GetModal_modal_updatePaymentCardPayload,
} from 'graphql/generated/GetModal';
import { GET_MODAL } from 'graphql/queries';
import { getSettings } from 'graphql/requests';
import {
  cacheResetModal,
  cacheSetModal,
  cacheUpdateModal,
} from 'graphql/cache';

const useModal = () => {
  const { data: modalData } = useQuery<GetModal>(GET_MODAL, {
    fetchPolicy: 'cache-only',
  });

  const {
    type,
    courseActionsPayload,
    courseNotesPayload,
    dialogPayload,
    resetCoursePayload,
    shareLinkPayload,
    unlockContentPayload,
    updatePaymentCardPayload,
  } = useMemo(
    () => ({
      type: modalData?.modal.type || null,
      courseActionsPayload: modalData?.modal.courseActionsPayload || null,
      courseNotesPayload: modalData?.modal.courseNotesPayload || null,
      dialogPayload: modalData?.modal.dialogPayload || null,
      resetCoursePayload: modalData?.modal.resetCoursePayload || null,
      shareLinkPayload: modalData?.modal.shareLinkPayload || null,
      unlockContentPayload: modalData?.modal.unlockContentPayload || null,
      updatePaymentCardPayload:
        modalData?.modal.updatePaymentCardPayload || null,
    }),
    [modalData],
  );

  const close = useCallback((dataClearDelay?: number) => {
    if (dataClearDelay) {
      cacheUpdateModal({ type: null });
      setTimeout(() => {
        cacheResetModal();
      }, dataClearDelay);
    } else {
      cacheResetModal();
    }
  }, []);

  const openAddMeditationTime = useCallback(() => {
    cacheSetModal({ type: ModalType.ADD_MEDITATION_TIME });
  }, []);

  const openCourseActions = useCallback(
    (payload: Omit<GetModal_modal_courseActionsPayload, '__typename'>) => {
      cacheSetModal({
        type: ModalType.COURSE_ACTIONS,
        courseActionsPayload: {
          __typename: 'ModalCourseActionsPayload',
          ...payload,
        },
      });
    },
    [],
  );

  const openCourseNotes = useCallback(
    (payload: Omit<GetModal_modal_courseNotesPayload, '__typename'>) => {
      cacheSetModal({
        type: ModalType.COURSE_NOTES,
        courseNotesPayload: {
          __typename: 'ModalCourseNotesPayload',
          ...payload,
        },
      });
    },
    [],
  );

  const openDailyDuration = useCallback(() => {
    cacheSetModal({ type: ModalType.DAILY_DURATION });
  }, []);

  const openDialog = useCallback(
    (payload: Omit<GetModal_modal_dialogPayload, '__typename'>) => {
      cacheSetModal({
        type: ModalType.DIALOG,
        dialogPayload: {
          __typename: 'ModalDialogPayload',
          ...payload,
        },
      });
    },
    [],
  );

  const openGiveFreeMonth = useCallback(() => {
    cacheSetModal({ type: ModalType.GIVE_FREE_MONTH });
  }, []);

  const openNewIntroPack = useCallback(() => {
    cacheSetModal({ type: ModalType.NEW_INTRO_PACK });
  }, []);

  const openOnboarding = useCallback(() => {
    cacheSetModal({ type: ModalType.ONBOARDING });
  }, []);

  const openResetAllContentConfirm = useCallback(() => {
    cacheUpdateModal({ type: ModalType.RESET_ALL_CONTENT });
  }, []);

  const openResetContent = useCallback(() => {
    cacheUpdateModal({ type: ModalType.RESET_CONTENT });
  }, []);

  const openResetCourseConfirm = useCallback(
    (payload: Omit<GetModal_modal_resetCoursePayload, '__typename'>) => {
      cacheUpdateModal({
        type: ModalType.RESET_COURSE,
        resetCoursePayload: {
          __typename: 'ModalResetCoursePayload',
          ...payload,
        },
      });
    },
    [],
  );

  const openResetIntroCoursesConfirm = useCallback(() => {
    cacheUpdateModal({ type: ModalType.RESET_INTRO_COURSES });
  }, []);

  const openSelfTimer = useCallback(() => {
    cacheSetModal({ type: ModalType.SELF_TIMER });
  }, []);

  const openShareLink = useCallback(
    (payload: Omit<GetModal_modal_shareLinkPayload, '__typename'>) => {
      cacheSetModal({
        type: ModalType.SHARE_LINK,
        shareLinkPayload: { __typename: 'ModalShareLinkPayload', ...payload },
      });
    },
    [],
  );

  const openShareMonth = useCallback(async () => {
    const settings = await getSettings();
    if (settings.hasSeenPopupWarningSharingFreeMonth) {
      cacheSetModal({ type: ModalType.GIVE_FREE_MONTH });
    } else {
      cacheSetModal({ type: ModalType.SHARE_MONTH_WARNING });
    }
  }, []);

  const openStripePayment = useCallback(() => {
    cacheSetModal({ type: ModalType.STRIPE_PAYMENT });
  }, []);

  const openUnlockContent = useCallback(
    (payload: Omit<GetModal_modal_unlockContentPayload, '__typename'>) => {
      cacheSetModal({
        type: ModalType.UNLOCK_CONTENT,
        unlockContentPayload: {
          __typename: 'ModalUnlockContentPayload',
          ...payload,
        },
      });
    },
    [],
  );

  const openUpdatePaymentCard = useCallback(
    (payload: Omit<GetModal_modal_updatePaymentCardPayload, '__typename'>) => {
      cacheSetModal({
        type: ModalType.UPDATE_PAYMENT_CARD,
        updatePaymentCardPayload: {
          __typename: 'ModalUpdatePaymentCardPayload',
          ...payload,
        },
      });
    },
    [],
  );

  return {
    close,
    courseActionsPayload,
    courseNotesPayload,
    dialogPayload,
    openAddMeditationTime,
    openCourseActions,
    openCourseNotes,
    openDailyDuration,
    openDialog,
    openGiveFreeMonth,
    openNewIntroPack,
    openOnboarding,
    openResetAllContentConfirm,
    openResetContent,
    openResetCourseConfirm,
    openResetIntroCoursesConfirm,
    openSelfTimer,
    openShareLink,
    openShareMonth,
    openStripePayment,
    openUnlockContent,
    openUpdatePaymentCard,
    resetCoursePayload,
    shareLinkPayload,
    type,
    unlockContentPayload,
    updatePaymentCardPayload,
  };
};

export default useModal;

export type UseModal = ReturnType<typeof useModal>;
