import { init } from '@sentry/react';
import { APP_BUILD, isLocal } from 'config';

export const initializeSentry = () => {
  if (!isLocal) {
    init({
      dsn:
        'https://018e2cc184f3483eafa5f4a759f32b60@o471955.ingest.sentry.io/5504703',
      environment: APP_BUILD,
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage',
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        'Script error.',
        'Network error',
        'SecurityError: Failed to read the',
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\/.+/i,
        /^safari-extension:\/\/.+/i,
        // Other plugins
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
    });
  }
};

// ToDo: We will probably need to use this in order to send errors to Sentry and _maybe_, other tracking systems
export const captureError = () => {};
