import React, { FC } from 'react';
import { Container, ShareIcon } from './styles';
import { Props } from './types';

const ShareButton: FC<Props> = ({ className, onClick }) => (
  <Container className={className} onClick={onClick}>
    <ShareIcon />
  </Container>
);

export default ShareButton;
