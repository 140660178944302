import { useMemo, useCallback } from 'react';
import { FormikHelpers } from 'formik';
import { validationSchema } from './constants';
import { Props, FormFields } from './types';

const useConnect = ({ login }: { login: Props['login'] }) => {
  const initialValues: FormFields = useMemo(
    () => ({
      pin: ['', '', '', ''],
      submitError: '',
      resendError: '',
    }),
    [],
  );

  const submit = useCallback(
    async (
      { pin }: { pin: FormFields['pin'] },
      { setErrors }: { setErrors: FormikHelpers<FormFields>['setErrors'] },
    ) => {
      try {
        const response = await login(pin.join(''));
        return response;
      } catch (error) {
        setErrors({ submitError: error.message });
      }
    },
    [login],
  );

  return { initialValues, submit, validationSchema };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
