import { useCallback, MouseEvent, useState } from 'react';
import { media } from 'styles/mixins';
import useMediaQuery from 'lib/useMediaQuery';
import { LogicProps } from './types';

const useLogic = ({
  courseHash,
  courseId,
  isShareable,
  onMarkAsUnplayed,
  onOpenActions,
  onOpenNotes,
  onOpenShareLink,
  onPlay,
  onToggleFavorite,
}: LogicProps) => {
  const isMobile = useMediaQuery(media.tabletPortrait);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handlePlay = useCallback(() => {
    onPlay(courseId);
  }, [courseId, onPlay]);

  const handleOpenActions = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isMobile && onOpenActions) {
        onOpenActions({ courseId });
      } else {
        setAnchorEl(e.currentTarget);
      }
    },
    [courseId, isMobile, onOpenActions],
  );

  const handleToggleFavorite = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (onToggleFavorite) {
        onToggleFavorite(courseId);
      }
    },
    [courseId, onToggleFavorite],
  );

  const handleMarkAsUnplayed = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (onMarkAsUnplayed) {
        closeMenu();
        onMarkAsUnplayed({ courseId });
      }
    },
    [closeMenu, courseId, onMarkAsUnplayed],
  );

  const handleOpenNotes = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (onOpenNotes) {
        closeMenu();
        onOpenNotes({ courseId });
      }
    },
    [closeMenu, courseId, onOpenNotes],
  );

  const handleOpenShare = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isShareable && onOpenShareLink) {
        closeMenu();
        setTimeout(() => {
          onOpenShareLink({
            courseId,
            title: 'Share this course',
            description:
              'Share this link with anyone you feel would benefit from this Waking Up course',
            link: `https://dynamic.wakingup.com/course/${courseHash}`,
          });
        }, 500);
      }
    },
    [closeMenu, courseHash, courseId, isShareable, onOpenShareLink],
  );

  return {
    anchorEl,
    closeMenu,
    handleMarkAsUnplayed,
    handleOpenActions,
    handleOpenNotes,
    handleOpenShare,
    handlePlay,
    handleToggleFavorite,
    isMobile,
  };
};

export default useLogic;

export type UseLogic = ReturnType<typeof useLogic>;
