import { useCallback, useEffect, useState } from 'react';
import { LogicProps } from './types';

const useLogic = ({ handleScroll, type }: LogicProps) => {
  const [isScrolling, setIsScrolling] = useState<boolean>(false);

  const handleMouseDown = useCallback(() => {
    handleScroll(type);
    setIsScrolling(true);
  }, [handleScroll, setIsScrolling, type]);

  const handleMouseOut = useCallback(() => {
    setIsScrolling(false);
  }, [setIsScrolling]);

  const handleMouseUp = useCallback(() => {
    handleScroll(type);
    setIsScrolling(false);
  }, [handleScroll, setIsScrolling, type]);

  useEffect(() => {
    let interval: number | undefined;

    if (isScrolling) {
      interval = setInterval(() => {
        handleScroll(type);
      }, 100);
    }

    return () => clearInterval(interval);
  }, [handleScroll, isScrolling, type]);

  return {
    handleMouseDown,
    handleMouseOut,
    handleMouseUp,
  };
};

export default useLogic;
