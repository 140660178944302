import SectionHeader from 'components/SectionHeader';
import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;

  button {
    margin-bottom: ${({ theme }) => theme.margin.md};
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30rem;

  ${media.phoneLandscape} {
    width: 100%;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.textLightBg.textStrong};
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  ${media.tabletPortrait} {
    text-align: center;
  }
  ${media.phoneLandscape} {
    text-align: center;
  }
`;

export const Header = styled(SectionHeader)`
  ${media.tabletPortrait} {
    display: none;
  }
`;
