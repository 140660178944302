import styled, { css } from 'styled-components';
import { buttonHover, circularIconDimensions, media } from 'styles/mixins';
import LoaderBox from 'components/LoaderBox';
import DefaultHeader from 'components/SectionHeader';
import DefaultLock from 'components/Lock';
import DefaultStretchedImage from 'components/StretchedImage';
import { Image } from 'components/StretchedImage/styles';
import DefaultPlayButton from 'components/PlayButton';
import DefaultCheckIcon from 'components/Icons/Check';
import DefaultActiveSessionsCount from './ActiveSessionsCount';

export const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
`;

export const Header = styled(DefaultHeader)`
  margin-bottom: ${({ theme }) => theme.margin.md};
`;

export const Loader = styled(LoaderBox)`
  flex: 1 1 auto;
`;

export const Content = styled.div`
  border-radius: 0.5rem;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  position: relative;
  ${buttonHover};
`;

export const Lock = styled(DefaultLock)`
  flex: 1 1 auto;
`;

export const ActiveSessionsCount = styled(DefaultActiveSessionsCount)`
  bottom: -1rem;
  left: 0;
  position: absolute;
  z-index: 2;

  ${media.tabletLandscape} {
    bottom: -0.8rem;
  }
`;

export const StretchedImage = styled(DefaultStretchedImage)<{
  $backgroundColor?: string;
}>`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
  min-height: 5rem;
  padding-left: 1.4rem;
  width: 100%;

  ${({ $backgroundColor }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};

  ${media.tabletLandscape} {
    ${Image} {
      right: 1.4rem;
    }
  }
`;

export const CompletedIcon = styled.div`
  ${circularIconDimensions};
  align-items: center;
  border-radius: 50%;
  border: 1px solid white;
  display: inline-flex;
  flex-direction: column;
  height: 1.3rem;
  justify-content: center;
  margin-left: 0.5rem;
  width: 1.3rem;
`;

export const CheckIcon = styled(DefaultCheckIcon)`
  color: white;
  height: 45%;
  width: 45%;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.colors.snow};
  user-select: none;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1;

  ${media.tabletLandscape} {
    font-size: 1.3rem;
  }

  ${media.phoneLandscape} {
    max-width: 15rem;
  }
`;

export const Subtitle = styled.div`
  font-size: 1.1rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1;
  margin-top: 0.4rem;
  opacity: 0.7;

  ${media.tabletLandscape} {
    font-size: 0.9rem;
    margin-top: 0.25rem;
  }
`;

export const Number = styled.div`
  font-size: 2rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};

  ${media.tabletLandscape} {
    font-size: 1.5rem;
  }
`;

export const PlayButton = styled(DefaultPlayButton).attrs(() => ({
  clear: true,
}))`
  ${circularIconDimensions};
`;

export const EmptyImage = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.blue500};
  border-radius: inherit;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  justify-content: center;
  min-height: 5rem;
  padding: 0 1.4rem;
  width: 100%;
  ${PlayButton} {
    display: none;
  }

  ${media.tabletLandscape} {
    justify-content: space-between;
    ${PlayButton} {
      display: flex;
    }
  }
`;

export const Overlay = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  border-radius: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
  &:hover {
    opacity: 1;
  }
  ${PlayButton} {
    height: 3.2rem;
    width: 3.2rem;
    padding: 0.3rem;
  }

  ${media.tabletLandscape} {
    opacity: 1;
    background: transparent;
    ${PlayButton} {
      display: none;
    }
  }
`;
