import getProfile from './getProfile';
import getDaily from './getDaily';
import getPacks from './getPacks';

const getNextMeditation = async () => {
  const profile = await getProfile();
  const { isDailyUnlocked, isSubscribed } = profile;

  if (isDailyUnlocked) {
    if (!isSubscribed) {
      return null;
    }

    const daily = await getDaily();
    if (daily) {
      return daily;
    }
  }

  const packs = await getPacks();
  const introCourses = packs
    .find((v) => v?.category === 'intro')
    ?.courses.sort((a, b) => b.course_number - a.course_number);

  if (introCourses) {
    const higherIntroCourseFinishedIndex = introCourses.findIndex(
      ({ status }) => status === 'finished',
    );

    let nextIntro;
    if (higherIntroCourseFinishedIndex === -1) {
      nextIntro = introCourses[introCourses.length - 1];
    } else if (higherIntroCourseFinishedIndex === 0) {
      nextIntro = introCourses[higherIntroCourseFinishedIndex];
    } else {
      nextIntro = introCourses[higherIntroCourseFinishedIndex - 1];
    }

    if (isSubscribed || nextIntro.is_free) {
      return nextIntro;
    }
  }

  return null;
};

export default getNextMeditation;
