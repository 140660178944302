import React, { FC } from 'react';
import { Close, Reset, Container, Submit } from './styles';
import { Props } from './types';

const FormActionButtons: FC<Props> = ({
  closeModal,
  invalid,
  isSubmitting,
  onReset,
  pristine,
}) => (
  <Container>
    <Submit disabled={pristine || invalid || isSubmitting} type="submit">
      Save
    </Submit>
    <Reset disabled={pristine || isSubmitting} onClick={onReset} type="reset">
      Reset
    </Reset>
    <Close onClick={closeModal} type="button">
      Close
    </Close>
  </Container>
);

export default FormActionButtons;
