import React, { FC } from 'react';
import { Loader, Progress, ProgressText } from './styles';
import useConnect from './connect';

const DeepLinks: FC = () => {
  useConnect();

  return (
    <Loader>
      <Progress />
      <ProgressText>Processing...</ProgressText>
    </Loader>
  );
};

export default DeepLinks;
