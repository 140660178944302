import styled, { css } from 'styled-components';
import DefaultHorizontalCarousel from 'components/HorizontalCarousel';
import LoaderBox from 'components/LoaderBox';
import { media } from 'styles/mixins';
import { courseDimensions } from '../dimensions';

export const Loader = styled(LoaderBox)`
  ${courseDimensions};
`;

export const HorizontalCarousel = styled(DefaultHorizontalCarousel)`
  ${({ theme: { pad, margin } }) => css`
    margin-top: ${margin.md};

    ${media.phoneLandscape} {
      margin: ${margin.md} -${pad.lg} ${margin.xl} 0;
    }
  `}
`;
