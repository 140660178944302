import styled from 'styled-components';
import DefaultMoreIcon from 'components/Icons/More';
import DefaultIconButton from 'components/IconButton';

export const MoreIcon = styled(DefaultMoreIcon).attrs(() => ({
  size: 20,
}))``;

export const Button = styled(DefaultIconButton)`
  align-items: center;
  display: flex;
  height: auto;
  right: 1.8rem;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1.2rem;
`;
