import React, { FC } from 'react';
import toUpperCase from 'lodash/toUpper';
import { Formik } from 'formik';
import Button from 'components/Button';
import TextField from 'components/Inputs/TextField';
import { Container, Title, Form, Error, Contact } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Redeem: FC<Props> = () => {
  const {
    initialValues,
    loading,
    onSubmit,
    title,
    validationSchema,
  } = useConnect();

  return (
    <Container>
      <Title>{title}</Title>
      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors }) => (
          <Form>
            <TextField
              autoFocus
              isClearable
              placeholder="Email"
              name="email"
              type="email"
            />
            <TextField
              isClearable
              placeholder="Code"
              name="code"
              normalize={toUpperCase}
            />
            <Button
              disabled={!values.email || !values.code}
              isLoading={loading}
              mode="outlined"
              type="submit"
            >
              Redeem
            </Button>
            {errors.submitError && (
              <>
                <Error>{errors.submitError}</Error>
                <Contact>
                  Please contact{' '}
                  <a href="mailto:support@wakingup.com">support@wakingup.com</a>{' '}
                  for help.
                </Contact>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Redeem;
