import styled from 'styled-components';
import DefaultAvatar from 'components/Avatar';

export const Container = styled.div<{ $inline?: boolean }>`
  display: flex;
  flex-flow: ${({ $inline }) => ($inline ? 'row' : 'column')} nowrap;
  align-items: center;
  justify-content: flex-start;
  text-align: ${({ $inline }) => ($inline ? 'left' : 'center')};
`;

export const Avatar = styled(DefaultAvatar)`
  height: 4.5rem;
  width: 4.5rem;
`;

export const Info = styled.div<{ $inline?: boolean }>`
  margin-left: ${({ $inline, theme }) => ($inline ? theme.margin.md : '0')};
  margin-top: ${({ $inline, theme }) => ($inline ? '0' : theme.margin.xsm)};
`;

export const FullName = styled.div`
  font-size: ${({ theme }) => theme.font.textSuperBig};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.margin.xxxsm};
`;

export const MemberSince = styled.div`
  font-size: ${({ theme }) => theme.font.textSmall};
  line-height: 1;
`;

export const City = styled.div`
  font-size: ${({ theme }) => theme.font.textSuperSmall};
  line-height: 1;
  margin-top: ${({ theme }) => theme.margin.xxxsm};
`;
