import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';
import { Content as ModalContent } from 'components/Modal/styles';

export const Modal = styled(DefaultModal)`
  ${ModalContent} {
    padding: ${({ theme }) => theme.pad.xxl};
    ${media.phonePortrait} {
      padding: ${({ theme }) => `${theme.pad.xxl} ${theme.pad.xl}`};
    }
  }
`;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.font.heading};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.margin.lg};
`;
