import React, { FC } from 'react';
import { CoursePermission } from 'models/Course';
import {
  Image,
  Number,
  NumberContainer,
  LockIcon,
  PermissionOverlay,
  PlayButton,
  PlayOverlay,
  StarIcon,
  StretchedImage,
} from './styles';
import { Props } from './types';

const CourseThumbnail: FC<Props> = ({
  alt,
  backgroundColor = 'rgb(100, 137, 255)',
  className,
  courseNumber,
  coursePermission = CoursePermission.UNLOCKED,
  hidePermission = false,
  imageUrl = '',
  isSquareImage = false,
  showPlayOnHover = false,
}) => {
  const permissionOverlay =
    !hidePermission &&
    (coursePermission === CoursePermission.FORBIDDEN ||
      coursePermission === CoursePermission.LOCKED) ? (
      <PermissionOverlay>
        {coursePermission === CoursePermission.FORBIDDEN ? (
          <LockIcon />
        ) : (
          <StarIcon />
        )}
      </PermissionOverlay>
    ) : null;

  const playOverlay =
    !permissionOverlay && showPlayOnHover ? (
      <PlayOverlay>
        <PlayButton />
      </PlayOverlay>
    ) : null;

  const hasSquareImage = !!imageUrl && isSquareImage;
  const hasRectangleImage = !!imageUrl && !isSquareImage;
  const hasNoImage = !imageUrl;

  return (
    <>
      {hasSquareImage && (
        <StretchedImage
          $backgroundColor={backgroundColor}
          alt={alt}
          className={className}
          src={imageUrl}
        >
          {permissionOverlay}
          {playOverlay}
        </StretchedImage>
      )}
      {hasRectangleImage && (
        <Image alt={alt} className={className} src={imageUrl}>
          {permissionOverlay}
          {playOverlay}
        </Image>
      )}
      {hasNoImage && (
        <NumberContainer
          $backgroundColor={backgroundColor}
          className={className}
        >
          <Number>{courseNumber}</Number>
          {permissionOverlay}
          {playOverlay}
        </NumberContainer>
      )}
    </>
  );
};

export default CourseThumbnail;
