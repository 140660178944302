import { useEffect, useCallback, useMemo } from 'react';
import { apolloClient } from 'services/api';
import { CourseType } from 'models/Course';
import { GetSettings } from 'graphql/generated/GetSettings';
import { PlayerAutoplayType } from 'graphql/generated/globalTypes';
import { GET_SETTINGS } from 'graphql/queries';
import useModal from 'graphql/hooks/useModal';
import useSettings from 'graphql/hooks/useSettings';
import useFavorites from 'graphql/hooks/useFavorites';
import useCourseActions from 'graphql/hooks/useCourseActions';

const useConnect = () => {
  const {
    loading: isLoadingSettings,
    settings: { favoritesMeditationAutoPlay, favoritesTheoriesAutoPlay },
  } = useSettings();
  const {
    openCourseActions,
    openCourseNotes,
    openResetCourseConfirm,
    openShareLink,
  } = useModal();
  const {
    favorites,
    loading: isLoadingFavorites,
    removeAll: removeAllFavorites,
    toggle: toggleFavorite,
  } = useFavorites();
  const { play, toggleAutoPlay } = useCourseActions();

  useEffect(() => {
    apolloClient
      .query<GetSettings>({
        query: GET_SETTINGS,
        fetchPolicy: 'network-only',
      })
      .catch(() => true);
  }, []);

  const { favoriteLessons, favoriteMeditations } = useMemo(
    () => ({
      favoriteLessons: favorites?.filter(
        (v) => v.course_type === CourseType.LESSON,
      ),
      favoriteMeditations: favorites?.filter(
        (v) => v.course_type === CourseType.MEDITATION,
      ),
    }),
    [favorites],
  );

  const loadFavoriteMeditationTrack = useCallback(
    async (courseId: string) => {
      await play(courseId, PlayerAutoplayType.FAVORITES_PRACTICE);
    },
    [play],
  );

  const loadFavoriteTheoryTrack = useCallback(
    async (courseId: string) => {
      await play(courseId, PlayerAutoplayType.FAVORITES_THEORY);
    },
    [play],
  );

  const toggleFavoritesMeditationAutoPlay = useCallback(async () => {
    await toggleAutoPlay(PlayerAutoplayType.FAVORITES_PRACTICE);
  }, [toggleAutoPlay]);

  const toggleFavoritesTheoriesAutoPlay = useCallback(async () => {
    await toggleAutoPlay(PlayerAutoplayType.FAVORITES_THEORY);
  }, [toggleAutoPlay]);

  return {
    emptyFavorites: favorites && favorites.length === 0,
    favoriteLessons,
    favoriteMeditations,
    favoritesMeditationAutoPlay,
    favoritesTheoriesAutoPlay,
    loading: isLoadingSettings || isLoadingFavorites,
    loadFavoriteMeditationTrack,
    loadFavoriteTheoryTrack,
    openCourseActions,
    openCourseNotes,
    openResetCourseConfirm,
    openShareLink,
    removeAllFavorites,
    toggleFavorite,
    toggleFavoritesMeditationAutoPlay,
    toggleFavoritesTheoriesAutoPlay,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
