import styled, { css } from 'styled-components';
import { FixedSizeList as DefaultList } from 'react-window';
import { courseDimensions } from 'containers/Main/Home/dimensions';
import DefaultItem from './Item';

export const Item = styled(DefaultItem)`
  ${courseDimensions}
`;

export const List = styled(DefaultList)`
  margin: 0 !important;

  &::-webkit-scrollbar {
    color: ${({ theme }) => theme.textSuperFaint} transparent;
    height: 0.65rem;

    ${({ height }) =>
      height < 285 &&
      css`
        height: 0;
      `}
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.textLight};
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.textSuperFaint};
    border-radius: 4px;
  }
`;
