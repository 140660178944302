import React, { FC } from 'react';
import { Button, MoreIcon } from './styles';
import { Props } from './types';
import useConnect from './connect';

const ActionsButton: FC<Props> = ({ className, courseId }) => {
  const { openActions } = useConnect(courseId);

  return (
    <Button className={className} onClick={openActions}>
      <MoreIcon />
    </Button>
  );
};

export default ActionsButton;
