import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultTrackInfo from '../TrackInfo';
import DefaultTrackPlayer from './TrackPlayer';
import DefaultSpeed from './Speed';
import DefaultShare from './Share';
import DefaultNotes from './Notes';
import DefaultFavorite from './Favorite';
import DefaultActions from './Actions';

export const TrackInfo = styled(DefaultTrackInfo)<{ $isMaximized: boolean }>`
  flex: 1 1 auto;
`;

export const TrackPlayer = styled(DefaultTrackPlayer)``;

export const TrackOptions = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;

  ${media.phoneLandscape} {
    margin-top: 0;
  }
`;
export const Left = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
`;
export const Right = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
`;

export const Speed = styled(DefaultSpeed)`
  font-size: 1.3rem;
  margin-right: 2rem;
`;

export const Share = styled(DefaultShare)``;

export const Notes = styled(DefaultNotes)`
  margin-right: 2rem;

  ${media.tabletPortrait} {
    display: none;
  }
`;

export const Favorite = styled(DefaultFavorite)``;

export const Actions = styled(DefaultActions)`
  display: none;

  ${media.tabletPortrait} {
    display: flex;
  }
`;

export const TrackControls = styled.div<{ $isMaximized: boolean }>`
  margin-bottom: 0;
  width: ${({ $isMaximized }) => ($isMaximized ? '60%' : '45%')};

  ${TrackOptions} {
    display: ${({ $isMaximized }) => ($isMaximized ? 'flex' : 'none')};
  }

  ${media.tabletPortrait} {
    margin-bottom: ${({ $isMaximized }) => ($isMaximized ? '0' : '0.1rem')};
    width: ${({ $isMaximized }) => ($isMaximized ? '75%' : 'auto')};
  }

  ${media.phoneLandscape} {
    width: ${({ $isMaximized }) => ($isMaximized ? '90%' : 'auto')};
  }
`;
