import React, { FC, useEffect, useRef, useState } from 'react';
import { Failed, ImageRoot, LoaderBox } from './styles';
import { Props } from './types';

const Image: FC<Props> = ({ alt = '', src, children, ...rest }) => {
  const img = useRef<HTMLImageElement>(document.createElement('img'));
  const [{ failed, loaded }, setState] = useState({
    failed: false,
    loaded: false,
  });

  useEffect(() => {
    const imgRef = img.current;

    const onLoad = () => setState((state) => ({ ...state, loaded: true }));
    const onFail = () =>
      setState((state) => ({ ...state, failed: true, loaded: true }));

    imgRef.src = src;
    imgRef.addEventListener('load', onLoad);
    imgRef.addEventListener('error', onFail);

    return () => {
      setState((state) => ({ ...state, failed: false }));

      imgRef.removeEventListener('load', onLoad);
      imgRef.removeEventListener('error', onFail);
    };
  }, [img, setState, src]);

  const isLoading = !loaded;
  const isLoadFailed = loaded && failed;
  const isLoadSuccessfull = loaded && !failed;

  return (
    <>
      {isLoading && (
        <LoaderBox data-jest-id="loader" {...rest}>
          {children}
        </LoaderBox>
      )}
      {isLoadFailed && (
        <Failed data-jest-id="failed" {...rest}>
          {children}
        </Failed>
      )}
      {isLoadSuccessfull && (
        <ImageRoot data-jest-id="image" alt={alt} src={src} {...rest}>
          {children}
        </ImageRoot>
      )}
    </>
  );
};

export default Image;
