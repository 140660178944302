import React, { FC, memo } from 'react';
import { CoursePermission, CourseStatus } from 'models/Course';
import {
  ActionsButton,
  ActionsIcon,
  Container,
  CourseThumbnail,
  Description,
  FavoriteMiniIcon,
  Info,
  Title,
} from './styles';
import Menu from './Menu';
import { Props } from './types';
import useLogic from './logic';

const Item: FC<Props> = ({
  backgroundColor = 'rgb(100, 137, 255)',
  className,
  courseHash,
  courseId,
  courseNumber,
  coursePermission,
  courseStatus,
  description = '',
  hasNotes,
  imageUrl,
  isDaily,
  isFavorite,
  isIntro,
  isShareable,
  onMarkAsUnplayed,
  onOpenActions,
  onOpenNotes,
  onOpenShareLink,
  onPlay,
  onToggleFavorite,
  title = '',
}) => {
  const {
    anchorEl,
    closeMenu,
    handleMarkAsUnplayed,
    handleOpenActions,
    handleOpenNotes,
    handleOpenShare,
    handlePlay,
    handleToggleFavorite,
    isMobile,
  } = useLogic({
    courseHash,
    courseId,
    isShareable,
    onMarkAsUnplayed,
    onOpenActions,
    onOpenNotes,
    onOpenShareLink,
    onPlay,
    onToggleFavorite,
  });

  const isFinished = courseStatus === CourseStatus.FINISHED;
  const showActionsButton =
    !isDaily && coursePermission === CoursePermission.UNLOCKED;
  const showMenu = !isDaily && !isMobile && showActionsButton;

  const menuId = `course-actions-${courseId}`;

  return (
    <Container className={className} onClick={handlePlay}>
      <CourseThumbnail
        $isIntro={isIntro}
        alt={title}
        backgroundColor={backgroundColor}
        courseNumber={courseNumber}
        coursePermission={coursePermission}
        imageUrl={imageUrl}
        isSquareImage={isDaily}
      />
      <Info $showAsFinished={isFinished}>
        {title && <Title>{title}</Title>}
        {description && (
          <Description>
            {isFavorite && <FavoriteMiniIcon />}
            {description}
          </Description>
        )}
      </Info>
      {showActionsButton && (
        <ActionsButton
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleOpenActions}
        >
          <ActionsIcon />
        </ActionsButton>
      )}
      {showMenu && (
        <Menu
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          isFavorite={isFavorite}
          menuId={menuId}
          onMarkAsUnplayed={handleMarkAsUnplayed}
          onOpenNotes={handleOpenNotes}
          onOpenShare={handleOpenShare}
          onToggleFavorite={handleToggleFavorite}
          showFavorite
          showMarkAsUnplayed={isFinished}
          showNotes={hasNotes}
          showShare={isShareable}
        />
      )}
    </Container>
  );
};

export default memo(Item);
