import { useState, useEffect } from 'react';
import { Howler } from 'howler';
import { getPlayerVolume, setPlayerVolume } from 'services/storage';

const useVolume = () => {
  const [volume, setVolume] = useState<number>(getPlayerVolume());
  const [isMuted, setIsMuted] = useState<boolean>(false);

  useEffect(() => {
    setPlayerVolume(volume);
    Howler.volume(isMuted ? 0 : volume / 100);
  }, [isMuted, volume]);

  return { isMuted, setIsMuted, setVolume, volume };
};

export default useVolume;

export type UseVolume = ReturnType<typeof useVolume>;
