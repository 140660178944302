/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const STRIPE_SOURCE_FRAGMENT = gql`
  fragment StripeSourceData on StripeSource {
    brand
    exp_month
    exp_year
    last4
  }
`;
