/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { dangerButtonStyles } from 'styles/mixins';
import { BaseLink } from '..';

export const Link = styled(BaseLink).attrs(({ theme }) => ({
  color: theme.colors.error,
}))`
  ${dangerButtonStyles};
  text-transform: unset;
`;
