import { useCallback } from 'react';
import useModal from 'graphql/hooks/useModal';
import { Props } from './types';

const useConnect = (courseId: Props['courseId']) => {
  const { openCourseActions } = useModal();

  const openActions = useCallback(() => {
    openCourseActions({ courseId });
  }, [courseId, openCourseActions]);

  return { openActions };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
