import React, { FC, memo } from 'react';
import {
  ActiveSessionsCount,
  CheckIcon,
  CompletedIcon,
  Container,
  Content,
  EmptyImage,
  Header,
  Info,
  Loader,
  Lock,
  Number,
  Overlay,
  PlayButton,
  StretchedImage,
  Subtitle,
  Title,
} from './styles';
import { Props } from './types';
import useConnect from './connect';

const Practice: FC<Props> = ({ className }) => {
  const {
    actionHref,
    backgroundColor,
    image,
    isAvailable,
    isCompleted,
    isDaily,
    isLoading,
    lockClick,
    play,
    subtitle,
    title,
  } = useConnect();

  return (
    <Container className={className}>
      <Header title="Practice" actionTitle="View All" actionHref={actionHref} />
      {isLoading ? (
        <Loader />
      ) : (
        <Content>
          <Lock
            isLocked={!isAvailable}
            linkUrl="/account/subscription"
            onClick={lockClick}
          >
            {image ? (
              <StretchedImage
                $backgroundColor={backgroundColor}
                alt="Next Practice"
                src={image}
              >
                <Info>
                  <Title>{title}</Title>
                  <Subtitle>
                    {subtitle}
                    {isDaily && isCompleted && (
                      <CompletedIcon>
                        <CheckIcon />
                      </CompletedIcon>
                    )}
                  </Subtitle>
                </Info>
              </StretchedImage>
            ) : (
              <EmptyImage>
                <Number>{title}</Number>
                {isAvailable && <PlayButton />}
              </EmptyImage>
            )}
          </Lock>
          {isAvailable && (
            <Overlay onClick={play}>
              <PlayButton />
            </Overlay>
          )}
          <ActiveSessionsCount />
        </Content>
      )}
    </Container>
  );
};

export default memo(Practice);
