import { gql } from '@apollo/client';
import { USER_DATA_FRAGMENT } from '../fragments';

export const ME = gql`
  query Me {
    me {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const GET_SETTINGS = gql`
  query GetSettings {
    me {
      id
      settings
    }
  }
`;

export const GET_USER_ACTIVITY = gql`
  query GetUserActivity {
    me {
      id
      activity {
        minutes
        streak_days
        longest_streak
        intro_course_completed_count
      }
    }
  }
`;

export const GET_ACTIVE_SESSIONS_COUNT = gql`
  query GetActiveSessionsCount {
    activeSessionsCount {
      total
    }
  }
`;
