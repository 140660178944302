import flatMap from 'lodash/flatMap';
import notEmpty from 'lib/notEmpty';
import { ANNIVERSARY_COURSE } from 'models/Course';
import getPacks from './getPacks';
import getDaily from './getDaily';
import getFeatured from './getFeatured';

const getCourses = async () => {
  const packs = await getPacks();
  const daily = await getDaily();
  // TODO: Remove this when we have the new course query implemented.
  const featured = await getFeatured();

  const courses = flatMap(packs, (pack) => pack?.courses).filter(notEmpty);

  courses.push(ANNIVERSARY_COURSE);

  if (daily) {
    courses.push(daily);
  }

  // TODO: Remove this when we have the new course query implemented.
  if (featured && featured.length > 0) {
    featured.forEach((v) => {
      if (!courses.some((c) => c.id === v.id)) {
        courses.push(v);
      }
    });
  }

  return courses;
};

export default getCourses;
