import { css } from 'styled-components';
import { media, prettyScrollbar } from 'styles/mixins';

/*
 * This file exists to avoid circular references.
 */

export const LESSON_DESKTOP_WIDTH = 280;
export const LESSON_DESKTOP_MARGIN_BOTTOM = 0;
export const LESSON_DESKTOP_MARGIN_RIGHT = 16;
export const LESSON_DESKTOP_HEIGHT = 269;
export const LESSON_MOBILE_WIDTH = 118;
export const LESSON_MOBILE_MARGIN_BOTTOM = 0;
export const LESSON_MOBILE_MARGIN_RIGHT = 14;
export const LESSON_MOBILE_HEIGHT = 160;

export const courseDimensions = css`
  height: ${LESSON_DESKTOP_HEIGHT}px;
  margin: 0 ${LESSON_DESKTOP_MARGIN_RIGHT}px ${LESSON_DESKTOP_MARGIN_BOTTOM}px 0;
  min-width: ${LESSON_DESKTOP_WIDTH}px;
  width: ${LESSON_DESKTOP_WIDTH}px;

  ${media.phoneLandscape} {
    margin: 0 ${LESSON_MOBILE_MARGIN_RIGHT}px ${LESSON_MOBILE_MARGIN_BOTTOM}px 0;
    height: ${LESSON_MOBILE_HEIGHT}px;
    min-width: ${LESSON_MOBILE_WIDTH}px;
    width: ${LESSON_MOBILE_WIDTH}px;
  }
`;

export const horizontalCarouselStyles = css`
  ${({ theme: { pad, margin } }) => css`
    margin-top: ${margin.md};
        
    ${prettyScrollbar({
      margin: `0 ${pad.xxxl} 0 0`,
      vertical: false,
    })};

    ${media.tabletPortrait} {
      ${prettyScrollbar({
        margin: `0 ${pad.xl} 0 0`,
        vertical: false,
      })};
    }
  
    ${media.phoneLandscape} {
      margin: ${margin.md} -${pad.lg} ${margin.xl} 0;
      
      ${() =>
        prettyScrollbar({
          margin: `0 ${pad.lg} 0 0`,
          vertical: false,
        })};
    `};
`;
