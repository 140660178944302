import React, { FC, memo } from 'react';
import {
  Actions,
  Favorite,
  Left,
  Notes,
  Right,
  Share,
  Speed,
  TrackControls,
  TrackInfo,
  TrackOptions,
  TrackPlayer,
} from './styles';
import { Props } from './types';
import useConnect from './connect';

const CoursePlayer: FC<Props> = ({ courseId, isMaximized }) => {
  const {
    activeSessionIdRef,
    isLoadingTrack,
    markTrackAsListened,
    trackBackgroundColor,
    trackHash,
    trackHasNotes,
    trackHasSpeedControl,
    trackImageUrl,
    trackIsDaily,
    trackIsIntro,
    trackIsMeditation,
    trackIsShareable,
    trackNumber,
    trackStatus,
    trackDisplayTitle,
    trackDisplaySubtitle,
    trackUrl10,
    trackUrl20,
  } = useConnect(courseId);

  return (
    <>
      <TrackInfo
        $isMaximized={isMaximized}
        courseNumber={trackNumber}
        imageUrl={trackImageUrl}
        isDaily={trackIsDaily || courseId === '658'}
        isIntro={trackIsIntro}
        isLoadingTrack={isLoadingTrack}
        trackBackgroundColor={
          courseId === '658' ? '#f0f0f0' : trackBackgroundColor
        }
        trackDisplayTitle={trackDisplayTitle}
        trackDisplaySubtitle={trackDisplaySubtitle}
      />
      <TrackControls $isMaximized={isMaximized}>
        <TrackPlayer
          activeSessionIdRef={activeSessionIdRef}
          courseId={courseId}
          isLoadingTrack={isLoadingTrack}
          markTrackAsListened={markTrackAsListened}
          trackHasSpeedControl={trackHasSpeedControl}
          trackIsDaily={trackIsDaily}
          trackIsMeditation={trackIsMeditation}
          trackStatus={trackStatus}
          trackUrl10={trackUrl10}
          trackUrl20={trackUrl20}
        />
        {!trackIsDaily && (
          <>
            <TrackOptions>
              <Left>
                {!isLoadingTrack && trackHasSpeedControl && <Speed />}
                {trackIsShareable && (
                  <Share courseId={courseId} trackHash={trackHash} />
                )}
              </Left>
              <Right>
                <Notes courseId={courseId} hasNotes={trackHasNotes} />
                <Favorite courseId={courseId} />
              </Right>
            </TrackOptions>
            {isMaximized && <Actions courseId={courseId} />}
          </>
        )}
      </TrackControls>
    </>
  );
};

export default memo(CoursePlayer);
