import Text from 'components/Text';
import styled from 'styled-components';

export const Container = styled.div<{ darkBg?: boolean }>`
  color: ${({ darkBg, theme }) =>
    darkBg ? theme.textLight : theme.textLightBg.text};
  margin-top: ${({ theme }) => theme.margin.md};
`;

export const Title = styled.p`
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.font.textSuperMegaBig};
  margin-bottom: ${({ theme }) => theme.margin.sm};
  color: inherit;
  text-align: center;
`;

export const Subtitle = styled(Text)`
  color: inherit;
  text-align: center;
`;
