import getCourses from './getCourses';

const getCourse = async (key: string) => {
  const lwKey = key.toLowerCase();
  const courses = await getCourses();

  return courses.find(
    (course) =>
      course.id.toLowerCase() === lwKey ||
      course.course_hash.toLowerCase() === lwKey,
  );
};

export default getCourse;
