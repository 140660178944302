import { ModalType } from 'graphql/generated/globalTypes';
import useModal from 'graphql/hooks/useModal';

const useConnect = () => {
  const { type, close } = useModal();

  return { isOpen: type === ModalType.DAILY_DURATION, closeModal: close };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
