/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_PLAYER = gql`
  query GetPlayer {
    player @client {
      audioStatus
      autoplayType
      courseId
      dailyMeditationDuration
      displayStatus
      endPosition
      endPositionPaused
      initialPosition
      selftimerDuration
      selftimerInterval
      selftimerStartTime
      type
    }
  }
`;
