export enum DailyMeditationDuration {
  MIN_10 = 1,
  MIN_20 = 2,
}

export const DAILY_DURATIONS = {
  [DailyMeditationDuration.MIN_10]: 600,
  [DailyMeditationDuration.MIN_20]: 1200,
};

export const durationOptions = [
  {
    id: DailyMeditationDuration.MIN_10,
    text: '10 minutes',
  },
  {
    id: DailyMeditationDuration.MIN_20,
    text: '20 minutes',
  },
];
