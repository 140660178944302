import React, { FC, memo } from 'react';
import { range } from 'lodash';
import { Container, Content, Duration, Info, Thumbnail, Title } from './styles';
import { Props } from './types';

const Loader: FC<Props> = ({ isMobile }) => {
  const length = !isMobile ? 3 : 6;

  return (
    <Container>
      {range(length).map((i: number) => (
        <Content key={i}>
          <Thumbnail />
          <Info>
            <Title />
            <Duration />
          </Info>
        </Content>
      ))}
    </Container>
  );
};

export default memo(Loader);
