import Text from 'components/Text';
import React, { FunctionComponent, memo } from 'react';
import { Props } from './types';

const PaymentSource: FunctionComponent<Props> = ({ source }) => (
  <div>
    <Text>
      Your subscription is currently being billed to a {source.brand}
      <br />
      ending in {source.last4} that expires on {source.expMonth}/
      {source.expYear}.
    </Text>
  </div>
);

export default memo(PaymentSource);
