import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';

const CorporateRedirect: FC<RouteComponentProps<{ company: string }>> = ({
  match: {
    params: { company },
  },
}) => <Redirect to={`/account/corporate/${company}`} />;

const CorporateContainer: FC<RouteComponentProps> = ({ match: { path } }) => (
  <Switch>
    <Route component={CorporateRedirect} path={`${path}/:company`} />
    <Redirect to="/" />
  </Switch>
);

export default CorporateContainer;
