import { screenSizes } from 'styles/metrics';

export const TABLET_LANDSCAPE_SLIDE_HEIGHT = '312px';

export const TABLET_SLIDE_HEIGHT = '330px';

export const PHONE_SLIDE_HEIGHT = '200px';

export const DEFAULT_SLIDE_HEIGHT = '390px';

export const DEFAULT_SLIDER_CENTER_PADDING = '190px';

export const SLIDER_RESPONSIVE_CONFIG = [
  {
    breakpoint: screenSizes.tabletLandscape,
    settings: { centerPadding: '120px' },
  },
  {
    breakpoint: screenSizes.phonePortrait,
    settings: { centerPadding: '20px' },
  },
];
