import Text from 'components/Text';
import Button from 'components/Button';
import styled from 'styled-components';

export const Container = styled.form.attrs(() => ({ noValidate: true }))`
  .StripeElement {
    background-color: white;
    height: 40px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    transition: box-shadow 150ms ease;
  }

  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

export const Submit = styled(Button)`
  &&& {
    margin-top: 1.25rem;
  }
`;

export const Error = styled(Text)`
  color: ${({ theme }) => theme.colors.error};
  margin-top: ${({ theme }) => theme.margin.xxsm};
`;

export const Title = styled(Text)``;
