import { useQuery, QueryHookOptions } from '@apollo/client';
import { Abouts } from '../generated/Abouts';
import { ABOUTS } from '../queries';

const useAbouts = (options: QueryHookOptions<Abouts> = {}) => {
  const { data, ...rest } = useQuery<Abouts>(ABOUTS, {
    errorPolicy: 'all',
    ...options,
  });

  const aboutsData = data?.abouts;
  const abouts = {
    privacy: aboutsData?.find((el) => el?.id === '3')?.body || '',
    terms: aboutsData?.find((el) => el?.id === '2')?.body || '',
    aboutSam: aboutsData?.find((el) => el?.id === '1')?.body || '',
    aboutCourse: aboutsData?.find((el) => el?.id === '5')?.body || '',
  };

  return { abouts, ...rest };
};

export default useAbouts;
