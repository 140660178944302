import React, { FC, useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { up } from 'lib/paths';
import FAQ from 'components/Pages/FAQ';
import Info from 'components/Pages/Info';
import useAbouts from 'graphql/hooks/useAbouts';
import { Container, Content, Header } from './styles';

const InfoPage: FC = () => {
  const { abouts } = useAbouts();
  const { aboutCourse, aboutSam, privacy, terms } = abouts;
  const { path } = useRouteMatch();

  const basePath = useMemo(() => up(path), [path]);

  return (
    <Container>
      <Header backHref={basePath} testId="info-header" title="Settings" />
      <Content>
        <Switch>
          <Route path={`${basePath}/help`}>
            <FAQ />
          </Route>
          <Route path={`${basePath}/course`}>
            <Info title="About the Course" html={aboutCourse} />
          </Route>
          <Route path={`${basePath}/sam`}>
            <Info title="About Sam Harris" html={aboutSam} />
          </Route>
          <Route path={`${basePath}/privacy`}>
            <Info title="Privacy" html={privacy} />
          </Route>
          <Route path={`${basePath}/terms`}>
            <Info title="Terms" html={terms} />
          </Route>
        </Switch>
      </Content>
    </Container>
  );
};

export default InfoPage;
