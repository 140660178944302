import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { normalizeFeatureContent } from 'models/FeatureContent';
import { GetFeatureContent } from '../generated/GetFeatureContent';
import { GET_FEATURE_CONTENT } from '../queries';

const useFeatureContent = () => {
  const {
    data: featureContentData,
    loading: isLoadingGetFeatureContent,
  } = useQuery<GetFeatureContent>(GET_FEATURE_CONTENT, {
    fetchPolicy: 'cache-first',
  });

  const featureContent = useMemo(
    () =>
      featureContentData?.feature_content
        ? normalizeFeatureContent(featureContentData.feature_content)
        : undefined,
    [featureContentData],
  );

  return {
    featureContent,
    loading: !featureContentData && isLoadingGetFeatureContent,
  };
};

export default useFeatureContent;

export type UseFeatureContent = ReturnType<typeof useFeatureContent>;
