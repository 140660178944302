import React, { FC } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { media } from 'styles/mixins';
import useMediaQuery from 'lib/useMediaQuery';
import { MeditationType } from 'models/Course';
import Control from './Control';
import Loader from './Loader';
import { Item, List } from './styles';
import { Props } from './types';

const HorizontalCarousel: FC<Props> = ({
  data,
  isLoading = false,
  setTrack,
  ...rest
}) => {
  const isMobile = useMediaQuery(media.phoneLandscape);

  if (isLoading || !data) {
    return <Loader isMobile={isMobile} />;
  }

  return (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          itemData={data}
          itemSize={isMobile ? 130 : 290}
          height={isMobile ? 160 : 285}
          layout="horizontal"
          width={width}
          {...rest}
        >
          {({ index, style }) => {
            const course = data[index];

            return (
              <div style={style}>
                <Item
                  backgroundColor={course.color}
                  imageURL={course.image_url}
                  courseId={course.id}
                  courseNumber={course.course_number}
                  coursePermission={course.permission}
                  courseStatus={course.status}
                  description={course.displayDescription}
                  isDaily={course.is_daily}
                  isIntro={
                    course.isIntroCourse &&
                    course.meditationType !== MeditationType.SPECIAL_INTRO
                  }
                  onPlay={setTrack}
                  title={course.title}
                />
              </div>
            );
          }}
        </List>
      )}
    </AutoSizer>
  );
};

export default HorizontalCarousel;

export { Control };
