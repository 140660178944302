import React, { FC } from 'react';
import { Button, Root } from './styles';
import { Props } from './types';

const DeleteButton: FC<Props> = (props) => (
  <Root>
    <Button {...props}>Remove all Favorites</Button>
  </Root>
);

export default DeleteButton;
