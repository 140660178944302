import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useRef,
  MouseEvent,
} from 'react';
import { useField } from 'formik';
import {
  ClearButton,
  ClearButtonContainer,
  ClearContainer,
  Container,
  Error,
  Input,
  Wrapper,
} from './styles';
import { Props } from './types';

const TextField: FC<Props> = ({
  className,
  id,
  isClearable = false,
  label,
  name,
  normalize,
  readOnly = false,
  ref,
  type = 'text',
  ...rest
}) => {
  const [field, meta, helpers] = useField(name);
  const { touched, error } = meta;
  const { setValue, setTouched, setError } = helpers;
  const _ref = useRef<HTMLInputElement>(null);
  const inputRef = ref || _ref;

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const value = normalize ? normalize(e.target.value) : e.target.value;
      setValue(value);
    },
    [setValue, normalize],
  );

  const onClear = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (isClearable) {
        setValue('');
        setError(null);
        setTouched(false);
        inputRef.current?.focus();
      }
    },
    [inputRef, isClearable, setError, setValue, setTouched],
  );

  const input = (
    <Input
      $hasLabel={!!label}
      $isClearable={isClearable}
      id={label ? id || name : id}
      readOnly={readOnly}
      ref={inputRef}
      type={type}
      {...field}
      {...rest}
      name={name}
      onChange={onChange}
    />
  );

  return (
    <Container className={className}>
      <Wrapper id={id || name} label={label} readOnly={readOnly}>
        {isClearable ? (
          <ClearContainer>
            {input}
            <ClearButtonContainer $hasValue={!!field.value}>
              <ClearButton onClick={onClear} tabIndex={-1} type="button" />
            </ClearButtonContainer>
          </ClearContainer>
        ) : (
          <>{input}</>
        )}
      </Wrapper>
      {touched && error && <Error>{error}</Error>}
    </Container>
  );
};

export default memo(TextField);
