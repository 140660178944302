import React, { FC } from 'react';
import SubscriptionPlans from 'components/SubscriptionPlans';
import Info from './Info';
import Modify from './Modify';
import { Content, Header, Box, Title } from './styles';
import useConnect from './connect';

const Subscription: FC = () => {
  const {
    isAnonymous,
    isMobileWebview,
    subscription,
    url,
    user,
  } = useConnect();
  const { plans, loading, cancel, reactivate, create } = subscription;

  const headerBackRef = !isMobileWebview ? '/account' : undefined;
  const headerTitle = !isMobileWebview ? 'Settings' : ' ';

  const plan = plans && plans.find((p) => user.subscriptionPlanId === p.id);

  if (isAnonymous) {
    return null;
  }

  if (isMobileWebview) {
    return (
      <>
        <Content>
          <Box>
            {user.isSubscribed ? (
              <>
                <Title>Your subscription</Title>
                <Info
                  plan={plan}
                  renewIntent={user.subscriptionRenewIntent}
                  joinedAt={user.joinedAt}
                  endTime={user.subscriptionExpiresAt}
                  isSubscribed={user.isSubscribed}
                  source={user.subscriptionSource}
                />
              </>
            ) : (
              <Title>There is no active subscription</Title>
            )}
          </Box>
        </Content>
      </>
    );
  }

  return (
    <>
      <Header
        testId="info-header"
        title={headerTitle}
        backHref={headerBackRef}
      />
      <Content>
        {user.isSubscribed && (
          <Box>
            <Title>Your subscription</Title>
            <Info
              plan={plan}
              renewIntent={user.subscriptionRenewIntent}
              joinedAt={user.joinedAt}
              endTime={user.subscriptionExpiresAt}
              isSubscribed={user.isSubscribed}
              source={user.subscriptionSource}
            />
            <Modify
              reactivate={reactivate}
              cancel={cancel}
              url={url}
              plans={plans}
              onToken={create}
              isLoading={loading}
              email={user.email}
              renewIntent={user.subscriptionRenewIntent}
              source={user.subscriptionSource}
            />
          </Box>
        )}
        {!user.isSubscribed && (
          <SubscriptionPlans
            email={user.email}
            isLoading={loading}
            onToken={create}
            plans={plans}
          />
        )}
      </Content>
    </>
  );
};

export default Subscription;
