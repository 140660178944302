import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from 'components/IconButton';
import DefaultPauseIcon from 'components/Icons/Pause';
import DefaultPlayIcon from 'components/Icons/Play';
import { Props } from './types';

export const Loading = styled(CircularProgress).attrs(() => ({
  color: 'inherit',
  size: '100%',
  thickness: 0.9,
}))`
  left: 0;
  position: absolute;
  top: 0;
`;

export const PlayIcon = styled(DefaultPlayIcon)`
  margin-left: 10%;
`;

export const PauseIcon = styled(DefaultPauseIcon)``;

export const Button = styled(IconButton)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue500};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0.5rem;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.colors.blue300};
  }

  & svg {
    height: 40%;
    width: 40%;
  }
`;

export const Container = styled.div<{
  $isLoading: Props['isLoading'];
}>`
  min-height: 5rem;
  min-width: 5rem;
  position: relative;
  ${Button} {
    pointer-events: ${({ $isLoading }) => ($isLoading ? 'none' : 'default')};
  }
`;
