import React, {
  FC,
  useCallback,
  ChangeEvent,
  ClipboardEvent,
  KeyboardEvent,
} from 'react';
import { StyledInput } from './styles';
import { Props } from './types';

const Input: FC<Props> = ({
  index,
  inputRef,
  onChange,
  onKeyDown,
  onPaste,
  ...rest
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      onChange(e.target.value.slice(-1), index),
    [index, onChange],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => onKeyDown(e, index),
    [index, onKeyDown],
  );

  const handlePaste = useCallback(
    (e: ClipboardEvent<HTMLInputElement>) => onPaste(e, index),
    [index, onPaste],
  );

  return (
    <StyledInput
      ref={inputRef}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      {...rest}
    />
  );
};

export default Input;
