import React, { FC, memo } from 'react';
import HorizontalCarousel from 'components/HorizontalCarousel';
import SectionHeader from 'components/SectionHeader';
import useConnect from './connect';

const Theory: FC = () => {
  const {
    handleControlPress,
    isLoading,
    lessons,
    listRef,
    loadTrack,
  } = useConnect();

  return (
    <>
      <SectionHeader
        actionTitle="View All"
        actionHref="/theory"
        onControlPress={handleControlPress}
        title="Theory"
      />
      <HorizontalCarousel
        data={lessons}
        outerRef={listRef}
        isLoading={isLoading}
        itemCount={!isLoading ? lessons.length : 10}
        setTrack={loadTrack}
      />
    </>
  );
};

export default memo(Theory);
