import React, { FC, memo } from 'react';
import { StyledSVG } from './styles';
import { Props } from './types';

const Logo: FC<Props> = ({ lightBg, ...rest }) => (
  <StyledSVG
    {...rest}
    lightBg={lightBg}
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 718 166"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="WAKING-UP-Sam-Harris" fill="#010101">
        <g id="WAKING-UP">
          <polygon
            id="Path"
            points="112.9 2.2 93.2 54.4 70.6 2.2 63.2 2.2 40.7 54.4 21 2.2 0.8 2.2 35.6 90.7 42.9 90.7 67 36.9 90.9 90.7 98.2 90.7 133 2.2"
          />
          <path
            d="M144.2,79.2 L180.4,79.2 L185.3,90.7 L204.8,90.7 L166.1,2.2 L158.7,2.2 L119.8,90.7 L139.3,90.7 L144.2,79.2 Z M162.3,34.5 L174,64.3 L150.7,64.3 L162.3,34.5 Z"
            id="Shape"
            fillRule="nonzero"
          />
          <polygon
            id="Path"
            points="287.5 2.2 265.9 2.2 232.1 43.4 232.1 2.2 214 2.2 214 90.7 232.1 90.7 232.1 44.6 270 90.7 291.9 90.7 253.6 43.9"
          />
          <rect id="Rectangle" x="301" y="2.2" width="18.1" height="88.5" />
          <polygon
            id="Path"
            points="393.7 54.9 343.8 2.2 336.4 2.2 336.4 90.7 354.5 90.7 354.5 37.6 404.2 90.7 411.8 90.7 411.8 2.2 393.7 2.2"
          />
          <path
            d="M513.6,42.2 L471.1,42.2 L471.1,57.9 L495.5,57.9 L495.5,65.1 C490.7,71.8 482.5,76.1 472.2,76.1 C454.4,76.1 442.7,63.6 442.7,46.4 C442.7,29.5 454,17.3 471.1,17.3 C482,17.3 489.9,22.1 495.8,30.3 L508.7,19.7 C500.2,7.6 487.2,0.5 471.4,0.5 C444.7,0.5 424,20.4 424,46.4 C424,72.4 444.8,92.3 471.4,92.3 C490.3,92.3 505.9,82.2 513.7,68.3 L513.7,42.2 L513.6,42.2 Z"
            id="Path"
          />
          <path
            d="M617.2,53.8 C617.2,67.1 608.9,73.9 597.7,73.9 C586.7,73.9 578.2,67.1 578.2,53.8 L578.2,2.2 L560.1,2.2 L560.1,52.5 C560.1,76.3 576.1,90.7 597.7,90.7 C619.2,90.7 635.3,76.2 635.3,52.5 L635.3,2.2 L617.2,2.2 L617.2,53.8 Z"
            id="Path"
          />
          <path
            d="M685.3,2.2 L652,2.2 L652,90.8 L670.1,90.8 L670.1,59.5 L685.3,59.5 C704,59.5 717.2,47.8 717.2,30.8 C717.2,13.5 703.9,2.2 685.3,2.2 Z M685.3,43.6 L670.1,43.6 L670.1,18.1 L685.3,18.1 C693.8,18.1 698.7,23.3 698.7,30.7 C698.7,38.4 693.7,43.6 685.3,43.6 Z"
            id="Shape"
            fillRule="nonzero"
          />
        </g>
        <g id="Sam-Harris" transform="translate(148.000000, 130.000000)">
          <path
            d="M23.1,6.6 L20.2,8.8 C18.4,6.1 15.6,4.6 12.6,4.6 C9.5,4.6 6.7,6.2 6.7,9.5 C6.7,12.5 9,13.6 12.1,15.1 L15.8,16.9 C20.7,19.2 23.6,21.7 23.6,26.2 C23.6,31.8 19,35.5 12.8,35.5 C7.5,35.5 3.1,32.7 0.5,28.3 L3.4,26.1 C5.4,29.2 8.4,31.8 12.7,31.8 C16.6,31.8 19.4,29.6 19.4,26.2 C19.4,23.1 17.1,21.9 13.8,20.3 L10.1,18.5 C6.2,16.6 2.6,14.3 2.6,9.5 C2.6,4.2 7.3,0.8 12.7,0.8 C17.1,0.9 21,3.3 23.1,6.6 Z"
            id="Path"
          />
          <path
            d="M66.4,28.1 L50.1,28.1 L47.2,34.9 L43.1,34.9 L57.6,0.9 L59.1,0.9 L73.6,34.9 L69.4,34.9 L66.4,28.1 Z M58.2,8.2 L51.5,24.6 L64.9,24.6 L58.2,8.2 Z"
            id="Shape"
            fillRule="nonzero"
          />
          <polygon
            id="Path"
            points="125.5 0.9 125.5 34.9 121.6 34.9 121.6 10.9 110.9 25.4 109.5 25.4 98.8 10.8 98.8 34.8 94.9 34.8 94.9 0.8 96.3 0.8 110.2 20.1 124.2 0.8 125.5 0.8"
          />
          <polygon
            id="Path"
            points="205.9 34.9 202 34.9 202 18.8 183.3 18.8 183.3 34.9 179.4 34.9 179.4 1.5 183.3 1.5 183.3 15.3 202 15.3 202 1.5 205.9 1.5"
          />
          <path
            d="M250.7,28.1 L234.4,28.1 L231.5,34.9 L227.4,34.9 L241.9,0.9 L243.4,0.9 L257.9,34.9 L253.7,34.9 L250.7,28.1 Z M242.5,8.2 L235.8,24.6 L249.2,24.6 L242.5,8.2 Z"
            id="Shape"
            fillRule="nonzero"
          />
          <path
            d="M300.7,34.9 L290.3,20.9 L283.2,20.9 L283.2,34.9 L279.3,34.9 L279.3,1.5 L291,1.5 C297.6,1.5 301.7,5.5 301.7,11.2 C301.7,15.7 299.1,19.2 294.7,20.5 L305.3,35 L300.7,35 L300.7,34.9 Z M283.1,17.3 L290.7,17.3 C295.1,17.3 297.4,14.8 297.4,11.2 C297.4,7.7 295.1,5 290.7,5 L283.1,5 L283.1,17.3 Z"
            id="Shape"
            fillRule="nonzero"
          />
          <path
            d="M347.4,34.9 L337,20.9 L329.9,20.9 L329.9,34.9 L326,34.9 L326,1.5 L337.7,1.5 C344.3,1.5 348.4,5.5 348.4,11.2 C348.4,15.7 345.8,19.2 341.4,20.5 L352,35 L347.4,35 L347.4,34.9 Z M329.9,17.3 L337.5,17.3 C341.9,17.3 344.2,14.8 344.2,11.2 C344.2,7.7 341.9,5 337.5,5 L329.9,5 L329.9,17.3 Z"
            id="Shape"
            fillRule="nonzero"
          />
          <polygon
            id="Path"
            points="376.7 34.9 372.8 34.9 372.8 1.5 376.7 1.5"
          />
          <path
            d="M421.2,6.6 L418.3,8.8 C416.5,6.1 413.7,4.6 410.7,4.6 C407.6,4.6 404.8,6.2 404.8,9.5 C404.8,12.5 407.1,13.6 410.2,15.1 L413.9,16.9 C418.8,19.2 421.7,21.7 421.7,26.2 C421.7,31.8 417.1,35.5 410.9,35.5 C405.6,35.5 401.2,32.7 398.6,28.3 L401.5,26.1 C403.5,29.2 406.5,31.8 410.8,31.8 C414.7,31.8 417.5,29.6 417.5,26.2 C417.5,23.1 415.2,21.9 411.9,20.3 L408.2,18.5 C404.3,16.6 400.7,14.3 400.7,9.5 C400.7,4.2 405.4,0.8 410.8,0.8 C415.3,0.9 419.2,3.3 421.2,6.6 Z"
            id="Path"
          />
        </g>
      </g>
    </g>
  </StyledSVG>
);

export default memo(Logo);
