import React, { FC } from 'react';
import image1 from 'assets/images/onboarding/new-onboarding-3.png';
import image2 from 'assets/images/onboarding/new-onboarding-1.png';
import image3 from 'assets/images/onboarding/new-onboarding-2.png';
import LessonIcon from 'components/Icons/Lesson';
import MeditationIcon from 'components/Icons/Meditation';
import LogoIcon from 'components/Icons/Logo';
import {
  Container,
  Description,
  Info,
  Title,
  Image,
  iconWidth,
} from './styles';
import { Props } from './types';

const getImage = (index: number) => {
  if (index === 0) return image1;
  if (index === 1) return image2;
  return image3;
};

const getIcon = (index: number) => {
  if (index === 0) return <LogoIcon size={iconWidth} />;
  if (index === 1) return <MeditationIcon size={iconWidth} />;
  return <LessonIcon size={iconWidth} />;
};

const Page: FC<Props> = ({ title, description, index }) => (
  <Container>
    <Image src={getImage(index)} />
    <Info>
      {getIcon(index)}
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Info>
  </Container>
);

export default Page;
