import styled from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DefaultWrapper from '../Wrapper';

export const Container = styled.div``;

export const Wrapper = styled(DefaultWrapper)<{ readOnly: boolean }>`
  pointer-events: ${({ readOnly }) => (readOnly ? 'none' : 'default')};
  user-select: ${({ readOnly }) => (readOnly ? 'none' : 'default')};
`;

export const PickersUtilsProvider = styled(
  MuiPickersUtilsProvider,
).attrs(() => ({ utils: DateFnsUtils }))``;

export const Picker = styled(KeyboardDatePicker).attrs(() => ({
  FormHelperTextProps: {
    error: true,
  },
  InputProps: {
    disableUnderline: true,
  },
}))<{ $hasLabel: boolean }>`
  width: 100%;
  .MuiInputBase-root {
    padding: ${({ $hasLabel, theme }) =>
      `${$hasLabel ? '0' : theme.pad.md} ${theme.pad.lg} ${
        $hasLabel ? '0' : theme.pad.md
      } ${theme.pad.xl}`};
  }
  .MuiInputBase-input {
    padding: 0;
    font-size: 0.9375rem;
    color: ${({ theme }) => theme.textInput};
  }
  .MuiButtonBase-root {
    padding: 6px;
  }
  .MuiSvgIcon-root {
    font-size: 1.4rem;
  }
`;

export const Error = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 0.9rem;
  margin: 0.4rem 0.4rem 0 0.4rem;
`;
