import DefaultChevronButton from 'components/ChevronButton';
import styled, { css } from 'styled-components';

export const ChevronButton = styled(DefaultChevronButton)<{ active: boolean }>`
  ${({ active }) =>
    !active &&
    css`
      background-color: ${({ theme }) => theme.colors.blue900};
      color: ${({ theme }) => theme.textLight};
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `};
`;

export const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  svg {
    margin-right: ${({ theme }) => theme.margin.xsm};
  }
`;

export const SelectedText = styled.div`
  color: ${({ theme }) => theme.textSuperFaint};
  margin-left: auto;
  margin-right: 0.4rem;
`;
