import { DocumentNode } from 'graphql';
import { apolloClient } from 'services/api';

function cacheReadQuery<T>(query: DocumentNode) {
  try {
    const cacheData = apolloClient.readQuery<T>({ query });
    return cacheData;
  } catch (e) {
    return null;
  }
}

export default cacheReadQuery;
