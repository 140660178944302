import React, { FC, memo } from 'react';
import { Container, Sup } from './styles';
import { Props } from './types';

const Price: FC<Props> = ({ amount, className }) => {
  const amountWhole = Math.floor(amount / 100);
  const amountDecimals = amount - amountWhole * 100;
  const amountDecimalsFilled =
    amountDecimals < 9 ? `0${amountDecimals}` : amountDecimals;

  return (
    <Container className={className}>
      <Sup>$</Sup>
      {!amount ? (
        '--'
      ) : (
        <>
          {amountWhole}
          {amountDecimals ? <Sup>.{amountDecimalsFilled}</Sup> : null}
        </>
      )}
    </Container>
  );
};

export default memo(Price);
