import React, { FC, memo } from 'react';
import Page from './Page';
import { Container, Slider } from './styles';

const PAGES = [
  {
    title: 'What makes Waking Up different?',
    description:
      'Meditation is about more than feeling good in the moment. There are fundamental discoveries to be made in the laboratory of your own mind.',
  },
  {
    title: 'Practice',
    description:
      'Begin with the Introductory Course, learning a progressive series of meditation techniques, before unlocking access to an ongoing series of Daily Meditations.',
  },
  {
    title: 'Theory',
    description:
      'Access a growing curriculum of Lessons on a wide variety of topics as well as Conversations with a wide range of teachers and scholars.',
  },
];

const settings = {
  dots: true,
  fade: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
};

const OnboardingModal: FC = () => (
  <Container>
    <Slider {...settings}>
      {PAGES.map(({ title, description }, index) => (
        <Page
          key={title}
          title={title}
          description={description}
          index={index}
        />
      ))}
    </Slider>
  </Container>
);

export default memo(OnboardingModal);
