import styled from 'styled-components';
import DefaultButton from 'components/Button';

// eslint-disable-next-line import/prefer-default-export
export const Button = styled(DefaultButton)`
  &&& {
    margin-bottom: ${({ theme }) => theme.margin.md};
    font-size: 0.9375rem;
    span {
      text-transform: lowercase;
    }
  }
`;
