import React, { FC, memo } from 'react';
import { PlayerAudioStatus } from 'graphql/generated/globalTypes';
import {
  Buttons,
  Container,
  ControlButton,
  JumpBackIcon,
  JumpButton,
  JumpForwardIcon,
  ProgressContainer,
  Slider,
  Status,
  Time,
  TimeDuration,
  TimePending,
  TimePlayed,
  TimeSeparator,
  VolumeControls,
} from './styles';
import { Props } from './types';
import useLogic from './logic';

const PlayerControls: FC<Props> = ({
  basic = false,
  className,
  duration,
  isLoading = false,
  isSeeking = false,
  seekDisabled = false,
  onChangePosition = () => null,
  onChangeAudioStatus = () => null,
  position = 0,
  audioStatus = PlayerAudioStatus.STOP,
}) => {
  const {
    localPosition,
    onJumpBackwards,
    onJumpForward,
    onSliderChange,
    onSliderChangeCommitted,
    timeDuration,
    timePending,
    timePlayed,
  } = useLogic({
    duration,
    onChangePosition,
    position,
  });

  return (
    <Container $basic={basic} className={className}>
      <Buttons>
        {!seekDisabled && (
          <JumpButton onClick={onJumpBackwards}>
            <JumpBackIcon />
          </JumpButton>
        )}
        <ControlButton
          audioStatus={audioStatus}
          isLoading={isLoading || isSeeking}
          onClick={onChangeAudioStatus}
        />
        {!seekDisabled && (
          <JumpButton onClick={onJumpForward}>
            <JumpForwardIcon />
          </JumpButton>
        )}
      </Buttons>
      <Status>
        <ProgressContainer>
          <Time>
            <TimePlayed>{timePlayed}</TimePlayed>
            <TimeSeparator>/</TimeSeparator>
            <TimePending>- {timePending}</TimePending>
            <TimeDuration>{timeDuration}</TimeDuration>
          </Time>
          <Slider
            max={duration}
            min={0}
            onChange={!seekDisabled ? onSliderChange : undefined}
            onChangeCommitted={
              !seekDisabled ? onSliderChangeCommitted : undefined
            }
            step={1}
            value={!duration ? 0 : localPosition}
          />
        </ProgressContainer>
        <VolumeControls />
      </Status>
    </Container>
  );
};

export default memo(PlayerControls);
