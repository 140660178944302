/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import IconButton from 'components/IconButton';
import { Props } from './types';

export const Container = styled(IconButton)<{
  $isFavorite: Props['isFavorite'];
  $light: Props['light'];
}>`
  align-items: center;
  color: ${({ $isFavorite, $light, theme }) => {
    if ($isFavorite) return '#f4477c';
    if ($light) return '#ffffff';
    return theme.textLightBg.text;
  }};
  display: flex;
  height: fit-content;
  justify-content: center;
  margin: 0;
  padding: 0;
`;
