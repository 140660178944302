import React, { FC } from 'react';
import { Container, Image, Loader } from './styles';
import { Props } from './types';
import useLogic from './logic';

const StretchedImage: FC<Props> = ({
  alt,
  children,
  className,
  onClick,
  position = 'right',
  src,
}) => {
  const { isLoading, onLoad, onError } = useLogic();

  return (
    <Container className={className} onClick={onClick}>
      {isLoading && <Loader />}
      <Image
        $position={position}
        alt={alt}
        src={src}
        onLoad={onLoad}
        onError={onError}
      />
      {children}
    </Container>
  );
};

export default StretchedImage;
