import styled from 'styled-components';
import { motion } from 'framer-motion';
import CloseIcon from 'components/Icons/RoundedClose';
import { ZIndex } from 'styles/metrics';
import { media } from 'styles/mixins';

export const Container = styled(motion.div).attrs(() => ({
  initial: { y: '100%', opacity: 0 },
  animate: {
    y: '-30px',
    opacity: 1,
    transition: { duration: 0.5, ease: 'easeInOut', delay: 1 },
  },
  exit: {
    y: '100%',
    opacity: 0,
    transition: { duration: 0.5, ease: 'easeInOut' },
  },
}))`
  align-items: center;
  background-color: #ffffff;
  border-radius: 25px;
  bottom: 0;
  box-shadow: 0 0 1px 0 rgba(36, 32, 56, 0.1),
    0 0 32px -12px rgba(36, 32, 56, 0.2);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 860px;
  padding: 0.8rem 0.9rem 0.8rem 1.2rem;
  position: fixed;
  right: 0;
  z-index: ${ZIndex.COOKIES_CONSENT};

  ${media.tabletPortrait} {
    margin-left: 4vw;
    margin-right: 4vw;
  }
`;

export const Message = styled.div`
  color: #92909c;
  letter-spacing: 0;
  line-height: 1.3;
  margin-right: 1.4rem;
`;

export const Href = styled.a`
  color: #92909c;
  letter-spacing: 0;
  line-height: 1.3;
  text-decoration: underline;
`;

export const CloseButton = styled(CloseIcon).attrs(() => ({}))`
  color: ${({ theme }) => theme.darkGrey};
  cursor: pointer;
  flex-shrink: 0;
`;
