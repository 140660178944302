import { format } from 'date-fns';
import { apolloClient } from 'services/api';
import { normalizeCourse, getDailyPermission } from 'models/Course';
import { DAILY_MEDITATION_PACK_ID, PackType } from 'models/Pack';
import { GetDaily, GetDailyVariables } from 'graphql/generated/GetDaily';
import { GET_DAILY } from 'graphql/queries';

const getDaily = async () => {
  const { data: dailyData } = await apolloClient.query<
    GetDaily,
    GetDailyVariables
  >({
    query: GET_DAILY,
    variables: { date: format(new Date(), 'yyyy-MM-dd') },
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  });

  if (!dailyData?.me || !dailyData?.daily) {
    return undefined;
  }

  const isSubscribed = dailyData.me.is_subscribed || false;
  const isDailyUnlocked = dailyData.me.unlocked_content_status === 'unlocked';

  const daily = normalizeCourse(dailyData.daily);
  daily.permission = getDailyPermission(isSubscribed, isDailyUnlocked);
  daily.packId = DAILY_MEDITATION_PACK_ID;
  daily.packHash = 'daily';
  daily.packTitle = 'Daily Meditation';
  daily.packType = PackType.MEDITATION;
  daily.packAutoPlay = false;

  return daily;
};

export default getDaily;
