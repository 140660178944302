import DefaultButton from 'components/Button';
import styled from 'styled-components';

export const Form = styled.form.attrs(() => ({ noValidate: true }))`
  display: flex;
  flex-direction: column;
`;

export const Button = styled(DefaultButton)`
  width: 10rem;
  margin-top: 1rem;
  align-self: center;
`;
