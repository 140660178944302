import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Menu: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
  </svg>
);

export default memo(Menu);
