import { gql } from '@apollo/client';
import {
  COURSE_DATA_FRAGMENT,
  USER_COURSES_DATA_FRAGMENT,
  USER_DATA_FRAGMENT,
} from '../fragments';

export const RESET_INTRO_COURSES = gql`
  mutation ResetIntroCourses {
    resetIntroCourses {
      ...UserData
      courses {
        ...CourseData
      }
    }
  }
  ${USER_DATA_FRAGMENT}
  ${COURSE_DATA_FRAGMENT}
`;

export const CHANGE_INTRO_COURSE = gql`
  mutation ChangeIntroCourse {
    changeIntroCourse {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const RESET_ALL_CONTENT = gql`
  mutation ResetAllContent {
    resetAllContent {
      ...UserData
      courses {
        ...CourseData
      }
    }
  }
  ${USER_DATA_FRAGMENT}
  ${COURSE_DATA_FRAGMENT}
`;

export const RESET_COURSE = gql`
  mutation ResetCourse($course_id: ID, $course_hash: String) {
    resetCourse(course_id: $course_id, course_hash: $course_hash) {
      ...UserCoursesData
    }
  }
  ${USER_COURSES_DATA_FRAGMENT}
`;
