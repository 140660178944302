import styled from 'styled-components';
import { media } from 'styles/mixins';
import DefaultModal from 'components/Modal';
import { Content as ModalContent } from 'components/Modal/styles';
import DefaultButton from 'components/Button';

export const Modal = styled(DefaultModal)`
  .MuiPaper-root {
    background: #ffffff;
  }
  ${ModalContent} {
    background: #ffffff;
    padding: 0;
  }

  ${media.tabletPortrait} {
    .MuiPaper-root {
      border-radius: ${({ theme }) =>
        `${theme.radius.lg} ${theme.radius.lg} 0 0`};
      bottom: 0;
      left: 0;
      padding: 0;
      position: absolute;
      right: 0;
    }
    ${ModalContent} {
      font-size: 0.9rem;
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.textLightBg.text};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 0.5rem;
`;

export const Image = styled.img`
  height: 200px;
  margin: 2rem 0;
  width: auto;

  @media (max-height: 500px) {
    height: 100px;
    margin: 1.4rem 0;
  }
`;

export const Title = styled.div`
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1.2;
  margin: 0 4rem 1rem 4rem;
  text-align: center;

  ${media.tabletPortrait} {
    margin: 0 3rem 1rem 3rem;
  }

  @media (max-height: 500px) {
    font-size: 1.6rem;
  }
`;

export const Description = styled.div`
  font-size: 1.2rem;
  text-align: center;
`;

export const Button = styled(DefaultButton)`
  &&& {
    margin: 2rem 0 4rem 0;
    width: 14rem;
  }

  @media (max-height: 500px) {
    &&& {
      margin: 2rem 0 1.4rem 0;
    }
  }
`;
