import styled from 'styled-components';
import DefaultShareButton from 'components/ShareButton';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
`;

export const ShareButton = styled(DefaultShareButton)`
  display: block;
  margin: 0;
  padding: 0;
  & svg {
    width: 1.2rem;
  }
`;

export const Title = styled.div`
  font-size: 0.75rem;
  line-height: 1;
  margin-top: 0.3rem;
`;
