import styled from 'styled-components';
import { Props } from './types';

export const Container = styled.div<Props>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;

  color: ${({ theme }) => theme.textLightBg.text};

  background-color: ${({ theme, src }) =>
    src ? theme.colors.grey : theme.colors.blue500};
  background-image: ${({ src }) => (src ? `url(${src})` : 'unset')};
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  box-shadow: ${({ theme, shadow }) =>
    shadow ? `0 2px 5px ${theme.textFaint}` : 'unset'};
`;

export const Letter = styled.div`
  color: ${({ theme }) => theme.colors.snow};
  text-transform: uppercase;
  font-size: inherit;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  line-height: 1;
`;
