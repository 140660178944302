import { apolloClient } from 'services/api';
import { Me } from 'graphql/generated/Me';
import { Login_login } from 'graphql/generated/Login';
import { ME } from 'graphql/queries';

function cacheUpdateMeData(userData: Login_login) {
  apolloClient.writeQuery<Me>({
    query: ME,
    data: { me: userData },
  });
}

export default cacheUpdateMeData;
