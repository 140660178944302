import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { unmountPurchaseDialog } from 'services/lightrail';
import { getToken, deleteToken } from 'services/storage';
import { unregisterAnalyticsUser, trackEvent } from 'services/analytics';
import { Me } from '../generated/Me';
import { Init } from '../generated/Init';
import { Logout, LogoutVariables } from '../generated/Logout';
import { INIT, ME } from '../queries';
import { LOGOUT } from '../mutations';
import { cacheResetPlayer } from '../cache';
import { formatErrorMessage } from '../helpers';

const useAuth = () => {
  const apolloClient = useApolloClient();
  const { replace } = useHistory();

  const { data: initData } = useQuery<Init>(INIT, {
    fetchPolicy: 'cache-only',
  });
  const init = initData?.init;

  const { data: tokenData } = useQuery<Me>(ME, {
    fetchPolicy: 'cache-only',
  });

  const token = tokenData?.me?.token;

  const setInit = useCallback(
    (newInit: boolean) => {
      // We want to delay the switch from false to true in order to
      // show the full screen loader a little longer
      setTimeout(
        () => {
          apolloClient.writeQuery<Init>({
            query: INIT,
            data: { init: newInit },
          });
        },
        newInit ? 500 : 0,
      );
    },
    [apolloClient],
  );

  const [logoutMutation, { loading: isLoadingLogout }] = useMutation<
    Logout,
    LogoutVariables
  >(LOGOUT);

  const logout = useCallback(
    async (type?: string) => {
      const storedToken = getToken();
      if (storedToken) {
        try {
          setInit(false);
          await logoutMutation({ variables: { device_token: storedToken! } });
          await apolloClient.cache.reset();
          if (type === 'checkout') {
            replace('/checkout');
          } else {
            replace('/');
          }
          unmountPurchaseDialog();
          deleteToken();
          unregisterAnalyticsUser();
          setInit(true);

          trackEvent('Logout');

          cacheResetPlayer();
        } catch (error) {
          setInit(true);
          throw new Error(formatErrorMessage(error, 'Failed to logout user'));
        }
      }
    },
    [logoutMutation, replace, setInit, apolloClient],
  );

  return {
    init,
    token,
    logout,
    isAuthenticated: !!token,
    loading: isLoadingLogout,
    setInit,
  };
};

export default useAuth;
