function interpolateColors(
  startColorStr: string,
  endColorStr: string,
  steps: number,
): string[] {
  const stepFactor = 1 / Math.max(steps - 1, 1);

  const startColorMatches = startColorStr.match(/\d+/g);
  const endColorMatches = endColorStr.match(/\d+/g);

  if (!startColorMatches || startColorMatches.length !== 3) {
    throw new Error('The start color is malformed!');
  }

  if (!endColorMatches || endColorMatches.length !== 3) {
    throw new Error('The end color is malformed!');
  }

  const startColor = startColorMatches.map(Number);
  const endColor = endColorMatches.map(Number);

  return Array(steps)
    .fill(null)
    .map((_, i) =>
      startColor.map((color1Val, j) => {
        const color2Val = endColor[j];
        return Math.round(color1Val + stepFactor * i * (color2Val - color1Val));
      }),
    )
    .map(([r, g, b]) => `rgb(${r}, ${g}, ${b})`);
}

export default interpolateColors;
