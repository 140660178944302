import braze from '@braze/web-sdk';
import { ApiConfig, DevConfig } from 'config';
import { AnalyticsEvent } from '../types';

export const setUpBraze = () => {
  braze.initialize(ApiConfig.BRAZE_KEY, {
    baseUrl: 'https://sdk.iad-06.braze.com',
    enableHtmlInAppMessages: true,
    enableLogging: DevConfig.brazeLogging,
  });
  braze.display.automaticallyShowNewInAppMessages();
  braze.openSession();

  if (DevConfig.brazeEnablePushNotifications) {
    braze.registerAppboyPushMessages();
  }
};

export const setBrazeUserProperties = (userProps: Record<string, string>) => {
  const brazeUser = braze.getUser();
  Object.keys(userProps).forEach((k) => {
    brazeUser.setCustomUserAttribute(k, userProps[k]);
  });
};

export const registerBrazeUser = (userId: string) => {
  braze.changeUser(userId);
};

export const trackBrazeEvent = (
  event: AnalyticsEvent,
  data?: Record<string, unknown>,
) => {
  braze.logCustomEvent(event, data);

  if (
    ['Media Start', 'Media Close', 'Media Complete'].includes(event) &&
    data
  ) {
    const action = event.split(' ')[1];
    braze.logCustomEvent(`Media Content ${action} ${data.name}`);
    braze.logCustomEvent(`Media Content ${action} ${data.hash}`);
  }
};
