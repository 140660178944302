import { apolloClient } from 'services/api';
import notEmpty from 'lib/notEmpty';
import {
  getLastIntroCourseId,
  getPackPermission,
  normalizePack,
  PACK_CATEGORIES_ALLOWED,
} from 'models/Pack';
import { getCoursePermission } from 'models/Course';
import { GET_PACKS } from 'graphql/queries';
import { GetPacks } from 'graphql/generated/GetPacks';

const getPacks = async () => {
  const { data: packsData } = await apolloClient.query<GetPacks>({
    query: GET_PACKS,
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  });

  if (!packsData?.me || !packsData?.packs) {
    return [];
  }

  const isSubscribed = packsData.me.is_subscribed || false;
  const lastIntroCourseId = getLastIntroCourseId(packsData.packs.data);

  return packsData.packs.data
    ? packsData.packs.data
        .filter(notEmpty)
        .filter(
          ({ category }) =>
            category && PACK_CATEGORIES_ALLOWED.includes(category),
        )
        .map((p) => {
          const pack = normalizePack(p);

          pack.courses = pack.courses
            .map((course) => ({
              ...course,
              hasSpeedControl: pack.hasSpeedControl,
              isLastIntro:
                !!course.id &&
                !!lastIntroCourseId &&
                course.id === lastIntroCourseId,
              packId: pack.id,
              packHash: pack.hash,
              packTitle: pack.title,
              packType: pack.type,
              packAutoPlay: pack.autoPlay,
              permission: getCoursePermission(isSubscribed, course.is_free),
            }))
            .sort((a, b) => a.course_number - b.course_number);

          return {
            ...pack,
            permission: getPackPermission(isSubscribed, pack.hasFreeCourses),
          };
        })
    : [];
};

export default getPacks;
