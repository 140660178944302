import React, { FC, useMemo } from 'react';
import { getInfoText, isShortVersion } from 'models/Plan';
import StripePlan from './StripePlan';
import { Container, FeatureList, Info, Plans, Title } from './styles';
import Thanks from './Thanks';
import { Props } from './types';

const SubscriptionPlans: FC<Props> = ({
  email,
  qualifyOriginalPricing = false,
  isLoading,
  onToken,
  plans,
  planId,
  darkBg,
  isPublic = false,
  hideFeaturesList = false,
  promoCode,
}) => {
  const plansToRender = useMemo(
    () => (planId ? plans.filter((plan) => plan.id === planId) : plans),
    [planId, plans],
  );

  const infoText = getInfoText(promoCode);
  const short = isShortVersion(promoCode);

  return (
    <Container darkBg={darkBg}>
      {!hideFeaturesList && (
        <>
          {!short && <Title>Become a Subscriber</Title>}
          {qualifyOriginalPricing && <Thanks darkBg={darkBg} />}
          <FeatureList>
            <li>Access to all Meditations</li>
            <li>Access to all Lessons</li>
            <li>Access to all Conversations</li>
            <li>Other exclusive content</li>
          </FeatureList>
        </>
      )}
      <Plans>
        {plansToRender.map((plan) => (
          <StripePlan
            key={plan.id}
            plan={plan}
            email={email}
            isPublic={isPublic}
            isLoading={isLoading}
            onToken={onToken}
            short={short}
          />
        ))}
      </Plans>
      {!short && <Info>{infoText}</Info>}
    </Container>
  );
};

export default SubscriptionPlans;
