import styled from 'styled-components';
import LogoBase from 'components/Logo';
import DefaultButton from 'components/Button';
import { media } from 'styles/mixins';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Logo = styled(LogoBase).attrs(() => ({
  lightBg: true,
}))`
  margin-bottom: ${({ theme }) => theme.margin.xl};

  ${media.phoneLandscape} {
    padding: ${({ theme }) => theme.pad.sm};
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: 1.85rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.25;
`;

export const Description = styled.p`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: 1rem;
  font-weight: ${({ theme }) => theme.font.weight.book};
  line-height: 1;
  padding-top: 1rem;
  padding-bottom: 2rem;
  white-space: pre-wrap;
`;

export const Subtitle = styled.h2`
  color: ${({ theme }) => theme.textLightBg.text};
  font-size: 1.45rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 1.25;
  margin-bottom: 1rem;
`;

export const Actions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Button = styled(DefaultButton)`
  &&& {
    background-color: transparent;
    color: black;
    margin-top: 0.6rem;
    max-width: 16rem;

    span {
      color: black;
      font-size: 0.8rem;

      circle {
        color: black;
      }
    }
  }
`;
