import styled from 'styled-components';
import { dangerButtonStyles } from 'styles/mixins';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.margin.xl};
  width: 100%;
`;

export const Button = styled.button`
  ${dangerButtonStyles};
`;
