import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import { media, prettyScrollbar } from 'styles/mixins';
import DefaultIconButton from 'components/IconButton';

export const Container = styled(Dialog)`
  position: relative;

  .MuiPaper-root {
    background-color: ${({ theme }) => theme.colors.grey};
    border-radius: ${({ theme }) => theme.radius.md};
    color: inherit;
    margin: 0;
    max-height: none;
    max-width: none;
    z-index: 1000;
  }
`;

export const Content = styled.div`
  ${prettyScrollbar({ vertical: true })};
  background-color: ${({ theme }) => theme.colors.grey};
  color: ${({ theme }) => theme.textLightBg.text};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-height: 100vh;
  max-width: 90vw;
  outline: none;
  overflow-y: auto;
  padding: 0 ${({ theme }) => theme.pad.xl} 3rem;
  position: relative;
  width: 40rem;

  ${media.phoneLandscape} {
    width: 100vw;
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.snow};
  margin: 0 -${({ theme }) => theme.pad.xl} 1rem;
  padding: 1rem 0;
  display: flex;
  ${media.phonePortrait} {
    flex-direction: column;
  }
`;

export const LogoBox = styled.div`
  padding: 0 ${({ theme }) => theme.pad.xl};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ImgLogo = styled.img`
  width: 8rem;
`;

export const TextBox = styled.div`
  padding-left: ${({ theme }) => theme.pad.xl};
  border-left: 1px solid ${({ theme }) => theme.textFaint};
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  ${media.phonePortrait} {
    padding: 0 ${({ theme }) => theme.pad.xl};
    align-items: center;
    text-align: center;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export const Description = styled.div``;

export const CloseButton = styled(DefaultIconButton)<{
  $reverseClose?: boolean;
}>`
  color: ${({ $reverseClose, theme }) =>
    $reverseClose ? theme.textLightBg.text : theme.colors.snow};
  font-size: ${({ theme }) => theme.font.textSuperBig};
  position: absolute;
  top: 0;
  right: 0;
  border: 18px solid transparent;
  border-top-color: ${({ $reverseClose, theme }) =>
    $reverseClose ? theme.colors.snow : theme.colors.blue500};
  border-right-color: ${({ $reverseClose, theme }) =>
    $reverseClose ? theme.colors.snow : theme.colors.blue500};
  border-top-right-radius: ${({ theme }) => theme.radius.sm};
  margin: 0;
  padding: 0;
  &::before {
    content: '\u00D7';
    position: absolute;
    height: 0.8rem;
    width: 0.8rem;
    top: -8px;
    right: -16px;
  }
`;
