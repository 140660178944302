import ScriptLoader from 'lib/ScriptLoader';
import themes from 'styles/themes';

const LIGHTRAIL_UI_URL =
  'https://embed.lightrail.com/dropin_v2/v2/lightrail-ui.js';

const DEFAULT_PURCHASE_OPTIONS = {
  launch_btn_label: 'Buy Gift Card',
  launch_btn_classname: 'ltrl_button',
  theme_btn_bg_primary_disabled: themes.light.textFaint,
  theme_btn_color_primary_disabled: themes.light.textSuperFaint,
};

// Lightrail doesn't provide type definitions
/* tslint:disable-next-line:no-any */
let purchaseDialog: any = null;

export function openPurchaseDialog() {
  purchaseDialog?.open();
}

export function unmountPurchaseDialog() {
  purchaseDialog?.unmount();
}

export const initializePurchaseDialog = (shopperToken?: string) =>
  ScriptLoader(LIGHTRAIL_UI_URL).then(
    () =>
      new Promise<void>((resolve, reject) => {
        try {
          // Lightrail doesn't provide type definitions
          /* tslint:disable-next-line:no-any */
          const lightrailUI = new (window as any).LightrailUI(shopperToken);
          purchaseDialog = lightrailUI.components.cardPurchaseDialog(
            DEFAULT_PURCHASE_OPTIONS,
          );

          purchaseDialog.on('ready', () => {
            resolve();
          });

          purchaseDialog.on('purchaseComplete', () => {
            // Let GTM know about this event.
            // Google Analytics doesn't provide type definitions
            /* tslint:disable-next-line:no-any */
            const { dataLayer } = window as any;
            dataLayer?.push({ event: 'purchaseComplete' });
          });

          purchaseDialog.mount();
        } catch (e) {
          reject(e);
        }
      }),
  );
