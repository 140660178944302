import useModal from 'graphql/hooks/useModal';
import { ModalType } from 'graphql/generated/globalTypes';
import { setAnalyticsUserProperties, trackEvent } from 'services/analytics';
import { useCallback } from 'react';

const useConnect = () => {
  const { type, close } = useModal();
  const isOpen = type === ModalType.ONBOARDING;

  const handelClickNoExperience = useCallback(() => {
    setAnalyticsUserProperties({ experience: 'Starting out' });
    trackEvent('Prompt Experience', {
      name: 'Experience',
      action: 'answer',
      value: 'Starting out',
    });
    close();
  }, [close]);

  const handleClickSomePractice = useCallback(() => {
    setAnalyticsUserProperties({ experience: 'Some Practice' });
    trackEvent('Prompt Experience', {
      name: 'Experience',
      action: 'answer',
      value: 'Some Practice',
    });
    close();
  }, [close]);

  const handleClickMoreExperienced = useCallback(() => {
    setAnalyticsUserProperties({ experience: 'More Experienced' });
    trackEvent('Prompt Experience', {
      name: 'Experience',
      action: 'answer',
      value: 'More Experienced',
    });
    close();
  }, [close]);

  const closeModal = useCallback(() => {
    close();
  }, [close]);

  return {
    handelClickNoExperience,
    handleClickSomePractice,
    handleClickMoreExperienced,
    isOpen,
    closeModal,
  };
};
export default useConnect;
export type UseConnect = ReturnType<typeof useConnect>;
