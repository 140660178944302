import { useEffect, useMemo, useCallback } from 'react';
import useMe from 'graphql/hooks/useMe';
import useFreeMonth from 'graphql/hooks/useFreeMonth';
import useModal from 'graphql/hooks/useModal';
import useCopyInputContent from 'lib/useCopyInputContent';
import { trackEvent } from 'services/analytics';
import { ModalType } from 'graphql/generated/globalTypes';

const CANNOT_SHARE_FREE_TIME = ['share', 'groups'];

const useConnect = () => {
  const { profile } = useMe();
  const { data, create } = useFreeMonth();
  const { type, close } = useModal();
  const { ref, copy } = useCopyInputContent();

  const isOpen = type === ModalType.GIVE_FREE_MONTH;

  const canShareFreeTime = useMemo(
    () => !CANNOT_SHARE_FREE_TIME.includes(profile.subscriptionSubtype),
    [profile.subscriptionSubtype],
  );

  useEffect(() => {
    if (isOpen) {
      create();
    }
  }, [isOpen, create]);

  const token = data?.token || undefined;
  const link = useMemo(
    () => (token ? `https://dynamic.wakingup.com/share/${token}` : ''),
    [token],
  );

  const copyLink = useCallback(() => {
    copy();
    trackEvent(canShareFreeTime ? 'Share App 30 days' : 'Share App regular', {
      token,
    });
  }, [canShareFreeTime, copy, token]);

  return {
    canShareFreeTime,
    inviteRef: ref,
    link,
    copyLink,
    isOpen,
    closeModal: close,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
