import React, { FC } from 'react';
import { durationOptions } from 'models/DailyMeditationDuration';
import Option from './Option';
import { Modal, Title } from './styles';
import { Props } from './types';
import useConnect from './connect';

const DailyDurationModal: FC<Props> = ({
  dailyDurationType,
  updateProfile,
}) => {
  const { isOpen, closeModal } = useConnect();

  return (
    <Modal hideLogo onClose={closeModal} open={isOpen}>
      <Title>Daily Meditation Duration</Title>
      {durationOptions.map(({ id, text }) => (
        <Option
          id={id}
          key={id}
          option={text}
          selected={dailyDurationType === id}
          onProfileUpdate={updateProfile}
          onClose={closeModal}
        />
      ))}
    </Modal>
  );
};

export default DailyDurationModal;
