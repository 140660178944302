import { useMemo, useCallback } from 'react';
import { ModalType } from 'graphql/generated/globalTypes';
import useModal from 'graphql/hooks/useModal';

const useConnect = () => {
  const { close, dialogPayload, type } = useModal();

  const { description, title } = useMemo(
    () => ({
      description: dialogPayload?.description,
      title: dialogPayload?.title,
    }),
    [dialogPayload],
  );

  const closeModal = useCallback(() => {
    close();
  }, [close]);

  return {
    closeModal,
    description,
    isOpen: type === ModalType.DIALOG,
    title,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
