import React, { FC, useCallback } from 'react';
import { injectStripe } from 'react-stripe-elements';
import CardSection from './CardSection';
import { handleSubmit } from './logic';
import { Button, Form } from './styles';
import { Props } from './types';

const CheckoutForm: FC<Props> = ({ stripe }) => {
  const onSubmit = useCallback(handleSubmit({ stripe }), [stripe]);

  return (
    <Form onSubmit={onSubmit}>
      <CardSection />
      <Button>Subscribe</Button>
    </Form>
  );
};

export default injectStripe(CheckoutForm);
