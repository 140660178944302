import React, { FC, useCallback } from 'react';
import { CheckmarkIcon, Container } from './styles';
import { Props } from './types';

const Element: FC<Props> = ({ isSelected, onClick, text, type }) => {
  const handleClick = useCallback(() => onClick(type), [onClick, type]);

  return (
    <Container
      $isSelected={isSelected}
      data-cypress-class="self-timer-list-selector-element"
      onClick={handleClick}
    >
      {text}
      {isSelected && (
        <CheckmarkIcon data-cypress-class="self-timer-list-selector-element-checkmark" />
      )}
    </Container>
  );
};

export default Element;
