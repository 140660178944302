import { User } from 'models/User';

export enum SubscriptionMode {
  LAMP = 0,
  CLOUD = 1,
}

export const SubscriptionModeNames = ['lamp', 'cloud'];

export enum SubscriptionPeriod {
  MONTH = 'm',
  YEAR = 'y',
  MENU_MONTH = 'mm',
  MENU_YEAR = 'my',
}

export type Props = {
  className?: string;
  code?: string;
  email?: string;
  isPublic?: boolean;
  onSubscribed?: (user?: User) => void;
  period?: SubscriptionPeriod;
};

export type LogicProps = {
  code: Props['code'];
  isPublic: boolean;
  onSubscribed: Props['onSubscribed'];
  period: Props['period'];
};
