import styled from 'styled-components';
import { motion } from 'framer-motion';
import { media } from 'styles/mixins';
import MuiCircularProgress from '@material-ui/core/CircularProgress';
import MuiLinearProgress from '@material-ui/core/LinearProgress';
import DefaultPlayButton from 'components/PlayButton';
import { DEFAULT_VIDEO_HEIGHT, DEFAULT_VIDEO_WIDTH } from './constants';

export const LoaderContainer = styled.div``;

export const Loader = styled(MuiCircularProgress)``;

export const PlayButton = styled(DefaultPlayButton).attrs(() => ({
  clear: true,
}))`
  color: ${({ theme }) => theme.colors.snow};
  height: 60px;
  width: 60px;
`;

export const Video = styled.video`
  background: ${({ theme }) => theme.colors.black};
  border-radius: inherit;
  display: block;
  height: 100%;
  object-fit: contain;
  width: 100%;

  &::-webkit-media-controls {
    display: none !important;
  }
`;

export const Source = styled.source``;

export const ProgressBar = styled(motion.custom(MuiLinearProgress)).attrs(
  () => ({
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.3 },
    variant: 'determinate',
  }),
)`
  &.MuiLinearProgress-colorPrimary {
    background-color: #d8d8d8;
    .MuiLinearProgress-barColorPrimary {
      background-color: ${({ theme }) => theme.colors.blue700};
    }
  }
`;

export const EndMessage = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
}))`
  font-size: 2.4rem;
  font-weight: ${({ theme }) => theme.font.weight.medium};
  line-height: 1;
  white-space: nowrap;
`;

export const Container = styled.div<{ $isFinished: boolean }>`
  border-radius: 1.25rem;
  cursor: ${({ $isFinished }) => ($isFinished ? 'default' : 'pointer')};
  display: inline-block;
  height: ${DEFAULT_VIDEO_HEIGHT};
  overflow: hidden;
  position: relative;
  width: ${DEFAULT_VIDEO_WIDTH};

  ${LoaderContainer}, ${PlayButton}, ${EndMessage} {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${ProgressBar} {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    &.MuiLinearProgress-root {
      height: 1rem;
    }
  }

  ${media.phoneLandscape} {
    border-radius: 0.85rem;
    ${ProgressBar} {
      &.MuiLinearProgress-root {
        height: 0.6rem;
      }
    }
  }
`;
