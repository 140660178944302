import styled from 'styled-components';

export const Container = styled.div<{
  $hasLabel?: boolean;
  $readOnly?: boolean;
}>`
  background: ${({ theme, $readOnly }) =>
    $readOnly ? '#e1e1e1' : theme.colors.snow};
  border-radius: ${({ theme }) => theme.radius.sm};
  line-height: 1.2rem;
  padding: ${({ $hasLabel, theme }) => ($hasLabel ? `${theme.pad.md} 0` : '0')};
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.textLabel};
  display: block;
  font-size: 0.9375rem;
  font-weight: bold;
  margin: ${({ theme }) =>
    `0 ${theme.pad.xl} ${theme.pad.xsm} ${theme.pad.xl}`};
  pointer-events: none;
`;
