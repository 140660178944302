import React, { FC, memo } from 'react';
import { Container, Option, Switch } from './styles';
import { Props } from './types';

const Switcher: FC<Props> = ({
  isLeft,
  leftText,
  onClickLeft,
  onClickRight,
  rightText,
  dark,
  ...rest
}) => (
  <Container dark={dark} {...rest}>
    <Option dark={dark} active={isLeft} onClick={onClickLeft}>
      {leftText}
    </Option>
    <Option dark={dark} active={!isLeft} onClick={onClickRight}>
      {rightText}
    </Option>
    <Switch isLeft={isLeft} />
  </Container>
);

export default memo(Switcher);
