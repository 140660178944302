import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Play: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={(size * 77) / 60}
    viewBox="0 0 60 77"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M 0,77 V 0 l 60,38.497898 z" strokeWidth=":4.20667601" />
  </svg>
);

export default memo(Play);
