import styled from 'styled-components';
import { ZIndex } from 'styles/metrics';
import DefaultLock from 'components/Icons/Lock';
import DefaultLink from 'components/Link';

export const Container = styled.div`
  align-items: stretch;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
`;

export const Content = styled.div`
  align-items: stretch;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  filter: brightness(60%);
`;

export const Link = styled(DefaultLink)`
  text-decoration: none;
`;

export const Overlay = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${ZIndex.OVERLAY};
`;

export const LockIcon = styled(DefaultLock)`
  color: ${({ theme }) => theme.colors.snow};
`;
