import React, { FC, memo } from 'react';
import { AnimatePresence } from 'framer-motion';
import useActiveSessions from 'graphql/hooks/useActiveSessions';
import { Props } from './types';
import { Container } from './styles';

const ActiveSessionsCount: FC<Props> = ({ className }) => {
  const { activeSessionsCount, loading } = useActiveSessions({
    fetchPolicy: 'cache-and-network',
    pollInterval: 15000,
  });

  if (loading || activeSessionsCount === 0) return null;

  return (
    <AnimatePresence>
      <Container className={className}>
        {activeSessionsCount} now practicing
      </Container>
    </AnimatePresence>
  );
};

export default memo(ActiveSessionsCount);
