import React, { FC } from 'react';
import { Formik } from 'formik';
import {
  Confirmation,
  Description,
  Form,
  Paragraph,
  Select,
  Submit,
  TextField,
  Title,
} from './styles';
import { HEAR_OPTIONS } from './constants';
import useConnect from './connect';

const Register: FC = () => {
  const { initialValues, isLoading, submit, validationSchema } = useConnect();

  return (
    <>
      <Title>Need a free account?</Title>
      <Description>
        <Paragraph>
          If you would like to use the Waking Up app but truly cannot afford it,
          you can request a free account by filling out the form below. We grant
          100% of these requests.
        </Paragraph>
        <Paragraph>
          While we operate a business, we believe that money should never be the
          reason why someone can’t gain access to Waking Up.
        </Paragraph>
      </Description>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submit}
      >
        {({ handleSubmit, values }) =>
          !values.finish ? (
            <Form onSubmit={handleSubmit}>
              <TextField
                id="email"
                label="Email"
                maxLength={150}
                name="email"
                placeholder="e.g user@wakingup.com"
                type="email"
              />
              <TextField
                id="firstName"
                label="First name"
                maxLength={150}
                name="firstName"
                placeholder="e.g John"
              />
              <TextField
                id="lastName"
                label="Last name"
                maxLength={150}
                name="lastName"
                placeholder="e.g Doe"
              />
              <Select
                id="hear"
                isClearable={false}
                label="How did you hear about us?"
                name="hear"
                options={HEAR_OPTIONS}
              />
              {['Other', 'Other Podcast'].includes(values.hear) && (
                <TextField
                  id="hearOtherInfo"
                  maxLength={250}
                  name="hearOtherInfo"
                  placeholder={
                    values.hear === 'Other Podcast'
                      ? 'e.g Joe Rogan Podcast'
                      : 'e.g Magazine interview'
                  }
                />
              )}
              <Submit
                disabled={isLoading || !values.email}
                isLoading={isLoading}
                type="submit"
              >
                Submit request
              </Submit>
            </Form>
          ) : (
            <Confirmation message="You will get an email shortly" />
          )
        }
      </Formik>
    </>
  );
};

export default Register;
