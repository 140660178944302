import { useCallback } from 'react';
import { ModalType } from 'graphql/generated/globalTypes';
import useModal from 'graphql/hooks/useModal';
import useCoursesActions from 'graphql/hooks/useCoursesActions';

const useConnect = () => {
  const { type, close } = useModal();
  const { changeIntroCourse } = useCoursesActions();

  const accept = useCallback(async () => {
    await changeIntroCourse();
    close();
  }, [changeIntroCourse, close]);

  return {
    accept,
    isOpen: type === ModalType.NEW_INTRO_PACK,
    closeModal: close,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
