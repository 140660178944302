import React from 'react';
import { render } from 'react-dom';
import { setUpAnalytics } from 'services/analytics';
import { initializeSentry } from 'services/sentry';
import App from './App';
import './styles';

initializeSentry();
setUpAnalytics();

const rootEl = document.getElementById('root');
render(<App />, rootEl);
