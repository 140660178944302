import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Unplayed: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={(size * 228) / 202}
    viewBox="0 0 202 228"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      d="M23.024 207.247c.22.043.392.035.526.023l.203-.178.81-.47 155.73-90.834c.405-.215.99-1.05.99-1.996 0-.476-.57-1.338-1.662-2.118L24.036 20.893c-.41-.27-1.047-.27-1.457 0l-.872.523c-.403.214-.99 1.049-.99 1.994v181.282c0 .491.584 1.407 1.862 2.26l.445.295zM23.308 228c-1.783 0-7.804-.305-12.662-4.112C3.873 219.192 0 212.224 0 204.692V23.41C0 15.14 4.503 7.336 11.513 3.378c7.16-4.531 16.578-4.503 23.712.078l155.688 90.84C197.959 98.994 202 106.1 202 113.792c0 8.365-4.605 16.255-11.755 20.168L35.63 224.152C31.182 227.479 26.065 228 23.308 228z"
    />
  </svg>
);

export default memo(Unplayed);
