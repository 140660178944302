import { useMemo, useCallback } from 'react';
import { FormikHelpers } from 'formik';
import { useMutation } from '@apollo/client';
import { trackEvent } from 'services/analytics';
import { SELF_SERVE_ACCOUNT } from 'graphql/mutations';
import { SelfServe, SelfServeVariables } from 'graphql/generated/SelfServe';
import { formatErrorMessage } from 'graphql/helpers';
import { validationSchema } from './constants';
import { FormFields } from './types';

const useConnect = () => {
  const [selfServe, { loading }] = useMutation<SelfServe, SelfServeVariables>(
    SELF_SERVE_ACCOUNT,
  );
  const initialValues: FormFields = useMemo(
    () => ({
      email: '',
      firstName: '',
      lastName: '',
      hear: '',
      hearOtherInfo: '',
      finish: false,
    }),
    [],
  );

  const submit = useCallback(
    async (values: FormFields, helpers: FormikHelpers<FormFields>) => {
      const variables = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        source: ['Other', 'Other Podcast'].includes(values.hear)
          ? `${values.hear}: ${values.hearOtherInfo}`
          : values.hear,
      };

      try {
        await selfServe({ variables });
        trackEvent('Free Account - Form Submit');
        helpers.setFieldValue('finish', true);
      } catch (e) {
        const field = ['Other', 'Other Podcast'].includes(values.hear)
          ? 'hearOtherInfo'
          : 'hear';

        helpers.setFieldError(field, formatErrorMessage(e));
      }
    },
    [selfServe],
  );

  return {
    initialValues,
    isLoading: loading,
    submit,
    validationSchema,
  };
};

export default useConnect;
