import { useCallback } from 'react';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';
import { Props } from './types';

const useConnect = (onClose: Props['onClose']) => {
  const { profile } = useMe();
  const { openSelfTimer } = useModal();

  const openSelfTimerAndCloseSidebar = useCallback(() => {
    openSelfTimer();
    onClose();
  }, [onClose, openSelfTimer]);

  return {
    profile,
    openSelfTimer: openSelfTimerAndCloseSidebar,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
