import { trackMediaEvent } from 'services/analytics';
import getProfile from './getProfile';
import updateSettings from './updateSettings';

const updatePlayerCode = async (
  courseId: string | null,
  setOptimisticResponse?: boolean,
) => {
  if (courseId) {
    const { id } = await getProfile();
    const code = `${id}-${courseId}-${new Date().getTime()}`;

    await updateSettings(
      { player: { code, courseId } },
      setOptimisticResponse || false,
    );

    trackMediaEvent('Media Start', courseId);
  } else {
    await updateSettings({ player: null }, setOptimisticResponse || false);
  }
};

export default updatePlayerCode;
