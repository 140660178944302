import React, { FC, memo } from 'react';
import { Conditions, Container, Terms, Link } from './styles';
import { Props } from './types';

const Footer: FC<Props> = ({ className, isPublic }) => (
  <Container className={className}>
    <Conditions>
      Subscriptions billed as one payment. Recurring billing. Cancel anytime for
      any reasons.
    </Conditions>
    <Terms>
      By continuing, you agree to Waking Up’s{' '}
      <Link
        href={isPublic ? 'https://wakingup.com/terms-of-service' : undefined}
        to={isPublic ? undefined : '/account/terms'}
      >
        Terms & Conditions
      </Link>{' '}
      and{' '}
      <Link
        href={isPublic ? 'https://wakingup.com/privacy-policy' : undefined}
        to={isPublic ? undefined : '/account/privacy'}
      >
        Privacy Policy
      </Link>
    </Terms>
  </Container>
);

export default memo(Footer);
