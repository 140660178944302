import { gql } from '@apollo/client';
import { USER_DATA_FRAGMENT } from '../fragments';

export const GET_PIN = gql`
  mutation GetPin($email: String!) {
    refreshPin(email: $email) {
      message
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $pin: String!) {
    login(email: $email, pin: $pin) {
      ...UserData
    }
  }
  ${USER_DATA_FRAGMENT}
`;

export const LOGOUT = gql`
  mutation Logout($device_token: String!) {
    logout(device_token: $device_token) {
      message
    }
  }
`;
