import { useRef, useMemo, useEffect } from 'react';
import { trackEvent } from 'services/analytics';
import { CourseType, CourseStatus } from 'models/Course';
import { getSettings, updateSettings } from 'graphql/requests';
import useMeActions from 'graphql/hooks/useMeActions';
import useCourse from 'graphql/hooks/useCourse';
import { Props } from './types';

const useConnect = (courseId: Props['courseId']) => {
  const { addProgressStart } = useMeActions();
  const { course, loading: isLoadingCourse, markAsListened } = useCourse(
    courseId,
  );
  const activeSessionIdRef = useRef<string | null>(null);

  const {
    trackBackgroundColor,
    trackDuration,
    trackHash,
    trackHasNotes,
    trackHasSpeedControl,
    trackImageUrl,
    trackIsDaily,
    trackIsIntro,
    trackIsMeditation,
    trackIsShareable,
    trackNumber,
    trackStatus,
    trackDisplayTitle,
    trackDisplaySubtitle,
    trackUrl10,
    trackUrl20,
  } = useMemo(() => {
    const isDaily = course?.isDaily || false;
    return {
      trackBackgroundColor: isDaily ? course?.squareImageColor : '',
      trackDuration: course?.mp3_length || 0,
      trackHash: course?.course_hash || '',
      trackHasNotes: course?.hasNotes || false,
      trackHasSpeedControl: course?.hasSpeedControl || false,
      trackImageUrl:
        (isDaily ? course?.square_image_original : course?.image_url) || '',
      trackIsDaily: isDaily,
      trackIsIntro: course?.isIntroCourse || false,
      trackIsMeditation: course?.course_type === CourseType.MEDITATION,
      trackIsShareable: course?.shareable || false,
      trackNumber: course?.course_number || 0,
      trackStatus: course?.status || CourseStatus.UNSTARTED,
      trackDisplayTitle: course?.displayTitle || '',
      trackDisplaySubtitle: course?.displaySubtitle || '',
      trackUrl10: course?.mp3_url || '',
      trackUrl20: course?.mp3_2_url || '',
    };
  }, [course]);

  useEffect(() => {
    const checkFirstMediaStart = async () => {
      const settings = await getSettings();
      if (!settings.firstMediaStarted) {
        trackEvent('First Media Start');
        await updateSettings({ firstMediaStarted: true }, true);
      }
    };

    if (trackDisplayTitle !== 'START HERE') {
      checkFirstMediaStart();
    }
  }, [trackDisplayTitle]);

  useEffect(() => {
    if (courseId && trackIsMeditation && trackDuration > 0) {
      const getActiveSessionId = async () => {
        activeSessionIdRef.current = await addProgressStart({
          mediaDuration: trackIsDaily ? 20 : trackDuration,
          courseId,
        });
      };

      getActiveSessionId();
    } else {
      activeSessionIdRef.current = null;
    }
  }, [
    addProgressStart,
    courseId,
    trackIsDaily,
    trackIsMeditation,
    trackDuration,
  ]);

  return {
    activeSessionIdRef,
    isLoadingTrack: isLoadingCourse,
    markTrackAsListened: markAsListened,
    trackBackgroundColor,
    trackHash,
    trackHasNotes,
    trackHasSpeedControl,
    trackImageUrl,
    trackIsDaily,
    trackIsIntro,
    trackIsMeditation,
    trackIsShareable,
    trackNumber,
    trackStatus,
    trackDisplayTitle,
    trackDisplaySubtitle,
    trackUrl10,
    trackUrl20,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
