import { createMuiTheme } from '@material-ui/core';
import { SnackbarOrigin, CombinedClassKey } from 'notistack';

// Brand Colors Palette
const black = '#0c0c0c';
const blue900 = '#233973';
const blue800 = '#073F7e';
const blue700 = '#3252ae';
const blue600 = '#2e5fb8';
const blue500 = '#2f73d8';
const blue300 = '#6688df';
const blue100 = '#83b0e7';
const greenblue = '#8fc5d7';
const whiteblue = '#e2eefe';

// Palette used in new subscription flows
const subscription: Record<string, string> = {
  black: '#000000',
  black100: '#010101',
  blue: '#3072D8',
  darkblue: '#1C3146',
  grey100: '#D9D9D9',
  grey300: '#B5B5B5',
  grey600: '#616161',
  orange: '#F85A02',
  red: '#FF0000',
  white: '#FFFFFF',
};

const light = {
  name: 'LIGHT',

  // Padding
  pad: {
    xsm: '0.25rem',
    sm: '0.5rem',
    md: '0.8rem',
    lg: '1rem',
    xl: '1.4rem',
    xxl: '2.4rem',
    xxxl: '3.2rem',
  },

  // Margins
  margin: {
    xxxxsm: '0.125rem',
    xxxsm: '0.25rem',
    xxsm: '0.5rem',
    xsm: '0.75rem',
    sm: '1rem',
    md: '1.25rem',
    lg: '1.75rem',
    xlg: '2.25rem',
    xl: '3rem',
    xxl: '3.75rem',
    xxxl: '5rem',
  },

  // Borders
  radius: {
    xsm: '0.125rem',
    sm: '0.25rem',
    md: '0.5rem',
    lg: '1rem',
  },

  // Sizes
  heights: {
    formElement: '3rem',
    header: '4rem',
  },

  colors: {
    black,
    blue900,
    blue800,
    blue700,
    blue600,
    blue500,
    blue300,
    blue100,
    greenblue,
    whiteblue,
    darkblue: '#002660',
    beige: '#F7F4F0',
    success: '#27ae60', // check icon in self player modal
    error: 'rgb(245, 49, 68)',
    snow: '#ffffff', // mainly used for typo with dark background
    teal: '#30D2C7', // some check icons
    grey: '#f4f4f8', // body background color
    subscription,
  },

  // Body Colors
  darkGrey: '#848484',

  // Text Colors
  textLight: '#ececec',
  textFaint: '#D8D8D8',
  textSuperFaint: '#8294A5',
  textPlaceholder: '#c8c7cd',
  textInput: '#4a4a4a',
  textLabel: '#192c3e',
  textLink: '#ffffff',

  textLightBg: {
    text: '#2c3e50',
    textFaint: '#8294A5',
    textLight: '#324354',
    textStrong: '#192C3E',
  },

  // Constraints
  constraints: {
    formWidth: '24rem',
    contentWidth: '30rem',
  },

  // Border Colors
  border: '1px solid rgba(25, 44, 62, 0.1)',

  // Font Sizes
  font: {
    textSuperSmall: '0.75rem',
    textSmall: '0.85rem',
    textNotSoSmall: '0.945rem',
    text: '1rem',
    textBig: '1.1rem',
    textSuperBig: '1.2rem',
    textSuperMegaBig: '1.5rem',
    heading: '2.25rem',

    weight: {
      book: 400,
      medium: 500,
      bold: 700,
      black: 900,
    },
  },

  letterSpacing: {
    subtle: '0.015rem',
  },

  shadow: {
    subtle: '0 0 0.25rem gray',
  },
};

export type Padding = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';
export type Theme = typeof light;
const themes = {
  light,
  dark: light,
};

export const materialUITheme = createMuiTheme({
  palette: {
    primary: {
      main: light.colors.blue500,
    },
    secondary: {
      main: light.colors.blue100,
      contrastText: '#ffcc00',
    },
  },
  typography: {
    fontFamily: `"Circular Std", sans-serif`,
  },
});

export const notistackConfig: {
  maxSnack: number;
  anchorOrigin: SnackbarOrigin;
  classes: Partial<Record<CombinedClassKey, string>>;
} = {
  maxSnack: 3,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  classes: {
    variantSuccess: 'wu-snackbar-success',
    variantError: 'wu-snackbar-error',
    variantInfo: 'wu-snackbar-info',
  },
};

export default themes as { [key in keyof typeof themes]: Theme };
