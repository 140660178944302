import React, { FC } from 'react';
import SubpacksList from 'components/SubpacksList';
import CourseList from 'components/CourseList';
import useConnect from './connect';
import { SectionHeader, Packs } from './styles';

const TheoryPage: FC = () => {
  const {
    backUrl,
    currentUrl,
    isLoading,
    loadTrack,
    openCourseActions,
    openCourseNotes,
    openResetCourseConfirm,
    openShareLink,
    packs,
    packParam,
    push,
    selectedPackAutoPlay,
    selectedPackCourses,
    selectedPackId,
    selectedPackSubpacks,
    subpackParam,
    toggleFavorite,
    togglePackAutoPlay,
  } = useConnect();

  return (
    <>
      <SectionHeader
        actionHref={backUrl}
        actionTitle={backUrl ? '< BACK' : undefined}
        title="Theory"
      />
      <Packs
        isLoading={isLoading}
        items={packs}
        packParam={packParam}
        push={push}
        selectedPackAutoPlay={selectedPackAutoPlay}
        selectedPackId={selectedPackId}
        subpackParam={subpackParam}
        togglePackAutoPlay={togglePackAutoPlay}
      />
      {selectedPackSubpacks && selectedPackSubpacks.length > 0 ? (
        <SubpacksList
          courses={selectedPackCourses}
          currentUrl={currentUrl}
          isLoading={isLoading}
          setTrack={loadTrack}
          subPacks={selectedPackSubpacks}
        />
      ) : (
        <CourseList
          courses={selectedPackCourses}
          isLoading={isLoading}
          openCourseActions={openCourseActions}
          openCourseNotes={openCourseNotes}
          openResetCourseConfirm={openResetCourseConfirm}
          openShareLink={openShareLink}
          setTrack={loadTrack}
          toggleFavorite={toggleFavorite}
        />
      )}
    </>
  );
};

export default TheoryPage;
