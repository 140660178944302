import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import {
  AddProgressStart,
  AddProgressStartVariables,
} from '../generated/AddProgressStart';
import { AddProgress, AddProgressVariables } from '../generated/AddProgress';
import {
  UpdateUserProfile,
  UpdateUserProfileVariables,
} from '../generated/UpdateUserProfile';
import { ME } from '../queries';
import {
  ADD_PROGRESS,
  ADD_PROGRESS_START,
  UPDATE_USER_PROFILE,
} from '../mutations';
import { formatErrorMessage } from '../helpers';

const useMeActions = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [addProgressStartMutation] = useMutation<
    AddProgressStart,
    AddProgressStartVariables
  >(ADD_PROGRESS_START);

  const [addProgressMutation] = useMutation<AddProgress, AddProgressVariables>(
    ADD_PROGRESS,
    { refetchQueries: [{ query: ME }] },
  );

  const [updateUserProfileMutation] = useMutation<
    UpdateUserProfile,
    UpdateUserProfileVariables
  >(UPDATE_USER_PROFILE);

  const addProgressStart = useCallback(
    async ({
      mediaDuration,
      courseId,
    }: {
      mediaDuration: number;
      courseId?: string;
    }) => {
      const variables: AddProgressStartVariables = courseId
        ? { media_duration: mediaDuration, course_id: Number(courseId) }
        : { media_duration: mediaDuration };
      try {
        const response = await addProgressStartMutation({ variables });
        return response.data?.addProgressStart?.id || null;
      } catch (error) {
        enqueueSnackbar('Unable to add progress start', { variant: 'error' });
        return null;
      }
    },
    [addProgressStartMutation, enqueueSnackbar],
  );

  const addProgress = useCallback(
    async (progressData: {
      duration_seconds: number;
      finished_at: Date;
      progress_id: string;
      progress_type: 'Course' | 'SelfTimer';
      active_session_id?: string | null;
    }) => {
      try {
        const { progress_type, ...restProps } = progressData;
        await addProgressMutation({
          variables: {
            ...restProps,
            progress_id: Number(restProps.progress_id),
            progress_type: progress_type.toLowerCase(),
          },
        });
      } catch (error) {
        throw new Error(
          formatErrorMessage(error, 'Unable to add user progress'),
        );
      }
    },
    [addProgressMutation],
  );

  const updateProfile = useCallback(
    (
      userData: Omit<
        UpdateUserProfileVariables & { avatarFile?: File },
        'hasAvatar'
      >,
    ) => {
      const { avatar, avatarFile, ...restUserData } = userData;
      const avatarVars = avatarFile
        ? { hasAvatar: true, avatar: avatarFile }
        : { hasAvatar: false };

      return updateUserProfileMutation({
        variables: { ...avatarVars, ...restUserData },
      });
    },
    [updateUserProfileMutation],
  );

  return {
    addProgress,
    addProgressStart,
    updateProfile,
  };
};

export default useMeActions;

export type UseMeActions = ReturnType<typeof useMeActions>;
