import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import notEmpty from 'lib/notEmpty';
import {
  getLastIntroCourseId,
  getPackPermission,
  normalizePack,
  PACK_CATEGORIES_ALLOWED,
} from 'models/Pack';
import { getCoursePermission } from 'models/Course';
import { GetPacks } from 'graphql/generated/GetPacks';
import { GET_PACKS } from 'graphql/queries';

const usePacks = () => {
  const { data: packsData, loading: isLoadingPackData } = useQuery<GetPacks>(
    GET_PACKS,
    { fetchPolicy: 'cache-first' },
  );

  const { packs, introPack } = useMemo(() => {
    if (!packsData?.me || !packsData?.packs) {
      return { packs: [], introPack: undefined };
    }

    const isSubscribed = packsData.me.is_subscribed || false;
    const lastIntroCourseId = getLastIntroCourseId(packsData.packs.data);

    const allPacks = packsData.packs.data
      ? packsData.packs.data
          .filter(notEmpty)
          .filter(
            ({ category, hide_pack_from_master_pack }) =>
              !hide_pack_from_master_pack &&
              category &&
              PACK_CATEGORIES_ALLOWED.includes(category),
          )
          .map((p) => {
            const pack = normalizePack(p);

            pack.courses = pack.courses
              .map((course) => ({
                ...course,
                hasSpeedControl: pack.hasSpeedControl,
                isLastIntro:
                  !!course.id &&
                  !!lastIntroCourseId &&
                  course.id === lastIntroCourseId,
                packId: pack.id,
                packHash: pack.hash,
                packTitle: pack.title,
                packType: pack.type,
                packAutoPlay: pack.autoPlay,
                permission: getCoursePermission(isSubscribed, course.is_free),
              }))
              .sort((a, b) => a.course_number - b.course_number);

            return {
              ...pack,
              permission: getPackPermission(isSubscribed, pack.hasFreeCourses),
            };
          })
      : [];

    const intro = allPacks.find((pack) => pack.category === 'intro');

    return { packs: allPacks, introPack: intro };
  }, [packsData]);

  return {
    introPack,
    loading: !packsData && isLoadingPackData,
    packs,
  };
};

export default usePacks;

export type UsePacks = ReturnType<typeof usePacks>;
