import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { trackMediaEvent } from 'services/analytics';
import { Settings } from 'models/Settings';
import {
  UpdateSettings,
  UpdateSettingsVariables,
} from 'graphql/generated/UpdateSettings';
import { UPDATE_SETTINGS } from 'graphql/mutations';
import { getSettings, getProfile } from 'graphql/requests';
import { cacheUpdateSettings } from 'graphql/cache';

const useSettingsActions = () => {
  const [updateSettingsMutation] = useMutation<
    UpdateSettings,
    UpdateSettingsVariables
  >(UPDATE_SETTINGS);

  const updateSettings = useCallback(
    async (newSettings: Partial<Settings>, setOptimisticResponse?: boolean) => {
      if (newSettings) {
        const currentSettings = await getSettings();

        try {
          const newFullSettings = JSON.stringify({
            ...currentSettings,
            ...newSettings,
          });

          if (setOptimisticResponse) {
            cacheUpdateSettings(newFullSettings);
          }

          await updateSettingsMutation({
            variables: { settings: newFullSettings },
            update() {
              if (!setOptimisticResponse) {
                cacheUpdateSettings(newFullSettings);
              }
            },
          });
        } catch (error) {
          if (setOptimisticResponse) {
            cacheUpdateSettings(JSON.stringify(currentSettings));
          }
        }
      }
    },
    [updateSettingsMutation],
  );

  const updateCourseResume = useCallback(
    async (
      courseId: string,
      resume: number,
      setOptimisticResponse?: boolean,
    ) => {
      const currentSettings = await getSettings();
      const newResumePlayingList = {
        ...currentSettings.resumePlayingList,
        [courseId]: Math.floor(resume),
      };

      await updateSettings(
        { resumePlayingList: newResumePlayingList },
        setOptimisticResponse || false,
      );
    },
    [updateSettings],
  );

  const updatePlayerCode = useCallback(
    async (courseId: string | null, setOptimisticResponse?: boolean) => {
      if (courseId) {
        const { id } = await getProfile();
        const code = `${id}-${courseId}-${new Date().getTime()}`;
        await updateSettings(
          { player: { code, courseId } },
          setOptimisticResponse || false,
        );
        trackMediaEvent('Media Start', courseId);
      } else {
        await updateSettings({ player: null }, setOptimisticResponse || false);
      }
    },
    [updateSettings],
  );

  return {
    updateCourseResume,
    updatePlayerCode,
    updateSettings,
  };
};

export default useSettingsActions;

export type UseSettingsActions = ReturnType<typeof useSettingsActions>;
