import styled, { css } from 'styled-components';
import { ZIndex } from 'styles/metrics';

export const Container = styled.div<{ dark?: boolean }>`
  display: flex;
  width: 12rem;
  padding: 0.25rem;
  border-radius: ${({ theme }) => theme.radius.sm};
  background-color: ${({ dark, theme }) =>
    dark ? theme.colors.blue900 : theme.colors.blue500};
  position: relative;
  user-select: none;
`;

export const Switch = styled.div<{ isLeft: boolean }>`
  width: calc(50% - 0.25rem);
  height: calc(100% - 0.5rem);
  background-color: ${({ theme }) => theme.colors.snow};
  border-radius: ${({ theme }) => theme.radius.sm};
  position: absolute;
  left: 0.25rem;
  transition: transform 250ms cubic-bezier(0.26, -0.07, 0.12, 1.3);
  ${({ isLeft }) => !isLeft && `transform: translateX(100%);`};
`;

const optionColorLight = css<{ active: boolean }>`
  color: ${({ active, theme }) =>
    active ? theme.colors.blue500 : theme.colors.snow};
`;

const optionColorDark = css`
  color: ${({ theme }) => theme.colors.black};
`;

export const Option = styled.div<{ active: boolean; dark?: boolean }>`
  width: 50%;
  text-align: center;
  cursor: pointer;
  z-index: ${ZIndex.OVERLAY};
  transition: color 250ms ease;
  ${({ dark }) => (dark ? optionColorDark : optionColorLight)}
`;
