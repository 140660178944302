import React, { FC, memo } from 'react';
import { Actions, Close, Container, Description, Modal, Title } from './styles';
import useConnect from './connect';

const Dialog: FC = () => {
  const { closeModal, description, isOpen, title } = useConnect();

  return (
    <Modal hideLogo reverseClose open={isOpen} onClose={closeModal}>
      <Container>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        <Actions>
          <Close onClick={closeModal}>Close</Close>
        </Actions>
      </Container>
    </Modal>
  );
};

export default memo(Dialog);
