import React, { FC, memo } from 'react';
import Link from 'components/Link';
import { Container, Image, Loader, Lock, Header } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Featured: FC<Props> = ({ className }) => {
  const {
    deepLink,
    imageUrl,
    isLoading,
    isPublicDeeplink,
    isUnlocked,
  } = useConnect();

  const isLoadingContent = isLoading || !imageUrl;

  return (
    <Container className={className}>
      <Header title="Featured Content" />
      {isLoadingContent && <Loader />}
      {!isLoadingContent && (
        <Lock isLocked={!isUnlocked} linkUrl={deepLink}>
          <Link
            href={isPublicDeeplink ? deepLink : undefined}
            to={isPublicDeeplink ? undefined : deepLink}
          >
            <Image src={imageUrl} />
          </Link>
        </Lock>
      )}
    </Container>
  );
};

export default memo(Featured);
