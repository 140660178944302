import React, { FC, memo } from 'react';
import { Anchor, RouterLink } from './styles';
import { Props } from './types';

const Link: FC<Props> = ({ children, to, ...other }) =>
  to ? (
    <RouterLink to={to} {...other}>
      {children}
    </RouterLink>
  ) : (
    <Anchor {...other}>{children}</Anchor>
  );

export default memo(Link);
