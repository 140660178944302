import React, { FC } from 'react';
import Modal from 'components/Modal';
import { CopyButton, LinkBox, TextArea } from './styles';
import useConnect from './connect';

const GiveFreeMonthModal: FC = () => {
  const {
    canShareFreeTime,
    inviteRef,
    link,
    copyLink,
    isOpen,
    closeModal,
  } = useConnect();

  const title = canShareFreeTime
    ? 'Give a free month'
    : 'Share the app with your friends';

  return (
    <Modal
      title={title}
      description="Share the link with anyone you feel would benefit from experiencing Waking Up"
      onClose={closeModal}
      open={isOpen}
    >
      <LinkBox>
        <TextArea readOnly ref={inviteRef} rows={1} value={link} />
        <CopyButton onClick={copyLink}>Copy</CopyButton>
      </LinkBox>
    </Modal>
  );
};

export default GiveFreeMonthModal;
