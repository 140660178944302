import { motion } from 'framer-motion';
import styled from 'styled-components';
import { media } from 'styles/mixins';
import { ZIndex, screenSizes } from 'styles/metrics';
import bg from 'assets/images/background.svg';
import DefaultChevronDownIcon from 'components/Icons/ChevronDown';
import DefaultIconButton from 'components/IconButton';
import { ContainerProps } from './types';

export const FullScreenOverlay = styled(motion.div).attrs(() => ({
  initial: { opacity: 0, position: 'fixed' },
  animate: { opacity: 1, position: 'fixed' },
  exit: { opacity: 0, position: 'fixed' },
  transition: { duration: 0.2 },
}))`
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${ZIndex.PLAYER_OVERLAY};
`;

export const ChevronIcon = styled(DefaultChevronDownIcon)`
  height: 2rem;
  width: 1rem;
`;

export const IconButton = styled(DefaultIconButton)`
  align-items: center;
  display: flex;
  height: auto;
  margin: 0;
  padding: 0;
`;

export const ChevronButton = styled(IconButton)``;

export const ButtonsBar = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  -ms-overflow-style: none;
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: flex-end;
  overflow: hidden;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Container = styled(motion.div).attrs(
  ({ $isMaximized, $wHeight, $wWidth }: ContainerProps) => {
    const isDesktop = $wWidth > screenSizes.tabletLandscape;

    return {
      initial: {
        x: isDesktop ? '2.5vw' : 0,
        y: '100%',
        height: `calc(${$wHeight}px - 1.5%)`,
        width: isDesktop ? '95vw' : '100vw',
      },
      animate: {
        x: $isMaximized && isDesktop ? '2.5vw' : 0,
        y: 0,
        height: $isMaximized ? `calc(${$wHeight}px - 1.5%)` : 56,
        width: $isMaximized && isDesktop ? '95vw' : '100vw',
      },
      exit: { y: '100%', opacity: 0 },
      transition: { duration: 0.25, ease: 'easeInOut' },
    };
  },
)<ContainerProps>`
  align-items: stretch;
  background-image: ${({ $isMaximized }) =>
    $isMaximized ? `url('${bg}')` : 'none'};
  background-color: ${({ theme }) => theme.colors.blue900};
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: ${({ $isMaximized, theme }) =>
    $isMaximized ? theme.radius.lg : '0'};
  border-top-right-radius: ${({ $isMaximized, theme }) =>
    $isMaximized ? theme.radius.lg : '0'};
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  left: 0;
  padding: ${({ $isMaximized }) =>
    $isMaximized ? '0 1.8rem 5rem 1.8rem' : '0 1.8rem 0.3rem 1.8rem'};
  position: fixed;
  z-index: ${ZIndex.PLAYER};

  ${ButtonsBar} {
    left: ${({ $isMaximized }) => ($isMaximized ? 'auto' : '1rem')};
    margin: ${({ $isMaximized }) => ($isMaximized ? '1rem 0' : '0')};
    position: ${({ $isMaximized }) => ($isMaximized ? 'relative' : 'absolute')};
    top: ${({ $isMaximized }) => ($isMaximized ? 'auto' : 'calc(50% - 1rem)')};
  }

  ${ChevronButton} {
    transform: ${({ $isMaximized }) =>
      $isMaximized ? 'none' : 'rotate(180deg)'};
  }

  ${media.tabletPortrait} {
    padding: ${({ $isMaximized }) =>
      $isMaximized ? '0 1.8rem 5rem 1.8rem' : '0 1.6rem 0.6rem 1.6rem'};
  }

  ${media.phoneLandscape} {
    padding: ${({ $isMaximized }) =>
      $isMaximized ? '0.5rem 1.6rem 1rem 1.6rem' : '0 1.6rem 0.6rem 1.6rem'};

    ${ButtonsBar} {
      margin: ${({ $isMaximized }) => ($isMaximized ? '0.4rem 0 0 0' : '0')};
    }
  }
`;
