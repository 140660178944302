import styled, { keyframes } from 'styled-components';

export const LoaderContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: ${({ theme }) => theme.radius.md};
  overflow: hidden;
`;

const swipe = keyframes`
  from {
    transform: translateX(-50%);
  }

  to {
    transform: translateX(140%);
  }
`;

export const AnimatedBackground = styled.div`
  animation: ${swipe} 1s linear infinite;

  background-image: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.4) 20%,
    transparent 40%
  );
  height: 100%;
`;
