import styled, { css } from 'styled-components';

/*
 * A perfect centered play icon will produce the illusion that it is a bit
 * placed to the left. We manually add some margin to compensate.
 *
 * More info: https://medium.com/@lukejones/optical-adjustment-b55492a1165c
 */
export const offCenterCorrection = css`
  margin-left: 10%;
`;

export const Container = styled.div<{ clear?: boolean; thick?: boolean }>`
  align-items: center;
  border-radius: 100%;
  border-color: ${({ theme, clear }) =>
    clear ? 'white' : theme.textLightBg.text};
  border-style: solid;
  border-width: ${({ thick }) => (thick ? 2 : 1)}px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  ${({ clear }) =>
    !clear &&
    css`
      opacity: 0.5;
    `};
  transition: background-color 200ms ease, opacity 200ms ease;
  width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    opacity: 0.9;
  }

  & svg {
    ${offCenterCorrection};
    color: ${({ theme, clear }) => (clear ? 'white' : theme.textLightBg.text)};
    height: 40%;
    width: 40%;
  }
`;
