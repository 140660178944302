import { gql } from '@apollo/client';

const localCacheTypes = gql`
  enum PlayerAudioStatus {
    PLAYING
    STOP
  }

  enum PlayerDisplayStatus {
    MAXIMIZED
    MINIMIZED
  }

  enum PlayerType {
    COURSE
    SELFTIMER
  }

  enum PlayerAutoplayType {
    FAVORITES_PRACTICE
    FAVORITES_THEORY
    PACK
  }

  type Player {
    audioStatus: PlayerAudioStatus!
    autoplayType: PlayerAutoplayType
    courseId: ID
    dailyMeditationDuration: Int
    displayStatus: PlayerDisplayStatus!
    endPosition: Int
    endPositionPaused: Boolean
    initialPosition: Int
    selftimerDuration: Int
    selftimerInterval: String
    selftimerStartTime: Int
    type: PlayerType
  }

  enum ModalType {
    ADD_MEDITATION_TIME
    COURSE_ACTIONS
    COURSE_NOTES
    DAILY_DURATION
    DIALOG
    GIVE_FREE_MONTH
    NEW_INTRO_PACK
    ONBOARDING
    RESET_ALL_CONTENT
    RESET_CONTENT
    RESET_COURSE
    RESET_INTRO_COURSES
    SELF_TIMER
    SHARE_LINK
    SHARE_MONTH_WARNING
    STRIPE_PAYMENT
    UNLOCK_CONTENT
    UPDATE_PAYMENT_CARD
  }

  type ModalCourseActionsPayload {
    courseId: String!
  }

  type ModalCourseNotesPayload {
    courseId: String!
  }

  type ModalDialogPayload {
    description: String
    title: String
  }

  type ModalResetCoursePayload {
    courseId: String!
  }

  type ModalShareLinkPayload {
    title: String
    description: String
    link: String!
    courseId: String!
  }

  type ModalUnlockContentPayload {
    courseId: String!
  }

  type ModalUpdatePaymentCardPayload {
    tokenId: String!
  }

  type Modal {
    type: ModalType
    courseActionsPayload: ModalCourseActionsPayload
    courseNotesPayload: ModalCourseNotesPayload
    dialogPayload: ModalDialogPayload
    resetCoursePayload: ModalResetCoursePayload
    shareLinkPayload: ModalShareLinkPayload
    unlockContentPayload: ModalUnlockContentPayload
    updatePaymentCardPayload: ModalUpdatePaymentCardPayload
  }

  extend type Query {
    init: Boolean
    loginEmail: String
    introCoursesCount: Int
    player: Player!
    modal: Modal!
  }
`;

export default localCacheTypes;
