import { apolloClient } from 'services/api';
import { normalizePlayer } from 'models/Player';
import { GetPlayer } from 'graphql/generated/GetPlayer';
import { GET_PLAYER } from 'graphql/queries';

const getPlayer = async () => {
  const { data: playerData } = await apolloClient.query<GetPlayer>({
    query: GET_PLAYER,
    errorPolicy: 'all',
    fetchPolicy: 'cache-only',
  });

  const player = normalizePlayer(playerData?.player);

  return player;
};

export default getPlayer;
