import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import { REDEEM_CODE } from '../mutations';
import { RedeemCode, RedeemCodeVariables } from '../generated/RedeemCode';
import { GET_PACKS, GET_DAILY } from '../queries';
import useAuth from './useAuth';

const useRedeem = () => {
  const { token } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [redeemCode, { loading }] = useMutation<
    RedeemCode,
    RedeemCodeVariables
  >(REDEEM_CODE, {
    refetchQueries: token
      ? [
          { query: GET_PACKS },
          {
            query: GET_DAILY,
            variables: { date: format(new Date(), 'yyyy-MM-dd') },
          },
        ]
      : undefined,
  });

  const redeem = useCallback(
    async (code: string, email?: string, isPublic = false) => {
      const response = await redeemCode({ variables: { code, email } });
      const profile = response?.data?.redeemMe;

      if (profile && !email && !isPublic) {
        enqueueSnackbar('Your subscription has been updated', {
          variant: 'success',
        });
      }

      return profile;
    },
    [redeemCode, enqueueSnackbar],
  );

  return {
    loading,
    redeem,
  };
};

export default useRedeem;
