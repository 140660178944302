import { useCallback, useMemo, useRef } from 'react';
import flatMap from 'lodash/flatMap';
import { parseISO, compareAsc } from 'date-fns';
import notEmpty from 'lib/notEmpty';
import { CoursePermission } from 'models/Course';
import { PackType } from 'models/Pack';
import usePacks from 'graphql/hooks/usePacks';
import useCourseActions from 'graphql/hooks/useCourseActions';

const useConnect = () => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const { packs, loading } = usePacks();
  const { play } = useCourseActions();

  const sortedLessons = useMemo(() => {
    const lessonPacks = packs.filter((pack) => pack.type === PackType.LESSON);

    return flatMap(lessonPacks, (pack) => pack?.courses)
      .filter(notEmpty)
      .sort((a, b) => {
        // Set lessons order to unfinished, finished, locked(unavailable) and `START HERE'.
        const aIsStartHere = a.title === 'START HERE';
        const bIsStartHere = b.title === 'START HERE';
        const aAvailable = a.permission === CoursePermission.UNLOCKED;
        const bAvailable = b.permission === CoursePermission.UNLOCKED;
        const aIsFinished = a.status === 'finished';
        const bIsFinished = b.status === 'finished';

        if (aAvailable && !bAvailable) {
          return -1;
        }
        if (!aAvailable && bAvailable) {
          return 1;
        }
        if (!aAvailable && !bAvailable) {
          return Number(b.id) - Number(a.id);
        }

        if (!aIsFinished && bIsFinished) {
          return -1;
        }
        if (aIsFinished && !bIsFinished) {
          return 1;
        }
        if (aIsFinished && bIsFinished) {
          if (!aIsStartHere && bIsStartHere) {
            return -1;
          }
          if (aIsStartHere && !bIsStartHere) {
            return 1;
          }

          if (!a.played_last_time_at) {
            return -1;
          }
          if (!b.played_last_time_at) {
            return 1;
          }
          return compareAsc(
            parseISO(a.played_last_time_at),
            parseISO(b.played_last_time_at),
          );
        }

        return Number(b.id) - Number(a.id);
      });
  }, [packs]);

  const handleControlPress = useCallback(
    (type: 'back' | 'forward') => {
      listRef?.current?.scrollTo({
        behavior: 'smooth',
        left:
          type === 'back'
            ? listRef.current.scrollLeft - 100
            : listRef.current.scrollLeft + 100,
      });
    },
    [listRef],
  );

  const loadTrack = useCallback(
    async (courseId: string) => {
      await play(courseId);
    },
    [play],
  );

  return {
    handleControlPress,
    isLoading: loading,
    lessons: sortedLessons,
    listRef,
    loadTrack,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
