import { gql } from '@apollo/client';
import { COURSE_DATA_FRAGMENT } from '../fragments';

export const ADD_FAVORITE = gql`
  mutation AddFavorite($course_id: Int!) {
    addFavorite(course_id: $course_id) {
      id
      courses {
        ...CourseData
      }
    }
  }
  ${COURSE_DATA_FRAGMENT}
`;

export const REMOVE_FAVORITE = gql`
  mutation RemoveFavorite($course_id: Int!) {
    removeFavorite(course_id: $course_id) {
      id
      courses {
        ...CourseData
      }
    }
  }
  ${COURSE_DATA_FRAGMENT}
`;

export const REMOVE_ALL_FAVORITES = gql`
  mutation RemoveAllFavorites {
    removeFavorite {
      id
      courses {
        ...CourseData
      }
    }
  }
  ${COURSE_DATA_FRAGMENT}
`;
