import React, { FC, memo } from 'react';
import {
  BackButton,
  BackIcon,
  BackPlace,
  Container,
  Logo,
  LogoLink,
  MenuButton,
  MenuIcon,
} from './styles';
import { Props } from './types';
import useConnect from './connect';

const Header: FC<Props> = ({ className, onToggleSidebar }) => {
  const { backToUrl, showBackButton } = useConnect();

  return (
    <Container className={className}>
      {showBackButton ? (
        <BackButton to={backToUrl}>
          <BackIcon />
        </BackButton>
      ) : (
        <BackPlace />
      )}
      <LogoLink to="/">
        <Logo data-cypress-id="app-logo" lightBg />
      </LogoLink>
      <MenuButton onClick={onToggleSidebar}>
        <MenuIcon />
      </MenuButton>
    </Container>
  );
};

export default memo(Header);
