import usePlayer from 'graphql/hooks/usePlayer';
import { PlayerDisplayStatus } from 'graphql/generated/globalTypes';

const useConnect = () => {
  const { courseId, displayStatus, toggleDisplayStatus, type } = usePlayer();

  return {
    courseId,
    isMaximized: displayStatus === PlayerDisplayStatus.MAXIMIZED,
    toggleDisplayStatus,
    type,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
