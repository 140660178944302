import React, { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import SubscriptionContainer from '../Account/Subscription';

const MobileContainer: FC<RouteComponentProps> = ({ match: { path } }) => (
  <Switch>
    <Route component={SubscriptionContainer} path={`${path}/subscription`} />
  </Switch>
);

export default MobileContainer;
