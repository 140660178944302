/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import {
  COURSE_DATA_FRAGMENT,
  FEATURE_CONTENT_DATA_FRAGMENT,
  PACK_DATA_FRAGMENT,
} from '../fragments';

export const GET_INITIAL_DATA = gql`
  query GetInitialData($date: String) {
    packs {
      total
      data {
        ...PackData
      }
    }
    daily(date: $date) {
      ...CourseData
    }
    feature_content {
      ...FeatureContentData
    }
    # TODO: Remove this when we have the new course query implemented.
    features {
      total
      data {
        id
        course {
          ...CourseData
        }
        created_at
        deep_link
        updated_at
      }
    }
  }
  ${PACK_DATA_FRAGMENT}
  ${COURSE_DATA_FRAGMENT}
  ${FEATURE_CONTENT_DATA_FRAGMENT}
`;
