export interface Plan {
  amount: number;
  company?: string;
  id: string;
  interval: string;
}

export const getInfoText = (code?: string) => {
  switch (code?.toLowerCase()) {
    case 'upgrade4':
    case 'upgrade5':
    case 'upgrade6':
      return 'You will be auto-renewed at this same level. Cancel anytime.';
    case 'android5':
    case 'android6':
      return 'Subscriptions renew at $99/year. Cancel anytime';
    default:
      return `Recurring billing. Cancel anytime.`;
  }
};

export const isShortVersion = (code?: string) => {
  switch (code?.toLowerCase()) {
    case 'upgrade1':
    case 'upgrade2':
    case 'upgrade3':
      return true;
    default:
      return false;
  }
};
