/* eslint-disable import/prefer-default-export */
import {
  object as yupObject,
  array as yupArray,
  string as yupString,
} from 'yup';

export const validationSchema = yupObject({
  pin: yupArray()
    .of(yupString())
    .test(
      'len',
      'Must be exactly 4 characters',
      (val) => val && val.join('').length === 4,
    ),
});
