import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Checkmark: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="none"
    height={(size * 13) / 18}
    stroke="currentColor"
    viewBox="0 0 18 13"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 1L6 12 1 7"
    />
  </svg>
);

export default memo(Checkmark);
