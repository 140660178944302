import React, { FC, memo } from 'react';
import { BaseIconProps as Props } from '.';

const Logo: FC<Props> = ({ size = 24, ...rest }) => (
  <svg
    fill="currentColor"
    height={(size * 89.415215) / 89.776558}
    viewBox="0 0 89.776558 89.415215"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M12.90568636 1.215215c-1.9.6-5.1 2.8-7.1 4.8-5.6 5.6-6.1 9.6-5.7 41.9.3 27.2.3 27.5 2.8 32 1.7 2.9 4.1 5.3 7 7l4.5 2.5h61l4.5-2.5c2.9-1.7 5.3-4.1 7-7 2.5-4.5 2.5-4.7 2.8-33 .2-20.5 0-29.7-.9-32.9-1.7-6-7.3-11.4-13.2-12.9-6-1.6-57.8-1.4-62.7.1zm26 35.9c0 15.5-.2 27.9-.4 27.7-1.6-1.8-24.5-53.4-23.9-54 1.4-1.3 4.6-1.7 14.6-1.8l9.7-.1zm33.7-27.4c2.4.8 2.6.3-10.4 28.2-1.9 4.1-5.3 11.5-7.5 16.5-2.3 4.9-4.2 9.2-4.4 9.4-.2.2-.4-12-.4-27.2v-27.7l10.3.1c5.6.1 11.2.4 12.4.7zm-3.8 51.3c-6.7 7.1-12.4 12.8-12.6 12.6-.2-.2 5.2-12.2 11.9-26.6l12.3-26.2.3 13.6.3 13.6zm-50.4-17.6c4.8 10.4 9.9 21.5 11.2 24.5l2.4 5.5-11.5-11.5-11.6-11.5v-13.4c0-7.4.2-13.2.4-13 .2.2 4.3 8.9 9.1 19.4zm62 27.1c-.8 5.5-4.4 9.1-10 9.9-7.9 1.2-7.8.5 1.3-9.1 9.4-9.8 10.1-9.9 8.7-.8zm-62.5 2l8.5 8.6-5.2-.5c-6.7-.8-10-3-11.3-7.6-.9-3.3-1.4-9.1-.7-9.1.1 0 4.1 3.9 8.7 8.6z" />
  </svg>
);

export default memo(Logo);
