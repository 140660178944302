/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const COURSE_DATA_FRAGMENT = gql`
  fragment CourseData on Course {
    course_hash
    course_number
    course_type
    daily_date
    daily_status
    description
    feature_image_url
    id
    image_url
    is_daily
    is_favorite
    is_free
    is_unlocked
    lockable
    meditation_type
    mp3_file_name
    mp3_file_size
    mp3_length
    mp3_url
    mp3_2_file_name
    mp3_2_file_size
    mp3_2_length
    mp3_2_url
    note_subtitle
    note_text
    note_title
    played_first_time_at
    played_last_time_at
    rectangle_image {
      color
      original
      thumb_x1
      thumb_x2
    }
    seconds_played
    shareable
    square_image {
      color
      original
      thumb_x1
      thumb_x2
    }
    status
    thumbnail_url {
      thumb_x1
      thumb_x2
    }
    title
    title_line_1
    title_line_2
  }
`;

export const USER_COURSES_DATA_FRAGMENT = gql`
  fragment UserCoursesData on UserCourses {
    course_id
    cursor_status
    daily_status
    favorited_at
    id
    is_favorite
    lockable
    played_first_time_at
    played_last_time_at
    seconds_played
    updated_favorite_at
    user_id
  }
`;
