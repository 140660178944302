import React, { FC } from 'react';
import { Container, Label, MaterialSwitch } from './styles';
import { Props } from './types';

const Switch: FC<Props> = ({
  className,
  label,
  labelPosition = 'left',
  ...rest
}) => {
  return (
    <Container $labelPosition={labelPosition} className={className}>
      {!!label && <Label $labelPosition={labelPosition}>{label}</Label>}
      <MaterialSwitch disableRipple {...rest} />
    </Container>
  );
};

export default Switch;
