import styled from 'styled-components';
import clamp from 'lodash/clamp';
import {
  BAR_MARGIN_PX,
  BAR_WIDTH_PX,
  CONTAINER_HEIGHT_PX,
  CONTAINER_WIDTH_PX,
  SLIDER_HEIGHT_PX,
} from './constants';

export const Container = styled.div`
  background-color: hsla(186, 100%, 90%, 0.06);
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  height: ${CONTAINER_HEIGHT_PX}px;
  margin: 0 auto ${({ theme }) => theme.margin.xl} auto;
  width: ${CONTAINER_WIDTH_PX}px;
`;

export const DurationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;
`;

export const DurationQuantity = styled.div`
  font-size: 6rem;
  line-height: 1;
`;

export const DurationUnit = styled.div`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weight.book};
`;

export const DurationSlider = styled.div`
  -ms-overflow-style: none;
  cursor: pointer;
  display: flex;
  height: ${SLIDER_HEIGHT_PX}px;
  margin-bottom: 1.5rem;
  outline: none;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-left: 50%;
  scrollbar-width: none;
  user-select: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::after {
    content: '';
    display: block;
    padding-left: 100%;
  }
`;

export const VerticalBar = styled.div<{ index: number }>`
  height: ${({ index }) =>
    clamp(index > 0 ? 100 - 23 * index : 100 + 23 * index, 33, 100)}%;
  opacity: ${({ index }) =>
    clamp(index > 0 ? 1 - 0.08 * index : 1 + 0.08 * index, 0.1, 100)};
  min-width: ${BAR_WIDTH_PX}px;
  background-color: white;
  margin-top: auto;

  &:not(:last-child) {
    margin-right: ${BAR_MARGIN_PX}px;
  }
`;
