import { apolloClient } from 'services/api';
import { GetSettings } from 'graphql/generated/GetSettings';
import { GET_SETTINGS } from 'graphql/queries';
import { getProfile } from 'graphql/requests';

async function cacheUpdateSettings(settings: string) {
  if (settings) {
    const { id, typename } = await getProfile();

    apolloClient.writeQuery<GetSettings>({
      query: GET_SETTINGS,
      data: { me: { __typename: typename, id, settings } },
    });
  }
}

export default cacheUpdateSettings;
