import React, { FC } from 'react';
import { CloseButton, CloseIcon, Modal, Subtitle, Text, Title } from './styles';
import useConnect from './connect';

const CourseNotesModal: FC = () => {
  const { closeModal, isMobile, isOpen, subtitle, text, title } = useConnect();

  return (
    <Modal
      disableRestoreFocus
      hideClose={isMobile}
      onClose={closeModal}
      open={isOpen}
    >
      {isMobile && (
        <CloseButton onClick={closeModal}>
          <CloseIcon />
        </CloseButton>
      )}
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Text>{text}</Text>
    </Modal>
  );
};

export default CourseNotesModal;
