import randomId from '../lib/randomId';

export interface SelfTimerAsset {
  id: string;
  name: string;
  url: string;
}

export const createSelfTimerAsset = (
  asset: Omit<SelfTimerAsset, 'id'>,
): SelfTimerAsset => ({
  ...asset,
  id: randomId(),
});
