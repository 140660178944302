/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import DefaultLoginCodeField from 'components/LoginCodeField';
import DefaultResend from './Resend';

export const LoginCodeField = styled(DefaultLoginCodeField)`
  margin-bottom: ${({ theme }) => theme.margin.md};
`;

export const Resend = styled(DefaultResend)`
  margin-top: ${({ theme }) => theme.margin.md};
`;
