import React, { FC, memo } from 'react';
import { Button, Buttons, Container, Logo, Text, VideoPlayer } from './styles';
import useConnect from './connect';

const FreeAccountPage: FC = () => {
  const {
    handleRequestFreeAccount,
    handleStartTrial,
    handleVideoEnd,
    isVideoPlayed,
  } = useConnect();

  return (
    <Container>
      <Logo />
      <Text>Listen to this important message from Sam before proceeding.</Text>
      <VideoPlayer onVideoEnd={handleVideoEnd} />
      <Buttons>
        <Button
          disabled={!isVideoPlayed}
          onClick={handleRequestFreeAccount}
          to="/request-free-account"
        >
          Request a Free Year of Waking Up
        </Button>
        <Button
          disabled={!isVideoPlayed}
          onClick={handleStartTrial}
          to={{ pathname: '/', state: { title: 'Start My Free Trial' } }}
        >
          I'm Unsure. Let Me Try Waking Up First
        </Button>
      </Buttons>
    </Container>
  );
};

export default memo(FreeAccountPage);
