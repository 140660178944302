import React, { FC } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { ApiConfig, AppConfig } from 'config';
import Footer from './Footer';
import {
  Button,
  CheckmarkIcon,
  ChevronRightIcon,
  Container,
  Content,
  FeatureList,
  FeatureListButton,
  List,
  ListItem,
  ListText,
  LoaderBox,
  LoaderCircular,
  MonthlyPrice,
  OldPrice,
  OldPriceNumber,
  Price,
  PriceNumber,
  StepContent,
  StrikeThrough,
  SubscriptionPlan,
  Title,
} from './styles';
import { LIST_ITEMS } from './constants';
import { Props } from './types';
import useConnect from './connect';

const NewSubscription: FC<Props> = ({
  className,
  code,
  email = '',
  isPublic = false,
  onSubscribed,
  period,
}) => {
  const {
    handleToken,
    isLoading,
    isLoadingGOVariant,
    mode,
    planAmount,
    planInterval,
    planOriginalAmount,
    planPeriod,
    subscriptionPlans,
  } = useConnect({ code, isPublic, onSubscribed, period });

  if (isLoadingGOVariant) {
    return <LoaderBox />;
  }

  const hasCode = !!code;
  const hasPeriod = !!period;
  const isLoadingSubscriptionPlans =
    !hasPeriod && subscriptionPlans.length === 0;
  const showSubscriptionPlans = !hasPeriod && subscriptionPlans.length > 0;
  const showPlanInfo = hasPeriod;

  return (
    <Container
      $hasCode={hasCode}
      $hasPeriod={hasPeriod}
      $mode={mode}
      className={className}
    >
      <Content $hasCode={hasCode} $hasPeriod={hasPeriod}>
        <Title $hasCode={hasCode} $hasPeriod={hasPeriod}>
          Continue Your Journey
        </Title>
        {isLoadingSubscriptionPlans && <LoaderCircular />}
        {showSubscriptionPlans && (
          <StepContent>
            {subscriptionPlans.map((plan) => (
              <SubscriptionPlan
                key={plan.title}
                $color={plan.color}
                description={plan.description}
                price={plan.price}
                title={plan.title}
                to={plan.link}
              />
            ))}
            <FeatureList>
              <FeatureListButton>
                See my Feature List
                <ChevronRightIcon />
              </FeatureListButton>
            </FeatureList>
          </StepContent>
        )}
        {showPlanInfo && (
          <StepContent>
            <List>
              {LIST_ITEMS.map((v) => (
                <ListItem key={v}>
                  <CheckmarkIcon />
                  <ListText>{v}</ListText>
                </ListItem>
              ))}
            </List>
            {hasCode && (
              <OldPrice>
                Unlock app for{' '}
                <StrikeThrough>
                  <OldPriceNumber amount={planOriginalAmount} />
                  /year
                </StrikeThrough>
              </OldPrice>
            )}
            <Price $hasCode={hasCode}>
              {hasCode ? 'Now only ' : 'Discover your mind for only... '}
              <PriceNumber $hasCode={hasCode} amount={planAmount} />/
              {planInterval}
            </Price>
            <StripeCheckout
              amount={planAmount}
              description={`${planPeriod} Subscription`}
              email={email}
              image={AppConfig.publicAppIconURL}
              locale="auto"
              name={AppConfig.appName}
              stripeKey={ApiConfig.STRIPE_KEY}
              token={handleToken}
              zipCode
            >
              <Button $hasCode={hasCode} isLoading={isLoading}>
                Start {planPeriod} Plan
              </Button>
            </StripeCheckout>
            {hasCode && planInterval !== 'month' && (
              <MonthlyPrice>
                That's only ${Math.floor(planAmount / 12) / 100}/month
              </MonthlyPrice>
            )}
          </StepContent>
        )}
      </Content>
      <Footer isPublic={isPublic} />
    </Container>
  );
};

export default NewSubscription;
