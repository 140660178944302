import { useState, useRef, useEffect, useCallback } from 'react';
import { Howl } from 'howler';
import { trackMediaEvent } from 'services/analytics';
import { CourseStatus } from 'models/Course';
import {
  DailyMeditationDuration,
  DAILY_DURATIONS,
} from 'models/DailyMeditationDuration';
import { PlayerAudioStatus } from 'graphql/generated/globalTypes';
import useUpdatedRef from 'lib/useUpdatedRef';
import useCallbackRef from 'lib/useCallbackRef';
import useSettings from 'graphql/hooks/useSettings';
import useSettingsActions from 'graphql/hooks/useSettingsActions';
import usePlayerCourse from 'graphql/hooks/usePlayerCourse';
import { Props } from './types';

const useConnect = ({
  activeSessionIdRef,
  courseId,
  isLoadingTrack,
  markTrackAsListened,
  trackHasSpeedControl,
  trackIsDaily,
  trackIsMeditation,
  trackStatus,
  trackUrl10,
  trackUrl20,
}: {
  activeSessionIdRef: Props['activeSessionIdRef'];
  courseId: Props['courseId'];
  isLoadingTrack: Props['isLoadingTrack'];
  markTrackAsListened: Props['markTrackAsListened'];
  trackHasSpeedControl: Props['trackHasSpeedControl'];
  trackIsDaily: Props['trackIsDaily'];
  trackIsMeditation: Props['trackIsMeditation'];
  trackStatus: Props['trackStatus'];
  trackUrl10: Props['trackUrl10'];
  trackUrl20: Props['trackUrl20'];
}) => {
  const {
    settings: { playbackSpeed, resumePlayingList },
  } = useSettings();
  const { updateSettings } = useSettingsActions();
  const {
    audioStatus,
    close,
    completeTrack,
    dailyMeditationDuration,
    displayStatus,
    endPosition,
    endPositionPaused,
    initialPosition,
    setAudioStatus,
    setDailyMeditationDuration,
  } = usePlayerCourse();

  const [isSeeking, setIsSeeking] = useState<boolean>(true);
  const [duration, setDuration] = useState<number>();
  const [position, setPosition] = useState<number>(() => {
    let startPosition = resumePlayingList[courseId] || 0;
    if (initialPosition !== null) {
      startPosition = initialPosition;
    } else if (endPosition !== null) {
      startPosition = 0;
    }

    return !trackIsDaily ||
      (dailyMeditationDuration &&
        startPosition < DAILY_DURATIONS[dailyMeditationDuration])
      ? startPosition
      : 0;
  });

  const trackUrl =
    trackIsDaily && dailyMeditationDuration === DailyMeditationDuration.MIN_20
      ? trackUrl20
      : trackUrl10;

  const track = useRef<Howl>();
  const isUnlockedWithPlay = useRef<boolean>(false);
  const isMarkedAsListenedRef = useRef<boolean>(false);
  const isTrackedAsCompletedRef = useRef<boolean>(false);
  const isEndPositionHandled = useRef<boolean>(false);
  const lastTimerPositionRef = useRef<number>();
  const audioStatusRef = useUpdatedRef(audioStatus);
  const positionRef = useUpdatedRef(position);
  const playbackSpeedRef = useRef<number>(
    trackHasSpeedControl ? Number(playbackSpeed) : 1,
  );

  const handleTimeUpdateRef = useCallbackRef(() => {
    if (!duration || !track.current?.playing()) {
      lastTimerPositionRef.current = undefined;
      return;
    }

    const currentPosition = track.current?.seek();
    if (
      typeof currentPosition !== 'number' ||
      (lastTimerPositionRef.current !== undefined &&
        currentPosition === lastTimerPositionRef.current)
    ) {
      return;
    }

    if (
      !isEndPositionHandled.current &&
      endPosition &&
      currentPosition >= endPosition
    ) {
      isEndPositionHandled.current = true;
      if (endPositionPaused) {
        track.current?.pause();
        trackMediaEvent('Media Stop', courseId);
      } else {
        setTimeout(() => close(currentPosition, duration), 750);
      }
    }

    const trackIsAlmostListened = currentPosition >= duration - 30;

    if (
      !isMarkedAsListenedRef.current &&
      trackIsAlmostListened &&
      (trackStatus !== CourseStatus.FINISHED ||
        (trackIsMeditation && currentPosition >= duration - 5))
    ) {
      isMarkedAsListenedRef.current = true;
      markTrackAsListened(activeSessionIdRef.current);
    }

    if (!isTrackedAsCompletedRef.current && trackIsAlmostListened) {
      isTrackedAsCompletedRef.current = true;
      trackMediaEvent('Media Complete', courseId);
      updateSettings({ player: null });
    }

    lastTimerPositionRef.current = currentPosition;
    if (isSeeking) setIsSeeking(false);

    setPosition(currentPosition);
  }, [
    close,
    courseId,
    duration,
    endPosition,
    endPositionPaused,
    isSeeking,
    trackIsMeditation,
    trackStatus,
  ]);

  useEffect(() => {
    if (!isLoadingTrack && trackUrl) {
      let intervalId: number;

      const handleTimeUpdate = () => {
        handleTimeUpdateRef.current();
      };

      track.current = new Howl({
        autoplay: audioStatusRef.current === PlayerAudioStatus.PLAYING,
        html5: true,
        onend() {
          clearInterval(intervalId);
          track.current?.pause();
          const trackDuration = track.current?.duration();
          if (trackDuration) setPosition(trackDuration);
          setTimeout(() => completeTrack(), 500);
        },
        onload() {
          const trackDuration = track.current?.duration();
          if (trackDuration) setDuration(trackDuration);

          if (positionRef.current > 0) {
            track.current?.seek(positionRef.current);
            if (audioStatusRef.current === PlayerAudioStatus.STOP) {
              setIsSeeking(false);
            }
          } else {
            setIsSeeking(false);
          }
        },
        onloaderror() {
          setIsSeeking(false);
          setAudioStatus(PlayerAudioStatus.STOP);
        },
        onpause() {
          setAudioStatus(PlayerAudioStatus.STOP);
          clearInterval(intervalId);
          lastTimerPositionRef.current = undefined;
        },
        onplay() {
          setAudioStatus(PlayerAudioStatus.PLAYING);
          intervalId = setInterval(handleTimeUpdate, 500);
        },
        onplayerror() {
          setIsSeeking(false);
          setAudioStatus(PlayerAudioStatus.STOP);
        },
        onunlock() {
          if (isUnlockedWithPlay.current) {
            setIsSeeking(true);
          }
        },
        preload: true,
        rate: playbackSpeedRef.current,
        src: [trackUrl],
      });

      return () => {
        clearInterval(intervalId);
        track.current?.unload();
      };
    }
  }, [
    audioStatusRef,
    completeTrack,
    handleTimeUpdateRef,
    isLoadingTrack,
    playbackSpeedRef,
    positionRef,
    setAudioStatus,
    trackUrl,
  ]);

  useEffect(() => {
    if (trackHasSpeedControl) {
      track.current?.rate(Number(playbackSpeed));
    }
  }, [playbackSpeed, trackHasSpeedControl]);

  const onChangeAudioStatus = useCallback(
    (newAudioStatus) => {
      if (newAudioStatus === PlayerAudioStatus.PLAYING) {
        isUnlockedWithPlay.current = true;
        track.current?.play();
        trackMediaEvent('Media Start', courseId);
      } else if (newAudioStatus === PlayerAudioStatus.STOP) {
        track.current?.pause();
        trackMediaEvent('Media Stop', courseId);
      }
    },
    [courseId],
  );

  const onChangePosition = useCallback((newPosition) => {
    // Avoid showing the loader if the load is instantaneous.
    if (track.current?.playing()) {
      setTimeout(() => {
        if (!track.current?.playing()) setIsSeeking(true);
      }, 300);
    }

    // If we manually seek a position, we ignore endTime.
    if (!isEndPositionHandled.current) {
      isEndPositionHandled.current = true;
    }

    setPosition(newPosition);
    track.current?.seek(newPosition);
  }, []);

  const onChangeDuration = useCallback(
    (newDuration: DailyMeditationDuration) => {
      if (track.current?.playing()) {
        setTimeout(() => {
          if (!track.current?.playing()) setIsSeeking(true);
        }, 300);
      }
      setDailyMeditationDuration(newDuration);
    },
    [setDailyMeditationDuration],
  );

  const closePlayer = useCallback(async () => {
    try {
      const currentPosition = track.current?.seek();
      const currentDuration = track.current?.duration();
      close(currentPosition, currentDuration);
    } catch (error) {
      close();
    }
  }, [close]);

  return {
    audioStatus,
    closePlayer,
    dailyMeditationDuration,
    displayStatus,
    duration,
    isSeeking,
    onChangeAudioStatus,
    onChangeDuration,
    onChangePosition,
    position,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
