import { useCallback } from 'react';
import { trackEvent } from 'services/analytics';
import useMe from 'graphql/hooks/useMe';
import useModal from 'graphql/hooks/useModal';

const useConnect = () => {
  const { canShareFreeMonth } = useMe();
  const { openShareMonth } = useModal();

  const checkoutClick = useCallback(() => {
    trackEvent('Purchase Initiate Checkout', {
      source: 'home',
      trackID: 'none',
      type: 'button',
    });
  }, []);

  return { canShareFreeMonth, checkoutClick, openShareMonth };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
