import styled from 'styled-components';
import DefaultSectionHeader from 'components/SectionHeader';
import DefaultForm from 'components/Form';
import DefaultTextField from 'components/Inputs/TextField';
import { media } from 'styles/mixins';

export const Container = styled.div`
  color: ${({ theme }) => theme.textLightBg.text};
  flex: 1;
`;

export const Header = styled(DefaultSectionHeader)`
  ${media.tabletPortrait} {
    display: none;
  }
`;

export const Title = styled.h1`
  color: inherit;
  font-size: ${({ theme }) => theme.font.heading};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: ${({ theme }) => theme.margin.lg};
  margin-top: ${({ theme }) => theme.margin.xxl};
  text-align: center;

  ${media.tabletPortrait} {
    margin-top: ${({ theme }) => theme.margin.md};
  }

  ${media.phoneLandscape} {
    margin-top: 0;
  }
`;

export const Form = styled(DefaultForm)`
  color: ${({ theme }) => theme.textLightBg.text};
`;

export const TextField = styled(DefaultTextField)`
  margin-bottom: ${({ theme }) => theme.margin.md};
`;

export const Error = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-weight: ${({ theme }) => theme.font.weight.book};
  margin-top: ${({ theme }) => theme.margin.md};
  text-align: center;
`;

export const Contact = styled.p`
  color: ${({ theme }) => theme.textLightBg.text};
  font-weight: ${({ theme }) => theme.font.weight.book};
  margin-top: 0.5rem;
  text-align: center;
`;
