import React, { FC, memo } from 'react';
import formatMindfulMinutes from 'lib/formatMindfulMinutes';
import {
  Container,
  Row,
  Stat,
  StatSeparator,
  StatTitle,
  StatValue,
} from './styles';
import { Props } from './types';

const UserStats: FC<Props> = ({
  bigValues = false,
  className,
  forceRows = false,
  hideSeparators = false,
  mindfulDays,
  mindfulMinutes,
  reverseColors = false,
  titlePosition = 'top',
  totalSessions,
}) => {
  return (
    <Container
      $bigValues={bigValues}
      $forceRows={forceRows}
      $hideSeparators={hideSeparators}
      $reverseColors={reverseColors}
      $titlePosition={titlePosition}
      className={className}
    >
      <Row>
        <Stat>
          <StatTitle>Mindful Days</StatTitle>
          <StatValue>{mindfulDays}</StatValue>
        </Stat>
        <StatSeparator />
        <Stat>
          <StatTitle>Mindful Minutes</StatTitle>
          <StatValue>{formatMindfulMinutes(mindfulMinutes)}</StatValue>
        </Stat>
        <StatSeparator />
        <Stat>
          <StatTitle>Total Sessions</StatTitle>
          <StatValue>{totalSessions}</StatValue>
        </Stat>
      </Row>
    </Container>
  );
};

export default memo(UserStats);
