import React, { FC } from 'react';
import { Redirect, Route } from 'react-router';
import SelfTimerModal from 'containers/Modals/SelfTimer';
import OnboardingModal from 'containers/Modals/Onboarding';
import GiveFreeMonthModal from 'containers/Modals/GiveFreeMonth';
import ShareLinkModal from 'containers/Modals/ShareLink';
import ShareMonthWarningModal from 'containers/Modals/ShareMonthWarning';
import CourseActionsModal from 'containers/Modals/CourseActions';
import CourseNotesModal from 'containers/Modals/CourseNotes';
import NewIntroPackModal from 'containers/Modals/NewIntroPack';
import UpdatePaymentCardModal from 'containers/Modals/UpdatePaymentCard';
import ResetContentModal from 'containers/Modals/ResetContent';
import ResetIntroCoursesConfirmModal from 'containers/Modals/ResetIntroCoursesConfirm';
import ResetAllContentConfirmModal from 'containers/Modals/ResetAllContentConfirm';
import ResetCourseConfirmModal from 'containers/Modals/ResetCourseConfirm';
import UnlockContentModal from 'containers/Modals/UnlockContent';
import NewSubscriptionModal from 'containers/Modals/NewSubscription';
import Dialog from 'containers/Modals/Dialog';
import Lightrail from './Lightrail';
import Player from './Player';
import Navigation from './Navigation';
import Home from './Home';
import Account from './Account';
import Corporate from './Corporate';
import Favorites from './Favorites';
import Theory from './Theory';
import DeepLinks from './DeepLinks';
import Practice from './Practice';
import Mobile from './Mobile';
import Redeem from './Account/Redeem';
import { Container, Content, Switch } from './styles';
import { Props } from './types';
import useConnect from './connect';

const Main: FC<Props> = () => {
  const {
    isAnonymous,
    isMinimizedPlayerOpen,
    location,
    returnTo,
  } = useConnect();

  if (typeof returnTo === 'string') {
    return <Redirect to={returnTo} />;
  }

  return (
    <Container>
      <Content>
        <Navigation />
        <Switch
          location={location}
          $isMinimizedPlayerOpen={isMinimizedPlayerOpen}
        >
          <Route exact path="/" component={Home} />
          <Route path={['/account', '/settings']}>
            <Account isAnonymous={isAnonymous} />
          </Route>
          {!isAnonymous && (
            <Route
              path={['/promotions/redeem', '/gifts/redeem']}
              component={Redeem}
            />
          )}
          <Route path="/corporate" component={Corporate} />
          <Route path={['/favorites', '/favourites']} component={Favorites} />
          <Route
            path={['/theory/:pack/:subpack', '/theory/:pack', '/theory']}
            component={Theory}
          />
          <Route
            path={[
              '/practice/:pack/:subpack',
              '/practice/:pack',
              '/practice',
              '/meditations/:pack/:subpack',
              '/meditations/:pack',
              '/meditations',
            ]}
            component={Practice}
          />
          <Route path="/mobile" component={Mobile} />
          <Route
            path={[
              '/open-self-timer',
              '/timer',
              '/pack/:id',
              '/course/:id',
              '/checkout',
              '/offer/:code',
              '/onboarding',
              '/dailyMeditationDuration',
              '/share',
              '/share/redeem/:token',
              '/playNextMeditation',
              '/openview/practice',
              '/openview/theory',
              '/openview/favorites',
              '/openview/settings',
              '/openview/profile',
              '/openview/subscriptions',
              '/openview/timer',
              '/openview/aboutCourse',
              '/openview/aboutSam',
              '/openview/faq',
              '/openview/terms',
              '/openview/privacy',
              '/openview/onboarding',
              '/openview/dailyMeditationDuration',
              '/openview/share',
              '/openview/playNextMeditation',
              '/redeemFreeMonth/:code',
            ]}
            component={DeepLinks}
          />
          <Redirect exact from="/promotions" to="/account" />
          <Redirect exact from="/gifts" to="/account" />
          <Redirect
            exact
            from="/payments"
            to={{ pathname: '/account', search: location.search }}
          />
          <Redirect exact from="/subscription" to="/checkout" />
          <Redirect to="/" />
        </Switch>
        <Lightrail />
        <Player />
        <SelfTimerModal />
        <OnboardingModal />
        <GiveFreeMonthModal />
        <ShareLinkModal />
        <ShareMonthWarningModal />
        <CourseActionsModal />
        <CourseNotesModal />
        <NewIntroPackModal />
        <UpdatePaymentCardModal />
        <ResetContentModal />
        <ResetIntroCoursesConfirmModal />
        <ResetAllContentConfirmModal />
        <ResetCourseConfirmModal />
        <UnlockContentModal />
        <NewSubscriptionModal />
        <Dialog />
      </Content>
    </Container>
  );
};

export default Main;
