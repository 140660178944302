import { useMemo, useCallback, useRef } from 'react';
import { CourseType } from 'models/Course';
import useFavorites from 'graphql/hooks/useFavorites';
import useCourseActions from 'graphql/hooks/useCourseActions';

const useConnect = () => {
  const listRef = useRef<HTMLDivElement | null>(null);
  const { favorites, loading } = useFavorites();
  const { play } = useCourseActions();

  const sortedFavorites = useMemo(
    () =>
      favorites
        ? [...favorites].sort((a, b) => {
            if (
              a.courseType === CourseType.MEDITATION &&
              b.courseType === CourseType.LESSON
            ) {
              return -1;
            }
            if (
              a.courseType === CourseType.LESSON &&
              b.courseType === CourseType.MEDITATION
            ) {
              return 1;
            }

            return a.courseNumber - b.courseNumber;
          })
        : undefined,
    [favorites],
  );

  const handleControlPress = useCallback(
    (type: 'back' | 'forward') => {
      listRef?.current?.scrollTo({
        behavior: 'smooth',
        left:
          type === 'back'
            ? listRef.current.scrollLeft - 100
            : listRef.current.scrollLeft + 100,
      });
    },
    [listRef],
  );

  const loadTrack = useCallback(
    async (courseId: string) => {
      await play(courseId);
    },
    [play],
  );

  return {
    favorites: sortedFavorites,
    handleControlPress,
    isLoading: loading,
    listRef,
    loadTrack,
  };
};

export default useConnect;

export type UseConnect = ReturnType<typeof useConnect>;
