import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { parse } from 'query-string';
import {
  initializePurchaseDialog,
  openPurchaseDialog,
} from 'services/lightrail';
import useShopperToken from 'graphql/hooks/useShopperToken';

const useConnect = () => {
  const { shopperToken } = useShopperToken();
  const { search } = useLocation();
  const { 'open-gift': openGift } = parse(search);

  const [isLightrailReady, setLightrailReady] = useState(false);
  const [isLightrailLoading, setLightrailLoading] = useState(false);

  useEffect(() => {
    if (!isLightrailReady && shopperToken) {
      const initLightrail = async () => {
        try {
          setLightrailLoading(true);
          await initializePurchaseDialog(shopperToken);
          setLightrailReady(true);

          if (openGift) {
            openPurchaseDialog();
          }
        } catch (error) {
          setLightrailReady(false);
        } finally {
          setLightrailLoading(false);
        }
      };

      initLightrail();
    }
  }, [isLightrailReady, openGift, shopperToken]);

  return {
    isLightrailReady,
    isLightrailLoading,
  };
};

export default useConnect;
