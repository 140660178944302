import React, { FC } from 'react';
import { ChevronRight, Container } from './styles';
import { Props } from './types';

const ChevronButton: FC<Props> = ({ title, children, ...rest }) => (
  <Container {...rest}>
    {title || children}
    <ChevronRight />
  </Container>
);

export default ChevronButton;
