import { useEffect, useMemo, useCallback } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router';
import { kebabCase } from 'lodash';
import { apolloClient } from 'services/api';
import { PackType } from 'models/Pack';
import { GetSettings } from 'graphql/generated/GetSettings';
import { PlayerAutoplayType } from 'graphql/generated/globalTypes';
import { GET_SETTINGS } from 'graphql/queries';
import useModal from 'graphql/hooks/useModal';
import useSettings from 'graphql/hooks/useSettings';
import useFavorites from 'graphql/hooks/useFavorites';
import usePacks from 'graphql/hooks/usePacks';
import useCourseActions from 'graphql/hooks/useCourseActions';

const useConnect = () => {
  const {
    loading: isLoadingSettings,
    settings: { packsAutoPlay },
  } = useSettings();
  const { toggle: toggleFavorite } = useFavorites();
  const {
    openCourseActions,
    openCourseNotes,
    openResetCourseConfirm,
    openShareLink,
  } = useModal();
  const { packs, loading: isLoadingPacks } = usePacks();
  const { play, toggleAutoPlay } = useCourseActions();
  const match = useRouteMatch<{ pack?: string; subpack?: string }>();
  const location = useLocation();
  const { push, replace } = useHistory();

  const currentBaseRoute = match.url;
  const packParam = match.params.pack;
  const subpackParam = match.params.subpack;
  const currentUrl = location.pathname;

  const { defaultPackTitle, theoryPacks } = useMemo(() => {
    const theoryPacksList = packs.filter(
      (pack) => pack.type === PackType.LESSON,
    );
    return {
      defaultPackTitle: theoryPacksList?.[0]?.title || '',
      theoryPacks: theoryPacksList,
    };
  }, [packs]);

  useEffect(() => {
    if (!packParam && defaultPackTitle) {
      apolloClient
        .query<GetSettings>({
          query: GET_SETTINGS,
          fetchPolicy: 'network-only',
        })
        .catch(() => true);

      replace(`${currentBaseRoute}/${kebabCase(defaultPackTitle)}`);
    }
  }, [currentBaseRoute, defaultPackTitle, packParam, replace]);

  const {
    backUrl,
    filteredPacks,
    selectedPackId,
    selectedPackCourses,
    selectedPackSubpacks,
  } = useMemo(() => {
    const selectedPack = theoryPacks.find(({ title }) =>
      subpackParam
        ? kebabCase(title) === subpackParam
        : kebabCase(title) === packParam,
    );
    const filteredPacksList = subpackParam
      ? theoryPacks.filter((p) => p.parentPackId === selectedPack?.parentPackId)
      : theoryPacks.filter((p) => p.category !== 'sub_pack');
    const subpacks =
      !!selectedPack && selectedPack.category === 'master_pack'
        ? theoryPacks.filter(
            ({ category, parentPackId }) =>
              category === 'sub_pack' &&
              parentPackId &&
              parentPackId === selectedPack.id,
          )
        : [];

    return {
      backUrl: subpackParam
        ? `${selectedPack?.urlBase}/${packParam}`
        : undefined,
      filteredPacks: filteredPacksList,
      selectedPackId: selectedPack?.id,
      selectedPackCourses: selectedPack?.courses,
      selectedPackSubpacks: subpacks,
    };
  }, [packParam, subpackParam, theoryPacks]);

  const selectedPackAutoPlay = useMemo(
    () => (selectedPackId ? packsAutoPlay.includes(selectedPackId) : false),
    [selectedPackId, packsAutoPlay],
  );

  const loadTrack = useCallback(
    async (courseId: string) => {
      await play(courseId, PlayerAutoplayType.PACK);
    },
    [play],
  );

  const togglePackAutoPlay = useCallback(
    async (packId: string) => {
      await toggleAutoPlay(PlayerAutoplayType.PACK, packId);
    },
    [toggleAutoPlay],
  );

  return {
    backUrl,
    currentUrl,
    isLoading: isLoadingSettings || isLoadingPacks,
    loadTrack,
    openCourseActions,
    openCourseNotes,
    openResetCourseConfirm,
    openShareLink,
    packParam,
    packs: filteredPacks,
    push,
    selectedPackAutoPlay,
    selectedPackCourses,
    selectedPackId,
    selectedPackSubpacks,
    subpackParam,
    toggleFavorite,
    togglePackAutoPlay,
  };
};

export default useConnect;

export type UseConnectReturnType = ReturnType<typeof useConnect>;
