import React, { FC, useCallback } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { toggleHelpScout } from 'services/helpscout';
import { SubscriptionStatus, RouteParams, RouteState } from './types';
import {
  Info,
  Button,
  Container,
  Email,
  Confirmation,
  Content,
  StoreButton,
} from './styles';

const SubscriptionSuccess: FC = () => {
  const { goBack } = useHistory();
  const { state } = useLocation<RouteState>();
  const { status } = useParams<RouteParams>();

  const onGoBack = useCallback(() => goBack(), [goBack]);

  if (status === SubscriptionStatus.success) {
    return (
      <Container>
        <Confirmation message="Thank you for subscribing to Waking Up!" />
        <Content>
          <Email>Account: {state?.user?.email}</Email>
          <StoreButton type="ios" />
          <StoreButton type="android" />
          <Button to="/" replace>
            Sign In
          </Button>
          <Button onClick={toggleHelpScout}>Contact Support</Button>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Info>Sorry! We couldn't finish your request. Something went wrong</Info>
      <Button onClick={onGoBack}>Try again</Button>
    </Container>
  );
};

export default SubscriptionSuccess;
